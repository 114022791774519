import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { Directive, ContentChild, ContentChildren, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { ComplexBase, setValue, Template, ArrayBase, ComponentBase, ComponentMixins } from '@syncfusion/ej2-angular-base';
import { Gantt, Filter, Selection, Sort, Reorder, Resize, Edit, DayMarkers, Toolbar, ContextMenu, ExcelExport, RowDD, ColumnMenu, PdfExport, VirtualScroll, CriticalPath, UndoRedo } from '@syncfusion/ej2-gantt';
const _c0 = ["template"];
const _c1 = ["headerTemplate"];
const _c2 = ["parentTaskbarTemplate"];
const _c3 = ["timelineTemplate"];
const _c4 = ["milestoneTemplate"];
const _c5 = ["taskbarTemplate"];
const _c6 = ["labelSettingsRightLabel"];
const _c7 = ["labelSettingsLeftLabel"];
const _c8 = ["labelSettingsTaskLabel"];
const _c9 = ["tooltipSettingsTaskbar"];
const _c10 = ["tooltipSettingsBaseline"];
const _c11 = ["tooltipSettingsConnectorLine"];
const _c12 = ["tooltipSettingsEditing"];
export * from '@syncfusion/ej2-gantt';
import { CommonModule } from '@angular/common';
let input$6 = ['allowEditing', 'allowFiltering', 'allowReordering', 'allowResizing', 'allowSorting', 'clipMode', 'customAttributes', 'disableHtmlEncode', 'displayAsCheckBox', 'edit', 'editType', 'field', 'filter', 'format', 'formatter', 'headerTemplate', 'headerText', 'headerTextAlign', 'hideAtMedia', 'isPrimaryKey', 'maxWidth', 'minWidth', 'sortComparer', 'template', 'textAlign', 'type', 'validationRules', 'valueAccessor', 'visible', 'width'];
let outputs$7 = [];
/**
 * `e-column` directive represent a column of the Angular Gantt.
 * It must be contained in a Gantt component(`ejs-gantt`).
 * ```html
 * <ejs-gantt [dataSource]='data' allowSelection='true' allowSorting='true'>
 *   <e-columns>
 *    <e-column field='ID' width='150'></e-column>
 *    <e-column field='taskName' headerText='Task Name' width='200'></e-column>
 *   </e-columns>
 * </ejs-gantt>
 * ```
 */
class ColumnDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$7);
    this.directivePropList = input$6;
  }
}
ColumnDirective.ɵfac = function ColumnDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ColumnDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
ColumnDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ColumnDirective,
  selectors: [["e-column"]],
  contentQueries: function ColumnDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerTemplate = _t.first);
    }
  },
  inputs: {
    allowEditing: "allowEditing",
    allowFiltering: "allowFiltering",
    allowReordering: "allowReordering",
    allowResizing: "allowResizing",
    allowSorting: "allowSorting",
    clipMode: "clipMode",
    customAttributes: "customAttributes",
    disableHtmlEncode: "disableHtmlEncode",
    displayAsCheckBox: "displayAsCheckBox",
    edit: "edit",
    editType: "editType",
    field: "field",
    filter: "filter",
    format: "format",
    formatter: "formatter",
    headerTemplate: "headerTemplate",
    headerText: "headerText",
    headerTextAlign: "headerTextAlign",
    hideAtMedia: "hideAtMedia",
    isPrimaryKey: "isPrimaryKey",
    maxWidth: "maxWidth",
    minWidth: "minWidth",
    sortComparer: "sortComparer",
    template: "template",
    textAlign: "textAlign",
    type: "type",
    validationRules: "validationRules",
    valueAccessor: "valueAccessor",
    visible: "visible",
    width: "width"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
__decorate([Template()], ColumnDirective.prototype, "template", void 0);
__decorate([Template()], ColumnDirective.prototype, "headerTemplate", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColumnDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-gantt>e-columns>e-column',
      inputs: input$6,
      outputs: outputs$7,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    template: [{
      type: ContentChild,
      args: ['template']
    }],
    headerTemplate: [{
      type: ContentChild,
      args: ['headerTemplate']
    }]
  });
})();
/**
 * Column Array Directive
 * @private
 */
class ColumnsDirective extends ArrayBase {
  constructor() {
    super('columns');
  }
}
ColumnsDirective.ɵfac = function ColumnsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ColumnsDirective)();
};
ColumnsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ColumnsDirective,
  selectors: [["e-columns"]],
  contentQueries: function ColumnsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ColumnDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColumnsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-gantt>e-columns',
      queries: {
        children: new ContentChildren(ColumnDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input$5 = ['additionalParams', 'fields', 'headerText', 'type'];
let outputs$6 = [];
/**
 * `e-add-dialog-field` directive represent a add dialog fields collection in Gantt task add dialog.
 * It must be contained in a Gantt component(`ejs-gantt`).
 * ```html
 * <ejs-gantt [dataSource]='data' allowSelection='true' allowSorting='true'>
 *   <e-add-dialog-fields>
 *     <e-add-dialog-field type='General' headerText='General'></e-add-dialog-field>
 *     <e-add-dialog-field type='Dependency' headerText='Dependency'></e-add-dialog-field>
 *   </e-add-dialog-fields>
 * </ejs-gantt>
 * ```
 */
class AddDialogFieldDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$6);
    this.directivePropList = input$5;
  }
}
AddDialogFieldDirective.ɵfac = function AddDialogFieldDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AddDialogFieldDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
AddDialogFieldDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AddDialogFieldDirective,
  selectors: [["e-add-dialog-field"]],
  inputs: {
    additionalParams: "additionalParams",
    fields: "fields",
    headerText: "headerText",
    type: "type"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AddDialogFieldDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-gantt>e-add-dialog-fields>e-add-dialog-field',
      inputs: input$5,
      outputs: outputs$6,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * AddDialogField Array Directive
 * @private
 */
class AddDialogFieldsDirective extends ArrayBase {
  constructor() {
    super('adddialogfields');
  }
}
AddDialogFieldsDirective.ɵfac = function AddDialogFieldsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AddDialogFieldsDirective)();
};
AddDialogFieldsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AddDialogFieldsDirective,
  selectors: [["e-add-dialog-fields"]],
  contentQueries: function AddDialogFieldsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, AddDialogFieldDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AddDialogFieldsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-gantt>e-add-dialog-fields',
      queries: {
        children: new ContentChildren(AddDialogFieldDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input$4 = ['additionalParams', 'fields', 'headerText', 'type'];
let outputs$5 = [];
/**
 * `e-edit-dialog-field` directive represent a edit dialog fields collection in Gantt task add dialog.
 * It must be contained in a Gantt component(`ejs-gantt`).
 * ```html
 * <ejs-gantt [dataSource]='data' allowSelection='true' allowSorting='true'>
 *   <e-edit-dialog-fields>
 *     <e-edit-dialog-field type='General' headerText='General'></e-edit-dialog-field>
 *     <e-edit-dialog-field type='Dependency' headerText='Dependency'></e-edit-dialog-field>
 *   </e-edit-dialog-fields>
 * </ejs-gantt>
 * ```
 */
class EditDialogFieldDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$5);
    this.directivePropList = input$4;
  }
}
EditDialogFieldDirective.ɵfac = function EditDialogFieldDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || EditDialogFieldDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
EditDialogFieldDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: EditDialogFieldDirective,
  selectors: [["e-edit-dialog-field"]],
  inputs: {
    additionalParams: "additionalParams",
    fields: "fields",
    headerText: "headerText",
    type: "type"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EditDialogFieldDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-gantt>e-edit-dialog-fields>e-edit-dialog-field',
      inputs: input$4,
      outputs: outputs$5,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * EditDialogField Array Directive
 * @private
 */
class EditDialogFieldsDirective extends ArrayBase {
  constructor() {
    super('editdialogfields');
  }
}
EditDialogFieldsDirective.ɵfac = function EditDialogFieldsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || EditDialogFieldsDirective)();
};
EditDialogFieldsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: EditDialogFieldsDirective,
  selectors: [["e-edit-dialog-fields"]],
  contentQueries: function EditDialogFieldsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, EditDialogFieldDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EditDialogFieldsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-gantt>e-edit-dialog-fields',
      queries: {
        children: new ContentChildren(EditDialogFieldDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input$3 = ['from', 'to'];
let outputs$4 = [];
/**
 * `e-day-working-time-collection` directive represent a working time ranges in a day.
 * It must be contained in a Gantt component(`ejs-gantt`).
 * ```html
 * <ejs-gantt [dataSource]='data' allowSelection='true' allowSorting='true'>
 *   <e-day-working-time-collection>
 *     <e-day-working-time from='8' to='12'></e-day-working-time>
 *     <e-day-working-time from='13' to='17'></e-day-working-time>
 *   </e-day-working-time-collection>
 * </ejs-gantt>
 * ```
 */
class DayWorkingTimeDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$4);
    this.directivePropList = input$3;
  }
}
DayWorkingTimeDirective.ɵfac = function DayWorkingTimeDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DayWorkingTimeDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
DayWorkingTimeDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: DayWorkingTimeDirective,
  selectors: [["e-day-working-time"]],
  inputs: {
    from: "from",
    to: "to"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DayWorkingTimeDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-gantt>e-day-working-time-collection>e-day-working-time',
      inputs: input$3,
      outputs: outputs$4,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * DayWorkingTime Array Directive
 * @private
 */
class DayWorkingTimeCollectionDirective extends ArrayBase {
  constructor() {
    super('dayworkingtime');
  }
}
DayWorkingTimeCollectionDirective.ɵfac = function DayWorkingTimeCollectionDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DayWorkingTimeCollectionDirective)();
};
DayWorkingTimeCollectionDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: DayWorkingTimeCollectionDirective,
  selectors: [["e-day-working-time-collection"]],
  contentQueries: function DayWorkingTimeCollectionDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, DayWorkingTimeDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DayWorkingTimeCollectionDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-gantt>e-day-working-time-collection',
      queries: {
        children: new ContentChildren(DayWorkingTimeDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input$2 = ['dayOfWeek', 'timeRange'];
let outputs$3 = [];
/**
 * `e-week-working-times` directive represent a working time ranges in a day.
 * It must be contained in a Gantt component(`ejs-gantt`).
 * ```html
 * <ejs-gantt [dataSource]='data' allowSelection='true' allowSorting='true'>
 *   <e-week-working-times>
 *     <e-week-working-time dayOfWeek='Monday'></e-week-working-time>
 *     <e-week-working-time dayOfWeek='Monday'></e-week-working-time>
 *   </e-week-working-times>
 * </ejs-gantt>
 * ```
 */
class WeekWorkingTimeDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$3);
    this.directivePropList = input$2;
  }
}
WeekWorkingTimeDirective.ɵfac = function WeekWorkingTimeDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || WeekWorkingTimeDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
WeekWorkingTimeDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: WeekWorkingTimeDirective,
  selectors: [["e-week-working-time"]],
  inputs: {
    dayOfWeek: "dayOfWeek",
    timeRange: "timeRange"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WeekWorkingTimeDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-gantt>e-week-working-times>e-week-working-time',
      inputs: input$2,
      outputs: outputs$3,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * WeekWorkingTime Array Directive
 * @private
 */
class WeekWorkingTimesDirective extends ArrayBase {
  constructor() {
    super('weekworkingtime');
  }
}
WeekWorkingTimesDirective.ɵfac = function WeekWorkingTimesDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || WeekWorkingTimesDirective)();
};
WeekWorkingTimesDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: WeekWorkingTimesDirective,
  selectors: [["e-week-working-times"]],
  contentQueries: function WeekWorkingTimesDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, WeekWorkingTimeDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WeekWorkingTimesDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-gantt>e-week-working-times',
      queries: {
        children: new ContentChildren(WeekWorkingTimeDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input$1 = ['cssClass', 'from', 'label', 'to'];
let outputs$2 = [];
/**
 * `e-holidays` directive represent a holidays collection in Gantt.
 * It must be contained in a Gantt component(`ejs-gantt`).
 * ```html
 * <ejs-gantt [dataSource]='data' allowSelection='true' allowSorting='true'>
 *   <e-holidays>
 *     <e-holiday from='02/20/2018' label='Holiday 1'></e-holiday>
 *     <e-holiday from='05/15/2018' label='Holiday 2'></e-holiday>
 *   </e-holidays>
 * </ejs-gantt>
 * ```
 */
class HolidayDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$2);
    this.directivePropList = input$1;
  }
}
HolidayDirective.ɵfac = function HolidayDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || HolidayDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
HolidayDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: HolidayDirective,
  selectors: [["e-holidays"]],
  inputs: {
    cssClass: "cssClass",
    from: "from",
    label: "label",
    to: "to"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HolidayDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-gantt>e-holidays>e-holidays',
      inputs: input$1,
      outputs: outputs$2,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * Holiday Array Directive
 * @private
 */
class HolidaysDirective extends ArrayBase {
  constructor() {
    super('holidays');
  }
}
HolidaysDirective.ɵfac = function HolidaysDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || HolidaysDirective)();
};
HolidaysDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: HolidaysDirective,
  selectors: [["e-holidays"]],
  contentQueries: function HolidaysDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, HolidayDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HolidaysDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-gantt>e-holidays',
      queries: {
        children: new ContentChildren(HolidayDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input = ['cssClass', 'day', 'label'];
let outputs$1 = [];
/**
 * `e-event-markers` directive represent a event marker collection in Gantt.
 * It must be contained in a Gantt component(`ejs-gantt`).
 * ```html
 * <ejs-gantt [dataSource]='data' allowSelection='true' allowSorting='true'>
 *   <e-event-markers>
 *     <e-event-marker day='02/10/2018' label='Project Starts'></e-event-marker>
 *   </e-event-markers>
 * </ejs-gantt>
 * ```
 */
class EventMarkerDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$1);
    this.directivePropList = input;
  }
}
EventMarkerDirective.ɵfac = function EventMarkerDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || EventMarkerDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
EventMarkerDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: EventMarkerDirective,
  selectors: [["e-event-marker"]],
  inputs: {
    cssClass: "cssClass",
    day: "day",
    label: "label"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EventMarkerDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-gantt>e-event-markers>e-event-marker',
      inputs: input,
      outputs: outputs$1,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * EventMarker Array Directive
 * @private
 */
class EventMarkersDirective extends ArrayBase {
  constructor() {
    super('eventmarkers');
  }
}
EventMarkersDirective.ɵfac = function EventMarkersDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || EventMarkersDirective)();
};
EventMarkersDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: EventMarkersDirective,
  selectors: [["e-event-markers"]],
  contentQueries: function EventMarkersDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, EventMarkerDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EventMarkersDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-gantt>e-event-markers',
      queries: {
        children: new ContentChildren(EventMarkerDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs = ['UpdateOffsetOnTaskbarEdit', 'addDialogFields', 'allowExcelExport', 'allowFiltering', 'allowKeyboard', 'allowParentDependency', 'allowPdfExport', 'allowReordering', 'allowResizing', 'allowRowDragAndDrop', 'allowSelection', 'allowSorting', 'allowTaskbarDragAndDrop', 'allowTaskbarOverlap', 'allowUnscheduledTasks', 'autoCalculateDateScheduling', 'autoFocusTasks', 'baselineColor', 'collapseAllParentTasks', 'columnMenuItems', 'columns', 'connectorLineBackground', 'connectorLineWidth', 'contextMenuItems', 'dataSource', 'dateFormat', 'dayWorkingTime', 'disableHtmlEncode', 'durationUnit', 'editDialogFields', 'editSettings', 'enableAdaptiveUI', 'enableContextMenu', 'enableCriticalPath', 'enableHtmlSanitizer', 'enableImmutableMode', 'enableMultiTaskbar', 'enablePersistence', 'enablePredecessorValidation', 'enableRtl', 'enableTimelineVirtualization', 'enableUndoRedo', 'enableVirtualMaskRow', 'enableVirtualization', 'eventMarkers', 'filterSettings', 'gridLines', 'height', 'highlightWeekends', 'holidays', 'includeWeekend', 'labelSettings', 'loadChildOnDemand', 'loadingIndicator', 'locale', 'milestoneTemplate', 'parentTaskbarTemplate', 'projectEndDate', 'projectStartDate', 'query', 'readOnly', 'renderBaseline', 'resourceFields', 'resourceIDMapping', 'resourceNameMapping', 'resources', 'rowHeight', 'searchSettings', 'segmentData', 'selectedRowIndex', 'selectionSettings', 'showColumnMenu', 'showInlineNotes', 'showOverAllocation', 'sortSettings', 'splitterSettings', 'taskFields', 'taskMode', 'taskType', 'taskbarHeight', 'taskbarTemplate', 'timelineSettings', 'timelineTemplate', 'timezone', 'toolbar', 'tooltipSettings', 'treeColumnIndex', 'undoRedoActions', 'undoRedoStepsCount', 'updateOffsetOnTaskbarEdit', 'validateManualTasksOnLinking', 'viewType', 'weekWorkingTime', 'width', 'workUnit', 'workWeek', 'zoomingLevels'];
const outputs = ['actionBegin', 'actionComplete', 'actionFailure', 'beforeExcelExport', 'beforePdfExport', 'beforeTooltipRender', 'cellDeselected', 'cellDeselecting', 'cellEdit', 'cellSelected', 'cellSelecting', 'collapsed', 'collapsing', 'columnDrag', 'columnDragStart', 'columnDrop', 'columnMenuClick', 'columnMenuOpen', 'contextMenuClick', 'contextMenuOpen', 'created', 'dataBound', 'dataStateChange', 'destroyed', 'endEdit', 'excelExportComplete', 'excelHeaderQueryCellInfo', 'excelQueryCellInfo', 'expanded', 'expanding', 'headerCellInfo', 'load', 'onMouseMove', 'onTaskbarClick', 'pdfColumnHeaderQueryCellInfo', 'pdfExportComplete', 'pdfQueryCellInfo', 'pdfQueryTaskbarInfo', 'pdfQueryTimelineCellInfo', 'queryCellInfo', 'queryTaskbarInfo', 'recordDoubleClick', 'resizeStart', 'resizeStop', 'resizing', 'rowDataBound', 'rowDeselected', 'rowDeselecting', 'rowDrag', 'rowDragStart', 'rowDragStartHelper', 'rowDrop', 'rowSelected', 'rowSelecting', 'splitterResizeStart', 'splitterResized', 'splitterResizing', 'taskbarEdited', 'taskbarEditing', 'toolbarClick', 'dataSourceChange'];
const twoWays = ['dataSource'];
/**
 * `ejs-gantt` represents the Angular Gantt Component.
 * ```html
 * <ejs-gantt [dataSource]='data' allowSelection='true' allowSorting='true'></ejs-gantt>
 * ```
 */
let GanttComponent = class GanttComponent extends Gantt {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['columns', 'addDialogFields', 'editDialogFields', 'dayWorkingTime', 'weekWorkingTime', 'holidays', 'eventMarkers'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    try {
      let mod = this.injector.get('GanttFilter');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GanttSelection');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GanttSort');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GanttReorder');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GanttResize');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GanttEdit');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GanttDayMarkers');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GanttToolbar');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GanttContextMenu');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GanttExcelExport');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GanttRowDD');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GanttColumnMenu');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GanttPdfExport');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GanttVirtualScroll');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GanttCriticalPath');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GanttUndoRedo');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    this.registerEvents(outputs);
    this.addTwoWay.call(this, twoWays);
    setValue('currentInstance', this, this.viewContainerRef);
    this.context = new ComponentBase();
  }
  ngOnInit() {
    this.context.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.context.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.context.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childColumns;
    if (this.childAddDialogFields) {
      this.tagObjects[1].instance = this.childAddDialogFields;
    }
    if (this.childEditDialogFields) {
      this.tagObjects[2].instance = this.childEditDialogFields;
    }
    if (this.childDayWorkingTime) {
      this.tagObjects[3].instance = this.childDayWorkingTime;
    }
    if (this.childWeekWorkingTime) {
      this.tagObjects[4].instance = this.childWeekWorkingTime;
    }
    if (this.childHolidays) {
      this.tagObjects[5].instance = this.childHolidays;
    }
    if (this.childEventMarkers) {
      this.tagObjects[6].instance = this.childEventMarkers;
    }
    this.context.ngAfterContentChecked(this);
  }
};
GanttComponent.ɵfac = function GanttComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || GanttComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
GanttComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: GanttComponent,
  selectors: [["ejs-gantt"]],
  contentQueries: function GanttComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c2, 5);
      i0.ɵɵcontentQuery(dirIndex, _c3, 5);
      i0.ɵɵcontentQuery(dirIndex, _c4, 5);
      i0.ɵɵcontentQuery(dirIndex, _c5, 5);
      i0.ɵɵcontentQuery(dirIndex, _c6, 5);
      i0.ɵɵcontentQuery(dirIndex, _c7, 5);
      i0.ɵɵcontentQuery(dirIndex, _c8, 5);
      i0.ɵɵcontentQuery(dirIndex, _c9, 5);
      i0.ɵɵcontentQuery(dirIndex, _c10, 5);
      i0.ɵɵcontentQuery(dirIndex, _c11, 5);
      i0.ɵɵcontentQuery(dirIndex, _c12, 5);
      i0.ɵɵcontentQuery(dirIndex, ColumnsDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, AddDialogFieldsDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, EditDialogFieldsDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, DayWorkingTimeCollectionDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, WeekWorkingTimesDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, HolidaysDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, EventMarkersDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.parentTaskbarTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.timelineTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.milestoneTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.taskbarTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.labelSettings_rightLabel = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.labelSettings_leftLabel = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.labelSettings_taskLabel = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tooltipSettings_taskbar = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tooltipSettings_baseline = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tooltipSettings_connectorLine = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tooltipSettings_editing = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childColumns = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childAddDialogFields = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childEditDialogFields = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childDayWorkingTime = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childWeekWorkingTime = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childHolidays = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childEventMarkers = _t.first);
    }
  },
  inputs: {
    UpdateOffsetOnTaskbarEdit: "UpdateOffsetOnTaskbarEdit",
    addDialogFields: "addDialogFields",
    allowExcelExport: "allowExcelExport",
    allowFiltering: "allowFiltering",
    allowKeyboard: "allowKeyboard",
    allowParentDependency: "allowParentDependency",
    allowPdfExport: "allowPdfExport",
    allowReordering: "allowReordering",
    allowResizing: "allowResizing",
    allowRowDragAndDrop: "allowRowDragAndDrop",
    allowSelection: "allowSelection",
    allowSorting: "allowSorting",
    allowTaskbarDragAndDrop: "allowTaskbarDragAndDrop",
    allowTaskbarOverlap: "allowTaskbarOverlap",
    allowUnscheduledTasks: "allowUnscheduledTasks",
    autoCalculateDateScheduling: "autoCalculateDateScheduling",
    autoFocusTasks: "autoFocusTasks",
    baselineColor: "baselineColor",
    collapseAllParentTasks: "collapseAllParentTasks",
    columnMenuItems: "columnMenuItems",
    columns: "columns",
    connectorLineBackground: "connectorLineBackground",
    connectorLineWidth: "connectorLineWidth",
    contextMenuItems: "contextMenuItems",
    dataSource: "dataSource",
    dateFormat: "dateFormat",
    dayWorkingTime: "dayWorkingTime",
    disableHtmlEncode: "disableHtmlEncode",
    durationUnit: "durationUnit",
    editDialogFields: "editDialogFields",
    editSettings: "editSettings",
    enableAdaptiveUI: "enableAdaptiveUI",
    enableContextMenu: "enableContextMenu",
    enableCriticalPath: "enableCriticalPath",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enableImmutableMode: "enableImmutableMode",
    enableMultiTaskbar: "enableMultiTaskbar",
    enablePersistence: "enablePersistence",
    enablePredecessorValidation: "enablePredecessorValidation",
    enableRtl: "enableRtl",
    enableTimelineVirtualization: "enableTimelineVirtualization",
    enableUndoRedo: "enableUndoRedo",
    enableVirtualMaskRow: "enableVirtualMaskRow",
    enableVirtualization: "enableVirtualization",
    eventMarkers: "eventMarkers",
    filterSettings: "filterSettings",
    gridLines: "gridLines",
    height: "height",
    highlightWeekends: "highlightWeekends",
    holidays: "holidays",
    includeWeekend: "includeWeekend",
    labelSettings: "labelSettings",
    loadChildOnDemand: "loadChildOnDemand",
    loadingIndicator: "loadingIndicator",
    locale: "locale",
    milestoneTemplate: "milestoneTemplate",
    parentTaskbarTemplate: "parentTaskbarTemplate",
    projectEndDate: "projectEndDate",
    projectStartDate: "projectStartDate",
    query: "query",
    readOnly: "readOnly",
    renderBaseline: "renderBaseline",
    resourceFields: "resourceFields",
    resourceIDMapping: "resourceIDMapping",
    resourceNameMapping: "resourceNameMapping",
    resources: "resources",
    rowHeight: "rowHeight",
    searchSettings: "searchSettings",
    segmentData: "segmentData",
    selectedRowIndex: "selectedRowIndex",
    selectionSettings: "selectionSettings",
    showColumnMenu: "showColumnMenu",
    showInlineNotes: "showInlineNotes",
    showOverAllocation: "showOverAllocation",
    sortSettings: "sortSettings",
    splitterSettings: "splitterSettings",
    taskFields: "taskFields",
    taskMode: "taskMode",
    taskType: "taskType",
    taskbarHeight: "taskbarHeight",
    taskbarTemplate: "taskbarTemplate",
    timelineSettings: "timelineSettings",
    timelineTemplate: "timelineTemplate",
    timezone: "timezone",
    toolbar: "toolbar",
    tooltipSettings: "tooltipSettings",
    treeColumnIndex: "treeColumnIndex",
    undoRedoActions: "undoRedoActions",
    undoRedoStepsCount: "undoRedoStepsCount",
    updateOffsetOnTaskbarEdit: "updateOffsetOnTaskbarEdit",
    validateManualTasksOnLinking: "validateManualTasksOnLinking",
    viewType: "viewType",
    weekWorkingTime: "weekWorkingTime",
    width: "width",
    workUnit: "workUnit",
    workWeek: "workWeek",
    zoomingLevels: "zoomingLevels"
  },
  outputs: {
    actionBegin: "actionBegin",
    actionComplete: "actionComplete",
    actionFailure: "actionFailure",
    beforeExcelExport: "beforeExcelExport",
    beforePdfExport: "beforePdfExport",
    beforeTooltipRender: "beforeTooltipRender",
    cellDeselected: "cellDeselected",
    cellDeselecting: "cellDeselecting",
    cellEdit: "cellEdit",
    cellSelected: "cellSelected",
    cellSelecting: "cellSelecting",
    collapsed: "collapsed",
    collapsing: "collapsing",
    columnDrag: "columnDrag",
    columnDragStart: "columnDragStart",
    columnDrop: "columnDrop",
    columnMenuClick: "columnMenuClick",
    columnMenuOpen: "columnMenuOpen",
    contextMenuClick: "contextMenuClick",
    contextMenuOpen: "contextMenuOpen",
    created: "created",
    dataBound: "dataBound",
    dataStateChange: "dataStateChange",
    destroyed: "destroyed",
    endEdit: "endEdit",
    excelExportComplete: "excelExportComplete",
    excelHeaderQueryCellInfo: "excelHeaderQueryCellInfo",
    excelQueryCellInfo: "excelQueryCellInfo",
    expanded: "expanded",
    expanding: "expanding",
    headerCellInfo: "headerCellInfo",
    load: "load",
    onMouseMove: "onMouseMove",
    onTaskbarClick: "onTaskbarClick",
    pdfColumnHeaderQueryCellInfo: "pdfColumnHeaderQueryCellInfo",
    pdfExportComplete: "pdfExportComplete",
    pdfQueryCellInfo: "pdfQueryCellInfo",
    pdfQueryTaskbarInfo: "pdfQueryTaskbarInfo",
    pdfQueryTimelineCellInfo: "pdfQueryTimelineCellInfo",
    queryCellInfo: "queryCellInfo",
    queryTaskbarInfo: "queryTaskbarInfo",
    recordDoubleClick: "recordDoubleClick",
    resizeStart: "resizeStart",
    resizeStop: "resizeStop",
    resizing: "resizing",
    rowDataBound: "rowDataBound",
    rowDeselected: "rowDeselected",
    rowDeselecting: "rowDeselecting",
    rowDrag: "rowDrag",
    rowDragStart: "rowDragStart",
    rowDragStartHelper: "rowDragStartHelper",
    rowDrop: "rowDrop",
    rowSelected: "rowSelected",
    rowSelecting: "rowSelecting",
    splitterResizeStart: "splitterResizeStart",
    splitterResized: "splitterResized",
    splitterResizing: "splitterResizing",
    taskbarEdited: "taskbarEdited",
    taskbarEditing: "taskbarEditing",
    toolbarClick: "toolbarClick",
    dataSourceChange: "dataSourceChange"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function GanttComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], GanttComponent.prototype, "parentTaskbarTemplate", void 0);
__decorate([Template()], GanttComponent.prototype, "timelineTemplate", void 0);
__decorate([Template()], GanttComponent.prototype, "milestoneTemplate", void 0);
__decorate([Template()], GanttComponent.prototype, "taskbarTemplate", void 0);
__decorate([Template()], GanttComponent.prototype, "labelSettings_rightLabel", void 0);
__decorate([Template()], GanttComponent.prototype, "labelSettings_leftLabel", void 0);
__decorate([Template()], GanttComponent.prototype, "labelSettings_taskLabel", void 0);
__decorate([Template()], GanttComponent.prototype, "tooltipSettings_taskbar", void 0);
__decorate([Template()], GanttComponent.prototype, "tooltipSettings_baseline", void 0);
__decorate([Template()], GanttComponent.prototype, "tooltipSettings_connectorLine", void 0);
__decorate([Template()], GanttComponent.prototype, "tooltipSettings_editing", void 0);
GanttComponent = __decorate([ComponentMixins([ComponentBase])], GanttComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GanttComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-gantt',
      inputs: inputs,
      outputs: outputs,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childColumns: new ContentChild(ColumnsDirective),
        childAddDialogFields: new ContentChild(AddDialogFieldsDirective),
        childEditDialogFields: new ContentChild(EditDialogFieldsDirective),
        childDayWorkingTime: new ContentChild(DayWorkingTimeCollectionDirective),
        childWeekWorkingTime: new ContentChild(WeekWorkingTimesDirective),
        childHolidays: new ContentChild(HolidaysDirective),
        childEventMarkers: new ContentChild(EventMarkersDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, {
    parentTaskbarTemplate: [{
      type: ContentChild,
      args: ['parentTaskbarTemplate']
    }],
    timelineTemplate: [{
      type: ContentChild,
      args: ['timelineTemplate']
    }],
    milestoneTemplate: [{
      type: ContentChild,
      args: ['milestoneTemplate']
    }],
    taskbarTemplate: [{
      type: ContentChild,
      args: ['taskbarTemplate']
    }],
    labelSettings_rightLabel: [{
      type: ContentChild,
      args: ['labelSettingsRightLabel']
    }],
    labelSettings_leftLabel: [{
      type: ContentChild,
      args: ['labelSettingsLeftLabel']
    }],
    labelSettings_taskLabel: [{
      type: ContentChild,
      args: ['labelSettingsTaskLabel']
    }],
    tooltipSettings_taskbar: [{
      type: ContentChild,
      args: ['tooltipSettingsTaskbar']
    }],
    tooltipSettings_baseline: [{
      type: ContentChild,
      args: ['tooltipSettingsBaseline']
    }],
    tooltipSettings_connectorLine: [{
      type: ContentChild,
      args: ['tooltipSettingsConnectorLine']
    }],
    tooltipSettings_editing: [{
      type: ContentChild,
      args: ['tooltipSettingsEditing']
    }]
  });
})();

/**
 * NgModule definition for the Gantt component.
 */
class GanttModule {}
GanttModule.ɵfac = function GanttModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || GanttModule)();
};
GanttModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: GanttModule
});
GanttModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GanttModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [GanttComponent, ColumnDirective, ColumnsDirective, AddDialogFieldDirective, AddDialogFieldsDirective, EditDialogFieldDirective, EditDialogFieldsDirective, DayWorkingTimeDirective, DayWorkingTimeCollectionDirective, WeekWorkingTimeDirective, WeekWorkingTimesDirective, HolidayDirective, HolidaysDirective, EventMarkerDirective, EventMarkersDirective],
      exports: [GanttComponent, ColumnDirective, ColumnsDirective, AddDialogFieldDirective, AddDialogFieldsDirective, EditDialogFieldDirective, EditDialogFieldsDirective, DayWorkingTimeDirective, DayWorkingTimeCollectionDirective, WeekWorkingTimeDirective, WeekWorkingTimesDirective, HolidayDirective, HolidaysDirective, EventMarkerDirective, EventMarkersDirective]
    }]
  }], null, null);
})();
const FilterService = {
  provide: 'GanttFilter',
  useValue: Filter
};
const SelectionService = {
  provide: 'GanttSelection',
  useValue: Selection
};
const SortService = {
  provide: 'GanttSort',
  useValue: Sort
};
const ReorderService = {
  provide: 'GanttReorder',
  useValue: Reorder
};
const ResizeService = {
  provide: 'GanttResize',
  useValue: Resize
};
const EditService = {
  provide: 'GanttEdit',
  useValue: Edit
};
const DayMarkersService = {
  provide: 'GanttDayMarkers',
  useValue: DayMarkers
};
const ToolbarService = {
  provide: 'GanttToolbar',
  useValue: Toolbar
};
const ContextMenuService = {
  provide: 'GanttContextMenu',
  useValue: ContextMenu
};
const ExcelExportService = {
  provide: 'GanttExcelExport',
  useValue: ExcelExport
};
const RowDDService = {
  provide: 'GanttRowDD',
  useValue: RowDD
};
const ColumnMenuService = {
  provide: 'GanttColumnMenu',
  useValue: ColumnMenu
};
const PdfExportService = {
  provide: 'GanttPdfExport',
  useValue: PdfExport
};
const VirtualScrollService = {
  provide: 'GanttVirtualScroll',
  useValue: VirtualScroll
};
const CriticalPathService = {
  provide: 'GanttCriticalPath',
  useValue: CriticalPath
};
const UndoRedoService = {
  provide: 'GanttUndoRedo',
  useValue: UndoRedo
};
/**
 * NgModule definition for the Gantt component with providers.
 */
class GanttAllModule {}
GanttAllModule.ɵfac = function GanttAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || GanttAllModule)();
};
GanttAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: GanttAllModule
});
GanttAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [FilterService, SelectionService, SortService, ReorderService, ResizeService, EditService, DayMarkersService, ToolbarService, ContextMenuService, ExcelExportService, RowDDService, ColumnMenuService, PdfExportService, VirtualScrollService, CriticalPathService, UndoRedoService],
  imports: [[CommonModule, GanttModule], GanttModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GanttAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, GanttModule],
      exports: [GanttModule],
      providers: [FilterService, SelectionService, SortService, ReorderService, ResizeService, EditService, DayMarkersService, ToolbarService, ContextMenuService, ExcelExportService, RowDDService, ColumnMenuService, PdfExportService, VirtualScrollService, CriticalPathService, UndoRedoService]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AddDialogFieldDirective, AddDialogFieldsDirective, ColumnDirective, ColumnMenuService, ColumnsDirective, ContextMenuService, CriticalPathService, DayMarkersService, DayWorkingTimeCollectionDirective, DayWorkingTimeDirective, EditDialogFieldDirective, EditDialogFieldsDirective, EditService, EventMarkerDirective, EventMarkersDirective, ExcelExportService, FilterService, GanttAllModule, GanttComponent, GanttModule, HolidayDirective, HolidaysDirective, PdfExportService, ReorderService, ResizeService, RowDDService, SelectionService, SortService, ToolbarService, UndoRedoService, VirtualScrollService, WeekWorkingTimeDirective, WeekWorkingTimesDirective };
