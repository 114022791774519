{
  "main": {
    "zh-Hant": {
      "identity": {
        "version": {
          "_cldrVersion": "36"
        },
        "language": "zh-Hant"
      },
      "dates": {
        "calendars": {
          "gregorian": {
            "months": {
              "format": {
                "abbreviated": {
                  "1": "1月",
                  "2": "2月",
                  "3": "3月",
                  "4": "4月",
                  "5": "5月",
                  "6": "6月",
                  "7": "7月",
                  "8": "8月",
                  "9": "9月",
                  "10": "10月",
                  "11": "11月",
                  "12": "12月"
                },
                "narrow": {
                  "1": "1",
                  "2": "2",
                  "3": "3",
                  "4": "4",
                  "5": "5",
                  "6": "6",
                  "7": "7",
                  "8": "8",
                  "9": "9",
                  "10": "10",
                  "11": "11",
                  "12": "12"
                },
                "wide": {
                  "1": "ㄧ月",
                  "2": "二月",
                  "3": "三月",
                  "4": "四月",
                  "5": "五月",
                  "6": "六月",
                  "7": "七月",
                  "8": "八月",
                  "9": "九月",
                  "10": "十月",
                  "11": "十ㄧ月",
                  "12": "十二月"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "1": "1月",
                  "2": "2月",
                  "3": "3月",
                  "4": "4月",
                  "5": "5月",
                  "6": "6月",
                  "7": "7月",
                  "8": "8月",
                  "9": "9月",
                  "10": "10月",
                  "11": "11月",
                  "12": "12月"
                },
                "narrow": {
                  "1": "1",
                  "2": "2",
                  "3": "3",
                  "4": "4",
                  "5": "5",
                  "6": "6",
                  "7": "7",
                  "8": "8",
                  "9": "9",
                  "10": "10",
                  "11": "11",
                  "12": "12"
                },
                "wide": {
                  "1": "ㄧ月",
                  "2": "二月",
                  "3": "三月",
                  "4": "四月",
                  "5": "五月",
                  "6": "六月",
                  "7": "七月",
                  "8": "八月",
                  "9": "九月",
                  "10": "十月",
                  "11": "十ㄧ月",
                  "12": "十二月"
                }
              }
            },
            "days": {
              "format": {
                "abbreviated": {
                  "sun": "日",
                  "mon": "ㄧ",
                  "tue": "二",
                  "wed": "三",
                  "thu": "四",
                  "fri": "五",
                  "sat": "六"
                },
                "narrow": {
                  "sun": "日",
                  "mon": "ㄧ",
                  "tue": "二",
                  "wed": "三",
                  "thu": "四",
                  "fri": "五",
                  "sat": "六"
                },
                "short": {
                  "sun": "日",
                  "mon": "ㄧ",
                  "tue": "二",
                  "wed": "三",
                  "thu": "四",
                  "fri": "五",
                  "sat": "六"
                },
                "wide": {
                  "sun": "星期日",
                  "mon": "星期ㄧ",
                  "tue": "星期二",
                  "wed": "星期三",
                  "thu": "星期四",
                  "fri": "星期五",
                  "sat": "星期六"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "sun": "日",
                  "mon": "ㄧ",
                  "tue": "二",
                  "wed": "三",
                  "thu": "四",
                  "fri": "五",
                  "sat": "六"
                },
                "narrow": {
                  "sun": "日",
                  "mon": "ㄧ",
                  "tue": "二",
                  "wed": "三",
                  "thu": "四",
                  "fri": "五",
                  "sat": "六"
                },
                "short": {
                  "sun": "日",
                  "mon": "ㄧ",
                  "tue": "二",
                  "wed": "三",
                  "thu": "四",
                  "fri": "五",
                  "sat": "六"
                },
                "wide": {
                  "sun": "星期日",
                  "mon": "星期ㄧ",
                  "tue": "星期二",
                  "wed": "星期三",
                  "thu": "星期四",
                  "fri": "星期五",
                  "sat": "星期六"
                }
              }
            },
            "quarters": {
              "format": {
                "abbreviated": {
                  "1": "1季度",
                  "2": "2季度",
                  "3": "3季度",
                  "4": "4季度"
                },
                "narrow": {
                  "1": "1",
                  "2": "2",
                  "3": "3",
                  "4": "4"
                },
                "wide": {
                  "1": "第ㄧ季度",
                  "2": "第二季度",
                  "3": "第三季度",
                  "4": "第四季度"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "1": "1季度",
                  "2": "2季度",
                  "3": "3季度",
                  "4": "4季度"
                },
                "narrow": {
                  "1": "1",
                  "2": "2",
                  "3": "3",
                  "4": "4"
                },
                "wide": {
                  "1": "第ㄧ季度",
                  "2": "第二季度",
                  "3": "第三季度",
                  "4": "第四季度"
                }
              }
            },
            "dayPeriods": {
              "format": {
                "abbreviated": {
                  "midnight": "午夜",
                  "am": "上午",
                  "pm": "下午",
                  "morning1": "早上",
                  "morning2": "上午",
                  "afternoon1": "中午",
                  "afternoon2": "下午",
                  "evening1": "晚上",
                  "night1": "淩晨"
                },
                "narrow": {
                  "midnight": "午夜",
                  "am": "上午",
                  "pm": "下午",
                  "morning1": "早上",
                  "morning2": "上午",
                  "afternoon1": "中午",
                  "afternoon2": "下午",
                  "evening1": "晚上",
                  "night1": "淩晨"
                },
                "wide": {
                  "midnight": "午夜",
                  "am": "上午",
                  "pm": "下午",
                  "morning1": "清晨",
                  "morning2": "上午",
                  "afternoon1": "中午",
                  "afternoon2": "下午",
                  "evening1": "晚上",
                  "night1": "淩晨"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "midnight": "午夜",
                  "am": "上午",
                  "pm": "下午",
                  "morning1": "早上",
                  "morning2": "上午",
                  "afternoon1": "中午",
                  "afternoon2": "下午",
                  "evening1": "晚上",
                  "night1": "淩晨"
                },
                "narrow": {
                  "midnight": "午夜",
                  "am": "上午",
                  "pm": "下午",
                  "morning1": "早上",
                  "morning2": "上午",
                  "afternoon1": "中午",
                  "afternoon2": "下午",
                  "evening1": "晚上",
                  "night1": "淩晨"
                },
                "wide": {
                  "midnight": "午夜",
                  "am": "上午",
                  "pm": "下午",
                  "morning1": "早上",
                  "morning2": "上午",
                  "afternoon1": "中午",
                  "afternoon2": "下午",
                  "evening1": "晚上",
                  "night1": "淩晨"
                }
              }
            },
            "eras": {
              "eraNames": {
                "0": "公元前",
                "0-alt-variant": "BCE",
                "1": "公元",
                "1-alt-variant": "CE"
              },
              "eraAbbr": {
                "0": "公元前",
                "0-alt-variant": "BCE",
                "1": "公元",
                "1-alt-variant": "CE"
              },
              "eraNarrow": {
                "0": "公元前",
                "0-alt-variant": "BCE",
                "1": "公元",
                "1-alt-variant": "CE"
              }
            },
            "dateFormats": {
              "full": "y年M月d日EEEE",
              "long": "y年M月d日",
              "medium": "y年M月d日",
              "short": "y/M/d"
            },
            "timeFormats": {
              "full": "zzzz ah:mm:ss",
              "long": "z ah:mm:ss",
              "medium": "ah:mm:ss",
              "short": "ah:mm"
            },
            "dateTimeFormats": {
              "full": "{1} {0}",
              "long": "{1} {0}",
              "medium": "{1} {0}",
              "short": "{1} {0}",
              "availableFormats": {
                "Bh": "Bh時",
                "Bhm": "Bh:mm",
                "Bhms": "Bh:mm:ss",
                "d": "d日",
                "E": "ccc",
                "EBhm": "EBh:mm",
                "EBhms": "EBh:mm:ss",
                "Ed": "d日E",
                "Ehm": "Eah:mm",
                "EHm": "EHH:mm",
                "Ehms": "Eah:mm:ss",
                "EHms": "EHH:mm:ss",
                "Gy": "Gy年",
                "GyMMM": "Gy年M月",
                "GyMMMd": "Gy年M月d日",
                "GyMMMEd": "Gy年M月d日E",
                "h": "ah時",
                "H": "H時",
                "hm": "ah:mm",
                "Hm": "HH:mm",
                "hms": "ah:mm:ss",
                "Hms": "HH:mm:ss",
                "hmsv": "v ah:mm:ss",
                "Hmsv": "v HH:mm:ss",
                "hmv": "v ah:mm",
                "Hmv": "v HH:mm",
                "M": "M月",
                "Md": "M/d",
                "MEd": "M/dE",
                "MMdd": "MM/dd",
                "MMM": "LLL",
                "MMMd": "M月d日",
                "MMMEd": "M月d日E",
                "MMMMd": "M月d日",
                "MMMMW-count-other": "MMMM第W周",
                "ms": "mm:ss",
                "y": "y年",
                "yM": "y年M月",
                "yMd": "y/M/d",
                "yMEd": "y/M/dE",
                "yMM": "y年M月",
                "yMMM": "y年M月",
                "yMMMd": "y年M月d日",
                "yMMMEd": "y年M月d日E",
                "yMMMM": "y年M月",
                "yQQQ": "y年第Q季度",
                "yQQQQ": "y年第Q季度",
                "yw-count-other": "Y年第w周"
              },
              "appendItems": {
                "Day": "{0} ({2}: {1})",
                "Day-Of-Week": "{0} {1}",
                "Era": "{1} {0}",
                "Hour": "{0} ({2}: {1})",
                "Minute": "{0} ({2}: {1})",
                "Month": "{0} ({2}: {1})",
                "Quarter": "{0} ({2}: {1})",
                "Second": "{0} ({2}: {1})",
                "Timezone": "{1}{0}",
                "Week": "{0} ({2}: {1})",
                "Year": "{1} {0}"
              },
              "intervalFormats": {
                "intervalFormatFallback": "{0} – {1}",
                "Bh": {
                  "B": "Bh時至Bh時",
                  "h": "Bh時至h時"
                },
                "Bhm": {
                  "B": "Bh:mm至Bh:mm",
                  "h": "Bh:mm至h:mm",
                  "m": "Bh:mm至h:mm"
                },
                "d": {
                  "d": "d–d日"
                },
                "Gy": {
                  "G": "G y – G y",
                  "y": "G y–y"
                },
                "GyM": {
                  "G": "GGGGG y-MM – GGGGG y-MM",
                  "M": "GGGGG y-MM – y-MM",
                  "y": "GGGGG y-MM – y-MM"
                },
                "GyMd": {
                  "d": "GGGGG y-MM-dd – y-MM-dd",
                  "G": "GGGGG y-MM-dd – GGGGG y-MM-dd",
                  "M": "GGGGG y-MM-dd – y-MM-dd",
                  "y": "GGGGG y-MM-dd – y-MM-dd"
                },
                "GyMEd": {
                  "d": "GGGGG y-MM-dd, E – y-MM-dd, E",
                  "G": "GGGGG y-MM-dd, E – GGGGG y-MM-dd, E",
                  "M": "GGGGG y-MM-dd, E – y-MM-dd, E",
                  "y": "GGGGG y-MM-dd, E – y-MM-dd, E"
                },
                "GyMMM": {
                  "G": "G y MMM – G y MMM",
                  "M": "G y MMM–MMM",
                  "y": "G y MMM – y MMM"
                },
                "GyMMMd": {
                  "d": "G y MMM d–d",
                  "G": "G y MMM d – G y MMM d",
                  "M": "G y MMM d – MMM d",
                  "y": "G y MMM d – y MMM d"
                },
                "GyMMMEd": {
                  "d": "G y MMM d, E – MMM d, E",
                  "G": "G y MMM d, E – G y MMM d, E",
                  "M": "G y MMM d, E – MMM d, E",
                  "y": "G y MMM d, E – y MMM d, E"
                },
                "h": {
                  "a": "ah時至ah時",
                  "h": "ah時至h時"
                },
                "H": {
                  "H": "HH–HH"
                },
                "hm": {
                  "a": "ah:mm至ah:mm",
                  "h": "ah:mm至h:mm",
                  "m": "ah:mm至h:mm"
                },
                "Hm": {
                  "H": "HH:mm–HH:mm",
                  "m": "HH:mm–HH:mm"
                },
                "hmv": {
                  "a": "vah:mm至ah:mm",
                  "h": "vah:mm至h:mm",
                  "m": "vah:mm至h:mm"
                },
                "Hmv": {
                  "H": "v HH:mm–HH:mm",
                  "m": "v HH:mm–HH:mm"
                },
                "hv": {
                  "a": "vah時至ah時",
                  "h": "vah時至h時"
                },
                "Hv": {
                  "H": "v HH–HH"
                },
                "M": {
                  "M": "M–M月"
                },
                "Md": {
                  "d": "M/d – M/d",
                  "M": "M/d – M/d"
                },
                "MEd": {
                  "d": "M/dE至M/dE",
                  "M": "M/dE至M/dE"
                },
                "MMM": {
                  "M": "MMM – MMM"
                },
                "MMMd": {
                  "d": "M月d日至d日",
                  "M": "M月d日至M月d日"
                },
                "MMMEd": {
                  "d": "M月d日E至d日E",
                  "M": "M月d日E至M月d日E"
                },
                "y": {
                  "y": "y–y年"
                },
                "yM": {
                  "M": "y年M月至M月",
                  "y": "y年M月至y年M月"
                },
                "yMd": {
                  "d": "y/M/d – y/M/d",
                  "M": "y/M/d – y/M/d",
                  "y": "y/M/d – y/M/d"
                },
                "yMEd": {
                  "d": "y/M/dE至y/M/dE",
                  "M": "y/M/dE至y/M/dE",
                  "y": "y/M/dE至y/M/dE"
                },
                "yMMM": {
                  "M": "y年M月至M月",
                  "y": "y年M月至y年M月"
                },
                "yMMMd": {
                  "d": "y年M月d日至d日",
                  "M": "y年M月d日至M月d日",
                  "y": "y年M月d日至y年M月d日"
                },
                "yMMMEd": {
                  "d": "y年M月d日E至d日E",
                  "M": "y年M月d日E至M月d日E",
                  "y": "y年M月d日E至y年M月d日E"
                },
                "yMMMM": {
                  "M": "y年M月至M月",
                  "y": "y年M月至y年M月"
                }
              }
            }
          }
        }
      }
    }
  }
}
