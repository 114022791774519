import {NgModule} from '@angular/core';
import {
  CommonModule,
  DatePipe,
  NgClass,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault
} from '@angular/common';
import {ngModule} from './primeng/primeng';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ej2Module} from "./ej2/ejs";
import {provideHttpClient} from "@angular/common/http";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  exports: [
    ngModule,
    ej2Module,
    FormsModule,
    ReactiveFormsModule,
    DatePipe,
    NgIf,
    NgFor,
    NgClass,
    NgSwitchDefault,
    NgSwitchCase,
    NgSwitch
  ],
  providers: [provideHttpClient()]
})
export class SharedModule {
}
