{
  "zh": {
    "grid": {
      "EmptyRecord": "無記錄可顯示",
      "True": "真正",
      "False": "假",
      "InvalidFilterMessage": "無效的過濾器數據",
      "GroupDropArea": "將列標題拖到此處以對其列進行分組",
      "UnGroup": "點擊此處取消分組",
      "GroupDisable": "該列的分組已禁用",
      "FilterbarTitle": "的濾網",
      "EmptyDataSourceError": "初始加載時，DataSource不能為空，因為列是從AutoGenerate Column Grid中的dataSource生成的",
      "Add": "加",
      "Edit": "編輯",
      "Cancel": "取消",
      "Update": "更新資料",
      "Delete": "刪除",
      "Print": "打印",
      "Pdfexport": "PDF導出",
      "Excelexport": "Excel導出",
      "Wordexport": "單詞導出",
      "Csvexport": "CSV導出",
      "Search": "搜索",
      "Columnchooser": "列",
      "Save": "保存",
      "Item": "項目",
      "Items": "項目",
      "EditOperationAlert": "未選擇任何記錄進行編輯操作",
      "DeleteOperationAlert": "未選擇要刪除操作的記錄",
      "SaveButton": "保存",
      "OKButton": "好",
      "CancelButton": "取消",
      "EditFormTitle": "的詳細內容",
      "AddFormTitle": "添加新記錄",
      "BatchSaveConfirm": "您確定要保存更改嗎？",
      "BatchSaveLostChanges": "未保存的更改將丟失。你確定你要繼續嗎？",
      "ConfirmDelete": "您確定要刪除記錄嗎？",
      "CancelEdit": "您確定要取消更改嗎？",
      "ChooseColumns": "選擇列",
      "SearchColumns": "搜索欄",
      "Matchs": "找不到匹配項",
      "FilterButton": "過濾",
      "ClearButton": "明確",
      "StartsWith": "以。。開始",
      "EndsWith": "以。。結束",
      "Contains": "包含",
      "Equal": "等於",
      "NotEqual": "不平等",
      "LessThan": "少於",
      "LessThanOrEqual": "小於或等於",
      "GreaterThan": "比...更棒",
      "GreaterThanOrEqual": "大於等於",
      "ChooseDate": "選擇日期",
      "EnterValue": "輸入值",
      "Copy": "複製",
      "Group": "按此列分組",
      "Ungroup": "按此列取消分組",
      "autoFitAll": "自動適合所有列",
      "autoFit": "自動適合此列",
      "Export": "出口",
      "FirstPage": "第一頁",
      "LastPage": "最後一頁",
      "PreviousPage": "上一頁",
      "NextPage": "下一頁",
      "SortAscending": "升序",
      "SortDescending": "降序排列",
      "EditRecord": "編輯記錄",
      "DeleteRecord": "刪除記錄",
      "FilterMenu": "過濾",
      "SelectAll": "全選",
      "Blanks": "空白",
      "FilterTrue": "真正",
      "FilterFalse": "假",
      "NoResult": "找不到匹配項",
      "ClearFilter": "清除篩選",
      "NumberFilter": "號碼過濾器",
      "TextFilter": "文字過濾器",
      "DateFilter": "日期過濾器",
      "DateTimeFilter": "日期時間過濾器",
      "MatchCase": "相符",
      "Between": "之間",
      "CustomFilter": "自定義過濾器",
      "CustomFilterPlaceHolder": "輸入值",
      "CustomFilterDatePlaceHolder": "選擇一個日期",
      "AND": "和",
      "OR": "要么",
      "ShowRowsWhere": "在以下位置顯示行：",
      "NotStartsWith": "不以開頭",
      "Like": "喜歡",
      "NotEndsWith": "不以結束",
      "NotContains": "不含",
      "IsNull": "無效的",
      "NotNull": "不為空",
      "IsEmpty": "空的",
      "IsNotEmpty": "不是空的",
      "AddCurrentSelection": "將當前選擇添加到過濾器",
      "UnGroupButton": "单击此处取消分组",
      "AutoFitAll": "自动调整所有列",
      "AutoFit": "自动调整此列",
      "Clear": "清除",
      "FilterMenuDialogARIA": "过滤器菜单对话框",
      "ExcelFilterDialogARIA": "Excel 过滤器对话框",
      "DialogEditARIA": "编辑对话框",
      "ColumnChooserDialogARIA": "列选择器",
      "ColumnMenuDialogARIA": "列菜单对话框",
      "CustomFilterDialogARIA": "自定义过滤器对话框",
      "SortAtoZ": "从 A 到 Z 排序",
      "SortZtoA": "从 Z 到 A 排序",
      "SortByOldest": "按最旧排序",
      "SortByNewest": "按最新排序",
      "SortSmallestToLargest": "从小到大排序",
      "SortLargestToSmallest": "从大到小排序",
      "Sort": "捃",
      "FilterDescription": "按 Alt Down 打开过滤器菜单",
      "SortDescription": "按 Enter 进行排序",
      "ColumnMenuDescription": "按 Alt Down 打开列菜单",
      "GroupDescription": "按 Ctrl 空格进行分组",
      "ColumnHeader": " 列标题 ",
      "TemplateCell": " 是模板单元格",
      "CommandColumnAria": "是命令列列标题 ",
      "DialogEdit": "对话框编辑",
      "ClipBoard": "剪贴板",
      "GroupButton": "组按钮",
      "UnGroupAria": "取消组合按钮",
      "GroupSeperator": "分组列的分隔符",
      "UnGroupIcon": "取消分组分组的列 ",
      "GroupedSortIcon": "对分组的列进行排序 ",
      "GroupedDrag": "拖动分组的列",
      "GroupCaption": " 是组字幕单元格",
      "CheckBoxLabel": "复选框",
      "Expanded": "展开",
      "Collapsed": "倒塌",
      "SelectAllCheckbox": "选择全部复选框",
      "SelectRow": "选择行"
    },
    "pager": {
      "currentPageInfo": "第{0}頁／共{1}頁",
      "totalItemsInfo": "（{0}個項目）",
      "firstPageTooltip": "轉到第一頁",
      "lastPageTooltip": "轉到最後一頁",
      "nextPageTooltip": "轉到下一頁",
      "previousPageTooltip": "轉到上一頁",
      "nextPagerTooltip": "轉到下一個尋呼機項目",
      "previousPagerTooltip": "轉到上一個尋呼機項目",
      "pagerDropDown": "每頁項目",
      "pagerAllDropDown": "物品",
      "All": "所有",
      "totalItemInfo": "（{0}项目）",
      "Container": "寻呼机容器",
      "Information": "寻呼机信息",
      "ExternalMsg": "寻呼机外部消息",
      "Page": "页 ",
      "Of": " 的 ",
      "Pages": " 页面"
    },
    "kanban": {
      "items": "項目",
      "min": "敏",
      "max": "最高",
      "cardsSelected": "選擇的卡",
      "addTitle": "新增卡",
      "editTitle": "編輯卡詳細信息",
      "deleteTitle": "刪除卡",
      "deleteContent": "您確定要刪除此卡嗎？",
      "save": "救",
      "delete": "刪除",
      "cancel": "取消",
      "yes": "是",
      "no": "沒有",
      "close": "关",
      "noCard": "没有可显示的卡片",
      "unassigned": "未分配",
      "cards": "运输"
    },
    "dropdowns": {
      "noRecordsTemplate": "沒有找到記錄",
      "actionFailureTemplate": "請求失敗",
      "overflowCountTemplate": "+${count}更多..",
      "selectAllText": "全選",
      "unSelectAllText": "全部取消選擇",
      "totalCountTemplate": "已選擇${count}個"
    },
    "drop-down-list": {
      "noRecordsTemplate": "沒有找到記錄",
      "actionFailureTemplate": "請求失敗"
    },
    "combo-box": {
      "noRecordsTemplate": "沒有找到記錄",
      "actionFailureTemplate": "請求失敗"
    },
    "auto-complete": {
      "noRecordsTemplate": "沒有找到記錄",
      "actionFailureTemplate": "請求失敗"
    },
    "multi-select": {
      "noRecordsTemplate": "沒有找到記錄",
      "actionFailureTemplate": "請求失敗",
      "overflowCountTemplate": "+${count}更多..",
      "selectAllText": "全選",
      "unSelectAllText": "全部取消選擇",
      "totalCountTemplate": "已選擇${count}個"
    },
    "listbox": {
      "noRecordsTemplate": "沒有找到記錄",
      "actionFailureTemplate": "請求失敗",
      "selectAllText": "全選",
      "unSelectAllText": "全部取消選擇",
      "moveUp": "提升",
      "moveDown": "下移",
      "moveTo": "搬去",
      "moveFrom": "從",
      "moveAllTo": "全部移至",
      "moveAllFrom": "全部移出"
    },
    "spreadsheet": {
      "InsertingEmptyValue": "参考值无效。",
      "FindValue": "寻找价值",
      "ReplaceValue": "替换值",
      "FindReplaceTooltip": "查找和替换",
      "ByRow": "按行",
      "ByColumn": "按列",
      "MatchExactCellElements": "匹配整个单元格内容",
      "EnterCellAddress": "输入单元格地址",
      "FindAndReplace": "查找和替换",
      "ReplaceAllEnd": " 比赛替换为",
      "FindNextBtn": "找下一个",
      "FindPreviousBtn": "查找上一个",
      "ReplaceBtn": "代替",
      "ReplaceAllBtn": "全部替换",
      "GotoHeader": "去",
      "Sheet": "床单",
      "SearchWithin": "在内部搜索",
      "SearchBy": "搜索依据",
      "Reference": "参考",
      "Workbook": "工作簿",
      "NoElements": "我们找不到您要找的东西。",
      "FindWhat": "找什么",
      "ReplaceWith": "用。。。来代替",
      "FileName": "文档名称",
      "ExtendValidation": "选择包含一些没有数据验证的单元格。您想将验证扩展到这些单元格吗？",
      "Yes": "是的",
      "No": "不",
      "PROPER": "将文本转换为正确的大小写；第一个字母大写，其他字母小写。",
      "Cut": "切",
      "Copy": "复制",
      "Paste": "粘贴",
      "PasteSpecial": "特殊粘贴",
      "All": "全部",
      "Values": "价值观",
      "Formats": "格式",
      "Font": "字体",
      "FontSize": "字体大小",
      "Bold": "胆大",
      "Italic": "斜体",
      "Underline": "强调",
      "Strikethrough": "删除线",
      "TextColor": "文字颜色",
      "FillColor": "填色",
      "HorizontalAlignment": "水平对齐",
      "AlignLeft": "左对齐",
      "AlignCenter": "中心",
      "AlignRight": "右对齐",
      "VerticalAlignment": "垂直对齐",
      "AlignTop": "顶部对齐",
      "AlignMiddle": "居中对齐",
      "AlignBottom": "底部对齐",
      "MergeCells": "合并单元格",
      "MergeAll": "全部合并",
      "MergeHorizontally": "水平合并",
      "MergeVertically": "垂直合并",
      "Unmerge": "取消合并",
      "UnmergeCells": "取消合并单元格",
      "SelectMergeType": "选择合并类型",
      "MergeCellsAlert": "合并单元格只会保留最左上角（最上层）的值。还是合并？",
      "PasteMergeAlert": "我们不能对合并单元格执行此操作。",
      "Borders": "边框",
      "TopBorders": "顶部边框",
      "LeftBorders": "左边框",
      "RightBorders": "右边框",
      "BottomBorders": "底部边框",
      "AllBorders": "所有边框",
      "HorizontalBorders": "水平边框",
      "VerticalBorders": "垂直边框",
      "OutsideBorders": "境外",
      "InsideBorders": "内部边界",
      "NoBorders": "无国界",
      "BorderColor": "边框颜色",
      "BorderStyle": "边框样式",
      "InsertFunction": "插入函数",
      "Insert": "插入",
      "Delete": "删除",
      "DuplicateSheet": "复制",
      "MoveRight": "向右移",
      "MoveLeft": "向左移动",
      "Rename": "改名",
      "Hide": "隐藏",
      "NameBox": "姓名框",
      "ShowHeaders": "显示标题",
      "HideHeaders": "隐藏标题",
      "ShowGridLines": "显示网格线",
      "HideGridLines": "隐藏网格线",
      "FreezePanes": "冻结窗格",
      "FreezeRows": "冻结行",
      "FreezeColumns": "冻结列",
      "UnfreezePanes": "解冻窗格",
      "UnfreezeRows": "解冻行",
      "UnfreezeColumns": "解冻列",
      "AddSheet": "添加工作表",
      "ListAllSheets": "列出所有工作表",
      "CollapseToolbar": "折叠工具栏",
      "ExpandToolbar": "展开工具栏",
      "CollapseFormulaBar": "折叠公式栏",
      "ExpandFormulaBar": "展开公式栏",
      "File": "文件",
      "Home": "家",
      "Formulas": "公式",
      "View": "看法",
      "New": "新的",
      "Open": "打开",
      "SaveAs": "另存为",
      "ExcelXlsx": "微软Excel",
      "ExcelXls": "微软 Excel 97-2003",
      "CSV": "逗号分隔值",
      "FormulaBar": "公式栏",
      "Sort": "种类",
      "SortAscending": "上升",
      "SortDescending": "降序",
      "CustomSort": "自定义排序",
      "AddColumn": "添加列",
      "ContainsHeader": "数据包含标题",
      "CaseSensitive": "区分大小写",
      "SortBy": "排序方式",
      "ThenBy": "然后通过",
      "SelectAColumn": "选择一列",
      "SortEmptyFieldError": "所有排序条件都必须指定一列。检查选定的排序标准并重试。",
      "SortDuplicateFieldError": " 不止一次按值排序。删除重复的排序条件并重试。",
      "SortOutOfRangeError": "选择已使用范围内的单元格或范围，然后重试。",
      "MultiRangeSortError": "这不能在多范围选择上完成。选择一个范围并重试。",
      "HideRow": "隐藏行",
      "HideRows": "隐藏行",
      "UnhideRows": "取消隐藏行",
      "HideColumn": "隐藏列",
      "HideColumns": "隐藏列",
      "UnhideColumns": "取消隐藏列",
      "InsertRow": "插入行",
      "InsertRows": "插入行",
      "Above": "多于",
      "Below": "以下",
      "InsertColumn": "插入列",
      "InsertColumns": "插入列",
      "Before": "前",
      "After": "后",
      "DeleteRow": "删除行",
      "DeleteRows": "删除行",
      "DeleteColumn": "删除列",
      "DeleteColumns": "删除列",
      "Ok": "好的",
      "Close": "关闭",
      "MoreOptions": "更多的选择",
      "Cancel": "取消",
      "Apply": "申请",
      "MoreColors": "更多颜色",
      "StandardColors": "标准颜色",
      "General": "一般的",
      "Number": "数字",
      "Currency": "货币",
      "Accounting": "会计",
      "ShortDate": "短约会",
      "LongDate": "长日期",
      "Time": "时间",
      "Percentage": "百分比",
      "Fraction": "分数",
      "Scientific": "科学的",
      "Text": "文本",
      "NumberFormat": "数字格式",
      "MobileFormulaBarPlaceHolder": "输入值或公式",
      "PasteAlert": "你不能在这里粘贴，因为复制区域和粘贴区域的大小不一样。请尝试在不同的范围内粘贴。",
      "DestroyAlert": "您确定要在不保存的情况下销毁当前工作簿并创建一个新工作簿吗？",
      "SheetRenameInvalidAlert": "工作表名称包含无效字符。",
      "SheetRenameEmptyAlert": "工作表名称不能为空。",
      "SheetRenameAlreadyExistsAlert": "工作表名称已存在。请输入其他名称。",
      "DeleteSheetAlert": "您确定要删除此工作表吗？",
      "DeleteSingleLastSheetAlert": "工作簿必须包含至少一个可见的工作表。",
      "PickACategory": "选择一个类别",
      "Description": "描述",
      "UnsupportedFile": "不支持的文件",
      "DataLimitExceeded": "文件数据太大，处理时间较长，要继续吗？",
      "FileSizeLimitExceeded": "文件太大，处理需要更多时间，要继续吗？",
      "InvalidUrl": "无效的网址",
      "SUM": "添加一系列数字和/或单元格。",
      "SUMIF": "根据指定条件添加单元格。",
      "SUMIFS": "根据指定条件添加单元格。",
      "ABS": "返回不带符号的数字的值。",
      "RAND": "返回 0 到 1 之间的随机数。",
      "RANDBETWEEN": "返回基于指定值的随机整数。",
      "FLOOR": "将数字向下舍入到给定因子的最接近倍数。",
      "CEILING": "将数字向上舍入为给定因子的最接近倍数。",
      "PRODUCT": "将一系列数字和/或单元格相乘。",
      "AVERAGE": "计算不包括文本的一系列数字和/或单元格的平均值。",
      "AVERAGEIF": "根据指定的标准计算单元格的平均值。",
      "AVERAGEIFS": "根据指定条件计算单元格的平均值。",
      "AVERAGEA": "计算将 TRUE 评估为 1、将文本和 FALSE 评估为 0 的单元格的平均值。",
      "COUNT": "计算范围内包含数值的单元格。",
      "COUNTIF": "根据指定条件对细胞进行计数。",
      "COUNTIFS": "根据指定条件对细胞进行计数。",
      "COUNTA": "对包含某个范围内的值的单元格进行计数。",
      "MIN": "返回给定参数的最小数量。",
      "MAX": "返回最大数量的给定参数。",
      "DATE": "返回基于给定年、月和日的日期。",
      "DAY": "返回给定日期的日期。",
      "DAYS": "返回两个日期之间的天数。",
      "IF": "根据给定的表达式返回值。",
      "IFS": "根据给定的多个表达式返回值。",
      "CalculateAND": "如果所有参数都为 TRUE，则返回 TRUE，否则返回 FALSE。",
      "CalculateOR": "如果任何参数为 TRUE，则返回 TRUE，否则返回 FALSE。",
      "IFERROR": "如果没有发现错误则返回值，否则它将返回指定的值。",
      "CHOOSE": "根据索引号从值列表中返回一个值。",
      "INDEX": "根据行号和列号返回给定范围内的单元格值。",
      "FIND": "返回一个字符串在另一个字符串中的位置，区分大小写。",
      "CONCATENATE": "将两个或多个字符串组合在一起。",
      "CONCAT": "连接一个列表或一系列文本字符串。",
      "SUBTOTAL": "使用给定的函数编号返回范围的小计。",
      "RADIANS": "将度数转换为弧度。",
      "MATCH": "返回给定范围内指定值的相对位置。",
      "SLOPE": "从数据点的线性回归返回直线的斜率。",
      "INTERCEPT": "通过线性回归计算 Y 截距线的点。",
      "UNIQUE": "返回范围或数组中的唯一值",
      "TEXT": "将值转换为指定数字格式的文本。",
      "DefineNameExists": "此名称已存在，请尝试其他名称。",
      "CircularReference": "当公式引用一个或多个循环引用时，这可能会导致计算不正确。",
      "SORT": "对数组的范围进行排序",
      "T": "检查值是否为文本并返回文本。",
      "EXACT": "检查两个文本字符串是否完全相同并返回 TRUE 或 FALSE。",
      "LEN": "返回给定字符串中的字符数。",
      "MOD": "返回数字除以除数后的余数。",
      "ODD": "将正数向上舍入并将负数向下舍入到最接近的奇数。",
      "PI": "返回 pi 的值。",
      "COUNTBLANK": "返回指定单元格范围内的空单元格数。",
      "EVEN": "将正数向上舍入并将负数向下舍入到最接近的偶数。",
      "DECIMAL": "将给定基数中数字的文本表示形式转换为十进制数。",
      "ADDRESS": "给定指定的行号和列号，以文本形式返回单元格引用。",
      "CHAR": "返回指定数字中的字符。",
      "CODE": "返回给定字符串中第一个字符的数字代码。",
      "DOLLAR": "将数字转换为货币格式的文本。",
      "SMALL": "返回给定数组中的第 k 个最小值。",
      "LARGE": "返回给定数组中的第 k 个最大值。",
      "TIME": "将小时、分钟、秒转换为时间格式的文本。",
      "DEGREES": "将弧度转换为度数。",
      "FACT": "返回数字的阶乘。",
      "MEDIAN": "返回给定数字集的中位数。",
      "EDATE": "返回指定日期之前或之后的给定月数的日期。",
      "DATEVALUE": "将日期字符串转换为日期值。",
      "NOW": "返回当前日期和时间。",
      "HOUR": "返回指定时间字符串中的小时数。",
      "MINUTE": "返回指定时间字符串中的分钟数。",
      "SECOND": "返回指定时间字符串中的秒数。",
      "MONTH": "返回指定日期字符串中的月数。",
      "OR": "或者",
      "AND": "和",
      "CustomFilterDatePlaceHolder": "选择日期",
      "CustomFilterPlaceHolder": "输入值",
      "CustomFilter": "自定义过滤器",
      "Between": "之间",
      "MatchCase": "相符",
      "DateTimeFilter": "日期时间过滤器",
      "Undo": "撤消",
      "Redo": "重做",
      "ClearAllFilter": "清除",
      "ReapplyFilter": "重新申请",
      "DateFilter": "日期过滤器",
      "TextFilter": "文本过滤器",
      "NumberFilter": "数字过滤器",
      "ClearFilter": "清除过滤器",
      "NoResult": "未找到匹配项",
      "FilterFalse": "错误的",
      "FilterTrue": "真的",
      "Blanks": "毛坯",
      "SelectAll": "全选",
      "GreaterThanOrEqual": "大于或等于",
      "GreaterThan": "比...更棒",
      "LessThanOrEqual": "小于或等于",
      "LessThan": "少于",
      "NotEqual": "不等于",
      "Equal": "平等的",
      "Contains": "包含",
      "NotContains": "不包含",
      "EndsWith": "以。。结束",
      "NotEndsWith": "不结束于",
      "StartsWith": "以。。开始",
      "NotStartsWith": "不以开头",
      "IsEmpty": "空的",
      "IsNotEmpty": "不是空的",
      "ClearButton": "清除",
      "FilterButton": "筛选",
      "CancelButton": "取消",
      "OKButton": "好的",
      "Search": "搜索",
      "DataValidation": "数据验证",
      "CellRange": "单元格范围",
      "Allow": "允许",
      "Data": "数据",
      "Minimum": "最低限度",
      "Maximum": "最大限度",
      "IgnoreBlank": "忽略空格",
      "WholeNumber": "完整的号码",
      "Decimal": "十进制",
      "Date": "日期",
      "TextLength": "文本长度",
      "List": "列表",
      "NotBetween": "不介于",
      "EqualTo": "等于",
      "NotEqualTo": "不等于",
      "GreaterThanOrEqualTo": "大于或等于",
      "LessThanOrEqualTo": "小于或等于",
      "InCellDropDown": "单元格下拉",
      "Sources": "来源",
      "Value": "价值",
      "Retry": "重试",
      "DialogError": "列表源必须是对单个行或列的引用。",
      "MinMaxError": "最大值必须大于或等于最小值。",
      "Spreadsheet": "电子表格",
      "MoreValidation": "此选择包含多个验证。",
      "FileNameError": "文件名不能包含像 \\ / : * 这样的字符？ \" < > [ ] |",
      "ValidationError": "此值与为单元格定义的数据有效性限制不匹配。",
      "ListLengthError": "列表值最多只允许 256 个字符",
      "ProtectSheet": "保护表",
      "UnprotectSheet": "取消保护工作表",
      "SelectCells": "选择锁定的单元格",
      "SelectUnlockedCells": "选择解锁的单元格",
      "Save": "救",
      "EmptyFileName": "文件名不能为空。",
      "LargeName": "名称太长。",
      "FormatCells": "格式化单元格",
      "FormatRows": "格式化行",
      "FormatColumns": "格式化列",
      "InsertLinks": "插入链接",
      "ProtectContent": "保护锁定单元格的内容",
      "ProtectAllowUser": " 允许此工作表的所有用户：",
      "EditAlert": "您尝试更改的单元格受到保护。要进行更改，请取消保护工作表。",
      "ClearValidation": "清除验证",
      "ISNUMBER": "当值解析为数值时返回 true。",
      "ROUND": "将数字四舍五入到指定的位数。",
      "GEOMEAN": "返回数组或正数据范围的几何平均值。",
      "POWER": "返回一个数的幂的结果",
      "LOG": "返回数字的对数，以您指定的底数为底。",
      "TRUNC": "将数字的截断值返回到指定的小数位数。",
      "EXP": "返回 e 的给定数次方。",
      "LOOKUP": "查找一组行或列中的值",
      "HLOOKUP": "查找值表顶行中的值并返回与指定表行同一列中的值。",
      "VLOOKUP": "在搜索范围的第一列中搜索指定值。",
      "NOT": "返回指定布尔表达式的反数。",
      "EOMONTH": "返回从指定的初始开始日期开始的指定月数之前和之后的月份的最后一天。",
      "SQRT": "返回正数的平方根。",
      "ROUNDDOWN": "将数字四舍五入为 0。",
      "RSQ": "返回基于数据点“known_y”和“known_x”的皮尔逊积矩相关系数的平方。",
      "HighlightCellsRules": "突出显示单元格规则",
      "CFEqualTo": "等于",
      "TextThatContains": "包含的文本",
      "ADateOccuring": "发生的日期",
      "DuplicateValues": "重复值",
      "TopBottomRules": "最高/最低规则",
      "Top10Items": "前 10 项",
      "Top10": "前10名",
      "Bottom10Items": "后 10 项",
      "Bottom10": "后 10 名",
      "AboveAverage": "高于平均水平",
      "BelowAverage": "低于平均水平",
      "FormatCellsGreaterThan": "格式化大于的单元格：",
      "FormatCellsLessThan": "格式化单元格小于：",
      "FormatCellsBetween": "格式化介于以下之间的单元格：",
      "FormatCellsEqualTo": "格式化单元格等于：",
      "FormatCellsThatContainTheText": "格式化包含文本的单元格：",
      "FormatCellsThatContainADateOccurring": "格式化包含发生日期的单元格：",
      "FormatCellsDuplicate": "格式化包含以下内容的单元格：",
      "FormatCellsTop": "格式化排在 TOP 的单元格：",
      "FormatCellsBottom": "格式化排在底部的单元格：",
      "FormatCellsAbove": "格式化高于平均水平的单元格：",
      "FormatCellsBelow": "格式化低于平均水平的单元格：",
      "With": "和",
      "DataBars": "数据条",
      "ColorScales": "色阶",
      "IconSets": "图标集",
      "ClearRules": "明确的规则",
      "SelectedCells": "清除所选单元格中的规则",
      "EntireSheet": "从整个工作表中清除规则",
      "LightRedFillWithDarkRedText": "淡红色填充深红色文字",
      "YellowFillWithDarkYellowText": "黄色填充深黄色文本",
      "GreenFillWithDarkGreenText": "绿色填充深绿色文字",
      "RedFill": "红色填充",
      "RedText": "红色文字",
      "Duplicate": "复制",
      "Unique": "独特",
      "And": "和",
      "WebPage": "网页",
      "ThisDocument": "这个文件",
      "DisplayText": "显示文字",
      "Url": "网址",
      "CellReference": "单元格引用",
      "DefinedNames": "定义名称",
      "EnterTheTextToDisplay": "输入要显示的文字",
      "EnterTheUrl": "输入网址",
      "INT": "将数字返回到最接近的整数。",
      "SUMPRODUCT": "返回给定数组范围的乘积之和。",
      "TODAY": "返回当前日期作为日期值。",
      "ROUNDUP": "将数字从零舍入。",
      "Link": "关联",
      "Hyperlink": "超级链接",
      "EditHyperlink": "编辑超链接",
      "OpenHyperlink": "打开超链接",
      "RemoveHyperlink": "删除超链接",
      "InvalidHyperlinkAlert": "该站点的地址无效。检查地址并重试。",
      "InsertLink": "插入链接",
      "EditLink": "编辑链接",
      "WrapText": "环绕文字",
      "Update": "更新",
      "SortAndFilter": "排序和过滤",
      "Filter": "筛选",
      "FilterCellValue": "按所选单元格的值过滤",
      "FilterOutOfRangeError": "选择已使用范围内的单元格或范围，然后重试。",
      "ClearFilterFrom": "清除过滤器自",
      "LN": "返回数字的自然对数。",
      "DefineNameInValid": "您输入的名称无效。",
      "EmptyError": "您必须输入一个值",
      "ClearHighlight": "清除突出显示",
      "HighlightInvalidData": "突出显示无效数据",
      "Clear": "清除",
      "ClearContents": "清除内容",
      "ClearAll": "全部清除",
      "ClearFormats": "清除格式",
      "ClearHyperlinks": "清除超链接",
      "Image": "图片",
      "ConditionalFormatting": "条件格式",
      "BlueDataBar": "蓝色数据栏",
      "GreenDataBar": "绿色数据栏",
      "RedDataBar": "红色数据条",
      "OrangeDataBar": "橙色数据栏",
      "LightBlueDataBar": "浅蓝色数据栏",
      "PurpleDataBar": "紫色数据栏",
      "GYRColorScale": "绿色 - 黄色 - 红色色标",
      "RYGColorScale": "红色 - 黄色 - 绿色色标",
      "GWRColorScale": "绿色 - 白色 - 红色色标",
      "RWGColorScale": "红色 - 白色 - 绿色色标",
      "BWRColorScale": "蓝色 - 白色 - 红色色标",
      "RWBColorScale": "红色 - 白色 - 蓝色色阶",
      "WRColorScale": "白色 - 红色色标",
      "RWColorScale": "红色 - 白色色标",
      "GWColorScale": "绿色 - 白色色标",
      "WGColorScale": "白色 - 绿色色标",
      "GYColorScale": "绿色 - 黄色色标",
      "YGColorScale": "黄色 - 绿色色标",
      "ThreeArrowsColor": "3支箭头（彩色）",
      "ThreeArrowsGray": "3支箭头（灰色）",
      "ThreeTriangles": "3个三角形",
      "FourArrowsColor": "4支箭头（灰色）",
      "FourArrowsGray": "4 支箭头（彩色）",
      "FiveArrowsColor": "5支箭头（灰色）",
      "FiveArrowsGray": "5支箭（彩色）",
      "ThreeTrafficLights1": "3 个交通灯（无边框）",
      "ThreeTrafficLights2": "3 个交通灯（镶边）",
      "ThreeSigns": "3 标志",
      "FourTrafficLights": "4个交通灯",
      "RedToBlack": "红变黑",
      "ThreeSymbols1": "3个符号（圆圈）",
      "ThreeSymbols2": "3个符号（未圈出）",
      "ThreeFlags": "3旗帜",
      "ThreeStars": "3星",
      "FourRatings": "4 个评分",
      "FiveQuarters": "5个宿舍",
      "FiveRatings": "5 个评分",
      "FiveBoxes": "5盒",
      "Chart": "图表",
      "Column": "柱子",
      "Bar": "酒吧",
      "Area": "区域",
      "Pie": "馅饼",
      "Doughnut": "油炸圈饼",
      "PieAndDoughnut": "馅饼/甜甜圈",
      "Line": "线",
      "Radar": "雷达",
      "Scatter": "分散",
      "ChartDesign": "图表设计",
      "ClusteredColumn": "簇状列",
      "StackedColumn": "堆积柱",
      "StackedColumn100": "100% 堆积柱",
      "ClusteredBar": "簇状条",
      "StackedBar": "堆积条",
      "StackedBar100": "100% 堆积条",
      "StackedArea": "堆积区",
      "StackedArea100": "100% 堆叠面积",
      "StackedLine": "堆积线",
      "StackedLine100": "100% 堆叠线",
      "LineMarker": "带标记的线",
      "StackedLineMarker": "带标记的堆叠线",
      "StackedLine100Marker": "带标记的 100% 堆叠线",
      "AddChartElement": "添加图表元素",
      "Axes": "轴",
      "AxisTitle": "轴标题",
      "ChartTitle": "图表标题",
      "DataLabels": "数据标签",
      "Gridlines": "网格线",
      "Legends": "传说",
      "PrimaryHorizontal": "小学水平",
      "PrimaryVertical": "初级垂直",
      "None": "没有任何",
      "AboveChart": "上图",
      "Center": "中心",
      "InsideEnd": "内端",
      "InsideBase": "内部基地",
      "OutsideEnd": "外端",
      "PrimaryMajorHorizontal": "初级专业水平",
      "PrimaryMajorVertical": "小学专业垂直",
      "PrimaryMinorHorizontal": "主要次要水平",
      "PrimaryMinorVertical": "主要次要垂直",
      "Right": "正确的",
      "Left": "剩下",
      "Bottom": "底部",
      "Top": "最佳",
      "SwitchRowColumn": "切换行/列",
      "ChartTheme": "图表主题",
      "ChartType": "图表类型",
      "Material": "材料",
      "Fabric": "织物",
      "Bootstrap": "引导程序",
      "HighContrastLight": "高对比灯",
      "MaterialDark": "材质深色",
      "FabricDark": "面料深色",
      "HighContrast": "高对比度",
      "BootstrapDark": "Bootstrap 深色",
      "Bootstrap4": "Bootstrap4",
      "Bootstrap5Dark": "Bootstrap5 深色",
      "Bootstrap5": "Bootstrap5",
      "Tailwind": "顺风",
      "TailwindDark": "顺风黑暗",
      "VerticalAxisTitle": "纵轴标题",
      "HorizontalAxisTitle": "横轴标题",
      "EnterTitle": "输入标题",
      "UnprotectWorksheet": "取消保护工作表",
      "ReEnterPassword": "重新输入密码以继续",
      "SheetPassword": "取消保护工作表的密码：",
      "ProtectWorkbook": "保护工作簿",
      "Password": "密码（可选）：",
      "EnterThePassword": "输入密码",
      "ConfirmPassword": "确认密码",
      "EnterTheConfirmPassword": "重新输入密码",
      "PasswordAlert": "确认密码不一致",
      "UnprotectWorkbook": "取消保护工作簿",
      "UnprotectPasswordAlert": "您提供的密码不正确。",
      "IncorrectPassword": "无法使用给定的密码打开文件或工作表",
      "PasswordAlertMsg": "请输入密码",
      "ConfirmPasswordAlertMsg": "请输入确认密码",
      "IsProtected": "受到保护",
      "PDF": "PDF文档",
      "AutoFillMergeAlertMsg": "为此，所有合并的单元格都需要具有相同的大小。",
      "Fluent": "流利",
      "FluentDark": "流利的黑暗",
      "Custom": "风俗",
      "WEEKDAY": "返回与日期对应的星期几。",
      "FillSeries": "填充系列",
      "CopyCells": "复制单元格",
      "FillFormattingOnly": "仅填充格式",
      "FillWithoutFormatting": "不带格式填充",
      "CustomFormat": "自定义数字格式",
      "CustomFormatPlaceholder": "键入或选择自定义格式",
      "CustomFormatTypeList": "类型",
      "CellReferenceTypoError": "我们在您的单元格引用中发现了拼写错误。是否要按如下方式更正此引用？",
      "AddCurrentSelection": "将当前选择添加到过滤器",
      "ExternalWorkbook": "导入的 Excel 文件包含外部工作簿引用。您想导入该文件吗？",
      "Directional": "方向性",
      "Shapes": "形状",
      "Indicators": "指标",
      "Ratings": "评级",
      "InvalidFormulaError": "我们发现您输入的公式无效。",
      "InvalidArguments": "我们发现您输入的公式包含无效参数。",
      "EmptyExpression": "我们发现您输入的公式包含空表达式。",
      "MismatchedParenthesis": "我们发现您键入的公式缺少一个或多个左括号或右括号。",
      "ImproperFormula": "我们发现您输入的公式不正确。",
      "WrongNumberOfArguments": "我们发现您输入的公式参数数量错误。",
      "Requires3Arguments": "我们发现您输入的公式需要 3 个参数。",
      "MismatchedStringQuotes": "我们发现您输入的公式的引号不匹配。",
      "FormulaCircularRef": "我们发现您输入的公式带有循环引用。"
    },
    "filemanager": {
      "NewFolder": "新建文件夾",
      "Upload": "上載",
      "Delete": "刪除",
      "Rename": "改名",
      "Download": "下載",
      "Cut": "切",
      "Copy": "複製",
      "Paste": "糊",
      "SortBy": "排序方式",
      "Refresh": "刷新",
      "Item-Selection": "選擇的項目",
      "Items-Selection": "選擇的項目",
      "View": "視圖",
      "Details": "細節",
      "SelectAll": "全選",
      "Open": "打開",
      "Tooltip-NewFolder": "新建文件夾",
      "Tooltip-Upload": "上載",
      "Tooltip-Delete": "刪除",
      "Tooltip-Rename": "改名",
      "Tooltip-Download": "下載",
      "Tooltip-Cut": "切",
      "Tooltip-Copy": "複製",
      "Tooltip-Paste": "糊",
      "Tooltip-SortBy": "排序方式",
      "Tooltip-Refresh": "刷新",
      "Tooltip-Selection": "清空選項",
      "Tooltip-View": "視圖",
      "Tooltip-Details": "細節",
      "Tooltip-SelectAll": "全選",
      "Name": "名稱",
      "Size": "尺寸",
      "DateModified": "改性",
      "DateCreated": "創建日期",
      "Path": "路徑",
      "Modified": "改性",
      "Created": "已建立",
      "Location": "位置",
      "Type": "類型",
      "Permission": "允許",
      "Ascending": "上升",
      "Descending": "降序",
      "None": "没有",
      "View-LargeIcons": "大圖標",
      "View-Details": "細節",
      "Search": "搜索",
      "Button-Ok": "好",
      "Button-Cancel": "取消",
      "Button-Yes": "是",
      "Button-No": "沒有",
      "Button-Create": "創建",
      "Button-Save": "保存",
      "Header-NewFolder": "夾",
      "Content-NewFolder": "輸入您的文件夾名稱",
      "Header-Rename": "改名",
      "Content-Rename": "輸入你的新名字",
      "Header-Rename-Confirmation": "重命名確認",
      "Content-Rename-Confirmation": "如果更改文件擴展名，文件可能會變得不穩定。您確定要更改嗎？",
      "Header-Delete": "刪除文件",
      "Content-Delete": "您確定要刪除此文件嗎？",
      "Header-Folder-Delete": "刪除資料夾",
      "Content-Folder-Delete": "您確定要刪除此文件夾嗎？",
      "Header-Multiple-Delete": "刪除多個文件",
      "Content-Multiple-Delete": "您確定要刪除這些{0}文件嗎？",
      "Header-Duplicate": "文件/文件夾存在",
      "Content-Duplicate": "{0}已經存在。您要重命名並粘貼嗎？",
      "Header-Upload": "上傳文件",
      "Error": "錯誤",
      "Validation-Empty": "文件或文件夾名稱不能為空。",
      "Validation-Invalid": "文件或文件夾名稱{0}包含無效字符。請使用其他名稱。有效的文件或文件夾名稱不能以點或空格結尾，並且不能包含以下任何字符：\\ /：*？“ <> |",
      "Validation-NewFolder-Exists": "名稱為{0}的文件或文件夾已存在。",
      "Validation-Rename-Exists": "無法將{0}重命名為{1}：目標已經存在。",
      "Folder-Empty": "這個文件夾是空的",
      "File-Upload": "將文件拖到此處進行上傳",
      "Search-Empty": "未找到結果",
      "Search-Key": "嘗試使用其他關鍵字",
      "Filter-Empty": "未找到結果",
      "Filter-Key": "嘗試使用其他過濾器",
      "Sub-Folder-Error": "目標文件夾是源文件夾的子文件夾。",
      "Same-Folder-Error": "目标文件夹与源文件夹相同。",
      "Access-Denied": "拒絕訪問",
      "Access-Details": "您無權訪問此文件夾。",
      "Header-Retry": "文件已存在",
      "Content-Retry": "該文件夾中已經存在具有該名稱的文件。你想幹什麼？",
      "Button-Keep-Both": "保留兩個",
      "Button-Replace": "更換",
      "Button-Skip": "跳躍",
      "ApplyAll-Label": "對所有當前項目執行此操作",
      "KB": "知识库",
      "Access-Message": "{0}無法訪問。您需要權限才能執行{1}操作。",
      "Network-Error": "NetworkError：无法在XMLHTTP上发送请求：无法加载",
      "Server-Error": "ServerError：来自的无效响应"
    },
    "calendar": {
      "today": "今天"
    },
    "datepicker": {
      "today": "今天",
      "placeholder": "選擇一個日期"
    },
    "daterangepicker": {
      "placeholder": "選擇日期範圍",
      "startLabel": "開始日期",
      "endLabel": "結束日期",
      "applyText": "應用",
      "cancelText": "取消",
      "selectedDays": "選定的日子",
      "days": "天",
      "customRange": "自訂範圍"
    },
    "timepicker": {
      "placeholder": "選擇一個時間"
    },
    "datetimepicker": {
      "today": "今天",
      "placeholder": "選擇日期和時間"
    },
    "dialog": {
      "close": "關"
    },
    "toast": {
      "close": "關"
    },
    "tab": {
      "closeButtonTitle": "關"
    },
    "schedule": {
      "day": "天",
      "week": "周",
      "workWeek": "工作周",
      "month": "月",
      "year": "年",
      "agenda": "议程",
      "weekAgenda": "周议程",
      "workWeekAgenda": "工作周议程",
      "monthAgenda": "月议程",
      "today": "今天",
      "noEvents": "没有事件",
      "emptyContainer": "这一天没有安排任何活动。",
      "allDay": "一整天",
      "start": "开始",
      "end": "结束",
      "more": "更多",
      "close": "关",
      "cancel": "取消",
      "noTitle": "（无题）",
      "delete": "删除",
      "deleteEvent": "删除事件",
      "deleteMultipleEvent": "刪除多個事件",
      "selectedItems": "选择的项目",
      "deleteSeries": "删除系列",
      "edit": "编辑",
      "editSeries": "编辑系列",
      "editEvent": "编辑事件",
      "createEvent": "创建",
      "subject": "学科",
      "addTitle": "添加标题",
      "moreDetails": "更多细节",
      "moreEvents": "更多事件",
      "save": "保存",
      "editContent": "您想如何更改该系列中的约会？",
      "deleteContent": "你确定要删除这个事件？",
      "deleteMultipleContent": "您確定要刪除所選事件嗎？",
      "newEvent": "新事件",
      "title": "标题",
      "location": "位置",
      "description": "描述",
      "timezone": "时区",
      "startTimezone": "启动时区",
      "endTimezone": "结束时区",
      "repeat": "重复",
      "saveButton": "保存",
      "cancelButton": "取消",
      "deleteButton": "删除",
      "recurrence": "循环",
      "wrongPattern": "重複發生模式無效。",
      "seriesChangeAlert": "您是否要取消對此系列特定實例所做的更改，然後再次將其與整個系列匹配？",
      "createError": "事件的持續時間必須短於其發生的頻率。縮短持續時間，或更改重複事件編輯器中的重複發生模式。",
      "sameDayAlert": "在同一天不能發生兩次同一事件。",
      "occurenceAlert": "如果它跳过同一约会的较晚发生，则无法重新安排定期约会的发生。",
      "editRecurrence": "编辑重复",
      "repeats": "重复",
      "alert": "警报",
      "startEndError": "选定的结束日期发生在开始日期之前。",
      "invalidDateError": "输入的日期值无效。",
      "blockAlert": "無法在阻止的時間範圍內安排事件。",
      "ok": "好",
      "yes": "是",
      "no": "沒有",
      "occurrence": "發生",
      "series": "系列",
      "previous": "以前",
      "next": "下一個",
      "timelineDay": "時間表日",
      "timelineWeek": "時間軸週",
      "timelineWorkWeek": "時間表工作週",
      "timelineMonth": "時間線月",
      "timelineYear": "時間軸年份",
      "editFollowingEvent": "後續活動",
      "deleteTitle": "刪除活動",
      "editTitle": "編輯活動",
      "beginFrom": "從開始",
      "endAt": "結束於",
      "expandAllDaySection": "展开全天部分",
      "collapseAllDaySection": "全天折叠部分",
      "searchTimezone": "搜索时区",
      "noRecords": "没有找到记录",
      "deleteRecurrenceContent": "你想只删除这个事件或整个系列？",
      "recurrenceDateValidation": "有些月份比所選日期少。在這幾個月中，事件將發生在該月的最後一個日期。"
    },
    "recurrenceeditor": {
      "none": "没有",
      "daily": "日常",
      "weekly": "每周",
      "monthly": "每月一次",
      "month": "月",
      "yearly": "每年",
      "never": "决不",
      "until": "直到",
      "count": "计数",
      "first": "第一",
      "second": "第二",
      "third": "第三",
      "fourth": "第四",
      "last": "持续",
      "repeat": "重复",
      "repeatEvery": "重复每一个",
      "on": "重复",
      "end": "结束",
      "onDay": "天",
      "days": "天",
      "weeks": "周",
      "months": "月份",
      "years": "年份",
      "every": "每一個",
      "summaryTimes": "時",
      "summaryOn": "上",
      "summaryUntil": "直到",
      "summaryRepeat": "重复",
      "summaryDay": "天",
      "summaryWeek": "週",
      "summaryMonth": "個月",
      "summaryYear": "年份",
      "monthWeek": "月週",
      "monthPosition": "月份位置",
      "monthExpander": "月擴展器",
      "yearExpander": "年擴展器",
      "repeatInterval": "重複間隔"
    },
    "gantt": {
      "emptyRecord": "無記錄可顯示",
      "id": "ID",
      "name": "名稱",
      "startDate": "開始日期",
      "endDate": "結束日期",
      "duration": "持續時間",
      "progress": "進展",
      "dependency": "相依性",
      "notes": "筆記",
      "baselineStartDate": "基准開始日期",
      "baselineEndDate": "基準結束日期",
      "taskMode": "任務模式",
      "changeScheduleMode": "更改時間表模式",
      "subTasksStartDate": "子任務開始日期",
      "subTasksEndDate": "子任務結束日期",
      "scheduleStartDate": "計劃開始日期",
      "scheduleEndDate": "計劃結束日期",
      "auto": "汽車",
      "manual": "手冊",
      "type": "類型",
      "offset": "偏移量",
      "resourceName": "資源資源",
      "resourceID": "資源ID",
      "day": "天",
      "hour": "小時",
      "minute": "分鐘",
      "days": "天",
      "hours": "小時",
      "minutes": "分鐘",
      "generalTab": "一般",
      "customTab": "自定義列",
      "writeNotes": "寫筆記",
      "addDialogTitle": "新任務",
      "editDialogTitle": "任務信息",
      "saveButton": "保存",
      "add": "加",
      "edit": "編輯",
      "update": "更新資料",
      "delete": "刪除",
      "cancel": "取消",
      "search": "搜索",
      "task": " 任務",
      "tasks": " 任務",
      "zoomIn": "放大",
      "zoomOut": "縮小",
      "zoomToFit": "縮放以適合",
      "excelExport": "Excel導出",
      "csvExport": "CSV導出",
      "expandAll": "展開全部",
      "collapseAll": "全部收縮",
      "nextTimeSpan": "下一個時間跨度",
      "prevTimeSpan": "以前的時間跨度",
      "okText": "好",
      "confirmDelete": "您確定要刪除記錄嗎？",
      "from": "從",
      "to": "至",
      "taskLink": "任務鏈接",
      "lag": "落後",
      "start": "開始",
      "finish": "完",
      "enterValue": "輸入值",
      "taskBeforePredecessor_FS": "您將“ {0}”移到了“ {1}”完成並鏈接了兩個任務之前開始。結果，這些鏈接不能被接受。在下面選擇一項操作即可執行",
      "taskAfterPredecessor_FS": "您將“ {0}”從“ {1}”移開，兩個任務已鏈接。結果，這些鏈接不能被接受。在下面選擇一項操作即可執行",
      "taskBeforePredecessor_SS": "您將“ {0}”移到了“ {1}”啟動之前，並且兩個任務已鏈接。結果，這些鏈接不能被接受。在下面選擇一項操作即可執行",
      "taskAfterPredecessor_SS": "在啟動“ {1}”之後，您將“ {0}”移至啟動位置，並且兩個任務已鏈接。結果，這些鏈接不能被接受。在下面選擇一項操作即可執行",
      "taskBeforePredecessor_FF": "您將“ {0}”移至“ {1}”完成之前已完成，並且兩個任務已鏈接。結果，這些鏈接不能被接受。在下面選擇一項操作即可執行",
      "taskAfterPredecessor_FF": "在完成“ {1}”之後，您將“ {0}”移至完成，並且兩個任務已鏈接。結果，這些鏈接不能被接受。在下面選擇一項操作即可執行",
      "taskBeforePredecessor_SF": "您將“ {0}”從“ {1}”移至開始，並且兩個任務已鏈接。結果，這些鏈接不能被接受。在下面選擇一項操作即可執行",
      "taskAfterPredecessor_SF": "啟動“ {1}”後，您將“ {0}”移至完成位置，並且兩個任務已鏈接。結果，這些鏈接不能被接受。在下面選擇一項操作即可執行",
      "taskInformation": "任務信息",
      "deleteTask": "刪除任務",
      "deleteDependency": "刪除依賴",
      "convert": "兌換",
      "save": "保存",
      "above": "以上",
      "below": "下面",
      "child": "兒童",
      "milestone": "里程碑",
      "toTask": "成 任務",
      "toMilestone": "成 里程碑",
      "eventMarkers": "事件標記",
      "leftTaskLabel": "左任務標籤",
      "rightTaskLabel": "正確的任務標籤",
      "timelineCell": "時間軸單元",
      "confirmPredecessorDelete": "您確定要刪除依賴關係鏈接嗎？",
      "unit": "單元",
      "work": "工作",
      "taskType": "任務類型",
      "unassignedTask": "未分配任務",
      "group": "組",
      "indent": "缩进",
      "outdent": "突出",
      "segments": "區隔",
      "splitTask": "拆分任務",
      "mergeTask": "合併任務",
      "left": "剩下",
      "right": "對"
    },
    "richtexteditor": {
      "alignments": "對齊方式",
      "justifyLeft": "左對齊",
      "justifyCenter": "居中對齊",
      "justifyRight": "右對齊",
      "justifyFull": "對齊對齊",
      "fontName": "字體名稱",
      "fontSize": "字體大小",
      "fontColor": "字體顏色",
      "backgroundColor": "背景顏色",
      "bold": "粗體",
      "italic": "斜體",
      "underline": "底線",
      "strikethrough": "刪除線",
      "clearFormat": "清除格式",
      "clearAll": "全部清除",
      "cut": "切",
      "copy": "複製",
      "paste": "糊",
      "unorderedList": "項目符號列表",
      "orderedList": "編號清單",
      "indent": "增加縮進",
      "outdent": "減少縮進",
      "undo": "撤消",
      "redo": "重做",
      "superscript": "上標",
      "subscript": "下標",
      "createLink": "插入超鏈接",
      "openLink": "打開鏈接",
      "editLink": "編輯連結",
      "removeLink": "刪除鏈接",
      "image": "插入圖片",
      "replace": "更換",
      "numberFormatList": "数字格式列表",
      "bulletFormatList": "项目符号格式列表",
      "audioReplace": "代替",
      "videoReplace": "代替",
      "videoAlign": "对齐",
      "videoDimension": "方面",
      "audioRemove": "消除",
      "videoRemove": "消除",
      "audioLayoutOption": "布局选项",
      "videoLayoutOption": "布局选项",
      "align": "對齊",
      "caption": "圖片說明",
      "remove": "去掉",
      "insertLink": "插入連結",
      "display": "顯示",
      "altText": "替代文字",
      "dimension": "變更尺寸",
      "fullscreen": "最大化",
      "maximize": "最大化",
      "minimize": "最小化",
      "lowerCase": "小寫",
      "upperCase": "大寫",
      "print": "打印",
      "formats": "格式",
      "sourcecode": "代碼查看",
      "preview": "預習",
      "viewside": "视面",
      "insertCode": "插入代碼",
      "linkText": "顯示文字",
      "linkTooltipLabel": "標題",
      "linkWebUrl": "網址",
      "linkTitle": "輸入標題",
      "linkurl": "https://example.com",
      "linkOpenInNewWindow": "在新窗口中打開鏈接",
      "linkHeader": "插入連結",
      "dialogInsert": "插入",
      "dialogCancel": "取消",
      "dialogUpdate": "更新資料",
      "imageHeader": "插入圖片",
      "audioHeader": "插入音频",
      "videoHeader": "插入视频",
      "imageLinkHeader": "您也可以提供網絡鏈接",
      "audioLinkHeader": "您还可以提供网络链接",
      "videoLinkHeader": "网址",
      "embedVideoLinkHeader": "媒体嵌入 URL",
      "mdimageLink": "請提供您圖片的網址",
      "imageUploadMessage": "將圖片放在此處或瀏覽以上傳",
      "audioUploadMessage": "拖放音频文件或浏览上传",
      "videoUploadMessage": "拖放视频文件或浏览上传",
      "imageDeviceUploadMessage": "點擊這裡上傳",
      "audioDeviceUploadMessage": "点击这里上传",
      "videoDeviceUploadMessage": "点击这里上传",
      "imageAlternateText": "替代文字",
      "alternateHeader": "替代文字",
      "browse": "瀏覽",
      "imageUrl": "https://example.com/image.png",
      "audioUrl": "https://example.com/audio.mp3",
      "videoUrl": "https://example.com/video.mp4",
      "webUrl": "网址",
      "embedUrl": "嵌入代码",
      "imageCaption": "标题",
      "imageSizeHeader": "圖片尺寸",
      "imageHeight": "高度",
      "imageWidth": "寬度",
      "videoHeight": "高度",
      "videoWidth": "宽度",
      "textPlaceholder": "輸入文字",
      "inserttablebtn": "插入表格",
      "tabledialogHeader": "插入表格",
      "tableWidth": "寬度",
      "cellpadding": "單元填充",
      "cellspacing": "單元間距",
      "columns": "列數",
      "rows": "行數",
      "tableRows": "排",
      "tableColumns": "柱子",
      "tableCellHorizontalAlign": "表格單元格水平對齊",
      "tableCellVerticalAlign": "表格垂直对齐",
      "createTable": "建立表格",
      "removeTable": "刪除表",
      "tableHeader": "标题行",
      "tableRemove": "删除表",
      "tableCellBackground": "表單元格背景",
      "tableEditProperties": "表格編輯屬性",
      "styles": "款式",
      "insertColumnLeft": "向左插入列",
      "insertColumnRight": "向右插入列",
      "deleteColumn": "刪除欄",
      "insertRowBefore": "在之前插入行",
      "insertRowAfter": "在之後插入行",
      "deleteRow": "刪除行",
      "tableEditHeader": "編輯表格",
      "TableHeadingText": "標題",
      "TableColText": "上校",
      "imageInsertLinkHeader": "插入連結",
      "editImageHeader": "編輯影像",
      "alignmentsDropDownLeft": "左對齊",
      "alignmentsDropDownCenter": "居中對齊",
      "alignmentsDropDownRight": "右對齊",
      "alignmentsDropDownJustify": "對齊對齊",
      "imageDisplayDropDownInline": "排隊",
      "imageDisplayDropDownBreak": "打破",
      "audioLayoutOptionDropDownInline": "排队",
      "audioLayoutOptionDropDownBreak": "休息",
      "videoLayoutOptionDropDownInline": "排队",
      "videoLayoutOptionDropDownBreak": "休息",
      "tableInsertRowDropDownBefore": "在之前插入行",
      "tableInsertRowDropDownAfter": "在之後插入行",
      "tableInsertRowDropDownDelete": "刪除行",
      "tableInsertColumnDropDownLeft": "向左插入列",
      "tableInsertColumnDropDownRight": "向右插入列",
      "tableInsertColumnDropDownDelete": "刪除欄",
      "tableVerticalAlignDropDownTop": "對齊頂部",
      "tableVerticalAlignDropDownMiddle": "中間對齊",
      "tableVerticalAlignDropDownBottom": "底部對齊",
      "tableStylesDropDownDashedBorder": "虛線邊框",
      "tableStylesDropDownAlternateRows": "交替行",
      "pasteFormat": "貼上格式",
      "pasteFormatContent": "選擇格式化動作",
      "plainText": "純文本",
      "cleanFormat": "清潔",
      "keepFormat": "保持",
      "pasteDialogOk": "好",
      "pasteDialogCancel": "取消",
      "fileManager": "文件管理器",
      "fileDialogHeader": "文件瀏覽器",
      "formatsDropDownParagraph": "段落",
      "formatsDropDownCode": "代码",
      "formatsDropDownQuotation": "引述",
      "formatsDropDownHeading1": "标题 1",
      "formatsDropDownHeading2": "标题 2",
      "formatsDropDownHeading3": "标题 3",
      "formatsDropDownHeading4": "标题 4",
      "fontNameSegoeUI": "SegoeUI",
      "fontNameArial": "Arial",
      "fontNameGeorgia": "Georgia",
      "fontNameImpact": "Impact",
      "fontNameTahoma": "Tahoma",
      "fontNameTimesNewRoman": "Times New Roman",
      "fontNameVerdana": "Verdana",
      "formatsOLListNumber": "数字",
      "formatsOLListLowerAlpha": "下阿尔法",
      "formatsOLListUpperAlpha": "上阿尔法",
      "formatsOLListLowerRoman": "下罗马",
      "formatsOLListUpperRoman": "上罗马",
      "formatsOLListLowerGreek": "下希腊语",
      "formatsULListDisc": "光盘",
      "formatsULListCircle": "圆圈",
      "formatsULListSquare": "正方形",
      "formatsOLListNone": "没有任何",
      "formatsULListNone": "没有任何",
      "formatPainter": "格式刷",
      "emojiPicker": "表情符号选择器",
      "embeddedCode": "嵌入代码",
      "pasteEmbeddedCodeHere": "将嵌入代码粘贴到此处",
      "emojiPickerTypeToFind": "键入以查找",
      "emojiPickerNoResultFound": "未找到结果",
      "emojiPickerTrySomethingElse": "尝试别的东西",
      "linkAriaLabel": "在新窗口中打开",
      "imageLinkAriaLabel": "在新窗口中打开",
      "unsupportedImage": "不支援的文件格式",
      "mergecells": "合并单元格",
      "verticalsplit": "垂直分割",
      "horizontalsplit": "水平分割",
      "numberFormatListLowerAlpha": "下阿尔法",
      "numberFormatListUpperAlpha": "上阿尔法",
      "numberFormatListLowerRoman": "下罗马语",
      "numberFormatListUpperRoman": "上罗马",
      "numberFormatListLowerGreek": "下层希腊语"
    },
    "colorpicker": {
      "Apply": "應用",
      "Cancel": "取消",
      "ModeSwitcher": "切換模式"
    },
    "uploader": {
      "Browse": "瀏覽...",
      "Clear": "明確",
      "Upload": "上載",
      "dropFilesHint": "或將文件放在此處",
      "invalidMaxFileSize": "檔案太大",
      "invalidMinFileSize": "檔案太小",
      "invalidFileType": "不允許的文件類型",
      "uploadFailedMessage": "文件上傳失敗",
      "uploadSuccessMessage": "文件上傳成功",
      "removedSuccessMessage": "文件已成功刪除",
      "removedFailedMessage": "無法刪除文件",
      "inProgress": "上載中",
      "readyToUploadMessage": "準備上傳",
      "abort": "中止",
      "remove": "去掉",
      "cancel": "取消",
      "delete": "刪除文件",
      "pauseUpload": "文件上傳已暫停",
      "pause": "暫停",
      "resume": "恢復",
      "retry": "重試",
      "fileUploadCancel": "文件上傳已取消"
    },
    "numerictextbox": {
      "incrementTitle": "增量值",
      "decrementTitle": "減量值"
    },
    "slider": {
      "incrementTitle": "增加",
      "decrementTitle": "減少"
    },
    "formValidator": {
      "required": "這是必填欄。",
      "email": "請輸入有效的電子郵件地址。",
      "url": "請輸入有效網址。",
      "date": "請輸入一個有效的日期。",
      "dateIso": "請輸入有效日期（ISO）。",
      "creditcard": "請輸入有效的卡號",
      "number": "請輸入一個有效的數字。",
      "digits": "請只輸入數字。",
      "maxLength": "請輸入不超過{0}個字符。",
      "minLength": "請至少輸入{0}個字符。",
      "rangeLength": "請輸入一個介於{0}和{1}個字符之間的值。",
      "range": "請輸入介於{0}和{1}之間的值。",
      "max": "請輸入小於或等於{0}的值。",
      "min": "請輸入一個大於或等於{0}的值。",
      "regex": "請輸入正確的值。",
      "tel": "請輸入一個有效的電話號碼。",
      "pattern": "請輸入正確的圖案值。",
      "equalTo": "請輸入有效的匹配文本"
    },
    "treegrid": {
      "Above": "以上",
      "Below": "下面",
      "AddRow": "添加行",
      "ExpandAll": "展開全部",
      "CollapseAll": "全部收縮"
    },
    "querybuilder": {
      "StartsWith": "以。。開始",
      "EndsWith": "以。。結束",
      "Contains": "包含",
      "NotLike": "包含",
      "Like": "不喜欢",
      "Equal": "等於",
      "NotEqual": "不平等",
      "LessThan": "少於",
      "LessThanOrEqual": "小於或等於",
      "GreaterThan": "比...更棒",
      "GreaterThanOrEqual": "大於等於",
      "Between": "之間",
      "NotBetween": "不介於",
      "Empty": "空的",
      "NotEmpty": "不是空的",
      "In": "在",
      "NotIn": "不在",
      "NotContains": "不包含",
      "Remove": "去掉",
      "SelectField": "選擇一個領域",
      "SelectOperator": "選擇運算符",
      "DeleteRule": "消除這種情況",
      "DeleteGroup": "刪除群組",
      "AddGroup": "新增群組",
      "AddCondition": "添加條件",
      "Edit": "編輯",
      "ValidationMessage": "這是必填欄",
      "SummaryViewTitle": "摘要視圖",
      "OtherFields": "其他領域",
      "AND": "和",
      "OR": "要么",
      "SelectValue": "輸入值",
      "IsEmpty": "是空的",
      "IsNotEmpty": "不為空",
      "IsNull": "一片空白",
      "IsNotNull": "不為空",
      "True": "真正",
      "False": "假",
      "DoesNotStartWith": "不开始于",
      "DoesNotEndWith": "不结束于",
      "DoesNotContain": "不含",
      "AddButton": "添加组/条件",
      "CloneGroup": "克隆组",
      "LockGroup": "锁集团",
      "CloneRule": "克隆规则",
      "LockRule": "锁定规则",
      "UnlockRule": "解锁规则",
      "UnlockGroup": "解锁组"
    },
    "barcode": {},
    "datamatrix": {},
    "qrcode": {},
    "pivotview": {
      "grandTotal": "累計",
      "total": "總",
      "value": "值",
      "noValue": "沒有價值",
      "row": "行",
      "column": "柱",
      "collapse": "坍方",
      "expand": "擴大",
      "rowAxisPrompt": "在這裡放行",
      "columnAxisPrompt": "將列放在此處",
      "valueAxisPrompt": "在這裡放值",
      "filterAxisPrompt": "在此放置過濾器",
      "filter": "過濾",
      "filtered": "已過濾",
      "sort": "分類",
      "filters": "篩選器",
      "rows": "行數",
      "columns": "列",
      "values": "價值觀",
      "close": "關",
      "cancel": "取消",
      "delete": "刪除",
      "CalculatedField": "計算場",
      "createCalculatedField": "創建計算字段",
      "fieldName": "輸入字段名稱",
      "error": "錯誤",
      "invalidFormula": "無效的公式。",
      "dropText": "例如：（'Sum（Order_Count）'+'Sum（In_Stock）'）* 250",
      "dropTextMobile": "在此處添加字段並編輯公式。",
      "dropAction": "計算字段不能放置在值軸以外的任何其他區域中。",
      "alert": "警報",
      "warning": "警告",
      "ok": "好",
      "search": "搜索",
      "drag": "拖動",
      "remove": "去掉",
      "allFields": "所有領域",
      "formula": "式",
      "addToRow": "添加到行",
      "addToColumn": "添加到列",
      "addToValue": "增值",
      "addToFilter": "添加到過濾器",
      "emptyData": "無記錄可顯示",
      "fieldExist": "此名稱中已經存在一個字段。請輸入其他名稱。",
      "confirmText": "此名稱中已經存在一個計算字段。您要更換嗎？",
      "noMatches": "無匹配",
      "format": "匯總值",
      "edit": "編輯",
      "clear": "明確",
      "formulaField": "拖放字段到公式",
      "dragField": "將字段拖到公式",
      "clearFilter": "明確",
      "by": "通過",
      "all": "所有",
      "multipleItems": "多個項目",
      "member": "會員",
      "label": "標籤",
      "date": "日期",
      "enterValue": "輸入值",
      "chooseDate": "輸入日期",
      "Before": "之前",
      "BeforeOrEqualTo": "之前或等於",
      "After": "後",
      "AfterOrEqualTo": "之後或等於",
      "labelTextContent": "顯示帶有標籤的項目",
      "dateTextContent": "顯示日期的項目",
      "valueTextContent": "顯示哪些項目",
      "Equals": "等於",
      "DoesNotEquals": "不等於",
      "BeginWith": "開始於",
      "DoesNotBeginWith": "並非始於",
      "EndsWith": "以。。結束",
      "DoesNotEndsWith": "不以",
      "Contains": "包含",
      "DoesNotContains": "不含",
      "GreaterThan": "比...更棒",
      "GreaterThanOrEqualTo": "大於或等於",
      "LessThan": "少於",
      "LessThanOrEqualTo": "小於或等於",
      "Between": "之間",
      "NotBetween": "不介於",
      "And": "和",
      "Sum": "和",
      "Count": "計數",
      "DistinctCount": "不重複計數",
      "Product": "產品",
      "Avg": "平均",
      "Min": "敏",
      "SampleVar": "樣本變量",
      "PopulationVar": "人口變量",
      "RunningTotals": "運行總計",
      "Max": "最高",
      "Index": "指數",
      "SampleStDev": "樣本標準差",
      "PopulationStDev": "人口標準",
      "PercentageOfRowTotal": "行總數的百分比",
      "PercentageOfParentTotal": "佔家長總數的百分比",
      "PercentageOfParentColumnTotal": "父欄總計的百分比",
      "PercentageOfParentRowTotal": "父行總數的百分比",
      "DifferenceFrom": "區別於",
      "PercentageOfDifferenceFrom": "相差百分比",
      "PercentageOfGrandTotal": "佔總數的百分比",
      "PercentageOfColumnTotal": "佔列總數的百分比",
      "NotEquals": "不等於",
      "AllValues": "所有值",
      "conditionalFormatting": "条件格式",
      "apply": "應用",
      "condition": "添加條件",
      "formatLabel": "格式",
      "valueFieldSettings": "值字段設置",
      "baseField": "基本字段：",
      "baseItem": "基本項目：",
      "summarizeValuesBy": "通過以下方式匯總值：",
      "sourceName": "欄位名稱：",
      "sourceCaption": "現場字幕：",
      "example": "例如：",
      "editorDataLimitMsg": " 更多項目。搜索以進一步完善。",
      "details": "細節",
      "manageRecords": "管理記錄",
      "Years": "年份",
      "Quarters": "宿舍",
      "Months": "月數",
      "Days": "天",
      "Hours": "小時",
      "Minutes": "分鐘",
      "Seconds": "秒",
      "save": "保存報告",
      "new": "創建一個新報告",
      "load": "加載",
      "saveAs": "另存為當前報告",
      "rename": "重命名當前報告",
      "deleteReport": "刪除當前報告",
      "export": "出口",
      "subTotals": "小計",
      "grandTotals": "總計",
      "reportName": "報告名稱：",
      "pdf": "PDF格式",
      "excel": "電子表格",
      "csv": "CSV文件",
      "png": "PNG",
      "jpeg": "JPEG格式",
      "svg": "SVG",
      "mdxQuery": "MDX查詢",
      "showSubTotals": "顯示小計",
      "doNotShowSubTotals": "不顯示總計",
      "showSubTotalsRowsOnly": "僅顯示小計行",
      "showSubTotalsColumnsOnly": "僅顯示小計列",
      "showGrandTotals": "顯示總計",
      "doNotShowGrandTotals": "不顯示總計",
      "showGrandTotalsRowsOnly": "僅顯示總計行",
      "showGrandTotalsColumnsOnly": "僅顯示總計列",
      "fieldList": "顯示欄位清單",
      "grid": "顯示表",
      "toolbarFormatting": "條件格式",
      "chart": "圖表",
      "reportMsg": "請輸入有效的報告名稱！！！",
      "reportList": "報告清單",
      "removeConfirm": "您確定要刪除此報告嗎？",
      "emptyReport": "找不到報告！",
      "bar": "酒吧",
      "line": "線",
      "area": "區域",
      "scatter": "分散",
      "polar": "極性",
      "of": "的",
      "emptyFormat": "找不到格式！！！",
      "emptyInput": "輸入一個值",
      "newReportConfirm": "是否要保存更改以報告？",
      "emptyReportName": "輸入報告名稱",
      "qtr": "季度",
      "null": "空值",
      "undefined": "未定義",
      "groupOutOfRange": "超出範圍",
      "fieldDropErrorAction": "您要移動的字段不能放置在報告的該區域中",
      "MoreOption": "更多...",
      "aggregate": "骨料",
      "drillThrough": "鑽透",
      "ascending": "上升",
      "descending": "降序",
      "number": "數",
      "currency": "貨幣",
      "percentage": "百分比",
      "formatType": "格式類型",
      "customText": "貨幣符號",
      "symbolPosition": "符號位置",
      "left": "剩下",
      "right": "對",
      "grouping": "分組",
      "true": "真正",
      "false": "假",
      "decimalPlaces": "小數位數",
      "numberFormat": "數字格式",
      "memberType": "欄位類型",
      "formatString": "格式字符串",
      "expressionField": "表達",
      "customFormat": "輸入自定義格式字符串",
      "selectedHierarchy": "家長階層",
      "olapDropText": "例如：[度量]。[訂單數量] +（[度量]。[訂單數量] * 0.10）",
      "Percent": "百分",
      "Custom": "自訂",
      "Measure": "測量",
      "Dimension": "尺寸",
      "Standard": "標準",
      "blank": "（空白）",
      "fieldTooltip": "拖放字段以創建表達式。並且，如果要編輯現有的計算字段！然後，您只需選擇“計算所得成員”下的字段即可實現。",
      "QuarterYear": "季度年",
      "fieldTitle": "欄位名稱",
      "drillError": "無法顯示計算字段的原始項目。",
      "caption": "現場字幕",
      "copy": "複製",
      "defaultReport": "默認報告",
      "customFormatString": "自訂格式",
      "invalidFormat": "無效的格式。",
      "group": "組",
      "unGroup": "取消分組",
      "invalidSelection": "無法將該選擇分組。",
      "groupName": "輸入標題以顯示在標題中",
      "captionName": "輸入組標題",
      "selectedItems": "選定項目",
      "groupFieldCaption": "現場字幕",
      "groupTitle": "組的名字",
      "startAt": "開始於",
      "endAt": "結束於",
      "groupBy": "間隔時間",
      "selectGroup": "選擇組",
      "numberFormatString": "示例：C，P，0000％，### 0。## 0＃等",
      "stackingcolumn": "堆積柱",
      "stackingbar": "疊桿",
      "stackingarea": "堆積面積",
      "stepline": "階梯線",
      "steparea": "台階面積",
      "splinearea": "花鍵面積",
      "spline": "花鍵",
      "stackingcolumn100": "100％堆積柱",
      "stackingbar100": "100％堆積酒吧",
      "stackingarea100": "100％堆積面積",
      "bubble": "氣泡",
      "pareto": "帕累托",
      "radar": "雷達",
      "chartTypeSettings": "圖表類型設置",
      "multipleAxes": "多軸",
      "sortAscending": "升序排序",
      "sortDescending": "降序排列",
      "sortNone": "排序數據順序",
      "clearCalculatedField": "清除編輯的字段信息",
      "editCalculatedField": "編輯計算字段",
      "ChartType": "圖表類型",
      "yes": "是",
      "no": "沒有",
      "numberFormatMenu": "數字格式...",
      "conditionalFormattingMenu": "条件格式...",
      "removeCalculatedField": "您確定要刪除此計算字段嗎？",
      "replaceConfirmBefore": "一份名為",
      "replaceConfirmAfter": " 已經存在。您要更換嗎？",
      "pie": "馅饼",
      "funnel": "漏斗",
      "doughnut": "甜甜圈",
      "pyramid": "金字塔",
      "showLegend": "显示图例",
      "exit": "出口",
      "invalidJSON": "无效的JSON数据",
      "invalidCSV": "无效的CSV数据",
      "stacked": "堆叠式",
      "single": "单身的",
      "multipleAxisMode": "多轴模式",
      "grandTotalPosition": "总计位置",
      "top": "最佳",
      "bottom": "底部",
      "None": "没有任何",
      "stackingline": "叠线",
      "stackingline100": "100% 叠线",
      "rowPage": "行寻呼机",
      "rowPerPage": "每页行数",
      "columnPage": "列寻呼机",
      "columnPerPage": "每页列数",
      "goToFirstPage": "转到第一页",
      "goToPreviousPage": "转到上一页",
      "goToNextPage": "转到下一页",
      "goToLastPage": "转到最后一页",
      "combined": "联合",
      "subTotalPosition": "小计位置",
      "auto": "汽車",
      "loading": "加载中..."
    },
    "pivotfieldlist": {
      "staticFieldList": "樞軸字段列表",
      "fieldList": "欄位清單",
      "dropFilterPrompt": "在此放置過濾器",
      "dropColPrompt": "將列放在此處",
      "dropRowPrompt": "在這裡放行",
      "dropValPrompt": "在這裡放值",
      "addPrompt": "在此處添加字段",
      "adaptiveFieldHeader": "選擇欄位",
      "centerHeader": "在以下軸之間拖動字段：",
      "add": "加",
      "drag": "拖動",
      "filter": "過濾",
      "filtered": "已過濾",
      "sort": "分類",
      "remove": "去掉",
      "filters": "篩選器",
      "rows": "行數",
      "columns": "列",
      "values": "價值觀",
      "CalculatedField": "計算場",
      "createCalculatedField": "創建計算字段",
      "fieldName": "輸入字段名稱",
      "error": "錯誤",
      "invalidFormula": "無效的公式。",
      "dropText": "例如：（'Sum（Order_Count）'+'Sum（In_Stock）''）* 250",
      "dropTextMobile": "在此處添加字段並編輯公式。",
      "dropAction": "計算字段不能放置在值軸以外的任何其他區域中。",
      "search": "搜索",
      "close": "關",
      "cancel": "取消",
      "delete": "刪除",
      "alert": "警報",
      "warning": "警告",
      "ok": "好",
      "allFields": "所有領域",
      "formula": "式",
      "fieldExist": "此名稱中已經存在一個字段。請輸入其他名稱。",
      "confirmText": "此名稱中已經存在一個計算字段。您要更換嗎？",
      "noMatches": "無匹配",
      "format": "匯總值",
      "edit": "編輯",
      "clear": "明確",
      "formulaField": "拖放字段到公式",
      "dragField": "將字段拖到公式",
      "clearFilter": "明確",
      "by": "通過",
      "enterValue": "輸入值",
      "chooseDate": "輸入日期",
      "all": "所有",
      "multipleItems": "多個項目",
      "Equals": "等於",
      "DoesNotEquals": "不等於",
      "BeginWith": "開始於",
      "DoesNotBeginWith": "並非始於",
      "EndsWith": "以。。結束",
      "DoesNotEndsWith": "不以",
      "Contains": "包含",
      "DoesNotContains": "不含",
      "GreaterThan": "比...更棒",
      "GreaterThanOrEqualTo": "大於或等於",
      "LessThan": "少於",
      "LessThanOrEqualTo": "小於或等於",
      "Between": "之間",
      "NotBetween": "不介於",
      "Before": "之前",
      "BeforeOrEqualTo": "之前或等於",
      "After": "後",
      "AfterOrEqualTo": "之後或等於",
      "member": "會員",
      "label": "標籤",
      "date": "日期",
      "value": "值",
      "labelTextContent": "顯示帶有標籤的項目",
      "dateTextContent": "顯示日期的項目",
      "valueTextContent": "顯示哪些項目",
      "And": "和",
      "Sum": "和",
      "Count": "計數",
      "DistinctCount": "不重複計數",
      "Product": "產品",
      "Avg": "平均",
      "Min": "敏",
      "Max": "最高",
      "Index": "指數",
      "SampleStDev": "樣本標準差",
      "PopulationStDev": "人口標準",
      "SampleVar": "樣本變量",
      "PopulationVar": "人口變量",
      "RunningTotals": "運行總計",
      "DifferenceFrom": "區別於",
      "PercentageOfDifferenceFrom": "相差百分比",
      "PercentageOfGrandTotal": "佔總數的百分比",
      "PercentageOfColumnTotal": "佔列總數的百分比",
      "PercentageOfRowTotal": "行總數的百分比",
      "PercentageOfParentTotal": "佔家長總數的百分比",
      "PercentageOfParentColumnTotal": "父欄總計的百分比",
      "PercentageOfParentRowTotal": "父行總數的百分比",
      "Years": "年份",
      "Quarters": "宿舍",
      "Months": "月數",
      "Days": "天",
      "Hours": "小時",
      "Minutes": "分鐘",
      "Seconds": "秒",
      "apply": "應用",
      "valueFieldSettings": "值字段設置",
      "sourceName": "欄位名稱：",
      "sourceCaption": "現場字幕：",
      "summarizeValuesBy": "通過以下方式匯總值：",
      "baseField": "基本字段：",
      "baseItem": "基本項目：",
      "example": "例如：",
      "editorDataLimitMsg": " 更多項目。搜索以進一步完善。",
      "deferLayoutUpdate": "延遲佈局更新",
      "null": "空值",
      "undefined": "未定義",
      "groupOutOfRange": "超出範圍",
      "fieldDropErrorAction": "您要移動的字段不能放置在報告的該區域中",
      "MoreOption": "更多...",
      "memberType": "欄位類型",
      "selectedHierarchy": "家長階層",
      "formatString": "格式字符串",
      "expressionField": "表達",
      "olapDropText": "例如：[度量]。[訂單數量] +（[度量]。[訂單數量] * 0.10）",
      "customFormat": "輸入自定義格式字符串",
      "Measure": "測量",
      "Dimension": "尺寸",
      "Standard": "標準",
      "Currency": "貨幣",
      "Percent": "百分",
      "Custom": "自訂",
      "blank": "（空白）",
      "fieldTooltip": "拖放字段以創建表達式。並且，如果要編輯現有的計算字段！您只需選擇“計算所得成員”下的字段即可實現。",
      "fieldTitle": "欄位名稱",
      "QuarterYear": "季度年",
      "caption": "現場字幕",
      "copy": "複製",
      "group": "組",
      "numberFormatString": "示例：C，P，0000％，### 0。## 0＃等",
      "sortAscending": "升序排序",
      "sortDescending": "降序排列",
      "sortNone": "排序數據順序",
      "clearCalculatedField": "清除編輯的字段信息",
      "editCalculatedField": "編輯計算字段",
      "selectGroup": "選擇組",
      "of": "的",
      "removeCalculatedField": "您確定要刪除此計算字段嗎？",
      "yes": "是",
      "no": "沒有",
      "None": "没有任何"
    },
    "pdfviewer": {
      "PdfViewer": "PDF查看器",
      "Cancel": "取消",
      "Download file": "下載文件",
      "Download": "下載",
      "Enter Password": "本文檔受密碼保護。請輸入密碼。",
      "File Corrupted": "文件損壞",
      "File Corrupted Content": "該文件已損壞，無法打開。",
      "Fit Page": "適合頁面",
      "Fit Width": "適合寬度",
      "Automatic": "自動",
      "Go To First Page": "顯示第一頁",
      "Invalid Password": "密碼錯誤。請再試一遍。",
      "Next Page": "顯示下一頁",
      "OK": "好的",
      "Open": "打開文件",
      "Page Number": "當前頁碼",
      "Previous Page": "顯示上一頁",
      "Go To Last Page": "顯示最後一頁",
      "Zoom": "放大",
      "Zoom In": "放大",
      "Zoom Out": "縮小",
      "Page Thumbnails": "頁面縮略圖",
      "Bookmarks": "書籤",
      "Print": "打印文件",
      "Password Protected": "需要密碼",
      "Copy": "複製",
      "Text Selection": "文字選擇工具",
      "Panning": "平移模式",
      "Text Search": "查找文字",
      "Find in document": "在文件中尋找",
      "Match case": "相符",
      "Apply": "應用",
      "GoToPage": "轉到頁面",
      "No matches": "查看器已完成文檔搜索。找不到更多匹配項",
      "No Text Found": "找不到文字",
      "Undo": "撤消",
      "Redo": "重做",
      "Annotation": "添加或編輯註釋",
      "Highlight": "突出顯示文字",
      "Underline": "下劃線文字",
      "Strikethrough": "刪除線文字",
      "Delete": "刪除註釋",
      "Opacity": "不透明度",
      "Color edit": "換顏色",
      "Opacity edit": "更改不透明度",
      "Highlight context": "突出",
      "Underline context": "底線",
      "Strikethrough context": "罷工",
      "Server error": "Web服務未監聽。 PDF Viewer的所有功能都依賴於Web服務。請啟動網絡服務以繼續。",
      "Open text": "打開",
      "First text": "第一頁",
      "Previous text": "上一頁",
      "Next text": "下一頁",
      "Last text": "最後一頁",
      "Zoom in text": "放大",
      "Zoom out text": "縮小",
      "Selection text": "選拔",
      "Pan text": "泛",
      "Print text": "打印",
      "Search text": "搜索",
      "Annotation Edit text": "編輯註釋",
      "Line Thickness": "線的粗細",
      "Line Properties": "線屬性",
      "Start Arrow": "開始箭頭",
      "End Arrow": "結束箭頭",
      "Line Style": "線型",
      "Fill Color": "填色",
      "Line Color": "線色",
      "None": "沒有",
      "Open Arrow": "打開",
      "Closed Arrow": "關閉",
      "Round Arrow": "回合",
      "Square Arrow": "廣場",
      "Diamond Arrow": "鑽石",
      "Butt": "屁股",
      "Cut": "切",
      "Paste": "糊",
      "Delete Context": "刪除",
      "Properties": "物產",
      "Add Stamp": "加蓋郵票",
      "Add Shapes": "添加形狀",
      "Stroke edit": "更改筆觸顏色",
      "Change thickness": "更改邊框厚度",
      "Add line": "加線",
      "Add arrow": "添加箭頭",
      "Add rectangle": "添加矩形",
      "Add circle": "添加圈子",
      "Add polygon": "添加多邊形",
      "Add Comments": "添加評論",
      "Comments": "註釋",
      "No Comments Yet": "暫時沒有評論",
      "Accepted": "公認",
      "Completed": "已完成",
      "Cancelled": "取消",
      "Rejected": "拒絕",
      "Leader Length": "引線長度",
      "Scale Ratio": "比例比",
      "Calibrate": "校準",
      "Calibrate Distance": "校準距離",
      "Calibrate Perimeter": "校準周長",
      "Calibrate Area": "校準區域",
      "Calibrate Radius": "校準半徑",
      "Calibrate Volume": "校準體積",
      "highlight": "强调",
      "underline": "强调",
      "strikethrough": "击穿",
      "Seach text": "搜索",
      "FormDesigner": "添加和编辑表单域",
      "Client error": "发现客户端错误。请检查 AjaxRequestSettings 属性中提供的自定义标头和 ServerActionSettings 属性中的 Web 操作方法。",
      "FormDesigner Edit text": "添加和编辑表单域",
      "SubmitForm": "提交表格",
      "Depth": "深度",
      "Closed": "关闭",
      "Round": "圆形的",
      "Square": "正方形",
      "Diamond": "钻石",
      "Edit": "编辑",
      "Comment": "评论",
      "Comment Panel": "评论面板",
      "Set Status": "设置状态",
      "Post": "邮政",
      "Page": "页",
      "Add a comment": "添加评论",
      "Add a reply": "添加回复",
      "Import Annotations": "从JSON文件导入注释",
      "Export Annotations": "将注释导出到JSON文件",
      "Export XFDF": "将注释导出到XFDF文件",
      "Import XFDF": "从XFDF文件导入注释",
      "Add": "添加",
      "Clear": "清除",
      "Bold": "大胆的",
      "Italic": "斜体",
      "Strikethroughs": "删除线",
      "Underlines": "强调",
      "Superscript": "上标",
      "Subscript": "下标",
      "Align left": "左对齐",
      "Align right": "左对齐",
      "Center": "中心",
      "Justify": "证明合法",
      "Font color": "字体颜色",
      "Text Align": "文本对齐",
      "Text Properties": "字体样式",
      "SignatureFieldDialogHeaderText": "添加签名",
      "HandwrittenSignatureDialogHeaderText": "添加签名",
      "InitialFieldDialogHeaderText": "添加首字母",
      "HandwrittenInitialDialogHeaderText": "添加首字母",
      "Draw Ink": "墨水",
      "Create": "创建",
      "Font family": "字体系列",
      "Font size": "字体大小",
      "Free Text": "自由文本",
      "Import Failed": "无效的JSON文件类型或文件名；请选择一个有效的JSON文件",
      "File not found": "在所需位置找不到导入的JSON文件",
      "Export Failed": "导出注释操作失败；请确保正确添加注释",
      "of": "的 ",
      "Dynamic": "动态的",
      "Standard Business": "标准业务",
      "Sign Here": "在这里签名",
      "Custom Stamp": "定制邮票",
      "Enter Signature as Name": "输入你的名字",
      "Draw-hand Signature": "画",
      "Type Signature": "类型",
      "Upload Signature": "上传",
      "Browse Signature Image": "浏览",
      "Save Signature": "保存签名",
      "Save Initial": "保存初始",
      "Textbox": "文本框",
      "Password": "密码",
      "Check Box": "复选框",
      "Radio Button": "单选按钮",
      "Dropdown": "落下",
      "List Box": "列表框",
      "Signature": "签名",
      "Delete FormField": "删除表单域",
      "Textbox Properties": "文本框属性",
      "Name": "姓名",
      "Tooltip": "工具提示",
      "Value": "价值",
      "Form Field Visibility": "表单字段可见性",
      "Read Only": "只读",
      "Required": "必需的",
      "Checked": "已检查",
      "Show Printing": "展示印刷",
      "Formatting": "格式",
      "Fill": "充满",
      "Border": "边界",
      "Border Color": "边框颜色",
      "Thickness": "厚度",
      "Max Length": "最长长度",
      "List Item": "项目名",
      "Export Value": "项目价值",
      "Dropdown Item List": "下拉项目列表",
      "List Box Item List": "列表框项目列表",
      "General": "一般的",
      "Appearance": "外貌",
      "Options": "选项",
      "Delete Item": "删除",
      "Up": "向上",
      "Down": "下",
      "Multiline": "多行",
      "Revised": "修改",
      "Reviewed": "已审核",
      "Received": "已收到",
      "Confidential": "机密的",
      "Approved": "得到正式认可的",
      "Not Approved": "不批准",
      "Witness": "见证",
      "Initial Here": "初始在这里",
      "Draft": "草稿",
      "Final": "最后",
      "For Public Release": "公开发布",
      "Not For Public Release": "不公开发布",
      "For Comment": "征求意见",
      "Void": "空白",
      "Preliminary Results": "初步结果",
      "Information Only": "仅供参考",
      "in": "在",
      "m": "米",
      "ft_in": "ft_in",
      "ft": "英尺",
      "p": "p",
      "cm": "厘米",
      "mm": "毫米",
      "pt": "点",
      "cu": "铜",
      "sq": "平方米",
      "Initial": "最初的"
    },
    "diagram": {
      "Copy": "複製",
      "Cut": "切",
      "Paste": "糊",
      "Undo": "撤消",
      "Redo": "重做",
      "SelectAll": "全選",
      "Grouping": "分組",
      "Group": "組",
      "UnGroup": "取消分組",
      "Order": "訂購",
      "BringToFront": "向前走",
      "MoveForward": "前進",
      "SendToBack": "發送回",
      "SendBackward": "向後發送"
    },
    "inplace-editor": {
      "save": "保存",
      "cancel": "取消",
      "loadingText": "加载中...",
      "editIcon": "點擊編輯",
      "editAreaClick": "点击编辑",
      "editAreaDoubleClick": "双击即可编辑"
    },
    "drawing": {},
    "drop-down-base": {
      "noRecordsTemplate": "沒有找到記錄",
      "actionFailureTemplate": "請求失敗"
    },
    "chart": {
      "Zoom": "放大",
      "ZoomIn": "放大",
      "ZoomOut": "縮小",
      "Reset": "重啟",
      "Pan": "泛",
      "ResetZoom": "重設縮放"
    },
    "maps": {
      "Zoom": "放大",
      "ZoomIn": "放大",
      "ZoomOut": "縮小",
      "Reset": "重啟",
      "Pan": "泛",
      "ResetZoom": "重設縮放",
      "ImageNotFound": "图片未找到"
    },
    "drop-down-tree": {
      "noRecordsTemplate": "沒有找到記錄",
      "actionFailureTemplate": "請求失敗",
      "overflowCountTemplate": "+ ${count}更多..",
      "totalCountTemplate": "已選擇${count}個"
    },
    "documenteditor": {
      "Table": "表",
      "Row": "行",
      "Cell": "細胞",
      "Ok": "好",
      "Apply": "缓慢而轻松",
      "Cancel": "取消",
      "Size": "尺寸",
      "Preferred Width": "首選寬度",
      "Points": "积分",
      "Percent": "百分",
      "Measure in": "量度",
      "Alignment": "结盟",
      "Left": "左边",
      "Center": "中心",
      "Right": "正确的",
      "Decimal": "第一个小数位",
      "Bar": "和一半",
      "Leader": "操作",
      "Tab": "逾期账户",
      "BookMarkList": "显示文档列表",
      "TabMarkList": "显示段落页",
      "Tab stop position": "磁盘空间",
      "Default tab stops": "卡丁车比赛太棒了。",
      "Tab stops to be cleared": "补卡",
      "Tabs": "选项卡窗口",
      "Set": "使用",
      "Clear": "电话",
      "Clear All": "删除所有内容",
      "Justify": "证明合法",
      "Indent from left": "从左缩进",
      "Borders and Shading": "边框和阴影",
      "Options": "选项",
      "Specify height": "指定高度",
      "At least": "至少",
      "Exactly": "确切地",
      "Row height is": "行高为",
      "Allow row to break across pages": "允许行跨页",
      "Repeat as header row at the top of each page": "在每页顶部重复作为标题行",
      "Vertical alignment": "垂直对齐",
      "Top": "顶部",
      "Bottom": "底部",
      "Default cell margins": "默认单元格边距",
      "Default cell spacing": "默认单元格间距",
      "Allow spacing between cells": "允许单元格之间的间距",
      "Cell margins": "单元格边距",
      "Same as the whole table": "和整张桌子一样",
      "Borders": "边框",
      "None": "没有任何",
      "Style": "风格",
      "Width": "宽度",
      "Height": "高度",
      "Letter": "信",
      "Tabloid": "小报",
      "Legal": "合法的",
      "Statement": "聲明",
      "Executive": "管理人员",
      "A3": "A3",
      "A4": "A4",
      "A5": "A5",
      "B4": "B4",
      "B5": "B5",
      "Custom Size": "自定义大小",
      "Different odd and even": "奇偶不同",
      "Different first page": "第一页不同",
      "From edge": "从边缘",
      "Header": "标头",
      "Footer": "页脚",
      "First Page Header": "第一页标题",
      "First Page Footer": "首页页脚",
      "Even Page Header": "偶数页眉",
      "Even Page Footer": "偶数页脚",
      "Odd Page Header": "奇数页眉",
      "Odd Page Footer": "奇数页脚",
      "Same as Previous": "与上一个相同",
      "Section": "部分",
      "Margin": "边距",
      "Paper": "纸",
      "Layout": "布局",
      "Orientation": "方向",
      "Landscape": "景观",
      "Portrait": "肖像",
      "Show page numbers": "显示页码",
      "Right align page numbers": "右对齐页码",
      "Nothing": "没有什么",
      "Tab leader": "制表头",
      "Show levels": "显示级别",
      "Use hyperlinks instead of page numbers": "使用超链接而不是页码",
      "Build table of contents from": "从中构建目录",
      "Styles": "样式",
      "Available styles": "可用款式",
      "TOC level": "TOC等级",
      "Heading": "标题",
      "Heading 1": "标题 1",
      "Heading 2": "标题 2",
      "Heading 3": "标题 3",
      "Heading 4": "标题 4",
      "Heading 5": "标题 5",
      "Heading 6": "标题 6",
      "List Paragraph": "列出段落",
      "Normal": "普通的",
      "Outline levels": "大綱級別",
      "Table entry fields": "表格条目字段",
      "Modify": "调整",
      "Color": "颜色",
      "Setting": "环境",
      "Box": "盒子",
      "All": "全部",
      "Custom": "风俗",
      "Preview": "预览",
      "Shading": "底纹",
      "Fill": "充满",
      "Apply To": "适用于",
      "Table Properties": "表格属性",
      "Cell Options": "单元选项",
      "Table Options": "表格选项",
      "Insert Table": "插入表格",
      "Number of columns": "列数",
      "Number of rows": "行数",
      "Text to display": "要显示的文本",
      "ScreenTip text": "屏幕提示文本",
      "Address": "地址",
      "Insert Hyperlink": "插入超链接",
      "Edit Hyperlink": "编辑超链接",
      "Insert": "插入",
      "General": "一般的",
      "Indentation": "缩进",
      "Before text": "正文前",
      "Special": "特别的",
      "First line": "第一行",
      "Hanging": "绞刑",
      "After text": "文字后",
      "By": "经过",
      "Before": "前",
      "Line Spacing": "行间距",
      "After": "后",
      "At": "在",
      "Multiple": "多种的",
      "Spacing": "间距",
      "Define new Multilevel list": "定义新的多级列表",
      "List level": "列表级别",
      "Choose level to modify": "选择要修改的级别",
      "Level": "等级",
      "Number format": "数字格式",
      "Number style for this level": "此级别的数字样式",
      "Enter formatting for number": "输入数字格式",
      "Start at": "开始于",
      "Restart list after": "之后重新启动列表",
      "Position": "位置",
      "Text indent at": "文字缩进于",
      "Aligned at": "对齐于",
      "Follow number with": "关注号码",
      "Tab character": "制表符",
      "Space": "空间",
      "Arabic": "阿拉伯",
      "UpRoman": "UpRoman",
      "LowRoman": "低罗马音",
      "UpLetter": "上信",
      "LowLetter": "低信",
      "Number": "數",
      "Leading zero": "前导零",
      "Bullet": "子弹",
      "Ordinal": "序数",
      "Ordinal Text": "序數文字",
      "For East": "对于东",
      "No Restart": "不重启",
      "Font": "字体",
      "Font style": "字体样式",
      "Underline style": "下划线样式",
      "Font color": "字体颜色",
      "Effects": "效果",
      "Strikethrough": "删除线",
      "Superscript": "上标",
      "Subscript": "下标",
      "Double strikethrough": "双删除线",
      "Regular": "常规的",
      "Bold": "大胆的",
      "Italic": "斜体",
      "Cut": "切",
      "Copy": "复制",
      "Paste": "粘贴",
      "Hyperlink": "超级链接",
      "Open Hyperlink": "打开超链接",
      "Copy Hyperlink": "复制超链接",
      "Remove Hyperlink": "删除超链接",
      "Paragraph": "段落",
      "Linked Style": "链接（段落和字符）",
      "Character": "特点",
      "Merge Cells": "合并单元格",
      "Insert Above": "在上方插入",
      "Insert Below": "在下方插入",
      "Insert Left": "向左插入",
      "Insert Right": "右插入",
      "Delete": "删除",
      "Delete Table": "删除表",
      "Delete Row": "删除行",
      "Delete Column": "删除列",
      "File Name": "文件名",
      "Format Type": "格式类型",
      "Save": "节省",
      "Navigation": "导航",
      "Results": "结果",
      "Replace": "代替",
      "Replace All": "全部替换",
      "We replaced all": "我们全部换掉",
      "Find": "寻找",
      "No matches": "无匹配",
      "All Done": "全做完了",
      "Result": "结果",
      "of": "的",
      "instances": "实例",
      "with": "和",
      "Click to follow link": "点击跟随链接",
      "Continue Numbering": "继续编号",
      "Bookmark name": "书签名称",
      "Close": "关闭",
      "Restart At": "重启时间",
      "Properties": "特性",
      "Name": "姓名",
      "Style type": "款式类型",
      "Style based on": "风格基于",
      "Style for following paragraph": "以下段落的样式",
      "Formatting": "格式化",
      "Numbering and Bullets": "编号和项目符号",
      "Numbering": "编号",
      "Update Field": "更新字段",
      "Edit Field": "编辑字段",
      "Bookmark": "书签",
      "Page Setup": "页面设置",
      "No bookmarks found": "找不到书签",
      "Number format tooltip information": "单级数字格式：</br>[PREFIX]%[LEVELNUMBER][SUFFIX]</br>例如，“第 %1 章”。 将显示编号如</br>第1章项目</br>第2章项目</br>... </br>第N章项目</br> </br>多级编号格式：</br>[ PREFIX]%[LEVELNUMBER][SUFFIX]+[PREFIX]%[LEVELNUMBER][SUFFIX]</br>例如，'%1.%2.' 将显示编号，如</br>1.1。 项目</br>1.2。 项目</br>…</br>1.N. 物品",
      "Format": "格式",
      "Create New Style": "创造新风格",
      "Modify Style": "修改样式",
      "New": "新的",
      "Bullets": "子弹",
      "Use bookmarks": "使用书签",
      "Table of Contents": "目录",
      "AutoFit": "自动调整",
      "AutoFit to Contents": "自动适应内容",
      "AutoFit to Window": "自动适应窗口",
      "Fixed Column Width": "固定列宽",
      "Reset": "重置",
      "Match case": "相符",
      "Whole words": "整个单词",
      "Add": "添加",
      "Go To": "去",
      "Search for": "搜索",
      "Replace with": "用。。。来代替",
      "TOC 1": "目录1",
      "TOC 2": "目录2",
      "TOC 3": "目录3",
      "TOC 4": "目录4",
      "TOC 5": "目录5",
      "TOC 6": "目录6",
      "TOC 7": "目录7",
      "TOC 8": "目录8",
      "TOC 9": "目录9",
      "Right-to-left": "右到左",
      "Left-to-right": "左到右",
      "Direction": "方向",
      "Table direction": "工作台方向",
      "Indent from right": "从右缩进",
      "Contextual Spacing": "不要在相同样式的段落之间添加空格",
      "Password Mismatch": "密码不匹配",
      "Restrict Editing": "限制编辑",
      "Formatting restrictions": "格式限制",
      "Allow formatting": "允许格式化",
      "Editing restrictions": "编辑限制",
      "Read only": "只读",
      "Exceptions Optional": "例外情况（可选）",
      "Select Part Of Document And User": "选择文档的某些部分并选择允许自由编辑它们的用户。",
      "Everyone": "每个人",
      "More users": "更多用户",
      "Add Users": "添加用户",
      "Enforcing Protection": "是，开始强制保护",
      "Start Enforcing Protection": "开始加强保护",
      "Enter User": "输入用户",
      "Users": "用户",
      "Enter new password": "输入新密码",
      "Reenter new password to confirm": "重新输入新密码以确认",
      "Your permissions": "您的权限",
      "Protected Document": "本文档受到保护，不会被无意编辑。",
      "FormFieldsOnly": "您只能在该地区填写表格。",
      "CommentsOnly": "您只能将评论插入该区域。",
      "ReadOnlyProtection": "您可以在此区域进行编辑。",
      "Stop Protection": "停止保护",
      "Password": "密码",
      "Spelling Editor": "拼写编辑器",
      "Spelling": "拼写",
      "Spell Check": "拼写检查",
      "Underline errors": "下划线错误",
      "Ignore": "忽略",
      "Ignore All": "忽略所有",
      "Add to Dictionary": "添加到词典",
      "Change": "改变",
      "Change All": "全部更改",
      "Suggestions": "建议",
      "The password is incorrect": "密码不正确",
      "Error in establishing connection with web server": "与网络服务器建立连接时出错",
      "Highlight the regions I can edit": "突出显示我可以编辑的区域",
      "Show All Regions I Can Edit": "显示我可以编辑的所有区域",
      "Find Next Region I Can Edit": "查找我可以编辑的下一个区域",
      "Keep source formatting": "保留源格式",
      "Match destination formatting": "匹配目标格式",
      "Text only": "纯文本",
      "Comments": "评论",
      "Type your comment": "输入您的评论",
      "Post": "邮政",
      "Reply": "回复",
      "New Comment": "新评论",
      "Edit": "编辑",
      "Resolve": "解决",
      "Reopen": "重开",
      "No comments in this document": "本文檔中無評論",
      "more": "更多",
      "Type your comment here": "在此输入您的评论",
      "Next Comment": "下一条评论",
      "Previous Comment": "上一条评论",
      "Un-posted comments": "未发表的评论",
      "Discard Comment": "添加的评论未发布。 如果您继续，该评论将被丢弃。",
      "Discard": "丢弃",
      "No Headings": "找不到标题！",
      "Add Headings": "本文档没有标题。 请添加标题并重试。",
      "More Options": "更多的选择",
      "Click to see this comment": "点击查看此评论",
      "Drop Down Form Field": "下拉表单域",
      "Drop-down items": "下拉项",
      "Items in drop-down list": "下拉列表中的项目",
      "ADD": "添加",
      "REMOVE": "消除",
      "Field settings": "字段设置",
      "Tooltip": "工具提示",
      "Drop-down enabled": "下拉已启用",
      "Check Box Form Field": "复选框表单域",
      "Check box size": "复选框大小",
      "Auto": "汽车",
      "Default value": "默认值",
      "Not checked": "未勾选",
      "Checked": "勾选",
      "Check box enabled": "启用复选框",
      "Text Form Field": "文本表单域",
      "Type": "类型",
      "Default text": "默认文本",
      "Maximum length": "最大长度",
      "Text format": "文本格式",
      "Fill-in enabled": "启用填充",
      "Default number": "默认号码",
      "Default date": "默认日期",
      "Date format": "日期格式",
      "Merge Track": "此操作不会标记为更改。 你想继续吗？",
      "UnTrack": "无法追踪",
      "Accept": "接受",
      "Reject": "拒绝",
      "Previous Changes": "以前的变化",
      "Next Changes": "接下来的变化",
      "Inserted": "已插入",
      "Deleted": "已删除",
      "Move From": "S",
      "Move To": "因为",
      "Changes": "变化",
      "Accept all": "全都接受",
      "Reject all": "全部拒绝",
      "No changes": "没有变化",
      "Accept Changes": "接受更改",
      "Reject Changes": "拒绝更改",
      "User": "用户",
      "View": "看法",
      "Insertion": "插入",
      "Deletion": "删除",
      "All caps": "全部大写",
      "This region is locked by": "该区域被锁定",
      "Lock": "锁",
      "Unlock": "开锁",
      "Already locked": "所选区域或部分区域已被其他用户锁定",
      "1, 2, 3, ...": "1、2、3，...",
      "a, b, c, ...": "a，b，c，...",
      "A, B, C, ...": "A，B，C，...",
      "I, II, III, ...": "I，II，III，...",
      "i, ii, iii, ...": "i，ii，iii，...",
      "Text Form": "文本形式",
      "Check Box": "复选框",
      "DropDown": "落下",
      "Form Fields": "表单字段",
      "Fillin enabled": "启用填充",
      "Multiple Comment": "请发表你的评论",
      "InsertFootnote": "插入脚注",
      "InsertEndnote": "插入尾注",
      "Footnote": "脚注",
      "Endnote": "尾注",
      "Notes Options": "查看选项",
      "Click to View/Edit Footnote": "单击以查看/编辑脚注",
      "Click to View/Edit Endnote": "单击以查看/编辑尾注",
      "InsertAsRows": "作为新行插入",
      "InsertAsColumns": "作为新列插入",
      "OverwriteCells": "覆盖单元格",
      "NestTable": "嵌套表",
      "No suggestions": "没有建议",
      "More Suggestion": "更多建议",
      "Ignore Once": "忽略一次",
      "Keep With Next": "继续下一步",
      "Keep Lines Together": "保持线条在一起",
      "WidowControl": "寡妇/孤儿控制",
      "Indents and Spacing": "缩进和间距",
      "Line and Page Breaks": "换行符和分页符",
      "Pagination": "分页",
      "Single": "单身的",
      "DashSmallGap": "破折号小缺口",
      "DashDot": "点划线",
      "Double": "双倍的",
      "ThinThickSmallGap": "薄厚小间隙",
      "ThickThinSmallGap": "厚薄小间隙",
      "ThickThinMediumGap": "厚薄中等间隙",
      "ThickThinLargeGap": "厚薄大间隙",
      "SingleWavy": "单波浪",
      "DoubleWavy": "双波浪",
      "Inset": "插图",
      "DashLargeGap": "破折号大缺口",
      "Dot": "点",
      "DashDotDot": "点点滴滴",
      "Triple": "三倍",
      "ThinThickThinSmallGap": "薄厚薄小间隙",
      "ThinThickThinMediumGap": "薄厚薄中等间隙",
      "ThinThickThinLargeGap": "薄厚薄大间隙",
      "DashDotStroked": "DashDotStroked",
      "Engrave3D": "雕刻3D",
      "Thick": "厚的",
      "Outset": "一开始",
      "Emboss3D": "立体浮雕",
      "ThinThickLargeGap": "薄厚大间隙",
      "ThinThickMediumGap": "薄厚中等间隙",
      "Number of rows must be between": "行数必须介于",
      "Number of columns must be between": "列数必须介于",
      "and": "和",
      "Unlimited": "无限",
      "Regular text": "常规文本",
      "Date": "日期",
      "Uppercase": "大写",
      "Lowercase": "小写",
      "FirstCapital": "首控",
      "TitleCase": "书架",
      "Filling in forms": "填写表格",
      "px": "像素",
      "Tracked changes": "跟踪更改",
      "TrackChangesOnly": "您可以在此区域中进行编辑，但所有更改都将被跟踪。",
      "RemovedIgnore": "你想删除忽略的异常吗？",
      "RemovedIgnoreExceptions": "如果您在文档保护中进行此更改，Word 将忽略此文档中的所有异常。",
      "Information": "信息",
      "Yes": "是的",
      "No": "不",
      "Page Break": "分页符",
      "Column Break": "分栏符",
      "Section Break Next Page": "分节符（下一页）",
      "Section Break Continuous": "分节符（连续）",
      "Unsupported format": "不支持您选择的文件格式。 请选择有效格式。",
      "One": "一",
      "Two": "二",
      "Three": "三",
      "Presets": "预设",
      "Columns": "列",
      "Split your text into two or more columns": "将您的文本分成两列或更多列",
      "Line between column": "列之间的线",
      "Width and Spacing": "宽度和间距",
      "Equal column width": "列宽相等",
      "Column": "柱子",
      "Paste Content Dialog": "由于浏览器的安全策略，从系统剪贴板粘贴是受限制的。",
      "Paste Content CheckBox": "他没有再出现。",
      "The top/bottom margins are too large for the page height in some sections.": "某些部分的顶部/底部边距对于页面高度来说太大。",
      "Column width cannot be less than 36 pt.": "列宽不能小于 36 磅。",
      "Left and right margins.": "您为左右边距、列间距或段落缩进选择的设置对于同一部分中的页面宽度来说太大。",
      "The address of this site is 'not valid. Check the address and try again.": "该网站的地址无效。检查地址并重试。",
      "Alt Text": "替代文本",
      "Title": "标题",
      "Description": "描述",
      "Left Indent": "通讯行业",
      "Right Indent": "正确路线",
      "Hanging Indent": "每个人都有自己的角色",
      "First Line Indent": "首先是弯曲线条。",
      "Left Margin": "玉塔",
      "Right Margin": "倾倒",
      "Top Margin": "传感器",
      "Bottom Margin": "演讲",
      "Left Tab": "我在你耳边",
      "Right Tab": "右耳",
      "Center Tab": "中央屏幕",
      "Decimal Tab": "死去的动物",
      "Bar Tab": "卡米尔·B.",
      "Move Table Column": "移动表中的列",
      "Paste CheckBox Content Dialog": "不再显示",
      "The address of this site is not valid. Check the address and try again.": "该网站的地址无效。",
      "Left and right margins": "您为左右边距、列间距或段落缩进选择的设置对于同一部分中的页面宽度来说太大。",
      "Number of rows must be between 1 and 32767.": "行数必须介于 1 到 32767 之间。",
      "Number of columns must be between 1 and 63.": "列数必须介于 1 到 63 之间。",
      "": "The top/bottom margins are too large for the page height in some sections.",
      "You may format text only with certain styles": "您只能使用某些样式来设置文本格式。",
      "Ignore all": "忽略所有"
    },
    "documenteditorcontainer": {
      "New": "新的",
      "Open": "打开",
      "Undo": "撤消",
      "Redo": "重做",
      "Image": "图像",
      "Table": "桌子",
      "Link": "关联",
      "Bookmark": "书签",
      "Table of Contents": "目录",
      "HEADING - - - - 1": "标题----1",
      "HEADING - - - - 2": "标题----2",
      "HEADING - - - - 3": "标题----3",
      "Header": "标头",
      "Footer": "页脚",
      "Page Setup": "页面设置",
      "Page Number": "页码",
      "Break": "休息",
      "Find": "寻找",
      "Local Clipboard": "本地剪贴板",
      "Restrict Editing": "限制编辑",
      "Upload from computer": "从电脑上传",
      "By URL": "通过网址",
      "Page Break": "分页符",
      "Section Break": "分节符",
      "Header And Footer": "页眉页脚",
      "Options": "选项",
      "Levels": "关卡",
      "Different First Page": "第一页不同",
      "Different header and footer for odd and even pages": "奇数页和偶数页的不同页眉和页脚。",
      "Different Odd And Even Pages": "不同的奇偶页",
      "Different header and footer for first page": "第一页的不同页眉和页脚。",
      "Position": "位置",
      "Header from Top": "顶部标题",
      "Footer from Bottom": "从底部开始的页脚",
      "Distance from top of the page to top of the header": "从页面顶部到页眉顶部的距离。",
      "Distance from bottom of the page to bottom of the footer": "从页面底部到页脚底部的距离。",
      "Aspect ratio": "纵横比",
      "W": "W",
      "H": "H",
      "Width": "宽度",
      "Height": "高度",
      "Text": "文本",
      "Paragraph": "段落",
      "Fill": "充满",
      "Fill color": "填色",
      "Border Style": "边框样式",
      "Outside borders": "境外",
      "All borders": "所有边框",
      "Inside borders": "内部边界",
      "Left border": "左边框",
      "Inside vertical border": "垂直边框内",
      "Right border": "右边框",
      "Top border": "上边框",
      "Inside horizontal border": "水平边框内",
      "Bottom border": "底边框",
      "Border color": "边框颜色",
      "Border width": "边框宽度",
      "Cell": "细胞",
      "Merge cells": "合并单元格",
      "Insert Or Delete": "插入/删除",
      "Insert columns to the left": "在左侧插入列",
      "Insert columns to the right": "在右侧插入列",
      "Insert rows above": "在上面插入行",
      "Insert rows below": "在下方插入行",
      "Delete rows": "删除行",
      "Delete columns": "删除列",
      "Cell Margin": "单元边距",
      "Top": "顶部",
      "Bottom": "底部",
      "Left": "左边",
      "Right": "正确的",
      "Align Text": "对齐文本",
      "Align top": "对齐顶部",
      "Align bottom": "对齐底部",
      "Align center": "居中对齐",
      "Number of heading or outline levels to be shown in table of contents": "要在目录中显示的标题或大纲级别数。",
      "Show page numbers": "显示页码",
      "Show page numbers in table of contents": "在目录中显示页码。",
      "Right align page numbers": "右对齐页码",
      "Right align page numbers in table of contents": "右对齐目录中的页码。",
      "Use hyperlinks": "使用超链接",
      "Use hyperlinks instead of page numbers": "使用超链接而不是页码。",
      "Font": "字体",
      "Font Size": "字体大小",
      "Font color": "字体颜色",
      "Text highlight color": "文字高亮颜色",
      "Clear all formatting": "清除所有格式",
      "Bold Tooltip": "粗体 (Ctrl+B)",
      "Italic Tooltip": "斜体 (Ctrl+I)",
      "Underline Tooltip": "下划线 (Ctrl+U)",
      "Strikethrough": "删除线",
      "Superscript Tooltip": "上标 (Ctrl+Shift++)",
      "Subscript Tooltip": "下标 (Ctrl+=)",
      "Align left Tooltip": "左对齐 (Ctrl+L)",
      "Center Tooltip": "居中 (Ctrl+E)",
      "Align right Tooltip": "右对齐 (Ctrl+R)",
      "Justify Tooltip": "对齐 (Ctrl+J)",
      "Decrease indent": "減少縮進",
      "Increase indent": "增加缩进",
      "Line spacing": "行间距",
      "Bullets": "子弹",
      "Numbering": "编号",
      "Styles": "样式",
      "Manage Styles": "管理样式",
      "Page": "页",
      "of": "的",
      "Fit one page": "适合一页",
      "Spell Check": "拼写检查",
      "Spelling": "用户",
      "Underline errors": "下划线错误",
      "Fit page width": "适合页面宽度",
      "Update": "更新",
      "Cancel": "取消",
      "Insert": "插入",
      "No Border": "无边界",
      "Create a new document": "创建一个新文档。",
      "Open a document": "打开文档。",
      "Undo Tooltip": "撤消上一个操作 (Ctrl+Z)。",
      "Redo Tooltip": "重做上次操作 (Ctrl+Y)。",
      "Insert inline picture from a file": "从文件中插入内联图片。",
      "Insert a table into the document": "在文档中插入表格",
      "Create Hyperlink": "在文档中创建链接以快速访问网页和文件 (Ctrl+K)。",
      "Insert a bookmark in a specific place in this document": "在此文档的特定位置插入书签。",
      "Provide an overview of your document by adding a table of contents": "通过添加目录提供文档的概述。",
      "Add or edit the header": "添加或编辑标题。",
      "Add or edit the footer": "添加或编辑页脚。",
      "Open the page setup dialog": "打开页面设置对话框。",
      "Add page numbers": "添加页码。",
      "Find Text": "在文档中查找文本 (Ctrl+F)。",
      "Toggle between the internal clipboard and system clipboard": "在内部剪贴板和系统剪贴板之间切换。</br>由于浏览器安全策略，拒绝通过脚本访问系统剪贴板。 相反，</br> 1. 您可以启用内部剪贴板以在组件内剪切、复制和粘贴。</br> 2. 您可以使用键盘快捷键（Ctrl+X、Ctrl+C 和 Ctrl+V）来剪切 , 使用系统剪贴板复制和粘贴。",
      "Current Page Number": "文档中的当前页码。 单击或点击以导航特定页面。",
      "Read only": "只读",
      "Protections": "保护措施",
      "Error in establishing connection with web server": "与网络服务器建立连接时出错",
      "Single": "单身的",
      "Double": "双倍的",
      "New comment": "新评论",
      "Comments": "评论",
      "Print layout": "打印布局",
      "Web layout": "网页布局",
      "Text Form": "文本形式",
      "Check Box": "复选框",
      "DropDown": "落下",
      "Update Fields": "更新字段",
      "Update cross reference fields": "更新交叉引用字段",
      "Hide properties pane": "隐藏属性面板",
      "Show properties pane": "显示属性面板",
      "Form Fields": "表单字段",
      "Track Changes": "跟踪文档中所做的更改",
      "TrackChanges": "跟踪变化",
      "AllCaps": "全大写",
      "Change case Tooltip": "改变大小写",
      "Insert Footnote": "插入脚注",
      "Insert Endnote": "插入尾注",
      "Footnote Tooltip": "插入脚注 (Alt+Ctrl+F)。",
      "Endnote Tooltip": "插入尾注 (Alt+Ctrl+D)。",
      "UPPERCASE": "大写字母",
      "No color": "没有颜色",
      "Top margin": "上边距",
      "Bottom margin": "下边距",
      "Left margin": "左边距",
      "Right margin": "右边距",
      "Normal": "普通的",
      "Heading": "标题",
      "Heading 1": "标题 1",
      "Heading 2": "标题 2",
      "Heading 3": "标题 3",
      "Heading 4": "标题 4",
      "Heading 5": "标题 5",
      "Heading 6": "标题 6",
      "ZoomLevelTooltip": "缩放级别。 单击或点击以打开“缩放”选项。",
      "None": "没有任何",
      "Borders": "边框",
      "ShowHiddenMarks Tooltip": "显示隐藏的字符，如空格、制表符、段落标记和换行符。(Ctrl + *)",
      "Columns": "列",
      "Column Break": "分栏符",
      "Link to Previous": "上一个链接",
      "Link to PreviousTooltip": "将此部分链接到上一部分的页眉或页脚。",
      "Alternate Text": "替代文本",
      "The address of this site is not valid. Check the address and try again.": "该网站的地址无效。",
      "OK": "好的",
      "Information": "信息",
      "Page Breaks": "在页面底部",
      "Section Breaks": "这片区域被占领了。",
      "Column": "分享",
      "Next Page": "另一张脸",
      "Continuous": "工作"
    },
    "carousel": {
      "nextSlide": "下一张幻灯片",
      "of": "的",
      "pauseSlideTransition": "暂停幻灯片过渡",
      "playSlideTransition": "播放幻灯片过渡",
      "previousSlide": "上一张幻灯片",
      "slide": "滑动",
      "slideShow": "幻灯片放映"
    },
    "message": {
      "close": "关"
    },
    "image-editor": {
      "0": "0%",
      "1": "1",
      "2": "2",
      "3": "3",
      "4": "4",
      "5": "5",
      "20": "20%",
      "40": "40%",
      "60": "60%",
      "80": "80%",
      "100": "100%",
      "Crop": "庄稼",
      "CropAndTransform": "裁剪和变换",
      "ZoomIn": "放大",
      "ZoomOut": "缩小",
      "Undo": "撤消",
      "Redo": "重做",
      "Transform": "转换",
      "Annotation": "注解",
      "Finetune": "微调",
      "Brightness": "亮度",
      "Contrast": "对比",
      "Hue": "色调",
      "Saturation": "饱和",
      "Opacity": "不透明度",
      "Blur": "模糊",
      "Sharpen": "锐化",
      "Exposure": "接触",
      "Filter": "筛选",
      "Default": "默认",
      "Chrome": "铬合金",
      "Cold": "寒冷的",
      "Warm": "温暖的",
      "Grayscale": "灰度",
      "BlackAndWhite": "黑与白",
      "Sepia": "棕褐色",
      "Invert": "倒置",
      "Text": "添加文字",
      "Pen": "笔",
      "Reset": "重置",
      "Save": "节省",
      "Select": "选择",
      "RotateLeft": "向左旋转",
      "RotateRight": "右旋",
      "HorizontalFlip": "水平翻转",
      "VerticalFlip": "垂直翻转",
      "OK": "好的",
      "Cancel": "取消",
      "FillColor": "填色",
      "StrokeColor": "描边颜色",
      "StrokeWidth": "笔划宽度",
      "FontFamily": "字体系列",
      "FontStyle": "字体样式",
      "FontSize": "字体大小",
      "FontColor": "字体颜色",
      "Pan": "平底锅",
      "Move": "移动",
      "Load": "加载",
      "Custom": "风俗",
      "Square": "正方形",
      "Circle": "圆圈",
      "Ellipse": "椭圆",
      "Rectangle": "长方形",
      "Line": "线",
      "Arrow": "箭",
      "Path": "小路",
      "Bold": "大胆的",
      "Italic": "斜体",
      "BoldItalic": "加粗斜体",
      "XSmall": "X 小号",
      "Small": "小的",
      "Medium": "中等的",
      "Large": "大的",
      "XLarge": "XL 号",
      "ABC": "ABC",
      "Browse": "浏览",
      "Duplicate": "复制",
      "Remove": "消除",
      "EditText": "编辑文本",
      "Start": "开始",
      "End": "结尾",
      "Bar": "酒吧",
      "ArrowSolid": "实心箭头",
      "CircleSolid": "实心圆",
      "SquareSolid": "方实心",
      "None": "没有任何",
      "CropSelection": "作物选择",
      "Image": "添加图片",
      "Transparency": "透明度",
      "Height": "高度",
      "Width": "宽度",
      "AspectRatio": "保持纵横比",
      "W": "瓦",
      "H": "H",
      "DragText": "将您的图像拖放到此处或",
      "DropText": "将您的图片拖放到此处或",
      "BrowseText": "浏览此处...",
      "SupportText": "支持：",
      "Frame": "框架",
      "Mat": "垫",
      "Bevel": "斜角",
      "Inset": "插图",
      "Hook": "钩",
      "Color": "颜色",
      "Size": "尺寸",
      "Offset": "抵消",
      "Radius": "半径",
      "Amount": "数量",
      "Resize": "调整大小",
      "Border": "边界",
      "Solid": "坚硬的",
      "Dashed": "虚线",
      "Dotted": "点状",
      "GradientColor": "渐变色",
      "ConfirmDialogHeader": "确认保存更改",
      "ConfirmDialogContent": "您想保存对图像所做的更改吗？",
      "AlertDialogHeader": "不支持的文件",
      "AlertDialogContent": "不支持删除的文件。",
      "Yes": "是的",
      "No": "不",
      "ImageErrorDialogHeader": "选择图像时出错。",
      "ImageErrorDialogContent": "只需选择您要打开的图像即可。",
      "Straighten": "难的",
      "NoOutline": "不需要",
      "Clone": "隐喻",
      "Delete": "破坏"
    },
    "mention": {
      "noRecordsTemplate": "没有找到记录"
    },
    "inplaceeditor": {
      "save": "节省",
      "cancel": "取消",
      "loadingText": "加载中...",
      "editIcon": "点击编辑",
      "editAreaClick": "点击编辑",
      "editAreaDoubleClick": "双击即可编辑"
    },
    "PdfViewer": {
      "PdfViewer": "PDF阅读器",
      "Cancel": "取消",
      "Download file": "下载文件",
      "Download": "下载",
      "Enter Password": "本文档受密码保护。",
      "File Corrupted": "文件损坏",
      "File Corrupted Content": "文件已损坏且无法打开。",
      "Fit Page": "适合页面",
      "Fit Width": "适合宽度",
      "Automatic": "自动的",
      "Go To First Page": "显示第一页",
      "Invalid Password": "密码错误。",
      "Next Page": "显示下一页",
      "OK": "好的",
      "Open": "打开文件",
      "Page Number": "当前页码",
      "Previous Page": "显示上一页",
      "Go To Last Page": "显示最后一页",
      "Zoom": "飞涨",
      "Zoom In": "放大",
      "Zoom Out": "缩小",
      "Page Thumbnails": "页面缩略图",
      "Bookmarks": "书签",
      "Print": "打印文件",
      "Password Protected": "要求输入密码",
      "Copy": "复制",
      "Text Selection": "文本选择工具",
      "Panning": "平移模式",
      "Text Search": "查找文字",
      "Find in document": "在文档中查找",
      "Match case": "相符",
      "Apply": "申请",
      "GoToPage": "转到页面",
      "No matches": "查看者已完成文档搜索。",
      "No Text Found": "未找到文字",
      "Undo": "撤消",
      "Redo": "重做",
      "Annotation": "添加或编辑注释",
      "Highlight": "突出显示文本",
      "Underline": "下划线文本",
      "Strikethrough": "删除线文本",
      "Delete": "删除注释",
      "Opacity": "不透明度",
      "Color edit": "换颜色",
      "Opacity edit": "更改不透明度",
      "Highlight context": "强调",
      "Underline context": "强调",
      "Strikethrough context": "删除线",
      "Server error": "Web 服务未监听。 ",
      "Open text": "打开",
      "First text": "第一页",
      "Previous text": "上一页",
      "Next text": "下一页",
      "Last text": "最后一页",
      "Zoom in text": "放大",
      "Zoom out text": "缩小",
      "Selection text": "选择",
      "Pan text": "平底锅",
      "Print text": "打印",
      "Search text": "搜索",
      "Annotation Edit text": "编辑注释",
      "Line Thickness": "线的粗细",
      "Line Properties": "线属性",
      "Start Arrow": "开始箭头",
      "End Arrow": "结束箭头",
      "Line Style": "线条样式",
      "Fill Color": "填色",
      "Line Color": "线条颜色",
      "None": "没有任何",
      "Open Arrow": "打开",
      "Closed Arrow": "关闭",
      "Round Arrow": "圆形的",
      "Square Arrow": "正方形",
      "Diamond Arrow": "钻石",
      "Butt": "屁股",
      "Cut": "切",
      "Paste": "粘贴",
      "Delete Context": "删除",
      "Properties": "特性",
      "Add Stamp": "添加图章",
      "Add Shapes": "添加形状",
      "Stroke edit": "更改描边颜色",
      "Change thickness": "更改边框粗细",
      "Add line": "添加线路",
      "Add arrow": "添加箭头",
      "Add rectangle": "添加矩形",
      "Add circle": "添加圈子",
      "Add polygon": "添加多边形",
      "Add Comments": "添加评论",
      "Comments": "评论",
      "No Comments Yet": "暂时没有评论",
      "Accepted": "公认",
      "Completed": "完全的",
      "Cancelled": "取消",
      "Rejected": "拒绝",
      "Leader Length": "引线长度",
      "Scale Ratio": "比例",
      "Calibrate": "校准",
      "Calibrate Distance": "校准距离",
      "Calibrate Perimeter": "校准周长",
      "Calibrate Area": "校准区域",
      "Calibrate Radius": "校准半径",
      "Calibrate Volume": "校准音量"
    },
    "stepper": {
      "optional": "选修的"
    }
  }
}
