{
  "zh-TW": {
    "accept": "是",
    "addRule": "增加規則",
    "am": "上午",
    "apply": "應用",
    "cancel": "取消",
    "choose": "選擇",
    "chooseDate": "選擇日期",
    "chooseMonth": "選擇月份",
    "chooseYear": "選擇年份",
    "clear": "清除",
    "completed": "已完成",
    "contains": "包含",
    "custom": "自訂義",
    "dateAfter": "日期晚於",
    "dateBefore": "日期早於",
    "dateFormat": "yy/mm/dd",
    "dateIs": "日期為",
    "dateIsNot": "日期不為",
    "dayNames": [
      "星期日",
      "星期一",
      "星期二",
      "星期三",
      "星期四",
      "星期五",
      "星期六"
    ],
    "dayNamesMin": [
      "日",
      "一",
      "二",
      "三",
      "四",
      "五",
      "六"
    ],
    "dayNamesShort": [
      "周日",
      "周一",
      "周二",
      "周三",
      "周四",
      "周五",
      "周六"
    ],
    "emptyFilterMessage": "無相關篩選結果",
    "emptyMessage": "無可用選項",
    "emptySearchMessage": "無相關搜尋結果",
    "emptySelectionMessage": "無選取項目",
    "endsWith": "以...結束",
    "equals": "等於",
    "fileSizeTypes": [
      "B",
      "KB",
      "MB",
      "GB",
      "TB",
      "PB",
      "EB",
      "ZB",
      "YB"
    ],
    "filter": "篩選",
    "firstDayOfWeek": 0,
    "gt": "大於",
    "gte": "大於或等於",
    "lt": "小於",
    "lte": "小於或等於",
    "matchAll": "全部匹配",
    "matchAny": "任意匹配",
    "medium": "中",
    "monthNames": [
      "一月",
      "二月",
      "三月",
      "四月",
      "五月",
      "六月",
      "七月",
      "八月",
      "九月",
      "十月",
      "十一月",
      "十二月"
    ],
    "monthNamesShort": [
      "1 月",
      "2 月",
      "3 月",
      "4 月",
      "5 月",
      "6 月",
      "7 月",
      "8 月",
      "9 月",
      "10 月",
      "11 月",
      "12 月"
    ],
    "nextDecade": "下一個十年",
    "nextHour": "下一個小時",
    "nextMinute": "下一分鐘",
    "nextMonth": "下一個月",
    "nextSecond": "下一秒",
    "nextYear": "下一年",
    "noFilter": "不篩選",
    "notContains": "不包含",
    "notEquals": "不等於",
    "now": "現在",
    "passwordPrompt": "輸入一組密碼",
    "pending": "待定",
    "pm": "下午",
    "prevDecade": "上一個十年",
    "prevHour": "上一個小時",
    "prevMinute": "上一分鐘",
    "prevMonth": "上一個月",
    "prevSecond": "上一秒",
    "prevYear": "上一年",
    "reject": "否",
    "removeRule": "移除規則",
    "searchMessage": "{0} 個相關結果",
    "selectionMessage": "{0} 個項目被選取",
    "showMonthAfterYear": true,
    "startsWith": "以...開始",
    "strong": "強",
    "today": "今日",
    "upload": "上傳",
    "weak": "弱",
    "weekHeader": "周",
    "aria": {
      "cancelEdit": "取消編輯",
      "close": "關閉",
      "collapseLabel": "收合",
      "collapseRow": "收合行",
      "editRow": "編輯行",
      "expandLabel": "展開",
      "expandRow": "展開行",
      "falseLabel": "否",
      "filterConstraint": "篩選條件",
      "filterOperator": "篩選運算子",
      "firstPageLabel": "第一頁",
      "gridView": "網格視圖",
      "hideFilterMenu": "隱藏篩選選單",
      "jumpToPageDropdownLabel": "跳至頁面下拉選單",
      "jumpToPageInputLabel": "跳至頁面輸入欄位",
      "lastPageLabel": "最後一頁",
      "listView": "列表視圖",
      "moveAllToSource": "全部移動至來源",
      "moveAllToTarget": "全部移動至目標",
      "moveBottom": "移動至底端",
      "moveDown": "往下移動",
      "moveTop": "移動至頂端",
      "moveToSource": "移動至來源",
      "moveToTarget": "移動至目標",
      "moveUp": "往上移動",
      "navigation": "轉導",
      "next": "下一個",
      "nextPageLabel": "下一頁",
      "nullLabel": "未選擇",
      "otpLabel": "請輸入一次性密碼字元 {0}",
      "pageLabel": "{page}",
      "passwordHide": "隱藏密碼",
      "passwordShow": "顯示密碼",
      "previous": "上一個",
      "previousPageLabel": "上一頁",
      "rotateLeft": "向左旋轉",
      "rotateRight": "向右旋轉",
      "rowsPerPageLabel": "每頁行數",
      "saveEdit": "儲存編輯",
      "scrollTop": "滾動至頂端",
      "selectAll": "已選取所有項目",
      "selectLabel": "選擇",
      "selectRow": "選取行",
      "showFilterMenu": "展示篩選選單",
      "slide": "滑動",
      "slideNumber": "{slideNumber}",
      "star": "1 顆星",
      "stars": "{star} 顆星",
      "trueLabel": "是",
      "unselectAll": "已取消選取所有項目",
      "unselectLabel": "取消選擇",
      "unselectRow": "取消選取行",
      "zoomImage": "放大圖片",
      "zoomIn": "放大",
      "zoomOut": "縮小"
    }
  }
}