export const headerMenu = [
  {
    label: '<div class="text-white">Dashboard</div>',
    icon: 'pi pi-microchip',
    iconStyle: {
      color: '#FFFFFF',
    },
    routerLink: ['./dashboard'],
    routerLinkActiveOptions: {
      exact: true
    },
  },
  {
    label: '<div class="text-white">協作看板</div>',
    icon: 'pi pi-objects-column',
    iconStyle: {
      color: '#FFFFFF',
    },
    routerLink: ['./kanban'],
    routerLinkActiveOptions: {
      exact: true
    },
  },
  {
    label: '<div class="text-white">專案管理</div>',
    icon: 'pi pi-building-columns',
    iconStyle: {
      color: '#FFFFFF',
    },
    routerLink: ['/projects'],
    routerLinkActiveOptions: {
      exact: true
    },
  },
  {
    label: '<div class="text-white">異動日誌</div>',
    icon: 'pi pi-eye',
    iconStyle: {
      color: '#FFFFFF',
    },
    routerLink: ['/logs-viewer'],
    routerLinkActiveOptions: {
      exact: true
    },
  },
  {
    label: '<div class="text-white">AI</div>',
    icon: 'pi pi-microchip-ai',
    iconStyle: {
      color: '#FFFFFF',
    },
    // routerLink: ['./resource'],
    routerLinkActiveOptions: {
      exact: true
    },
  },
  {
    label: '<div class="text-white">問題回報</div>',
    icon: 'pi pi-wrench',
    iconStyle: {
      color: '#FFFFFF',
    },
    routerLink: ['./bugs-report'],
    routerLinkActiveOptions: {
      exact: true
    },
  }
];
