{
  "main": {
    "zh-Hant": {
      "identity": {
        "version": {
          "_cldrVersion": "36"
        },
        "language": "zh-Hant"
      },
      "dates": {
        "timeZoneNames": {
          "hourFormat": "+HH:mm;-HH:mm",
          "gmtFormat": "GMT{0}",
          "gmtZeroFormat": "GMT",
          "regionFormat": "{0}時間",
          "regionFormat-type-daylight": "{0}夏令時間",
          "regionFormat-type-standard": "{0}標準時間",
          "fallbackFormat": "{1}（{0}）",
          "zone": {
            "America": {
              "Adak": {
                "exemplarCity": "埃達克"
              },
              "Anchorage": {
                "exemplarCity": "安克雷奇"
              },
              "Anguilla": {
                "exemplarCity": "安圭拉"
              },
              "Antigua": {
                "exemplarCity": "安提瓜"
              },
              "Araguaina": {
                "exemplarCity": "阿拉瓜伊納"
              },
              "Argentina": {
                "Rio_Gallegos": {
                  "exemplarCity": "裏奧加耶戈斯"
                },
                "San_Juan": {
                  "exemplarCity": "聖胡安"
                },
                "Ushuaia": {
                  "exemplarCity": "烏斯懷亞"
                },
                "La_Rioja": {
                  "exemplarCity": "拉裏奧哈"
                },
                "San_Luis": {
                  "exemplarCity": "聖路易斯"
                },
                "Salta": {
                  "exemplarCity": "薩爾塔"
                },
                "Tucuman": {
                  "exemplarCity": "圖庫曼"
                }
              },
              "Aruba": {
                "exemplarCity": "阿魯巴"
              },
              "Asuncion": {
                "exemplarCity": "亞松森"
              },
              "Bahia": {
                "exemplarCity": "巴伊亞"
              },
              "Bahia_Banderas": {
                "exemplarCity": "巴伊亞班德拉斯"
              },
              "Barbados": {
                "exemplarCity": "巴巴多斯"
              },
              "Belem": {
                "exemplarCity": "貝倫"
              },
              "Belize": {
                "exemplarCity": "伯利茲"
              },
              "Blanc-Sablon": {
                "exemplarCity": "布蘭克薩布隆"
              },
              "Boa_Vista": {
                "exemplarCity": "博阿維斯塔"
              },
              "Bogota": {
                "exemplarCity": "波哥大"
              },
              "Boise": {
                "exemplarCity": "博伊西"
              },
              "Buenos_Aires": {
                "exemplarCity": "布宜諾斯艾利斯"
              },
              "Cambridge_Bay": {
                "exemplarCity": "劍橋灣"
              },
              "Campo_Grande": {
                "exemplarCity": "大坎普"
              },
              "Cancun": {
                "exemplarCity": "坎昆"
              },
              "Caracas": {
                "exemplarCity": "加拉加斯"
              },
              "Catamarca": {
                "exemplarCity": "卡塔馬卡"
              },
              "Cayenne": {
                "exemplarCity": "卡宴"
              },
              "Cayman": {
                "exemplarCity": "開曼"
              },
              "Chicago": {
                "exemplarCity": "芝加哥"
              },
              "Chihuahua": {
                "exemplarCity": "奇瓦瓦"
              },
              "Coral_Harbour": {
                "exemplarCity": "阿蒂科肯"
              },
              "Cordoba": {
                "exemplarCity": "科爾多瓦"
              },
              "Costa_Rica": {
                "exemplarCity": "哥斯達黎加"
              },
              "Creston": {
                "exemplarCity": "克雷斯頓"
              },
              "Cuiaba": {
                "exemplarCity": "庫亞巴"
              },
              "Curacao": {
                "exemplarCity": "庫拉索"
              },
              "Danmarkshavn": {
                "exemplarCity": "丹馬沙文"
              },
              "Dawson": {
                "exemplarCity": "道森"
              },
              "Dawson_Creek": {
                "exemplarCity": "道森克裏克"
              },
              "Denver": {
                "exemplarCity": "丹佛"
              },
              "Detroit": {
                "exemplarCity": "底特律"
              },
              "Dominica": {
                "exemplarCity": "多米尼加"
              },
              "Edmonton": {
                "exemplarCity": "埃德蒙頓"
              },
              "Eirunepe": {
                "exemplarCity": "依倫尼貝"
              },
              "El_Salvador": {
                "exemplarCity": "薩爾瓦多"
              },
              "Fort_Nelson": {
                "exemplarCity": "納爾遜堡"
              },
              "Fortaleza": {
                "exemplarCity": "福塔雷薩"
              },
              "Glace_Bay": {
                "exemplarCity": "格萊斯貝"
              },
              "Godthab": {
                "exemplarCity": "努克"
              },
              "Goose_Bay": {
                "exemplarCity": "古斯灣"
              },
              "Grand_Turk": {
                "exemplarCity": "大特克"
              },
              "Grenada": {
                "exemplarCity": "格林納達"
              },
              "Guadeloupe": {
                "exemplarCity": "瓜德羅普"
              },
              "Guatemala": {
                "exemplarCity": "危地馬拉"
              },
              "Guayaquil": {
                "exemplarCity": "瓜亞基爾"
              },
              "Guyana": {
                "exemplarCity": "圭亞那"
              },
              "Halifax": {
                "exemplarCity": "哈利法克斯"
              },
              "Havana": {
                "exemplarCity": "哈瓦那"
              },
              "Hermosillo": {
                "exemplarCity": "埃莫西約"
              },
              "Indiana": {
                "Vincennes": {
                  "exemplarCity": "印第安納州溫森斯"
                },
                "Petersburg": {
                  "exemplarCity": "印第安納州彼得斯堡"
                },
                "Tell_City": {
                  "exemplarCity": "印第安納州特爾城"
                },
                "Knox": {
                  "exemplarCity": "印第安納州諾克斯"
                },
                "Winamac": {
                  "exemplarCity": "印第安納州威納馬克"
                },
                "Marengo": {
                  "exemplarCity": "印第安納州馬倫戈"
                },
                "Vevay": {
                  "exemplarCity": "印第安納州維維市"
                }
              },
              "Indianapolis": {
                "exemplarCity": "印第安納波利斯"
              },
              "Inuvik": {
                "exemplarCity": "伊努維克"
              },
              "Iqaluit": {
                "exemplarCity": "伊魁特"
              },
              "Jamaica": {
                "exemplarCity": "牙買加"
              },
              "Jujuy": {
                "exemplarCity": "胡胡伊"
              },
              "Juneau": {
                "exemplarCity": "朱諾"
              },
              "Kentucky": {
                "Monticello": {
                  "exemplarCity": "肯塔基州蒙蒂塞洛"
                }
              },
              "Kralendijk": {
                "exemplarCity": "克拉倫代克"
              },
              "La_Paz": {
                "exemplarCity": "拉巴斯"
              },
              "Lima": {
                "exemplarCity": "利馬"
              },
              "Los_Angeles": {
                "exemplarCity": "洛杉磯"
              },
              "Louisville": {
                "exemplarCity": "路易斯維爾"
              },
              "Lower_Princes": {
                "exemplarCity": "下太子區"
              },
              "Maceio": {
                "exemplarCity": "馬塞約"
              },
              "Managua": {
                "exemplarCity": "馬那瓜"
              },
              "Manaus": {
                "exemplarCity": "馬瑙斯"
              },
              "Marigot": {
                "exemplarCity": "馬裏戈特"
              },
              "Martinique": {
                "exemplarCity": "馬提尼克"
              },
              "Matamoros": {
                "exemplarCity": "馬塔莫羅斯"
              },
              "Mazatlan": {
                "exemplarCity": "馬薩特蘭"
              },
              "Mendoza": {
                "exemplarCity": "門多薩"
              },
              "Menominee": {
                "exemplarCity": "梅諾米尼"
              },
              "Merida": {
                "exemplarCity": "梅裏達"
              },
              "Metlakatla": {
                "exemplarCity": "梅特拉卡特拉"
              },
              "Mexico_City": {
                "exemplarCity": "墨西哥城"
              },
              "Miquelon": {
                "exemplarCity": "密克隆"
              },
              "Moncton": {
                "exemplarCity": "蒙克頓"
              },
              "Monterrey": {
                "exemplarCity": "蒙特雷"
              },
              "Montevideo": {
                "exemplarCity": "蒙得維的亞"
              },
              "Montserrat": {
                "exemplarCity": "蒙特塞拉特"
              },
              "Nassau": {
                "exemplarCity": "拿騷"
              },
              "New_York": {
                "exemplarCity": "紐約"
              },
              "Nipigon": {
                "exemplarCity": "尼皮貢"
              },
              "Nome": {
                "exemplarCity": "諾姆"
              },
              "Noronha": {
                "exemplarCity": "洛羅尼亞"
              },
              "North_Dakota": {
                "Beulah": {
                  "exemplarCity": "北達科他州比尤拉"
                },
                "New_Salem": {
                  "exemplarCity": "北達科他州新塞勒姆"
                },
                "Center": {
                  "exemplarCity": "北達科他州申特"
                }
              },
              "Ojinaga": {
                "exemplarCity": "奧希納加"
              },
              "Panama": {
                "exemplarCity": "巴拿馬"
              },
              "Pangnirtung": {
                "exemplarCity": "旁涅唐"
              },
              "Paramaribo": {
                "exemplarCity": "帕拉馬裏博"
              },
              "Phoenix": {
                "exemplarCity": "鳳凰城"
              },
              "Port-au-Prince": {
                "exemplarCity": "太子港"
              },
              "Port_of_Spain": {
                "exemplarCity": "西班牙港"
              },
              "Porto_Velho": {
                "exemplarCity": "波多韋柳"
              },
              "Puerto_Rico": {
                "exemplarCity": "波多黎各"
              },
              "Punta_Arenas": {
                "exemplarCity": "蓬塔阿雷納斯"
              },
              "Rainy_River": {
                "exemplarCity": "雷尼河"
              },
              "Rankin_Inlet": {
                "exemplarCity": "蘭今灣"
              },
              "Recife": {
                "exemplarCity": "累西肺"
              },
              "Regina": {
                "exemplarCity": "裏賈納"
              },
              "Resolute": {
                "exemplarCity": "雷索盧特"
              },
              "Rio_Branco": {
                "exemplarCity": "裏奧布郎庫"
              },
              "Santa_Isabel": {
                "exemplarCity": "聖伊薩貝爾"
              },
              "Santarem": {
                "exemplarCity": "聖塔倫"
              },
              "Santiago": {
                "exemplarCity": "聖地亞哥"
              },
              "Santo_Domingo": {
                "exemplarCity": "聖多明各"
              },
              "Sao_Paulo": {
                "exemplarCity": "聖保羅"
              },
              "Scoresbysund": {
                "exemplarCity": "斯科列斯比桑德"
              },
              "Sitka": {
                "exemplarCity": "錫特卡"
              },
              "St_Barthelemy": {
                "exemplarCity": "聖巴泰勒米島"
              },
              "St_Johns": {
                "exemplarCity": "聖約翰斯"
              },
              "St_Kitts": {
                "exemplarCity": "聖基茨"
              },
              "St_Lucia": {
                "exemplarCity": "聖盧西亞"
              },
              "St_Thomas": {
                "exemplarCity": "聖托馬斯"
              },
              "St_Vincent": {
                "exemplarCity": "聖文森特"
              },
              "Swift_Current": {
                "exemplarCity": "斯威夫特卡倫特"
              },
              "Tegucigalpa": {
                "exemplarCity": "特古西加爾巴"
              },
              "Thule": {
                "exemplarCity": "圖勒"
              },
              "Thunder_Bay": {
                "exemplarCity": "桑德貝"
              },
              "Tijuana": {
                "exemplarCity": "蒂華納"
              },
              "Toronto": {
                "exemplarCity": "多倫多"
              },
              "Tortola": {
                "exemplarCity": "托爾托拉"
              },
              "Vancouver": {
                "exemplarCity": "溫哥華"
              },
              "Whitehorse": {
                "exemplarCity": "懷特霍斯"
              },
              "Winnipeg": {
                "exemplarCity": "溫尼伯"
              },
              "Yakutat": {
                "exemplarCity": "亞庫塔特"
              },
              "Yellowknife": {
                "exemplarCity": "耶洛奈夫"
              }
            },
            "Atlantic": {
              "Azores": {
                "exemplarCity": "亞速爾群島"
              },
              "Bermuda": {
                "exemplarCity": "百慕大"
              },
              "Canary": {
                "exemplarCity": "加那利"
              },
              "Cape_Verde": {
                "exemplarCity": "佛得角"
              },
              "Faeroe": {
                "exemplarCity": "法羅"
              },
              "Madeira": {
                "exemplarCity": "馬德拉"
              },
              "Reykjavik": {
                "exemplarCity": "雷克雅未克"
              },
              "South_Georgia": {
                "exemplarCity": "南喬治亞"
              },
              "St_Helena": {
                "exemplarCity": "聖赫勒拿"
              },
              "Stanley": {
                "exemplarCity": "斯坦利"
              }
            },
            "Europe": {
              "Amsterdam": {
                "exemplarCity": "阿姆斯特丹"
              },
              "Andorra": {
                "exemplarCity": "安道爾"
              },
              "Astrakhan": {
                "exemplarCity": "阿斯特拉罕"
              },
              "Athens": {
                "exemplarCity": "雅典"
              },
              "Belgrade": {
                "exemplarCity": "貝爾格萊德"
              },
              "Berlin": {
                "exemplarCity": "柏林"
              },
              "Bratislava": {
                "exemplarCity": "布拉迪斯拉發"
              },
              "Brussels": {
                "exemplarCity": "布魯塞爾"
              },
              "Bucharest": {
                "exemplarCity": "布加勒斯特"
              },
              "Budapest": {
                "exemplarCity": "布達佩斯"
              },
              "Busingen": {
                "exemplarCity": "比辛根"
              },
              "Chisinau": {
                "exemplarCity": "基希訥烏"
              },
              "Copenhagen": {
                "exemplarCity": "哥本哈根"
              },
              "Dublin": {
                "long": {
                  "daylight": "愛爾蘭標準時間"
                },
                "exemplarCity": "都柏林"
              },
              "Gibraltar": {
                "exemplarCity": "直布羅陀"
              },
              "Guernsey": {
                "exemplarCity": "根西島"
              },
              "Helsinki": {
                "exemplarCity": "赫爾辛基"
              },
              "Isle_of_Man": {
                "exemplarCity": "馬恩島"
              },
              "Istanbul": {
                "exemplarCity": "伊斯坦布爾"
              },
              "Jersey": {
                "exemplarCity": "澤西島"
              },
              "Kaliningrad": {
                "exemplarCity": "加裏寧格勒"
              },
              "Kiev": {
                "exemplarCity": "基輔"
              },
              "Kirov": {
                "exemplarCity": "基洛夫"
              },
              "Lisbon": {
                "exemplarCity": "裏斯本"
              },
              "Ljubljana": {
                "exemplarCity": "盧布爾雅那"
              },
              "London": {
                "long": {
                  "daylight": "英國夏令時間"
                },
                "exemplarCity": "倫敦"
              },
              "Luxembourg": {
                "exemplarCity": "盧森堡"
              },
              "Madrid": {
                "exemplarCity": "馬德裏"
              },
              "Malta": {
                "exemplarCity": "馬耳他"
              },
              "Mariehamn": {
                "exemplarCity": "瑪麗港"
              },
              "Minsk": {
                "exemplarCity": "明斯克"
              },
              "Monaco": {
                "exemplarCity": "摩納哥"
              },
              "Moscow": {
                "exemplarCity": "莫斯科"
              },
              "Oslo": {
                "exemplarCity": "奧斯陸"
              },
              "Paris": {
                "exemplarCity": "巴黎"
              },
              "Podgorica": {
                "exemplarCity": "波德戈裏察"
              },
              "Prague": {
                "exemplarCity": "布拉格"
              },
              "Riga": {
                "exemplarCity": "裏加"
              },
              "Rome": {
                "exemplarCity": "羅馬"
              },
              "Samara": {
                "exemplarCity": "薩馬拉"
              },
              "San_Marino": {
                "exemplarCity": "聖馬力諾"
              },
              "Sarajevo": {
                "exemplarCity": "薩拉熱窩"
              },
              "Saratov": {
                "exemplarCity": "薩拉托夫"
              },
              "Simferopol": {
                "exemplarCity": "辛菲羅波爾"
              },
              "Skopje": {
                "exemplarCity": "斯科普裏"
              },
              "Sofia": {
                "exemplarCity": "索非亞"
              },
              "Stockholm": {
                "exemplarCity": "斯德哥爾摩"
              },
              "Tallinn": {
                "exemplarCity": "塔林"
              },
              "Tirane": {
                "exemplarCity": "地拉那"
              },
              "Ulyanovsk": {
                "exemplarCity": "烏裏揚諾夫斯克"
              },
              "Uzhgorod": {
                "exemplarCity": "烏日哥羅德"
              },
              "Vaduz": {
                "exemplarCity": "瓦杜茲"
              },
              "Vatican": {
                "exemplarCity": "梵蒂岡"
              },
              "Vienna": {
                "exemplarCity": "維也納"
              },
              "Vilnius": {
                "exemplarCity": "維爾紐斯"
              },
              "Volgograd": {
                "exemplarCity": "伏爾加格勒"
              },
              "Warsaw": {
                "exemplarCity": "華沙"
              },
              "Zagreb": {
                "exemplarCity": "薩格勒布"
              },
              "Zaporozhye": {
                "exemplarCity": "紮波羅熱"
              },
              "Zurich": {
                "exemplarCity": "蘇黎世"
              }
            },
            "Africa": {
              "Abidjan": {
                "exemplarCity": "阿比讓"
              },
              "Accra": {
                "exemplarCity": "阿克拉"
              },
              "Addis_Ababa": {
                "exemplarCity": "亞的斯亞貝巴"
              },
              "Algiers": {
                "exemplarCity": "阿爾及爾"
              },
              "Asmera": {
                "exemplarCity": "阿斯馬拉"
              },
              "Bamako": {
                "exemplarCity": "巴馬科"
              },
              "Bangui": {
                "exemplarCity": "班吉"
              },
              "Banjul": {
                "exemplarCity": "班珠爾"
              },
              "Bissau": {
                "exemplarCity": "比紹"
              },
              "Blantyre": {
                "exemplarCity": "布蘭太爾"
              },
              "Brazzaville": {
                "exemplarCity": "布拉柴維爾"
              },
              "Bujumbura": {
                "exemplarCity": "布瓊布拉"
              },
              "Cairo": {
                "exemplarCity": "開羅"
              },
              "Casablanca": {
                "exemplarCity": "卡薩布蘭卡"
              },
              "Ceuta": {
                "exemplarCity": "休達"
              },
              "Conakry": {
                "exemplarCity": "科納克裏"
              },
              "Dakar": {
                "exemplarCity": "達喀爾"
              },
              "Dar_es_Salaam": {
                "exemplarCity": "達累斯薩拉姆"
              },
              "Djibouti": {
                "exemplarCity": "吉布提"
              },
              "Douala": {
                "exemplarCity": "杜阿拉"
              },
              "El_Aaiun": {
                "exemplarCity": "阿尤恩"
              },
              "Freetown": {
                "exemplarCity": "弗裏敦"
              },
              "Gaborone": {
                "exemplarCity": "哈博羅內"
              },
              "Harare": {
                "exemplarCity": "哈拉雷"
              },
              "Johannesburg": {
                "exemplarCity": "約翰內斯堡"
              },
              "Juba": {
                "exemplarCity": "朱巴"
              },
              "Kampala": {
                "exemplarCity": "坎帕拉"
              },
              "Khartoum": {
                "exemplarCity": "喀土穆"
              },
              "Kigali": {
                "exemplarCity": "基加利"
              },
              "Kinshasa": {
                "exemplarCity": "金沙薩"
              },
              "Lagos": {
                "exemplarCity": "拉各斯"
              },
              "Libreville": {
                "exemplarCity": "利伯維爾"
              },
              "Lome": {
                "exemplarCity": "洛美"
              },
              "Luanda": {
                "exemplarCity": "羅安達"
              },
              "Lubumbashi": {
                "exemplarCity": "盧本巴希"
              },
              "Lusaka": {
                "exemplarCity": "盧薩卡"
              },
              "Malabo": {
                "exemplarCity": "馬拉博"
              },
              "Maputo": {
                "exemplarCity": "馬普托"
              },
              "Maseru": {
                "exemplarCity": "馬塞盧"
              },
              "Mbabane": {
                "exemplarCity": "姆巴巴納"
              },
              "Mogadishu": {
                "exemplarCity": "摩加迪沙"
              },
              "Monrovia": {
                "exemplarCity": "蒙羅維亞"
              },
              "Nairobi": {
                "exemplarCity": "內羅畢"
              },
              "Ndjamena": {
                "exemplarCity": "恩賈梅納"
              },
              "Niamey": {
                "exemplarCity": "尼亞美"
              },
              "Nouakchott": {
                "exemplarCity": "努瓦克肖特"
              },
              "Ouagadougou": {
                "exemplarCity": "瓦加杜古"
              },
              "Porto-Novo": {
                "exemplarCity": "波多諾伏"
              },
              "Sao_Tome": {
                "exemplarCity": "聖多美"
              },
              "Tripoli": {
                "exemplarCity": "的黎波裏"
              },
              "Tunis": {
                "exemplarCity": "突尼斯"
              },
              "Windhoek": {
                "exemplarCity": "溫得和克"
              }
            },
            "Asia": {
              "Aden": {
                "exemplarCity": "亞丁"
              },
              "Almaty": {
                "exemplarCity": "阿拉木圖"
              },
              "Amman": {
                "exemplarCity": "安曼"
              },
              "Anadyr": {
                "exemplarCity": "阿納德爾"
              },
              "Aqtau": {
                "exemplarCity": "阿克套"
              },
              "Aqtobe": {
                "exemplarCity": "阿克托別"
              },
              "Ashgabat": {
                "exemplarCity": "阿什哈巴德"
              },
              "Atyrau": {
                "exemplarCity": "阿特勞"
              },
              "Baghdad": {
                "exemplarCity": "巴格達"
              },
              "Bahrain": {
                "exemplarCity": "巴林"
              },
              "Baku": {
                "exemplarCity": "巴庫"
              },
              "Bangkok": {
                "exemplarCity": "曼谷"
              },
              "Barnaul": {
                "exemplarCity": "巴爾瑙爾"
              },
              "Beirut": {
                "exemplarCity": "貝魯特"
              },
              "Bishkek": {
                "exemplarCity": "比什凱克"
              },
              "Brunei": {
                "exemplarCity": "文萊"
              },
              "Calcutta": {
                "exemplarCity": "加爾各答"
              },
              "Chita": {
                "exemplarCity": "赤塔"
              },
              "Choibalsan": {
                "exemplarCity": "喬巴山"
              },
              "Colombo": {
                "exemplarCity": "科倫坡"
              },
              "Damascus": {
                "exemplarCity": "大馬士革"
              },
              "Dhaka": {
                "exemplarCity": "達卡"
              },
              "Dili": {
                "exemplarCity": "帝力"
              },
              "Dubai": {
                "exemplarCity": "迪拜"
              },
              "Dushanbe": {
                "exemplarCity": "杜尚別"
              },
              "Famagusta": {
                "exemplarCity": "法馬古斯塔"
              },
              "Gaza": {
                "exemplarCity": "加沙"
              },
              "Hebron": {
                "exemplarCity": "希伯倫"
              },
              "Hong_Kong": {
                "exemplarCity": "香港"
              },
              "Hovd": {
                "exemplarCity": "科布多"
              },
              "Irkutsk": {
                "exemplarCity": "伊爾庫茨克"
              },
              "Jakarta": {
                "exemplarCity": "雅加達"
              },
              "Jayapura": {
                "exemplarCity": "查亞普拉"
              },
              "Jerusalem": {
                "exemplarCity": "耶路撒冷"
              },
              "Kabul": {
                "exemplarCity": "喀布爾"
              },
              "Kamchatka": {
                "exemplarCity": "堪察加"
              },
              "Karachi": {
                "exemplarCity": "卡拉奇"
              },
              "Katmandu": {
                "exemplarCity": "加德滿都"
              },
              "Khandyga": {
                "exemplarCity": "漢德加"
              },
              "Krasnoyarsk": {
                "exemplarCity": "克拉斯諾亞爾斯克"
              },
              "Kuala_Lumpur": {
                "exemplarCity": "吉隆坡"
              },
              "Kuching": {
                "exemplarCity": "古晉"
              },
              "Kuwait": {
                "exemplarCity": "科威特"
              },
              "Macau": {
                "exemplarCity": "澳門"
              },
              "Magadan": {
                "exemplarCity": "馬加丹"
              },
              "Makassar": {
                "exemplarCity": "望加錫"
              },
              "Manila": {
                "exemplarCity": "馬尼拉"
              },
              "Muscat": {
                "exemplarCity": "馬斯喀特"
              },
              "Nicosia": {
                "exemplarCity": "尼科西亞"
              },
              "Novokuznetsk": {
                "exemplarCity": "新庫茲涅茨克"
              },
              "Novosibirsk": {
                "exemplarCity": "新西伯利亞"
              },
              "Omsk": {
                "exemplarCity": "鄂木斯克"
              },
              "Oral": {
                "exemplarCity": "烏拉爾"
              },
              "Phnom_Penh": {
                "exemplarCity": "金邊"
              },
              "Pontianak": {
                "exemplarCity": "坤甸"
              },
              "Pyongyang": {
                "exemplarCity": "平壤"
              },
              "Qatar": {
                "exemplarCity": "卡塔爾"
              },
              "Qostanay": {
                "exemplarCity": "庫斯塔奈"
              },
              "Qyzylorda": {
                "exemplarCity": "克孜洛爾達"
              },
              "Rangoon": {
                "exemplarCity": "仰光"
              },
              "Riyadh": {
                "exemplarCity": "利雅得"
              },
              "Saigon": {
                "exemplarCity": "胡誌明市"
              },
              "Sakhalin": {
                "exemplarCity": "薩哈林"
              },
              "Samarkand": {
                "exemplarCity": "撒馬爾罕"
              },
              "Seoul": {
                "exemplarCity": "首爾"
              },
              "Shanghai": {
                "exemplarCity": "上海"
              },
              "Singapore": {
                "exemplarCity": "新加坡"
              },
              "Srednekolymsk": {
                "exemplarCity": "中科雷姆斯克"
              },
              "Taipei": {
                "exemplarCity": "臺北"
              },
              "Tashkent": {
                "exemplarCity": "塔什幹"
              },
              "Tbilisi": {
                "exemplarCity": "第比利斯"
              },
              "Tehran": {
                "exemplarCity": "德黑蘭"
              },
              "Thimphu": {
                "exemplarCity": "廷布"
              },
              "Tokyo": {
                "exemplarCity": "東京"
              },
              "Tomsk": {
                "exemplarCity": "托木斯克"
              },
              "Ulaanbaatar": {
                "exemplarCity": "烏蘭巴托"
              },
              "Urumqi": {
                "exemplarCity": "烏魯木齊"
              },
              "Ust-Nera": {
                "exemplarCity": "烏斯內拉"
              },
              "Vientiane": {
                "exemplarCity": "萬象"
              },
              "Vladivostok": {
                "exemplarCity": "符拉迪沃斯托克"
              },
              "Yakutsk": {
                "exemplarCity": "雅庫茨克"
              },
              "Yekaterinburg": {
                "exemplarCity": "葉卡捷琳堡"
              },
              "Yerevan": {
                "exemplarCity": "埃裏溫"
              }
            },
            "Indian": {
              "Antananarivo": {
                "exemplarCity": "安塔那那利佛"
              },
              "Chagos": {
                "exemplarCity": "查戈斯"
              },
              "Christmas": {
                "exemplarCity": "聖誕島"
              },
              "Cocos": {
                "exemplarCity": "可可斯"
              },
              "Comoro": {
                "exemplarCity": "科摩羅"
              },
              "Kerguelen": {
                "exemplarCity": "凱爾蓋朗"
              },
              "Mahe": {
                "exemplarCity": "馬埃島"
              },
              "Maldives": {
                "exemplarCity": "馬爾代夫"
              },
              "Mauritius": {
                "exemplarCity": "毛裏求斯"
              },
              "Mayotte": {
                "exemplarCity": "馬約特"
              },
              "Reunion": {
                "exemplarCity": "留尼汪"
              }
            },
            "Australia": {
              "Adelaide": {
                "exemplarCity": "阿德萊德"
              },
              "Brisbane": {
                "exemplarCity": "布裏斯班"
              },
              "Broken_Hill": {
                "exemplarCity": "布羅肯希爾"
              },
              "Currie": {
                "exemplarCity": "庫利"
              },
              "Darwin": {
                "exemplarCity": "達爾文"
              },
              "Eucla": {
                "exemplarCity": "尤克拉"
              },
              "Hobart": {
                "exemplarCity": "霍巴特"
              },
              "Lindeman": {
                "exemplarCity": "林德曼"
              },
              "Lord_Howe": {
                "exemplarCity": "豪勛爵"
              },
              "Melbourne": {
                "exemplarCity": "墨爾本"
              },
              "Perth": {
                "exemplarCity": "珀斯"
              },
              "Sydney": {
                "exemplarCity": "悉尼"
              }
            },
            "Pacific": {
              "Apia": {
                "exemplarCity": "阿皮亞"
              },
              "Auckland": {
                "exemplarCity": "奧克蘭"
              },
              "Bougainville": {
                "exemplarCity": "布幹維爾"
              },
              "Chatham": {
                "exemplarCity": "查塔姆"
              },
              "Easter": {
                "exemplarCity": "復活節島"
              },
              "Efate": {
                "exemplarCity": "埃法特"
              },
              "Enderbury": {
                "exemplarCity": "恩德伯裏"
              },
              "Fakaofo": {
                "exemplarCity": "法考福"
              },
              "Fiji": {
                "exemplarCity": "斐濟"
              },
              "Funafuti": {
                "exemplarCity": "富納富提"
              },
              "Galapagos": {
                "exemplarCity": "加拉帕戈斯"
              },
              "Gambier": {
                "exemplarCity": "甘比爾"
              },
              "Guadalcanal": {
                "exemplarCity": "瓜達爾卡納爾"
              },
              "Guam": {
                "exemplarCity": "關島"
              },
              "Honolulu": {
                "exemplarCity": "檀香山"
              },
              "Johnston": {
                "exemplarCity": "約翰斯頓"
              },
              "Kiritimati": {
                "exemplarCity": "基裏地馬地島"
              },
              "Kosrae": {
                "exemplarCity": "庫賽埃"
              },
              "Kwajalein": {
                "exemplarCity": "誇賈林"
              },
              "Majuro": {
                "exemplarCity": "馬朱羅"
              },
              "Marquesas": {
                "exemplarCity": "馬克薩斯"
              },
              "Midway": {
                "exemplarCity": "中途島"
              },
              "Nauru": {
                "exemplarCity": "瑙魯"
              },
              "Niue": {
                "exemplarCity": "紐埃"
              },
              "Norfolk": {
                "exemplarCity": "諾福克"
              },
              "Noumea": {
                "exemplarCity": "努美阿"
              },
              "Pago_Pago": {
                "exemplarCity": "帕果帕果"
              },
              "Palau": {
                "exemplarCity": "帕勞"
              },
              "Pitcairn": {
                "exemplarCity": "皮特凱恩"
              },
              "Ponape": {
                "exemplarCity": "波納佩島"
              },
              "Port_Moresby": {
                "exemplarCity": "莫爾茲比港"
              },
              "Rarotonga": {
                "exemplarCity": "拉羅湯加"
              },
              "Saipan": {
                "exemplarCity": "塞班"
              },
              "Tahiti": {
                "exemplarCity": "塔希提"
              },
              "Tarawa": {
                "exemplarCity": "塔拉瓦"
              },
              "Tongatapu": {
                "exemplarCity": "東加塔布"
              },
              "Truk": {
                "exemplarCity": "特魯克群島"
              },
              "Wake": {
                "exemplarCity": "威克"
              },
              "Wallis": {
                "exemplarCity": "瓦利斯"
              }
            },
            "Arctic": {
              "Longyearbyen": {
                "exemplarCity": "朗伊爾城"
              }
            },
            "Antarctica": {
              "Casey": {
                "exemplarCity": "卡塞"
              },
              "Davis": {
                "exemplarCity": "戴維斯"
              },
              "DumontDUrville": {
                "exemplarCity": "迪蒙迪爾維爾"
              },
              "Macquarie": {
                "exemplarCity": "麥格理"
              },
              "Mawson": {
                "exemplarCity": "莫森"
              },
              "McMurdo": {
                "exemplarCity": "麥克默多"
              },
              "Palmer": {
                "exemplarCity": "帕默爾"
              },
              "Rothera": {
                "exemplarCity": "羅瑟拉"
              },
              "Syowa": {
                "exemplarCity": "昭和"
              },
              "Troll": {
                "exemplarCity": "特羅爾"
              },
              "Vostok": {
                "exemplarCity": "沃斯托克"
              }
            },
            "Etc": {
              "UTC": {
                "long": {
                  "standard": "協調世界時"
                },
                "short": {
                  "standard": "UTC"
                }
              },
              "Unknown": {
                "exemplarCity": "未知城市"
              }
            }
          },
          "metazone": {
            "Acre": {
              "long": {
                "generic": "阿克裏時間",
                "standard": "阿克裏標準時間",
                "daylight": "阿克裏夏令時間"
              }
            },
            "Afghanistan": {
              "long": {
                "standard": "阿富汗時間"
              }
            },
            "Africa_Central": {
              "long": {
                "standard": "中部非洲時間"
              }
            },
            "Africa_Eastern": {
              "long": {
                "standard": "東部非洲時間"
              }
            },
            "Africa_Southern": {
              "long": {
                "standard": "南非標準時間"
              }
            },
            "Africa_Western": {
              "long": {
                "generic": "西部非洲時間",
                "standard": "西部非洲標準時間",
                "daylight": "西部非洲夏令時間"
              }
            },
            "Alaska": {
              "long": {
                "generic": "阿拉斯加時間",
                "standard": "阿拉斯加標準時間",
                "daylight": "阿拉斯加夏令時間"
              }
            },
            "Almaty": {
              "long": {
                "generic": "阿拉木圖時間",
                "standard": "阿拉木圖標準時間",
                "daylight": "阿拉木圖夏令時間"
              }
            },
            "Amazon": {
              "long": {
                "generic": "亞馬遜時間",
                "standard": "亞馬遜標準時間",
                "daylight": "亞馬遜夏令時間"
              }
            },
            "America_Central": {
              "long": {
                "generic": "北美中部時間",
                "standard": "北美中部標準時間",
                "daylight": "北美中部夏令時間"
              }
            },
            "America_Eastern": {
              "long": {
                "generic": "北美東部時間",
                "standard": "北美東部標準時間",
                "daylight": "北美東部夏令時間"
              }
            },
            "America_Mountain": {
              "long": {
                "generic": "北美山區時間",
                "standard": "北美山區標準時間",
                "daylight": "北美山區夏令時間"
              }
            },
            "America_Pacific": {
              "long": {
                "generic": "北美太平洋時間",
                "standard": "北美太平洋標準時間",
                "daylight": "北美太平洋夏令時間"
              }
            },
            "Anadyr": {
              "long": {
                "generic": "阿納德爾時間",
                "standard": "阿納德爾標準時間",
                "daylight": "阿納德爾夏令時間"
              }
            },
            "Apia": {
              "long": {
                "generic": "阿皮亞時間",
                "standard": "阿皮亞標準時間",
                "daylight": "阿皮亞夏令時間"
              }
            },
            "Aqtau": {
              "long": {
                "generic": "阿克套時間",
                "standard": "阿克套標準時間",
                "daylight": "阿克套夏令時間"
              }
            },
            "Aqtobe": {
              "long": {
                "generic": "阿克托別時間",
                "standard": "阿克托別標準時間",
                "daylight": "阿克托別夏令時間"
              }
            },
            "Arabian": {
              "long": {
                "generic": "阿拉伯時間",
                "standard": "阿拉伯標準時間",
                "daylight": "阿拉伯夏令時間"
              }
            },
            "Argentina": {
              "long": {
                "generic": "阿根廷時間",
                "standard": "阿根廷標準時間",
                "daylight": "阿根廷夏令時間"
              }
            },
            "Argentina_Western": {
              "long": {
                "generic": "阿根廷西部時間",
                "standard": "阿根廷西部標準時間",
                "daylight": "阿根廷西部夏令時間"
              }
            },
            "Armenia": {
              "long": {
                "generic": "亞美尼亞時間",
                "standard": "亞美尼亞標準時間",
                "daylight": "亞美尼亞夏令時間"
              }
            },
            "Atlantic": {
              "long": {
                "generic": "大西洋時間",
                "standard": "大西洋標準時間",
                "daylight": "大西洋夏令時間"
              }
            },
            "Australia_Central": {
              "long": {
                "generic": "澳大利亞中部時間",
                "standard": "澳大利亞中部標準時間",
                "daylight": "澳大利亞中部夏令時間"
              }
            },
            "Australia_CentralWestern": {
              "long": {
                "generic": "澳大利亞中西部時間",
                "standard": "澳大利亞中西部標準時間",
                "daylight": "澳大利亞中西部夏令時間"
              }
            },
            "Australia_Eastern": {
              "long": {
                "generic": "澳大利亞東部時間",
                "standard": "澳大利亞東部標準時間",
                "daylight": "澳大利亞東部夏令時間"
              }
            },
            "Australia_Western": {
              "long": {
                "generic": "澳大利亞西部時間",
                "standard": "澳大利亞西部標準時間",
                "daylight": "澳大利亞西部夏令時間"
              }
            },
            "Azerbaijan": {
              "long": {
                "generic": "阿塞拜疆時間",
                "standard": "阿塞拜疆標準時間",
                "daylight": "阿塞拜疆夏令時間"
              }
            },
            "Azores": {
              "long": {
                "generic": "亞速爾群島時間",
                "standard": "亞速爾群島標準時間",
                "daylight": "亞速爾群島夏令時間"
              }
            },
            "Bangladesh": {
              "long": {
                "generic": "孟加拉時間",
                "standard": "孟加拉標準時間",
                "daylight": "孟加拉夏令時間"
              }
            },
            "Bhutan": {
              "long": {
                "standard": "不丹時間"
              }
            },
            "Bolivia": {
              "long": {
                "standard": "玻利維亞標準時間"
              }
            },
            "Brasilia": {
              "long": {
                "generic": "巴西利亞時間",
                "standard": "巴西利亞標準時間",
                "daylight": "巴西利亞夏令時間"
              }
            },
            "Brunei": {
              "long": {
                "standard": "文萊達魯薩蘭時間"
              }
            },
            "Cape_Verde": {
              "long": {
                "generic": "佛得角時間",
                "standard": "佛得角標準時間",
                "daylight": "佛得角夏令時間"
              }
            },
            "Casey": {
              "long": {
                "standard": "凱西時間"
              }
            },
            "Chamorro": {
              "long": {
                "standard": "查莫羅時間"
              }
            },
            "Chatham": {
              "long": {
                "generic": "查坦時間",
                "standard": "查坦標準時間",
                "daylight": "查坦夏令時間"
              }
            },
            "Chile": {
              "long": {
                "generic": "智利時間",
                "standard": "智利標準時間",
                "daylight": "智利夏令時間"
              }
            },
            "China": {
              "long": {
                "generic": "中國時間",
                "standard": "中國標準時間",
                "daylight": "中國夏令時間"
              }
            },
            "Choibalsan": {
              "long": {
                "generic": "喬巴山時間",
                "standard": "喬巴山標準時間",
                "daylight": "喬巴山夏令時間"
              }
            },
            "Christmas": {
              "long": {
                "standard": "聖誕島時間"
              }
            },
            "Cocos": {
              "long": {
                "standard": "科科斯群島時間"
              }
            },
            "Colombia": {
              "long": {
                "generic": "哥倫比亞時間",
                "standard": "哥倫比亞標準時間",
                "daylight": "哥倫比亞夏令時間"
              }
            },
            "Cook": {
              "long": {
                "generic": "庫克群島時間",
                "standard": "庫克群島標準時間",
                "daylight": "庫克群島仲夏時間"
              }
            },
            "Cuba": {
              "long": {
                "generic": "古巴時間",
                "standard": "古巴標準時間",
                "daylight": "古巴夏令時間"
              }
            },
            "Davis": {
              "long": {
                "standard": "戴維斯時間"
              }
            },
            "DumontDUrville": {
              "long": {
                "standard": "迪蒙迪爾維爾時間"
              }
            },
            "East_Timor": {
              "long": {
                "standard": "東帝汶時間"
              }
            },
            "Easter": {
              "long": {
                "generic": "復活節島時間",
                "standard": "復活節島標準時間",
                "daylight": "復活節島夏令時間"
              }
            },
            "Ecuador": {
              "long": {
                "standard": "厄瓜多爾標準時間"
              }
            },
            "Europe_Central": {
              "long": {
                "generic": "中歐時間",
                "standard": "中歐標準時間",
                "daylight": "中歐夏令時間"
              }
            },
            "Europe_Eastern": {
              "long": {
                "generic": "東歐時間",
                "standard": "東歐標準時間",
                "daylight": "東歐夏令時間"
              }
            },
            "Europe_Further_Eastern": {
              "long": {
                "standard": "遠東標準時間"
              }
            },
            "Europe_Western": {
              "long": {
                "generic": "西歐時間",
                "standard": "西歐標準時間",
                "daylight": "西歐夏令時間"
              }
            },
            "Falkland": {
              "long": {
                "generic": "福克蘭群島時間",
                "standard": "福克蘭群島標準時間",
                "daylight": "福克蘭群島夏令時間"
              }
            },
            "Fiji": {
              "long": {
                "generic": "斐濟時間",
                "standard": "斐濟標準時間",
                "daylight": "斐濟夏令時間"
              }
            },
            "French_Guiana": {
              "long": {
                "standard": "法屬圭亞那標準時間"
              }
            },
            "French_Southern": {
              "long": {
                "standard": "法屬南方和南極領地時間"
              }
            },
            "Galapagos": {
              "long": {
                "standard": "加拉帕戈斯時間"
              }
            },
            "Gambier": {
              "long": {
                "standard": "甘比爾時間"
              }
            },
            "Georgia": {
              "long": {
                "generic": "格魯吉亞時間",
                "standard": "格魯吉亞標準時間",
                "daylight": "格魯吉亞夏令時間"
              }
            },
            "Gilbert_Islands": {
              "long": {
                "standard": "吉爾伯特群島時間"
              }
            },
            "GMT": {
              "long": {
                "standard": "格林尼治標準時間"
              }
            },
            "Greenland_Eastern": {
              "long": {
                "generic": "格陵蘭島東部時間",
                "standard": "格陵蘭島東部標準時間",
                "daylight": "格陵蘭島東部夏令時間"
              }
            },
            "Greenland_Western": {
              "long": {
                "generic": "格陵蘭島西部時間",
                "standard": "格陵蘭島西部標準時間",
                "daylight": "格陵蘭島西部夏令時間"
              }
            },
            "Guam": {
              "long": {
                "standard": "關島時間"
              }
            },
            "Gulf": {
              "long": {
                "standard": "海灣標準時間"
              }
            },
            "Guyana": {
              "long": {
                "standard": "圭亞那時間"
              }
            },
            "Hawaii_Aleutian": {
              "long": {
                "generic": "夏威夷-阿留申時間",
                "standard": "夏威夷-阿留申標準時間",
                "daylight": "夏威夷-阿留申夏令時間"
              }
            },
            "Hong_Kong": {
              "long": {
                "generic": "香港時間",
                "standard": "香港標準時間",
                "daylight": "香港夏令時間"
              }
            },
            "Hovd": {
              "long": {
                "generic": "科布多時間",
                "standard": "科布多標準時間",
                "daylight": "科布多夏令時間"
              }
            },
            "India": {
              "long": {
                "standard": "印度時間"
              }
            },
            "Indian_Ocean": {
              "long": {
                "standard": "印度洋時間"
              }
            },
            "Indochina": {
              "long": {
                "standard": "印度支那時間"
              }
            },
            "Indonesia_Central": {
              "long": {
                "standard": "印度尼西亞中部時間"
              }
            },
            "Indonesia_Eastern": {
              "long": {
                "standard": "印度尼西亞東部時間"
              }
            },
            "Indonesia_Western": {
              "long": {
                "standard": "印度尼西亞西部時間"
              }
            },
            "Iran": {
              "long": {
                "generic": "伊朗時間",
                "standard": "伊朗標準時間",
                "daylight": "伊朗夏令時間"
              }
            },
            "Irkutsk": {
              "long": {
                "generic": "伊爾庫茨克時間",
                "standard": "伊爾庫茨克標準時間",
                "daylight": "伊爾庫茨克夏令時間"
              }
            },
            "Israel": {
              "long": {
                "generic": "以色列時間",
                "standard": "以色列標準時間",
                "daylight": "以色列夏令時間"
              }
            },
            "Japan": {
              "long": {
                "generic": "日本時間",
                "standard": "日本標準時間",
                "daylight": "日本夏令時間"
              }
            },
            "Kamchatka": {
              "long": {
                "generic": "彼得羅巴甫洛夫斯克-堪察加時間",
                "standard": "彼得羅巴甫洛夫斯克-堪察加標準時間",
                "daylight": "彼得羅巴甫洛夫斯克-堪察加夏令時間"
              }
            },
            "Kazakhstan_Eastern": {
              "long": {
                "standard": "哈薩克斯坦東部時間"
              }
            },
            "Kazakhstan_Western": {
              "long": {
                "standard": "哈薩克斯坦西部時間"
              }
            },
            "Korea": {
              "long": {
                "generic": "韓國時間",
                "standard": "韓國標準時間",
                "daylight": "韓國夏令時間"
              }
            },
            "Kosrae": {
              "long": {
                "standard": "科斯雷時間"
              }
            },
            "Krasnoyarsk": {
              "long": {
                "generic": "克拉斯諾亞爾斯克時間",
                "standard": "克拉斯諾亞爾斯克標準時間",
                "daylight": "克拉斯諾亞爾斯克夏令時間"
              }
            },
            "Kyrgystan": {
              "long": {
                "standard": "吉爾吉斯斯坦時間"
              }
            },
            "Lanka": {
              "long": {
                "standard": "蘭卡時間"
              }
            },
            "Line_Islands": {
              "long": {
                "standard": "萊恩群島時間"
              }
            },
            "Lord_Howe": {
              "long": {
                "generic": "豪勛爵島時間",
                "standard": "豪勛爵島標準時間",
                "daylight": "豪勛爵島夏令時間"
              }
            },
            "Macau": {
              "long": {
                "generic": "澳門時間",
                "standard": "澳門標準時間",
                "daylight": "澳門夏令時間"
              }
            },
            "Macquarie": {
              "long": {
                "standard": "麥誇裏島時間"
              }
            },
            "Magadan": {
              "long": {
                "generic": "馬加丹時間",
                "standard": "馬加丹標準時間",
                "daylight": "馬加丹夏令時間"
              }
            },
            "Malaysia": {
              "long": {
                "standard": "馬來西亞時間"
              }
            },
            "Maldives": {
              "long": {
                "standard": "馬爾代夫時間"
              }
            },
            "Marquesas": {
              "long": {
                "standard": "馬克薩斯群島時間"
              }
            },
            "Marshall_Islands": {
              "long": {
                "standard": "馬紹爾群島時間"
              }
            },
            "Mauritius": {
              "long": {
                "generic": "毛裏求斯時間",
                "standard": "毛裏求斯標準時間",
                "daylight": "毛裏求斯夏令時間"
              }
            },
            "Mawson": {
              "long": {
                "standard": "莫森時間"
              }
            },
            "Mexico_Northwest": {
              "long": {
                "generic": "墨西哥西北部時間",
                "standard": "墨西哥西北部標準時間",
                "daylight": "墨西哥西北部夏令時間"
              }
            },
            "Mexico_Pacific": {
              "long": {
                "generic": "墨西哥太平洋時間",
                "standard": "墨西哥太平洋標準時間",
                "daylight": "墨西哥太平洋夏令時間"
              }
            },
            "Mongolia": {
              "long": {
                "generic": "烏蘭巴托時間",
                "standard": "烏蘭巴托標準時間",
                "daylight": "烏蘭巴托夏令時間"
              }
            },
            "Moscow": {
              "long": {
                "generic": "莫斯科時間",
                "standard": "莫斯科標準時間",
                "daylight": "莫斯科夏令時間"
              }
            },
            "Myanmar": {
              "long": {
                "standard": "緬甸時間"
              }
            },
            "Nauru": {
              "long": {
                "standard": "瑙魯時間"
              }
            },
            "Nepal": {
              "long": {
                "standard": "尼泊爾時間"
              }
            },
            "New_Caledonia": {
              "long": {
                "generic": "新喀裏多尼亞時間",
                "standard": "新喀裏多尼亞標準時間",
                "daylight": "新喀裏多尼亞夏令時間"
              }
            },
            "New_Zealand": {
              "long": {
                "generic": "新西蘭時間",
                "standard": "新西蘭標準時間",
                "daylight": "新西蘭夏令時間"
              }
            },
            "Newfoundland": {
              "long": {
                "generic": "紐芬蘭時間",
                "standard": "紐芬蘭標準時間",
                "daylight": "紐芬蘭夏令時間"
              }
            },
            "Niue": {
              "long": {
                "standard": "紐埃時間"
              }
            },
            "Norfolk": {
              "long": {
                "standard": "諾福克島時間"
              }
            },
            "Noronha": {
              "long": {
                "generic": "費爾南多-迪諾羅尼亞島時間",
                "standard": "費爾南多-迪諾羅尼亞島標準時間",
                "daylight": "費爾南多-迪諾羅尼亞島夏令時間"
              }
            },
            "North_Mariana": {
              "long": {
                "standard": "北馬裏亞納群島時間"
              }
            },
            "Novosibirsk": {
              "long": {
                "generic": "新西伯利亞時間",
                "standard": "新西伯利亞標準時間",
                "daylight": "新西伯利亞夏令時間"
              }
            },
            "Omsk": {
              "long": {
                "generic": "鄂木斯克時間",
                "standard": "鄂木斯克標準時間",
                "daylight": "鄂木斯克夏令時間"
              }
            },
            "Pakistan": {
              "long": {
                "generic": "巴基斯坦時間",
                "standard": "巴基斯坦標準時間",
                "daylight": "巴基斯坦夏令時間"
              }
            },
            "Palau": {
              "long": {
                "standard": "帕勞時間"
              }
            },
            "Papua_New_Guinea": {
              "long": {
                "standard": "巴布亞新幾內亞時間"
              }
            },
            "Paraguay": {
              "long": {
                "generic": "巴拉圭時間",
                "standard": "巴拉圭標準時間",
                "daylight": "巴拉圭夏令時間"
              }
            },
            "Peru": {
              "long": {
                "generic": "秘魯時間",
                "standard": "秘魯標準時間",
                "daylight": "秘魯夏令時間"
              }
            },
            "Philippines": {
              "long": {
                "generic": "菲律賓時間",
                "standard": "菲律賓標準時間",
                "daylight": "菲律賓夏令時間"
              }
            },
            "Phoenix_Islands": {
              "long": {
                "standard": "菲尼克斯群島時間"
              }
            },
            "Pierre_Miquelon": {
              "long": {
                "generic": "聖皮埃爾和密克隆群島時間",
                "standard": "聖皮埃爾和密克隆群島標準時間",
                "daylight": "聖皮埃爾和密克隆群島夏令時間"
              }
            },
            "Pitcairn": {
              "long": {
                "standard": "皮特凱恩時間"
              }
            },
            "Ponape": {
              "long": {
                "standard": "波納佩時間"
              }
            },
            "Pyongyang": {
              "long": {
                "standard": "平壤時間"
              }
            },
            "Qyzylorda": {
              "long": {
                "generic": "克孜洛爾達時間",
                "standard": "克孜洛爾達標準時間",
                "daylight": "克孜洛爾達夏令時間"
              }
            },
            "Reunion": {
              "long": {
                "standard": "留尼汪時間"
              }
            },
            "Rothera": {
              "long": {
                "standard": "羅瑟拉時間"
              }
            },
            "Sakhalin": {
              "long": {
                "generic": "庫頁島時間",
                "standard": "庫頁島標準時間",
                "daylight": "庫頁島夏令時間"
              }
            },
            "Samara": {
              "long": {
                "generic": "薩馬拉時間",
                "standard": "薩馬拉標準時間",
                "daylight": "薩馬拉夏令時間"
              }
            },
            "Samoa": {
              "long": {
                "generic": "薩摩亞時間",
                "standard": "薩摩亞標準時間",
                "daylight": "薩摩亞夏令時間"
              }
            },
            "Seychelles": {
              "long": {
                "standard": "塞舌爾時間"
              }
            },
            "Singapore": {
              "long": {
                "standard": "新加坡標準時間"
              }
            },
            "Solomon": {
              "long": {
                "standard": "所羅門群島時間"
              }
            },
            "South_Georgia": {
              "long": {
                "standard": "南喬治亞島時間"
              }
            },
            "Suriname": {
              "long": {
                "standard": "蘇裏南時間"
              }
            },
            "Syowa": {
              "long": {
                "standard": "昭和時間"
              }
            },
            "Tahiti": {
              "long": {
                "standard": "塔希提島時間"
              }
            },
            "Taipei": {
              "long": {
                "generic": "臺北時間",
                "standard": "臺北標準時間",
                "daylight": "臺北夏令時間"
              }
            },
            "Tajikistan": {
              "long": {
                "standard": "塔吉克斯坦時間"
              }
            },
            "Tokelau": {
              "long": {
                "standard": "托克勞時間"
              }
            },
            "Tonga": {
              "long": {
                "generic": "湯加時間",
                "standard": "湯加標準時間",
                "daylight": "湯加夏令時間"
              }
            },
            "Truk": {
              "long": {
                "standard": "楚克時間"
              }
            },
            "Turkmenistan": {
              "long": {
                "generic": "土庫曼斯坦時間",
                "standard": "土庫曼斯坦標準時間",
                "daylight": "土庫曼斯坦夏令時間"
              }
            },
            "Tuvalu": {
              "long": {
                "standard": "圖瓦盧時間"
              }
            },
            "Uruguay": {
              "long": {
                "generic": "烏拉圭時間",
                "standard": "烏拉圭標準時間",
                "daylight": "烏拉圭夏令時間"
              }
            },
            "Uzbekistan": {
              "long": {
                "generic": "烏茲別克斯坦時間",
                "standard": "烏茲別克斯坦標準時間",
                "daylight": "烏茲別克斯坦夏令時間"
              }
            },
            "Vanuatu": {
              "long": {
                "generic": "瓦努阿圖時間",
                "standard": "瓦努阿圖標準時間",
                "daylight": "瓦努阿圖夏令時間"
              }
            },
            "Venezuela": {
              "long": {
                "standard": "委內瑞拉時間"
              }
            },
            "Vladivostok": {
              "long": {
                "generic": "海參崴時間",
                "standard": "海參崴標準時間",
                "daylight": "海參崴夏令時間"
              }
            },
            "Volgograd": {
              "long": {
                "generic": "伏爾加格勒時間",
                "standard": "伏爾加格勒標準時間",
                "daylight": "伏爾加格勒夏令時間"
              }
            },
            "Vostok": {
              "long": {
                "standard": "沃斯托克時間"
              }
            },
            "Wake": {
              "long": {
                "standard": "威克島時間"
              }
            },
            "Wallis": {
              "long": {
                "standard": "瓦利斯和富圖納時間"
              }
            },
            "Yakutsk": {
              "long": {
                "generic": "雅庫茨克時間",
                "standard": "雅庫茨克標準時間",
                "daylight": "雅庫茨克夏令時間"
              }
            },
            "Yekaterinburg": {
              "long": {
                "generic": "葉卡捷琳堡時間",
                "standard": "葉卡捷琳堡標準時間",
                "daylight": "葉卡捷琳堡夏令時間"
              }
            }
          }
        }
      }
    }
  }
}
