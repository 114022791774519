import {Component} from '@angular/core';
import {SharedModule} from "../../../shared/shared.module";
import {ConfirmationService, MenuItem} from 'primeng/api';
import {headerMenu} from "../../../services/data/menu";
import {L10n, setCulture, loadCldr} from '@syncfusion/ej2-base'
import {LanguageService} from "../../../services/language.service";
import {NgOptimizedImage} from "@angular/common";
import {AuthService} from "../../../services/auth.service";
import * as enus from '../../../../locale/en-US/en-US.json';
import * as zh from '../../../..//locale/zh/zh.json';
import * as zhhant from '../../../..//locale/zh-Hant/zh-Hant.json';
import * as pzh from '../../../../locale/primelocale/zh-CN.json';
import * as penus from '../../../../locale/primelocale/en.json';
import  * as pzhtw from '../../../../locale/primelocale/zh-TW.json';
import * as gregorian from '../../../../locale/zh-Hant/ca-gregorian.json';
import * as numbers from '../../../../locale/zh-Hant/numbers.json';
import * as timeZoneNames from '../../../../locale/zh-Hant/timezonename.json';
import * as zhgregorian from '../../../../locale/zh/ca-gregorian.json';
import * as zhnumbers from '../../../../locale/zh/numbers.json';
import * as zhtimeZoneNames from '../../../../locale/zh/timezonename.json';
import {TokenStorageService} from "../../../services/token-storage.service";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [SharedModule, NgOptimizedImage],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

  menu: MenuItem[] = headerMenu;

  nowLanguage: string | undefined = '正體中文';

  languageMenu: MenuItem[] = [
    {label: '正體中文', title: 'zh-Hant', command: () => this.onChange('zh-Hant')},
    {label: 'English', title: 'en-US', command: () => this.onChange('en-US')},
    {label: '简体中文', title: 'zh', command: () => this.onChange('zh')}
  ];

  user_name: string = '測試中';

  constructor(
    private languageServ: LanguageService,
    private confirmationServ: ConfirmationService,
    private authServ: AuthService,
    private tokenStorageServ: TokenStorageService
  ) {
    this.loadLanguageResource('zh-Hant');
    this.languageServ.setLanguage('zh-Hant');
    loadCldr(gregorian, numbers, timeZoneNames);
    this.user_name = this.tokenStorageServ.getUserName();
  }

  onChange(e: any) {
    console.log(e)
    this.nowLanguage = this.languageMenu.find(item => e.toLocaleLowerCase().includes(item.title?.toLocaleLowerCase()))?.label;
    this.loadLanguageResource(e);
    this.languageServ.setLanguage(e);

  }

  loadLanguageResource(locale: string): void {

        // 在這裡處理獲取的數據
        switch (locale) {
          case 'en-US':
            L10n.load(enus);
            L10n.load(penus);
            break;
          case 'zh':
            L10n.load(zh);
            L10n.load(pzh);
            loadCldr(zhgregorian, zhnumbers, zhtimeZoneNames);
            break;
          case 'zh-Hant':
            L10n.load(zhhant);
            L10n.load(pzhtw);
            loadCldr(gregorian, numbers, timeZoneNames);
            break;
        }
        setCulture(locale);

  }

  // 登出確認
  confirm() {
    this.confirmationServ.confirm({
      message: `確定要登出嗎?`,
      header: '登出確認',
      accept: () => {
        this.authServ.logout();
      }
    });
  }

}
