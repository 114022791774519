{
  "main": {
    "zh": {
      "identity": {
        "version": {
          "_cldrVersion": "36"
        },
        "language": "zh"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "hanidec",
          "traditional": "hans",
          "finance": "hansfin"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-hanidec": {
          "decimal": ".",
          "group": ",",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "symbols-numberSystem-latn": {
          "decimal": ".",
          "group": ",",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-hanidec": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-other": "0",
              "10000-count-other": "0万",
              "100000-count-other": "00万",
              "1000000-count-other": "000万",
              "10000000-count-other": "0000万",
              "100000000-count-other": "0亿",
              "1000000000-count-other": "00亿",
              "10000000000-count-other": "000亿",
              "100000000000-count-other": "0000亿",
              "1000000000000-count-other": "0万亿",
              "10000000000000-count-other": "00万亿",
              "100000000000000-count-other": "000万亿"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-other": "0",
              "10000-count-other": "0万",
              "100000-count-other": "00万",
              "1000000-count-other": "000万",
              "10000000-count-other": "0000万",
              "100000000-count-other": "0亿",
              "1000000000-count-other": "00亿",
              "10000000000-count-other": "000亿",
              "100000000000-count-other": "0000亿",
              "1000000000000-count-other": "0万亿",
              "10000000000000-count-other": "00万亿",
              "100000000000000-count-other": "000万亿"
            }
          }
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-other": "0",
              "10000-count-other": "0万",
              "100000-count-other": "00万",
              "1000000-count-other": "000万",
              "10000000-count-other": "0000万",
              "100000000-count-other": "0亿",
              "1000000000-count-other": "00亿",
              "10000000000-count-other": "000亿",
              "100000000000-count-other": "0000亿",
              "1000000000000-count-other": "0万亿",
              "10000000000000-count-other": "00万亿",
              "100000000000000-count-other": "000万亿"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-other": "0",
              "10000-count-other": "0万",
              "100000-count-other": "00万",
              "1000000-count-other": "000万",
              "10000000-count-other": "0000万",
              "100000000-count-other": "0亿",
              "1000000000-count-other": "00亿",
              "10000000000-count-other": "000亿",
              "100000000000-count-other": "0000亿",
              "1000000000000-count-other": "0万亿",
              "10000000000000-count-other": "00万亿",
              "100000000000000-count-other": "000万亿"
            }
          }
        },
        "scientificFormats-numberSystem-hanidec": {
          "standard": "#E0"
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-hanidec": {
          "standard": "#,##0%"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-hanidec": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "¤#,##0.00",
          "accounting": "¤#,##0.00;(¤#,##0.00)",
          "short": {
            "standard": {
              "1000-count-other": "0",
              "10000-count-other": "¤0万",
              "100000-count-other": "¤00万",
              "1000000-count-other": "¤000万",
              "10000000-count-other": "¤0000万",
              "100000000-count-other": "¤0亿",
              "1000000000-count-other": "¤00亿",
              "10000000000-count-other": "¤000亿",
              "100000000000-count-other": "¤0000亿",
              "1000000000000-count-other": "¤0万亿",
              "10000000000000-count-other": "¤00万亿",
              "100000000000000-count-other": "¤000万亿"
            }
          },
          "unitPattern-count-other": "{0}{1}"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "¤#,##0.00",
          "accounting": "¤#,##0.00;(¤#,##0.00)",
          "short": {
            "standard": {
              "1000-count-other": "0",
              "10000-count-other": "¤0万",
              "100000-count-other": "¤00万",
              "1000000-count-other": "¤000万",
              "10000000-count-other": "¤0000万",
              "100000000-count-other": "¤0亿",
              "1000000000-count-other": "¤00亿",
              "10000000000-count-other": "¤000亿",
              "100000000000-count-other": "¤0000亿",
              "1000000000000-count-other": "¤0万亿",
              "10000000000000-count-other": "¤00万亿",
              "100000000000000-count-other": "¤000万亿"
            }
          },
          "unitPattern-count-other": "{0}{1}"
        },
        "miscPatterns-numberSystem-hanidec": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}-{1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}-{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-other": "{0} 天",
          "other": "在第 {0} 个路口右转。"
        }
      }
    }
  }
}
