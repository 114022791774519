import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    private nowLanguage: string = '';

    setLanguage(language: string) {
        this.nowLanguage = language;
    }

    getLanguage() {
        return this.nowLanguage;
    }

    constructor() {
    }
}
