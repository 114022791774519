export const environment = {
  production: false,
  apiUrl: 'https://pm.t.api.likbox.com',
  projectCode: 'saas-pm',
  apiVersion: 'v1.0',
  recaptcha: {
    siteKey: '6Letlr8nAAAAAFBA8M_tDHZN7PUTPHO6LLHEUW8K'
  },
  // s3變數
  region: 'ap-southeast-1',
  accessKeyId: "AKIAWI64IZ74HKEYR26P",
  secretAccessKey: "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"
};
