<p-menubar styleClass="text-sm font-medium" [model]="menu">
  <ng-template pTemplate="end">
    <div class="header-end">
      <label class="language-text">{{ nowLanguage }}</label>
      <p-button class="language-btn" icon="pi pi-globe" [rounded]="true" [text]="true"
                (click)="language.toggle($event)"></p-button>
      <p-menu #language [model]="languageMenu" [popup]="true"></p-menu>
      <div class="user-box" (click)="memberMenu.toggle($event)">
        <p-image src="./assets/img/icon/profile-user-white.png" alt="" height="30" width="30"></p-image>
      </div>
    </div>
    <p-overlayPanel #memberMenu styleClass="overlay-panel-min-width">
      <ng-template pTemplate="content">
        <div class="grid">
          <div class="col-12">
            <div class="flex justify-content-center">
              <p-image src="./assets/img/icon/profile-user.png" alt="" height="30"
                       width="30"></p-image>
            </div>
            <div class="font-bold text-xs text-center mt-2">{{ user_name }}</div>
          </div>
          <p-divider class="w-full"></p-divider>
          <div class="flex flex-column w-full">
            <p-button styleClass="p-button-secondary p-button-text p-button-sm" label="帳號設定"
                      icon="pi pi-user-edit" routerLink="/user-info"></p-button>

            <p-button styleClass="p-button-secondary p-button-text p-button-sm" label="登出"
                      icon="pi pi-sign-out" (click)="confirm()"></p-button>
          </div>
        </div>
      </ng-template>
    </p-overlayPanel>
  </ng-template>
</p-menubar>
