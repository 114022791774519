{
  "zh-Hant": {
    "grid": {
      "EmptyRecord": "無資料顯示",
      "True": "真",
      "False": "假",
      "InvalidFilterMessage": "無效的篩選資料",
      "GroupDropArea": "將欄位標題拖曳至此以對該欄位進行分組",
      "UnGroup": "按此處取消分組",
      "GroupDisable": "該欄位的分組已停用",
      "FilterbarTitle": "的篩選器",
      "EmptyDataSourceError": "初始加載時，資料來源不能為空，因為欄位是從 AutoGenerate Column Grid 中的資料來源生成的",
      "Add": "新增",
      "Edit": "編輯",
      "Cancel": "取消",
      "Update": "更新",
      "Delete": "刪除",
      "Print": "列印",
      "Pdfexport": "匯出為PDF",
      "Excelexport": "匯出為Excel",
      "Wordexport": "匯出為Word",
      "Csvexport": "匯出為CSV",
      "Search": "搜尋",
      "Columnchooser": "選擇欄位",
      "Save": "儲存",
      "Item": "項目",
      "Items": "項目",
      "EditOperationAlert": "未選擇任何記錄進行編輯操作",
      "DeleteOperationAlert": "未選擇要刪除操作的記錄",
      "SaveButton": "儲存",
      "OKButton": "確定",
      "CancelButton": "取消",
      "EditFormTitle": "的詳細資料",
      "AddFormTitle": "新增新紀錄",
      "BatchSaveConfirm": "您確定要儲存變更嗎？",
      "BatchSaveLostChanges": "未儲存的變更將會遺失。您確定要繼續嗎？",
      "ConfirmDelete": "您確定要刪除記錄嗎？",
      "CancelEdit": "您確定要取消變更嗎？",
      "ChooseColumns": "選擇欄位",
      "SearchColumns": "搜尋欄",
      "Matchs": "找不到符合的項目",
      "FilterButton": "篩選",
      "ClearButton": "清除",
      "StartsWith": "以...開頭",
      "EndsWith": "以...結尾",
      "Contains": "包含",
      "Equal": "等於",
      "NotEqual": "不等於",
      "LessThan": "小於",
      "LessThanOrEqual": "小於或等於",
      "GreaterThan": "大於",
      "GreaterThanOrEqual": "大於或等於",
      "ChooseDate": "選擇日期",
      "EnterValue": "輸入數值",
      "Copy": "複製",
      "Group": "按此欄位分組",
      "Ungroup": "按此欄位取消分組",
      "Export": "匯出",
      "FirstPage": "第一頁",
      "LastPage": "最後一頁",
      "PreviousPage": "上一頁",
      "NextPage": "下一頁",
      "SortAscending": "升序排序",
      "SortDescending": "降序排序",
      "EditRecord": "編輯記錄",
      "DeleteRecord": "刪除記錄",
      "FilterMenu": "過濾選單",
      "SelectAll": "選取全部",
      "Blanks": "空白",
      "FilterTrue": "真",
      "FilterFalse": "假",
      "NoResult": "找不到符合的項目",
      "ClearFilter": "清除篩選",
      "NumberFilter": "數字篩選器",
      "TextFilter": "文字篩選器",
      "DateFilter": "日期篩選器",
      "DateTimeFilter": "日期時間篩選器",
      "MatchCase": "區分大小寫",
      "Between": "介於",
      "CustomFilter": "自訂過濾",
      "CustomFilterPlaceHolder": "輸入值",
      "CustomFilterDatePlaceHolder": "選擇日期",
      "AND": "且",
      "OR": "或",
      "ShowRowsWhere": "顯示符合以下條件的行：",
      "NotStartsWith": "不以...開頭",
      "NotEndsWith": "不以...結尾",
      "NotContains": "不包含",
      "CurrentPageInfo": "當前頁面信息",
      "TotalItemsInfo": "總項目資訊",
      "TotalItemInfo": "總項目資訊",
      "FirstPageTooltip": "第一頁",
      "LastPageTooltip": "最後一頁",
      "NextPageTooltip": "下一頁",
      "PreviousPageTooltip": "上一頁",
      "NextPagerTooltip": "下一頁",
      "PreviousPagerTooltip": "上一頁",
      "PagerDropDown": "分頁下拉選單",
      "PagerAllDropDown": "全部",
      "All": "全部",
      "IsEmpty": "為空",
      "NotNull": "非空值",
      "IsNull": "空值",
      "IsNotEmpty": "非空",
      "AddCurrentSelection": "將目前選取的內容新增到篩選器",
      "UnGroupButton": "按此取消分組",
      "AutoFitAll": "自動調整所有欄位寬度",
      "AutoFit": "自動調整此欄位寬度",
      "Clear": "清除",
      "FilterMenuDialogARIA": "篩選器菜單對話框",
      "ExcelFilterDialogARIA": "Excel 篩選器對話框",
      "DialogEditARIA": "編輯對話框",
      "ColumnChooserDialogARIA": "欄位選擇器",
      "ColumnMenuDialogARIA": "欄位菜單對話框",
      "CustomFilterDialogARIA": "自訂篩選器對話框",
      "SortAtoZ": "從 A 到 Z 排序",
      "SortZtoA": "從 Z 到 A 排序",
      "SortByOldest": "依據最舊排序",
      "SortByNewest": "依據最新排序",
      "SortSmallestToLargest": "從小到大排序",
      "SortLargestToSmallest": "從大到小排序",
      "Sort": "排序",
      "FilterDescription": "按 Alt + 下鍵開啟篩選器菜單",
      "SortDescription": "按 Enter 鍵進行排序",
      "ColumnMenuDescription": "按 Alt + 下鍵開啟欄位菜單",
      "GroupDescription": "按 Ctrl + 空格鍵進行分組",
      "ColumnHeader": " 欄位標題 ",
      "TemplateCell": " 是範本儲存格",
      "CommandColumnAria": "是命令列列標題 ",
      "DialogEdit": "編輯對話框",
      "ClipBoard": "剪貼簿",
      "GroupButton": "分組按鈕",
      "UnGroupAria": "取消分組按鈕",
      "GroupSeperator": "分組列的分隔符",
      "UnGroupIcon": "取消分組分組的列 ",
      "GroupedSortIcon": "對分組的列進行排序 ",
      "GroupedDrag": "拖曳分組的列",
      "GroupCaption": " 是分組標題儲存格",
      "CheckBoxLabel": "勾選框",
      "Expanded": "展開",
      "Collapsed": "收合",
      "SelectAllCheckbox": "全選勾選框",
      "SelectRow": "選擇列",
      "Like": "類似",
      "autoFitAll": "自動調整所有欄位",
      "autoFit": "自動調整此欄位"
    },
    "pager": {
      "currentPageInfo": "第{0}頁／共{1}頁",
      "totalItemsInfo": "（{0}個項目）",
      "firstPageTooltip": "轉到第一頁",
      "lastPageTooltip": "轉到最後一頁",
      "nextPageTooltip": "轉到下一頁",
      "previousPageTooltip": "轉到上一頁",
      "nextPagerTooltip": "轉到下一個尋呼機項目",
      "previousPagerTooltip": "轉到上一個尋呼機項目",
      "pagerDropDown": "每頁項目",
      "pagerAllDropDown": "物品",
      "All": "所有",
      "totalItemInfo": "（{0}項目）",
      "Container": "尋呼機容器",
      "Information": "尋呼機資訊",
      "ExternalMsg": "尋呼機外部消息",
      "Page": "頁 ",
      "Of": " 的 ",
      "Pages": " 頁面"
    },
    "kanban": {
      "items": "項目",
      "min": "最小",
      "max": "最大",
      "cardsSelected": "已選取卡片",
      "addTitle": "新增卡片",
      "editTitle": "編輯卡片詳細資料",
      "deleteTitle": "刪除卡片",
      "deleteContent": "您確定要刪除此卡片嗎？",
      "save": "儲存",
      "delete": "刪除",
      "cancel": "取消",
      "yes": "是",
      "no": "否",
      "close": "關閉",
      "noCard": "無卡片可顯示",
      "unassigned": "未指派",
      "cards": "卡片",
      "To Do": "待辦",
      "In Progress": "進行中",
      "In Priview": "審核中",
      "Done": "完成"
    },
    "dropdowns": {
      "noRecordsTemplate": "沒有找到記錄",
      "actionFailureTemplate": "請求失敗",
      "overflowCountTemplate": "+${count}更多..",
      "selectAllText": "全選",
      "unSelectAllText": "全部取消選擇",
      "totalCountTemplate": "已選擇${count}個"
    },
    "drop-down-list": {
      "noRecordsTemplate": "沒有找到記錄",
      "actionFailureTemplate": "請求失敗"
    },
    "combo-box": {
      "noRecordsTemplate": "沒有找到記錄",
      "actionFailureTemplate": "請求失敗"
    },
    "auto-complete": {
      "noRecordsTemplate": "沒有找到記錄",
      "actionFailureTemplate": "請求失敗"
    },
    "multi-select": {
      "noRecordsTemplate": "沒有找到記錄",
      "actionFailureTemplate": "請求失敗",
      "overflowCountTemplate": "+${count}更多..",
      "selectAllText": "全選",
      "unSelectAllText": "全部取消選擇",
      "totalCountTemplate": "已選擇${count}個"
    },
    "listbox": {
      "noRecordsTemplate": "沒有找到記錄",
      "actionFailureTemplate": "請求失敗",
      "selectAllText": "全選",
      "unSelectAllText": "全部取消選擇",
      "moveUp": "提升",
      "moveDown": "下移",
      "moveTo": "搬去",
      "moveFrom": "從",
      "moveAllTo": "全部移至",
      "moveAllFrom": "全部移出"
    },
    "spreadsheet": {
      "InsertingEmptyValue": "參考值無效。",
      "FindValue": "尋找價值",
      "ReplaceValue": "替換值",
      "FindReplaceTooltip": "尋找和替換",
      "ByRow": "按行",
      "ByColumn": "按列",
      "MatchExactCellElements": "匹配整個儲存格內容",
      "EnterCellAddress": "輸入儲存格地址",
      "FindAndReplace": "尋找和替換",
      "ReplaceAllEnd": " 比賽取代為",
      "FindNextBtn": "找下一個",
      "FindPreviousBtn": "尋找上一個",
      "ReplaceBtn": "代替",
      "ReplaceAllBtn": "全部替換",
      "GotoHeader": "去",
      "Sheet": "床單",
      "SearchWithin": "在內部搜尋",
      "SearchBy": "搜尋依據",
      "Reference": "參考",
      "Workbook": "工作簿",
      "NoElements": "我們找不到您要找的東西。",
      "FindWhat": "找什麼",
      "ReplaceWith": "用。。。來代替",
      "FileName": "文件名稱",
      "ExtendValidation": "選擇包含一些沒有資料驗證的儲存格。您想將驗證擴展到這些儲存格嗎？",
      "Yes": "是的",
      "No": "不",
      "PROPER": "將文字轉換為正確的大小寫；第一個字母大寫，其他字母小寫。",
      "Cut": "切",
      "Copy": "複製",
      "Paste": "貼上",
      "PasteSpecial": "特殊貼上",
      "All": "全部",
      "Values": "價值觀",
      "Formats": "格式",
      "Font": "字型",
      "FontSize": "字型大小",
      "Bold": "粗體",
      "Italic": "斜體",
      "Underline": "底線",
      "Strikethrough": "刪除線",
      "TextColor": "文字顏色",
      "FillColor": "填色",
      "HorizontalAlignment": "水平對齊",
      "AlignLeft": "左對齊",
      "AlignCenter": "中心",
      "AlignRight": "右對齊",
      "VerticalAlignment": "垂直對齊",
      "AlignTop": "頂部對齊",
      "AlignMiddle": "居中對齊",
      "AlignBottom": "底部對齊",
      "MergeCells": "合併儲存格",
      "MergeAll": "全部合併",
      "MergeHorizontally": "水平合併",
      "MergeVertically": "垂直合併",
      "Unmerge": "取消合併",
      "UnmergeCells": "取消合併儲存格",
      "SelectMergeType": "選擇合併類型",
      "MergeCellsAlert": "合併儲存格只會保留最左上角（最上層）的值。還是合併？",
      "PasteMergeAlert": "我們不能對合併儲存格執行此操作。",
      "Borders": "邊框",
      "TopBorders": "頂部邊框",
      "LeftBorders": "左邊框",
      "RightBorders": "右邊框",
      "BottomBorders": "底部邊框",
      "AllBorders": "所有邊框",
      "HorizontalBorders": "水平邊框",
      "VerticalBorders": "垂直邊框",
      "OutsideBorders": "境外",
      "InsideBorders": "內部邊界",
      "NoBorders": "無國界",
      "BorderColor": "邊框顏色",
      "BorderStyle": "邊框樣式",
      "InsertFunction": "插入函數",
      "Insert": "插入",
      "Delete": "刪除",
      "DuplicateSheet": "複製",
      "MoveRight": "向右移",
      "MoveLeft": "向左移動",
      "Rename": "改名",
      "Hide": "隱藏",
      "NameBox": "姓名框",
      "ShowHeaders": "顯示標題",
      "HideHeaders": "隱藏標題",
      "ShowGridLines": "顯示網格線",
      "HideGridLines": "隱藏網格線",
      "FreezePanes": "凍結窗格",
      "FreezeRows": "凍結行",
      "FreezeColumns": "凍結列",
      "UnfreezePanes": "解凍窗格",
      "UnfreezeRows": "解凍行",
      "UnfreezeColumns": "解凍列",
      "AddSheet": "新增工作表",
      "ListAllSheets": "列出所有工作表",
      "CollapseToolbar": "摺疊工具列",
      "ExpandToolbar": "展開工具列",
      "CollapseFormulaBar": "摺疊公式欄",
      "ExpandFormulaBar": "展開公式欄",
      "File": "檔案",
      "Home": "家",
      "Formulas": "公式",
      "View": "看法",
      "New": "新的",
      "Open": "打開",
      "SaveAs": "另存為",
      "ExcelXlsx": "微軟Excel",
      "ExcelXls": "微軟 Excel 97-2003",
      "CSV": "逗號分隔值",
      "FormulaBar": "公式欄",
      "Sort": "種類",
      "SortAscending": "上升",
      "SortDescending": "降序",
      "CustomSort": "自訂排序",
      "AddColumn": "新增列",
      "ContainsHeader": "封包含標題",
      "CaseSensitive": "區分大小寫",
      "SortBy": "排序方式",
      "ThenBy": "然後通過",
      "SelectAColumn": "選擇一列",
      "SortEmptyFieldError": "所有排序條件都必須指定一列。檢查選定的排序標準並重試。",
      "SortDuplicateFieldError": " 不止一次按值排序。刪除重複的排序條件並重試。",
      "SortOutOfRangeError": "選擇已使用範圍內的儲存格或範圍，然後重試。",
      "MultiRangeSortError": "這不能在多範圍選擇上完成。選擇一個範圍並重試。",
      "HideRow": "隱藏行",
      "HideRows": "隱藏行",
      "UnhideRows": "取消隱藏行",
      "HideColumn": "隱藏列",
      "HideColumns": "隱藏列",
      "UnhideColumns": "取消隱藏列",
      "InsertRow": "插入行",
      "InsertRows": "插入行",
      "Above": "多於",
      "Below": "以下",
      "InsertColumn": "插入列",
      "InsertColumns": "插入列",
      "Before": "前",
      "After": "後",
      "DeleteRow": "刪除行",
      "DeleteRows": "刪除行",
      "DeleteColumn": "刪除列",
      "DeleteColumns": "刪除列",
      "Ok": "好的",
      "Close": "關閉",
      "MoreOptions": "更多的選擇",
      "Cancel": "取消",
      "Apply": "申請",
      "MoreColors": "更多顏色",
      "StandardColors": "標準顏色",
      "General": "一般的",
      "Number": "數字",
      "Currency": "貨幣",
      "Accounting": "會計",
      "ShortDate": "短約會",
      "LongDate": "長日期",
      "Time": "時間",
      "Percentage": "百分比",
      "Fraction": "分數",
      "Scientific": "科學的",
      "Text": "文字",
      "NumberFormat": "數字格式",
      "MobileFormulaBarPlaceHolder": "輸入值或公式",
      "PasteAlert": "你不能在這裡貼上，因為複製區域和貼上區域的大小不一樣。請嘗試在不同的範圍內貼上。",
      "DestroyAlert": "您確定要在不保存的情況下銷毀當前工作簿並建立一個新工作簿嗎？",
      "SheetRenameInvalidAlert": "工作表名稱包含無效字元。",
      "SheetRenameEmptyAlert": "工作表名稱不能為空。",
      "SheetRenameAlreadyExistsAlert": "工作表名稱已存在。請輸入其他名稱。",
      "DeleteSheetAlert": "您確定要刪除此工作表嗎？",
      "DeleteSingleLastSheetAlert": "工作簿必須包含至少一個可見的工作表。",
      "PickACategory": "選擇一個類別",
      "Description": "描述",
      "UnsupportedFile": "不支援的檔案",
      "DataLimitExceeded": "檔案資料太大，處理時間較長，要繼續嗎？",
      "FileSizeLimitExceeded": "檔案太大，處理需要更多時間，要繼續嗎？",
      "InvalidUrl": "無效的網址",
      "SUM": "新增一系列數字和/或儲存格。",
      "SUMIF": "根據指定條件新增儲存格。",
      "SUMIFS": "根據指定條件新增儲存格。",
      "ABS": "返回不帶符號的數字的值。",
      "RAND": "返回 0 到 1 之間的隨機數。",
      "RANDBETWEEN": "返回基於指定值的隨機整數。",
      "FLOOR": "將數字向下舍入到給定因子的最接近倍數。",
      "CEILING": "將數字向上舍入為給定因子的最接近倍數。",
      "PRODUCT": "將一系列數字和/或儲存格相乘。",
      "AVERAGE": "計算不包括文字的一系列數字和/或儲存格的平均值。",
      "AVERAGEIF": "根據指定的標準計算儲存格的平均值。",
      "AVERAGEIFS": "根據指定條件計算儲存格的平均值。",
      "AVERAGEA": "計算將 TRUE 評估為 1、將文字和 FALSE 評估為 0 的儲存格的平均值。",
      "COUNT": "計算範圍內包含數值的儲存格。",
      "COUNTIF": "根據指定條件對細胞進行計數。",
      "COUNTIFS": "根據指定條件對細胞進行計數。",
      "COUNTA": "對包含某個範圍內的值的儲存格進行計數。",
      "MIN": "返回給定參數的最小數量。",
      "MAX": "返回最大數量的給定參數。",
      "DATE": "返回基於給定年、月和日的日期。",
      "DAY": "返回給定日期的日期。",
      "DAYS": "返回兩個日期之間的天數。",
      "IF": "根據給定的表示式返回值。",
      "IFS": "根據給定的多個表示式返回值。",
      "CalculateAND": "如果所有參數都為 TRUE，則返回 TRUE，否則返回 FALSE。",
      "CalculateOR": "如果任何參數為 TRUE，則返回 TRUE，否則返回 FALSE。",
      "IFERROR": "如果沒有發現錯誤則返回值，否則它將返回指定的值。",
      "CHOOSE": "根據索引號從值列表中返回一個值。",
      "INDEX": "根據行號和列號返回給定範圍內的儲存格值。",
      "FIND": "返回一個字串在另一個字串中的位置，區分大小寫。",
      "CONCATENATE": "將兩個或多個字串組合在一起。",
      "CONCAT": "連接一個列表或一系列文字字串。",
      "SUBTOTAL": "使用給定的函數編號返回範圍的小計。",
      "RADIANS": "將度數轉換為弧度。",
      "MATCH": "返回給定範圍內指定值的相對位置。",
      "SLOPE": "從資料點的線性回歸返回直線的斜率。",
      "INTERCEPT": "通過線性回歸計算 Y 截距線的點。",
      "UNIQUE": "返回範圍或陣列中的唯一值",
      "TEXT": "將值轉換為指定數字格式的文字。",
      "DefineNameExists": "此名稱已存在，請嘗試其他名稱。",
      "CircularReference": "當公式引用一個或多個循環引用時，這可能會導致計算不正確。",
      "SORT": "對陣列的範圍進行排序",
      "T": "檢查值是否為文字並返回文字。",
      "EXACT": "檢查兩個文字字串是否完全相同並返回 TRUE 或 FALSE。",
      "LEN": "返回給定字串中的字元數。",
      "MOD": "返回數字除以除數後的餘數。",
      "ODD": "將正數向上舍入並將負數向下舍入到最接近的奇數。",
      "PI": "返回 pi 的值。",
      "COUNTBLANK": "返回指定儲存格範圍內的空儲存格數。",
      "EVEN": "將正數向上舍入並將負數向下舍入到最接近的偶數。",
      "DECIMAL": "將給定基數中數字的文字表示形式轉換為十進制數。",
      "ADDRESS": "給定指定的行號和列號，以文字形式返回儲存格引用。",
      "CHAR": "返回指定數字中的字元。",
      "CODE": "返回給定字串中第一個字元的數字程式碼。",
      "DOLLAR": "將數字轉換為貨幣格式的文字。",
      "SMALL": "返回給定陣列中的第 k 個最小值。",
      "LARGE": "返回給定陣列中的第 k 個最大值。",
      "TIME": "將小時、分鐘、秒轉換為時間格式的文字。",
      "DEGREES": "將弧度轉換為度數。",
      "FACT": "返回數字的階乘。",
      "MEDIAN": "返回給定數字集的中位數。",
      "EDATE": "返回指定日期之前或之後的給定月數的日期。",
      "DATEVALUE": "將日期字串轉換為日期值。",
      "NOW": "返回當前日期和時間。",
      "HOUR": "返回指定時間字串中的小時數。",
      "MINUTE": "返回指定時間字串中的分鐘數。",
      "SECOND": "返回指定時間字串中的秒數。",
      "MONTH": "返回指定日期字串中的月數。",
      "OR": "或者",
      "AND": "和",
      "CustomFilterDatePlaceHolder": "選擇日期",
      "CustomFilterPlaceHolder": "輸入值",
      "CustomFilter": "自訂過濾器",
      "Between": "之間",
      "MatchCase": "相符",
      "DateTimeFilter": "日期時間過濾器",
      "Undo": "撤消",
      "Redo": "重做",
      "ClearAllFilter": "清除",
      "ReapplyFilter": "重新申請",
      "DateFilter": "日期過濾器",
      "TextFilter": "文字過濾器",
      "NumberFilter": "數字過濾器",
      "ClearFilter": "清除過濾器",
      "NoResult": "未找到匹配項",
      "FilterFalse": "錯誤的",
      "FilterTrue": "真的",
      "Blanks": "毛坯",
      "SelectAll": "全選",
      "GreaterThanOrEqual": "大於或等於",
      "GreaterThan": "比...更棒",
      "LessThanOrEqual": "小於或等於",
      "LessThan": "少於",
      "NotEqual": "不等於",
      "Equal": "平等的",
      "Contains": "包含",
      "NotContains": "不包含",
      "EndsWith": "以。。結束",
      "NotEndsWith": "不結束於",
      "StartsWith": "以。。開始",
      "NotStartsWith": "不以開頭",
      "IsEmpty": "空的",
      "IsNotEmpty": "不是空的",
      "ClearButton": "清除",
      "FilterButton": "篩選",
      "CancelButton": "取消",
      "OKButton": "好的",
      "Search": "搜尋",
      "DataValidation": "資料驗證",
      "CellRange": "儲存格範圍",
      "Allow": "允許",
      "Data": "資料",
      "Minimum": "最低限度",
      "Maximum": "最大限度",
      "IgnoreBlank": "忽略空格",
      "WholeNumber": "完整的號碼",
      "Decimal": "十進制",
      "Date": "日期",
      "TextLength": "文字長度",
      "List": "列表",
      "NotBetween": "不介於",
      "EqualTo": "等於",
      "NotEqualTo": "不等於",
      "GreaterThanOrEqualTo": "大於或等於",
      "LessThanOrEqualTo": "小於或等於",
      "InCellDropDown": "儲存格下拉",
      "Sources": "來源",
      "Value": "價值",
      "Retry": "重試",
      "DialogError": "列表源必須是對單個行或列的引用。",
      "MinMaxError": "最大值必須大於或等於最小值。",
      "Spreadsheet": "電子表格",
      "MoreValidation": "此選擇包含多個驗證。",
      "FileNameError": "檔案名稱不能包含像 \\ / : * 這樣的字元？ \" < > [ ] |",
      "ValidationError": "此值與為儲存格定義的資料有效性限制不匹配。",
      "ListLengthError": "列表值最多隻允許 256 個字元",
      "ProtectSheet": "保護表",
      "UnprotectSheet": "取消保護工作表",
      "SelectCells": "選擇鎖定的儲存格",
      "SelectUnlockedCells": "選擇解鎖的儲存格",
      "Save": "救",
      "EmptyFileName": "檔案名稱不能為空。",
      "LargeName": "名稱太長。",
      "FormatCells": "格式化儲存格",
      "FormatRows": "格式化行",
      "FormatColumns": "格式化列",
      "InsertLinks": "插入連結",
      "ProtectContent": "保護鎖定儲存格的內容",
      "ProtectAllowUser": " 允許此工作表的所有使用者：",
      "EditAlert": "您嘗試更改的儲存格受到保護。要進行更改，請取消保護工作表。",
      "ClearValidation": "清除驗證",
      "ISNUMBER": "當值解析為數值時返回 true。",
      "ROUND": "將數字四捨五入到指定的位數。",
      "GEOMEAN": "返回陣列或正資料範圍的幾何平均值。",
      "POWER": "返回一個數的冪的結果",
      "LOG": "返回數字的對數，以您指定的底數為底。",
      "TRUNC": "將數字的截斷值返回到指定的小數位數。",
      "EXP": "返回 e 的給定數次方。",
      "LOOKUP": "尋找一組行或列中的值",
      "HLOOKUP": "尋找值表頂行中的值並返回與指定表行同一列中的值。",
      "VLOOKUP": "在搜尋範圍的第一列中搜尋指定值。",
      "NOT": "返回指定布林值表示式的反數。",
      "EOMONTH": "返回從指定的初始開始日期開始的指定月數之前和之後的月份的最後一天。",
      "SQRT": "返回正數的平方根。",
      "ROUNDDOWN": "將數字四捨五入為 0。",
      "RSQ": "返回基於資料點“known_y”和“known_x”的皮爾遜積矩相關係數的平方。",
      "HighlightCellsRules": "突出顯示儲存格規則",
      "CFEqualTo": "等於",
      "TextThatContains": "包含的文字",
      "ADateOccuring": "發生的日期",
      "DuplicateValues": "重複值",
      "TopBottomRules": "最高/最低規則",
      "Top10Items": "前 10 項",
      "Top10": "前10名",
      "Bottom10Items": "後 10 項",
      "Bottom10": "後 10 名",
      "AboveAverage": "高於平均水平",
      "BelowAverage": "低於平均水平",
      "FormatCellsGreaterThan": "格式化大於的儲存格：",
      "FormatCellsLessThan": "格式化儲存格小於：",
      "FormatCellsBetween": "格式化介於以下之間的儲存格：",
      "FormatCellsEqualTo": "格式化儲存格等於：",
      "FormatCellsThatContainTheText": "格式化包含文字的儲存格：",
      "FormatCellsThatContainADateOccurring": "格式化包含發生日期的儲存格：",
      "FormatCellsDuplicate": "格式化包含以下內容的儲存格：",
      "FormatCellsTop": "格式化排在 TOP 的儲存格：",
      "FormatCellsBottom": "格式化排在底部的儲存格：",
      "FormatCellsAbove": "格式化高於平均水平的儲存格：",
      "FormatCellsBelow": "格式化低於平均水平的儲存格：",
      "With": "和",
      "DataBars": "資料條",
      "ColorScales": "色階",
      "IconSets": "圖示集",
      "ClearRules": "明確的規則",
      "SelectedCells": "清除所選儲存格中的規則",
      "EntireSheet": "從整個工作表中清除規則",
      "LightRedFillWithDarkRedText": "淡紅色填充深紅色文字",
      "YellowFillWithDarkYellowText": "黃色填充深黃色文字",
      "GreenFillWithDarkGreenText": "綠色填充深綠色文字",
      "RedFill": "紅色填充",
      "RedText": "紅色文字",
      "Duplicate": "複製",
      "Unique": "獨特",
      "And": "和",
      "WebPage": "網頁",
      "ThisDocument": "這個檔案",
      "DisplayText": "顯示文字",
      "Url": "網址",
      "CellReference": "儲存格引用",
      "DefinedNames": "定義名稱",
      "EnterTheTextToDisplay": "輸入要顯示的文字",
      "EnterTheUrl": "輸入網址",
      "INT": "將數字返回到最接近的整數。",
      "SUMPRODUCT": "返回給定陣列範圍的乘積之和。",
      "TODAY": "返回當前日期作為日期值。",
      "ROUNDUP": "將數字從零舍入。",
      "Link": "關聯",
      "Hyperlink": "超級連結",
      "EditHyperlink": "編輯超連結",
      "OpenHyperlink": "打開超連結",
      "RemoveHyperlink": "刪除超連結",
      "InvalidHyperlinkAlert": "該站點的地址無效。檢查地址並重試。",
      "InsertLink": "插入連結",
      "EditLink": "編輯連結",
      "WrapText": "環繞文字",
      "Update": "更新",
      "SortAndFilter": "排序和過濾",
      "Filter": "篩選",
      "FilterCellValue": "按所選儲存格的值過濾",
      "FilterOutOfRangeError": "選擇已使用範圍內的儲存格或範圍，然後重試。",
      "ClearFilterFrom": "清除過濾器自",
      "LN": "返回數字的自然對數。",
      "DefineNameInValid": "您輸入的名稱無效。",
      "EmptyError": "您必須輸入一個值",
      "ClearHighlight": "清除突出顯示",
      "HighlightInvalidData": "突出顯示無效資料",
      "Clear": "清除",
      "ClearContents": "清除內容",
      "ClearAll": "全部清除",
      "ClearFormats": "清除格式",
      "ClearHyperlinks": "清除超連結",
      "Image": "圖片",
      "ConditionalFormatting": "條件格式",
      "BlueDataBar": "藍色資料欄",
      "GreenDataBar": "綠色資料欄",
      "RedDataBar": "紅色資料條",
      "OrangeDataBar": "橙色資料欄",
      "LightBlueDataBar": "淺藍色資料欄",
      "PurpleDataBar": "紫色資料欄",
      "GYRColorScale": "綠色 - 黃色 - 紅色色標",
      "RYGColorScale": "紅色 - 黃色 - 綠色色標",
      "GWRColorScale": "綠色 - 白色 - 紅色色標",
      "RWGColorScale": "紅色 - 白色 - 綠色色標",
      "BWRColorScale": "藍色 - 白色 - 紅色色標",
      "RWBColorScale": "紅色 - 白色 - 藍色色階",
      "WRColorScale": "白色 - 紅色色標",
      "RWColorScale": "紅色 - 白色色標",
      "GWColorScale": "綠色 - 白色色標",
      "WGColorScale": "白色 - 綠色色標",
      "GYColorScale": "綠色 - 黃色色標",
      "YGColorScale": "黃色 - 綠色色標",
      "ThreeArrowsColor": "3支箭頭（彩色）",
      "ThreeArrowsGray": "3支箭頭（灰色）",
      "ThreeTriangles": "3個三角形",
      "FourArrowsColor": "4支箭頭（灰色）",
      "FourArrowsGray": "4 支箭頭（彩色）",
      "FiveArrowsColor": "5支箭頭（灰色）",
      "FiveArrowsGray": "5支箭（彩色）",
      "ThreeTrafficLights1": "3 個交通燈（無邊框）",
      "ThreeTrafficLights2": "3 個交通燈（鑲邊）",
      "ThreeSigns": "3 標誌",
      "FourTrafficLights": "4個交通燈",
      "RedToBlack": "紅變黑",
      "ThreeSymbols1": "3個符號（圓圈）",
      "ThreeSymbols2": "3個符號（未圈出）",
      "ThreeFlags": "3旗幟",
      "ThreeStars": "3星",
      "FourRatings": "4 個評分",
      "FiveQuarters": "5個宿舍",
      "FiveRatings": "5 個評分",
      "FiveBoxes": "5盒",
      "Chart": "圖表",
      "Column": "柱子",
      "Bar": "酒吧",
      "Area": "區域",
      "Pie": "餡餅",
      "Doughnut": "油炸圈餅",
      "PieAndDoughnut": "餡餅/甜甜圈",
      "Line": "線",
      "Radar": "雷達",
      "Scatter": "分散",
      "ChartDesign": "圖表設計",
      "ClusteredColumn": "簇狀列",
      "StackedColumn": "堆積柱",
      "StackedColumn100": "100% 堆積柱",
      "ClusteredBar": "簇狀條",
      "StackedBar": "堆積條",
      "StackedBar100": "100% 堆積條",
      "StackedArea": "堆積區",
      "StackedArea100": "100% 堆疊面積",
      "StackedLine": "堆積線",
      "StackedLine100": "100% 堆疊線",
      "LineMarker": "帶標記的線",
      "StackedLineMarker": "帶標記的堆疊線",
      "StackedLine100Marker": "帶標記的 100% 堆疊線",
      "AddChartElement": "新增圖表元素",
      "Axes": "軸",
      "AxisTitle": "軸標題",
      "ChartTitle": "圖表標題",
      "DataLabels": "資料標籤",
      "Gridlines": "網格線",
      "Legends": "傳說",
      "PrimaryHorizontal": "小學水平",
      "PrimaryVertical": "初級垂直",
      "None": "沒有任何",
      "AboveChart": "上圖",
      "Center": "中心",
      "InsideEnd": "內端",
      "InsideBase": "內部基地",
      "OutsideEnd": "外端",
      "PrimaryMajorHorizontal": "初級專業水平",
      "PrimaryMajorVertical": "小學專業垂直",
      "PrimaryMinorHorizontal": "主要次要水平",
      "PrimaryMinorVertical": "主要次要垂直",
      "Right": "正確的",
      "Left": "剩下",
      "Bottom": "底部",
      "Top": "最佳",
      "SwitchRowColumn": "切換行/列",
      "ChartTheme": "圖表主題",
      "ChartType": "圖表類型",
      "Material": "材料",
      "Fabric": "織物",
      "Bootstrap": "啟動載入器",
      "HighContrastLight": "高對比燈",
      "MaterialDark": "材質深色",
      "FabricDark": "面料深色",
      "HighContrast": "高對比度",
      "BootstrapDark": "Bootstrap 深色",
      "Bootstrap4": "Bootstrap4",
      "Bootstrap5Dark": "Bootstrap5 深色",
      "Bootstrap5": "Bootstrap5",
      "Tailwind": "順風",
      "TailwindDark": "順風黑暗",
      "VerticalAxisTitle": "縱軸標題",
      "HorizontalAxisTitle": "橫軸標題",
      "EnterTitle": "輸入標題",
      "UnprotectWorksheet": "取消保護工作表",
      "ReEnterPassword": "重新輸入密碼以繼續",
      "SheetPassword": "取消保護工作表的密碼：",
      "ProtectWorkbook": "保護工作簿",
      "Password": "密碼（可選）：",
      "EnterThePassword": "輸入密碼",
      "ConfirmPassword": "確認密碼",
      "EnterTheConfirmPassword": "重新輸入密碼",
      "PasswordAlert": "確認密碼不一致",
      "UnprotectWorkbook": "取消保護工作簿",
      "UnprotectPasswordAlert": "您提供的密碼不正確。",
      "IncorrectPassword": "無法使用給定的密碼打開檔案或工作表",
      "PasswordAlertMsg": "請輸入密碼",
      "ConfirmPasswordAlertMsg": "請輸入確認密碼",
      "IsProtected": "受到保護",
      "PDF": "PDF文件",
      "AutoFillMergeAlertMsg": "為此，所有合併的儲存格都需要具有相同的大小。",
      "Fluent": "流利",
      "FluentDark": "流利的黑暗",
      "Custom": "風俗",
      "WEEKDAY": "返回與日期對應的星期幾。",
      "FillSeries": "填充系列",
      "CopyCells": "複製儲存格",
      "FillFormattingOnly": "僅填充格式",
      "FillWithoutFormatting": "不帶格式填充",
      "CustomFormat": "自訂數字格式",
      "CustomFormatPlaceholder": "鍵入或選擇自訂格式",
      "CustomFormatTypeList": "類型",
      "CellReferenceTypoError": "我們在您的儲存格引用中發現了拼寫錯誤。是否要按如下方式更正此引用？",
      "AddCurrentSelection": "將當前選擇新增到過濾器",
      "ExternalWorkbook": "匯入的 Excel 檔案包含外部工作簿引用。您想匯入該檔案嗎？",
      "Directional": "方向性",
      "Shapes": "形狀",
      "Indicators": "指標",
      "Ratings": "評級",
      "InvalidFormulaError": "我們發現您輸入的公式無效。",
      "InvalidArguments": "我們發現您輸入的公式包含無效參數。",
      "EmptyExpression": "我們發現您輸入的公式包含空表示式。",
      "MismatchedParenthesis": "我們發現您鍵入的公式缺少一個或多個左括號或右括號。",
      "ImproperFormula": "我們發現您輸入的公式不正確。",
      "WrongNumberOfArguments": "我們發現您輸入的公式參數數量錯誤。",
      "Requires3Arguments": "我們發現您輸入的公式需要 3 個參數。",
      "MismatchedStringQuotes": "我們發現您輸入的公式的引號不匹配。",
      "FormulaCircularRef": "我們發現您輸入的公式帶有循環引用。"
    },
    "filemanager": {
      "NewFolder": "新建檔案夾",
      "Upload": "上載",
      "Delete": "刪除",
      "Rename": "改名",
      "Download": "下載",
      "Cut": "切",
      "Copy": "複製",
      "Paste": "糊",
      "SortBy": "排序方式",
      "Refresh": "刷新",
      "Item-Selection": "選擇的項目",
      "Items-Selection": "選擇的項目",
      "View": "視圖",
      "Details": "細節",
      "SelectAll": "全選",
      "Open": "打開",
      "Tooltip-NewFolder": "新建檔案夾",
      "Tooltip-Upload": "上載",
      "Tooltip-Delete": "刪除",
      "Tooltip-Rename": "改名",
      "Tooltip-Download": "下載",
      "Tooltip-Cut": "切",
      "Tooltip-Copy": "複製",
      "Tooltip-Paste": "糊",
      "Tooltip-SortBy": "排序方式",
      "Tooltip-Refresh": "刷新",
      "Tooltip-Selection": "清空選項",
      "Tooltip-View": "視圖",
      "Tooltip-Details": "細節",
      "Tooltip-SelectAll": "全選",
      "Name": "名稱",
      "Size": "尺寸",
      "DateModified": "改性",
      "DateCreated": "創建日期",
      "Path": "路徑",
      "Modified": "改性",
      "Created": "已建立",
      "Location": "位置",
      "Type": "類型",
      "Permission": "允許",
      "Ascending": "上升",
      "Descending": "降序",
      "None": "沒有",
      "View-LargeIcons": "大圖標",
      "View-Details": "細節",
      "Search": "搜尋",
      "Button-Ok": "好",
      "Button-Cancel": "取消",
      "Button-Yes": "是",
      "Button-No": "沒有",
      "Button-Create": "創建",
      "Button-Save": "保存",
      "Header-NewFolder": "夾",
      "Content-NewFolder": "輸入您的檔案夾名稱",
      "Header-Rename": "改名",
      "Content-Rename": "輸入你的新名字",
      "Header-Rename-Confirmation": "重新命名確認",
      "Content-Rename-Confirmation": "如果更改檔案擴展名，檔案可能會變得不穩定。您確定要更改嗎？",
      "Header-Delete": "刪除檔案",
      "Content-Delete": "您確定要刪除此檔案嗎？",
      "Header-Folder-Delete": "刪除資料夾",
      "Content-Folder-Delete": "您確定要刪除此檔案夾嗎？",
      "Header-Multiple-Delete": "刪除多個檔案",
      "Content-Multiple-Delete": "您確定要刪除這些{0}檔案嗎？",
      "Header-Duplicate": "檔案/檔案夾存在",
      "Content-Duplicate": "{0}已經存在。您要重新命名並粘貼嗎？",
      "Header-Upload": "上傳檔案",
      "Error": "錯誤",
      "Validation-Empty": "檔案或檔案夾名稱不能為空。",
      "Validation-Invalid": "檔案或檔案夾名稱{0}包含無效字元。請使用其他名稱。有效的檔案或檔案夾名稱不能以點或空格結尾，並且不能包含以下任何字元：\\ /：*？“ <> |",
      "Validation-NewFolder-Exists": "名稱為{0}的檔案或檔案夾已存在。",
      "Validation-Rename-Exists": "無法將{0}重新命名為{1}：目標已經存在。",
      "Folder-Empty": "這個檔案夾是空的",
      "File-Upload": "將檔案拖到此處進行上傳",
      "Search-Empty": "未找到結果",
      "Search-Key": "嘗試使用其他關鍵字",
      "Filter-Empty": "未找到結果",
      "Filter-Key": "嘗試使用其他過濾器",
      "Sub-Folder-Error": "目標檔案夾是原始檔夾的子檔案夾。",
      "Same-Folder-Error": "目標資料夾與原始檔夾相同。",
      "Access-Denied": "拒絕訪問",
      "Access-Details": "您無權訪問此檔案夾。",
      "Header-Retry": "檔案已存在",
      "Content-Retry": "該檔案夾中已經存在具有該名稱的檔案。你想幹什麼？",
      "Button-Keep-Both": "保留兩個",
      "Button-Replace": "更換",
      "Button-Skip": "跳躍",
      "ApplyAll-Label": "對所有當前項目執行此操作",
      "KB": "知識庫",
      "Access-Message": "{0}無法訪問。您需要權限才能執行{1}操作。",
      "Network-Error": "NetworkError：無法在XMLHTTP上傳送請求：無法載入",
      "Server-Error": "ServerError：來自的無效響應"
    },
    "calendar": {
      "today": "今天"
    },
    "datepicker": {
      "today": "今天",
      "placeholder": "選擇一個日期"
    },
    "daterangepicker": {
      "placeholder": "選擇日期範圍",
      "startLabel": "開始日期",
      "endLabel": "結束日期",
      "applyText": "應用",
      "cancelText": "取消",
      "selectedDays": "選定的日子",
      "days": "天",
      "customRange": "自訂範圍"
    },
    "timepicker": {
      "placeholder": "選擇一個時間"
    },
    "datetimepicker": {
      "today": "今天",
      "placeholder": "選擇日期和時間"
    },
    "dialog": {
      "close": "關"
    },
    "toast": {
      "close": "關"
    },
    "tab": {
      "closeButtonTitle": "關"
    },
    "schedule": {
      "day": "天",
      "week": "周",
      "workWeek": "工作周",
      "month": "月",
      "year": "年",
      "agenda": "議程",
      "weekAgenda": "周議程",
      "workWeekAgenda": "工作周議程",
      "monthAgenda": "月議程",
      "today": "今天",
      "noEvents": "沒有事件",
      "emptyContainer": "這一天沒有安排任何活動。",
      "allDay": "一整天",
      "start": "開始",
      "end": "結束",
      "more": "更多",
      "close": "關",
      "cancel": "取消",
      "noTitle": "（無題）",
      "delete": "刪除",
      "deleteEvent": "刪除事件",
      "deleteMultipleEvent": "刪除多個事件",
      "selectedItems": "選擇的項目",
      "deleteSeries": "刪除系列",
      "edit": "編輯",
      "editSeries": "編輯系列",
      "editEvent": "編輯事件",
      "createEvent": "建立",
      "subject": "學科",
      "addTitle": "新增標題",
      "moreDetails": "更多細節",
      "moreEvents": "更多事件",
      "save": "保存",
      "editContent": "您想如何更改該系列中的約會？",
      "deleteContent": "你確定要刪除這個事件？",
      "deleteMultipleContent": "您確定要刪除所選事件嗎？",
      "newEvent": "新事件",
      "title": "標題",
      "location": "位置",
      "description": "描述",
      "timezone": "時區",
      "startTimezone": "啟動時區",
      "endTimezone": "結束時區",
      "repeat": "重複",
      "saveButton": "保存",
      "cancelButton": "取消",
      "deleteButton": "刪除",
      "recurrence": "循環",
      "wrongPattern": "重複發生模式無效。",
      "seriesChangeAlert": "您是否要取消對此系列特定實例所做的更改，然後再次將其與整個系列匹配？",
      "createError": "事件的持續時間必須短於其發生的頻率。縮短持續時間，或更改重複事件編輯器中的重複發生模式。",
      "sameDayAlert": "在同一天不能發生兩次同一事件。",
      "occurenceAlert": "如果它跳過同一約會的較晚發生，則無法重新安排定期約會的發生。",
      "editRecurrence": "編輯重複",
      "repeats": "重複",
      "alert": "警報",
      "startEndError": "選定的結束日期發生在開始日期之前。",
      "invalidDateError": "輸入的日期值無效。",
      "blockAlert": "無法在阻止的時間範圍內安排事件。",
      "ok": "好",
      "yes": "是",
      "no": "沒有",
      "occurrence": "發生",
      "series": "系列",
      "previous": "以前",
      "next": "下一個",
      "timelineDay": "時間表日",
      "timelineWeek": "時間軸週",
      "timelineWorkWeek": "時間表工作週",
      "timelineMonth": "時間線月",
      "timelineYear": "時間軸年份",
      "editFollowingEvent": "後續活動",
      "deleteTitle": "刪除活動",
      "editTitle": "編輯活動",
      "beginFrom": "從開始",
      "endAt": "結束於",
      "expandAllDaySection": "展開全天部分",
      "collapseAllDaySection": "全天摺疊部分",
      "searchTimezone": "搜尋時區",
      "noRecords": "沒有找到記錄",
      "deleteRecurrenceContent": "你想只刪除這個事件或整個系列？",
      "recurrenceDateValidation": "有些月份比所選日期少。在這幾個月中，事件將發生在該月的最後一個日期。"
    },
    "recurrenceeditor": {
      "none": "沒有",
      "daily": "日常",
      "weekly": "每週",
      "monthly": "每月一次",
      "month": "月",
      "yearly": "每年",
      "never": "決不",
      "until": "直到",
      "count": "計數",
      "first": "第一",
      "second": "第二",
      "third": "第三",
      "fourth": "第四",
      "last": "持續",
      "repeat": "重複",
      "repeatEvery": "重複每一個",
      "on": "重複",
      "end": "結束",
      "onDay": "天",
      "days": "天",
      "months": "月份",
      "years": "年份",
      "every": "每一個",
      "summaryTimes": "時",
      "summaryOn": "上",
      "summaryUntil": "直到",
      "summaryRepeat": "重複",
      "summaryDay": "天",
      "summaryWeek": "週",
      "summaryMonth": "個月",
      "summaryYear": "年份",
      "monthWeek": "月週",
      "monthPosition": "月份位置",
      "monthExpander": "月擴展器",
      "yearExpander": "年擴展器",
      "repeatInterval": "重複間隔"
    },
    "gantt": {
      "emptyRecord": "無可顯示的記錄",
      "id": "ID",
      "name": "名稱",
      "startDate": "開始日",
      "endDate": "結束日",
      "duration": "工作天",
      "progress": "進度(%)",
      "dependency": "相依性",
      "notes": "備註",
      "baselineStartDate": "基準開始日",
      "baselineEndDate": "基準結束日",
      "taskMode": "任務模式",
      "changeScheduleMode": "變更排程模式",
      "subTasksStartDate": "子任務開始日",
      "subTasksEndDate": "子任務結束日",
      "scheduleStartDate": "排程開始日",
      "scheduleEndDate": "排程結束日",
      "auto": "自動",
      "manual": "手動",
      "type": "類型",
      "offset": "偏移量(天)",
      "resourceName": "資源",
      "resourceID": "資源ID",
      "day": "天",
      "hour": "小時",
      "minute": "分鐘",
      "days": "天",
      "hours": "小時",
      "minutes": "分鐘",
      "generalTab": "一般",
      "customTab": "自訂欄位",
      "writeNotes": "寫備註",
      "addDialogTitle": "新增任務",
      "editDialogTitle": "任務資訊",
      "saveButton": "儲存",
      "add": "新增",
      "edit": "編輯",
      "update": "更新",
      "delete": "刪除",
      "cancel": "取消",
      "search": "搜尋",
      "task": "任務",
      "tasks": "任務",
      "zoomIn": "放大",
      "zoomOut": "縮小",
      "zoomToFit": "適合視圖",
      "excelExport": "匯出 Excel",
      "csvExport": "匯出 CSV",
      "expandAll": "展開全部",
      "collapseAll": "全部收合",
      "criticalPath": "關鍵任務",
      "nextTimeSpan": "下一時間範圍",
      "prevTimeSpan": "前一時間範圍",
      "okText": "確定",
      "confirmDelete": "確定要刪除此任務嗎？",
      "from": "從",
      "to": "到",
      "taskLink": "任務連結",
      "lag": "延遲(天)",
      "start": "開始(S)",
      "finish": "完成(F)",
      "enterValue": "輸入值",
      "taskBeforePredecessor_FS": "您將 '{0}' 移動到 '{1}' 之前開始，這兩個任務會連結。因此，無法保留連結。請從下方選擇一個動作",
      "taskAfterPredecessor_FS": "您將 '{0}' 移動到 '{1}' 之後開始，這兩個任務會連結。因此，無法保留連結。請從下方選擇一個動作",
      "taskBeforePredecessor_SS": "您將 '{0}' 移動到 '{1}' 之前開始，這兩個任務會連結。因此，無法保留連結。請從下方選擇一個動作",
      "taskAfterPredecessor_SS": "您將 '{0}' 移動到 '{1}' 之後開始，這兩個任務會連結。因此，無法保留連結。請從下方選擇一個動作",
      "taskBeforePredecessor_FF": "您將 '{0}' 移動到 '{1}' 之前完成，這兩個任務會連結。因此，無法保留連結。請從下方選擇一個動作",
      "taskAfterPredecessor_FF": "您將 '{0}' 移動到 '{1}' 之後完成，這兩個任務會連結。因此，無法保留連結。請從下方選擇一個動作",
      "taskBeforePredecessor_SF": "您將 '{0}' 移動到 '{1}' 之前開始，這兩個任務會連結。因此，無法保留連結。請從下方選擇一個動作",
      "taskAfterPredecessor_SF": "您將 '{0}' 移動到 '{1}' 之後開始，這兩個任務會連結。因此，無法保留連結。請從下方選擇一個動作",
      "taskInformation": "任務資訊",
      "deleteTask": "刪除任務",
      "deleteDependency": "刪除相依性",
      "convert": "轉換",
      "save": "儲存",
      "above": "上方插入任務",
      "below": "下方插入任務",
      "child": "子任務",
      "milestone": "里程碑",
      "toTask": "成 任務",
      "toMilestone": "成 里程碑",
      "eventMarkers": "事件標記",
      "leftTaskLabel": "左側任務標籤",
      "rightTaskLabel": "右側任務標籤",
      "timelineCell": "時間軸儲存格",
      "confirmPredecessorDelete": "確定要移除相依性連結嗎？",
      "unit": "單位(%)",
      "work": "工時",
      "taskType": "任務類型",
      "unassignedTask": "未指派任務",
      "group": "群組",
      "indent": "降階",
      "outdent": "升階",
      "segments": "分段",
      "splitTask": "分割任務",
      "mergeTask": "合併任務",
      "left": "左",
      "right": "右",
      "Starts With": "以。。開始",
      "Ends With": "以。。結束"
    },
    "richtexteditor": {
      "alignments": "對齊方式",
      "justifyLeft": "左對齊",
      "justifyCenter": "居中對齊",
      "justifyRight": "右對齊",
      "justifyFull": "對齊對齊",
      "fontName": "字體名稱",
      "fontSize": "字體大小",
      "fontColor": "字體顏色",
      "backgroundColor": "背景顏色",
      "bold": "粗體",
      "italic": "斜體",
      "underline": "底線",
      "strikethrough": "刪除線",
      "clearFormat": "清除格式",
      "clearAll": "全部清除",
      "cut": "切",
      "copy": "複製",
      "paste": "糊",
      "unorderedList": "項目符號列表",
      "orderedList": "編號清單",
      "indent": "增加縮進",
      "outdent": "減少縮進",
      "undo": "撤消",
      "redo": "重做",
      "superscript": "上標",
      "subscript": "下標",
      "createLink": "插入超鏈接",
      "openLink": "打開鏈接",
      "editLink": "編輯連結",
      "removeLink": "刪除鏈接",
      "image": "插入圖片",
      "replace": "更換",
      "numberFormatList": "數字格式列表",
      "bulletFormatList": "項目符號格式列表",
      "audioReplace": "代替",
      "videoReplace": "代替",
      "videoAlign": "對齊",
      "videoDimension": "方面",
      "audioRemove": "消除",
      "videoRemove": "消除",
      "audioLayoutOption": "佈局選項",
      "videoLayoutOption": "佈局選項",
      "align": "對齊",
      "caption": "圖片說明",
      "remove": "去掉",
      "insertLink": "插入連結",
      "display": "顯示",
      "altText": "替代文字",
      "dimension": "變更尺寸",
      "fullscreen": "最大化",
      "maximize": "最大化",
      "minimize": "最小化",
      "lowerCase": "小寫",
      "upperCase": "大寫",
      "print": "列印",
      "formats": "格式",
      "sourcecode": "代碼查看",
      "preview": "預習",
      "viewside": "視面",
      "insertCode": "插入代碼",
      "linkText": "顯示文字",
      "linkTooltipLabel": "標題",
      "linkWebUrl": "網址",
      "linkTitle": "輸入標題",
      "linkurl": "https://example.com",
      "linkOpenInNewWindow": "在新窗口中打開鏈接",
      "linkHeader": "插入連結",
      "dialogInsert": "插入",
      "dialogCancel": "取消",
      "dialogUpdate": "更新資料",
      "imageHeader": "插入圖片",
      "audioHeader": "插入音訊",
      "videoHeader": "插入視訊",
      "imageLinkHeader": "您也可以提供網絡鏈接",
      "audioLinkHeader": "您還可以提供網路連結",
      "videoLinkHeader": "網址",
      "embedVideoLinkHeader": "媒體嵌入 URL",
      "mdimageLink": "請提供您圖片的網址",
      "imageUploadMessage": "將圖片放在此處或瀏覽以上傳",
      "audioUploadMessage": "拖放音訊檔案或瀏覽上傳",
      "videoUploadMessage": "拖放視訊檔案或瀏覽上傳",
      "imageDeviceUploadMessage": "點擊這裡上傳",
      "audioDeviceUploadMessage": "點選這裡上傳",
      "videoDeviceUploadMessage": "點選這裡上傳",
      "imageAlternateText": "替代文字",
      "alternateHeader": "替代文字",
      "browse": "瀏覽",
      "imageUrl": "https://example.com/image.png",
      "audioUrl": "https://example.com/audio.mp3",
      "videoUrl": "https://example.com/video.mp4",
      "webUrl": "網址",
      "embedUrl": "嵌入程式碼",
      "imageCaption": "標題",
      "imageSizeHeader": "圖片尺寸",
      "imageHeight": "高度",
      "imageWidth": "寬度",
      "videoHeight": "高度",
      "videoWidth": "寬度",
      "textPlaceholder": "輸入文字",
      "inserttablebtn": "插入表格",
      "tabledialogHeader": "插入表格",
      "tableWidth": "寬度",
      "cellpadding": "單元填充",
      "cellspacing": "單元間距",
      "columns": "列數",
      "rows": "行數",
      "tableRows": "排",
      "tableColumns": "柱子",
      "tableCellHorizontalAlign": "表格單元格水平對齊",
      "tableCellVerticalAlign": "表格垂直對齊",
      "createTable": "建立表格",
      "removeTable": "刪除表",
      "tableHeader": "標題行",
      "tableRemove": "刪除表",
      "tableCellBackground": "表單元格背景",
      "tableEditProperties": "表格編輯屬性",
      "styles": "款式",
      "insertColumnLeft": "向左插入列",
      "insertColumnRight": "向右插入列",
      "deleteColumn": "刪除欄",
      "insertRowBefore": "在之前插入行",
      "insertRowAfter": "在之後插入行",
      "deleteRow": "刪除行",
      "tableEditHeader": "編輯表格",
      "TableHeadingText": "標題",
      "TableColText": "上校",
      "imageInsertLinkHeader": "插入連結",
      "editImageHeader": "編輯影像",
      "alignmentsDropDownLeft": "左對齊",
      "alignmentsDropDownCenter": "居中對齊",
      "alignmentsDropDownRight": "右對齊",
      "alignmentsDropDownJustify": "對齊對齊",
      "imageDisplayDropDownInline": "排隊",
      "imageDisplayDropDownBreak": "打破",
      "audioLayoutOptionDropDownInline": "排隊",
      "audioLayoutOptionDropDownBreak": "休息",
      "videoLayoutOptionDropDownInline": "排隊",
      "videoLayoutOptionDropDownBreak": "休息",
      "tableInsertRowDropDownBefore": "在之前插入行",
      "tableInsertRowDropDownAfter": "在之後插入行",
      "tableInsertRowDropDownDelete": "刪除行",
      "tableInsertColumnDropDownLeft": "向左插入列",
      "tableInsertColumnDropDownRight": "向右插入列",
      "tableInsertColumnDropDownDelete": "刪除欄",
      "tableVerticalAlignDropDownTop": "對齊頂部",
      "tableVerticalAlignDropDownMiddle": "中間對齊",
      "tableVerticalAlignDropDownBottom": "底部對齊",
      "tableStylesDropDownDashedBorder": "虛線邊框",
      "tableStylesDropDownAlternateRows": "交替行",
      "pasteFormat": "貼上格式",
      "pasteFormatContent": "選擇格式化動作",
      "plainText": "純文字",
      "cleanFormat": "清潔",
      "keepFormat": "保持",
      "pasteDialogOk": "好",
      "pasteDialogCancel": "取消",
      "fileManager": "檔案管理器",
      "fileDialogHeader": "檔案瀏覽器",
      "formatsDropDownParagraph": "段落",
      "formatsDropDownCode": "程式碼",
      "formatsDropDownQuotation": "引述",
      "formatsDropDownHeading1": "標題 1",
      "formatsDropDownHeading2": "標題 2",
      "formatsDropDownHeading3": "標題 3",
      "formatsDropDownHeading4": "標題 4",
      "fontNameSegoeUI": "SegoeUI",
      "fontNameArial": "Arial",
      "fontNameGeorgia": "Georgia",
      "fontNameImpact": "Impact",
      "fontNameTahoma": "Tahoma",
      "fontNameTimesNewRoman": "Times New Roman",
      "fontNameVerdana": "Verdana",
      "formatsOLListNumber": "數字",
      "formatsOLListLowerAlpha": "下阿爾法",
      "formatsOLListUpperAlpha": "上阿爾法",
      "formatsOLListLowerRoman": "下羅馬",
      "formatsOLListUpperRoman": "上羅馬",
      "formatsOLListLowerGreek": "下希臘語",
      "formatsULListDisc": "光碟",
      "formatsULListCircle": "圓圈",
      "formatsULListSquare": "正方形",
      "formatsOLListNone": "沒有任何",
      "formatsULListNone": "沒有任何",
      "formatPainter": "格式刷",
      "emojiPicker": "表情符號選擇器",
      "embeddedCode": "嵌入程式碼",
      "pasteEmbeddedCodeHere": "將嵌入程式碼貼上到此處",
      "emojiPickerTypeToFind": "鍵入以尋找",
      "emojiPickerNoResultFound": "未找到結果",
      "emojiPickerTrySomethingElse": "嘗試別的東西",
      "linkAriaLabel": "在新窗口中打開",
      "imageLinkAriaLabel": "在新窗口中打開",
      "unsupportedImage": "不支援的檔案格式",
      "mergecells": "合併儲存格",
      "verticalsplit": "垂直分割",
      "horizontalsplit": "水平分割",
      "numberFormatListLowerAlpha": "下阿爾法",
      "numberFormatListUpperAlpha": "上阿爾法",
      "numberFormatListLowerRoman": "下羅馬語",
      "numberFormatListUpperRoman": "上羅馬",
      "numberFormatListLowerGreek": "下層希臘語"
    },
    "colorpicker": {
      "Apply": "應用",
      "Cancel": "取消",
      "ModeSwitcher": "切換模式"
    },
    "uploader": {
      "Browse": "瀏覽...",
      "Clear": "明確",
      "Upload": "上載",
      "dropFilesHint": "或將檔案放在此處",
      "invalidMaxFileSize": "檔案太大",
      "invalidMinFileSize": "檔案太小",
      "invalidFileType": "不允許的檔案類型",
      "uploadFailedMessage": "檔案上傳失敗",
      "uploadSuccessMessage": "檔案上傳成功",
      "removedSuccessMessage": "檔案已成功刪除",
      "removedFailedMessage": "無法刪除檔案",
      "inProgress": "上載中",
      "readyToUploadMessage": "準備上傳",
      "abort": "中止",
      "remove": "去掉",
      "cancel": "取消",
      "delete": "刪除檔案",
      "pauseUpload": "檔案上傳已暫停",
      "pause": "暫停",
      "resume": "恢復",
      "retry": "重試",
      "fileUploadCancel": "檔案上傳已取消"
    },
    "numerictextbox": {
      "incrementTitle": "增量值",
      "decrementTitle": "減量值"
    },
    "slider": {
      "incrementTitle": "增加",
      "decrementTitle": "減少"
    },
    "formValidator": {
      "required": "這是必填欄。",
      "email": "請輸入有效的電子郵件地址。",
      "url": "請輸入有效網址。",
      "date": "請輸入一個有效的日期。",
      "dateIso": "請輸入有效日期（ISO）。",
      "creditcard": "請輸入有效的卡號",
      "number": "請輸入一個有效的數字。",
      "digits": "請只輸入數字。",
      "maxLength": "請輸入不超過{0}個字元。",
      "minLength": "請至少輸入{0}個字元。",
      "rangeLength": "請輸入一個介於{0}和{1}個字元之間的值。",
      "range": "請輸入介於{0}和{1}之間的值。",
      "max": "請輸入小於或等於{0}的值。",
      "min": "請輸入一個大於或等於{0}的值。",
      "regex": "請輸入正確的值。",
      "tel": "請輸入一個有效的電話號碼。",
      "pattern": "請輸入正確的圖案值。",
      "equalTo": "請輸入有效的匹配文字"
    },
    "treegrid": {
      "Above": "以上",
      "Below": "下面",
      "AddRow": "新增行",
      "ExpandAll": "展開全部",
      "CollapseAll": "全部收縮"
    },
    "querybuilder": {
      "StartsWith": "以。。開始",
      "EndsWith": "以。。結束",
      "Contains": "包含",
      "NotLike": "包含",
      "Like": "不喜歡",
      "Equal": "等於",
      "NotEqual": "不平等",
      "LessThan": "少於",
      "LessThanOrEqual": "小於或等於",
      "GreaterThan": "比...更棒",
      "GreaterThanOrEqual": "大於等於",
      "Between": "之間",
      "NotBetween": "不介於",
      "Empty": "空的",
      "NotEmpty": "不是空的",
      "In": "在",
      "NotIn": "不在",
      "NotContains": "不包含",
      "Remove": "去掉",
      "SelectField": "選擇一個領域",
      "SelectOperator": "選擇運算符",
      "DeleteRule": "消除這種情況",
      "DeleteGroup": "刪除群組",
      "AddGroup": "新增群組",
      "AddCondition": "新增條件",
      "Edit": "編輯",
      "ValidationMessage": "這是必填欄",
      "SummaryViewTitle": "摘要視圖",
      "OtherFields": "其他領域",
      "AND": "和",
      "OR": "要麼",
      "SelectValue": "輸入值",
      "IsEmpty": "是空的",
      "IsNotEmpty": "不為空",
      "IsNull": "一片空白",
      "IsNotNull": "不為空",
      "True": "真正",
      "False": "假",
      "DoesNotStartWith": "不開始於",
      "DoesNotEndWith": "不結束於",
      "DoesNotContain": "不含",
      "AddButton": "新增組/條件",
      "CloneGroup": "克隆組",
      "LockGroup": "鎖集團",
      "CloneRule": "克隆規則",
      "LockRule": "鎖定規則",
      "UnlockRule": "解鎖規則",
      "UnlockGroup": "解鎖組"
    },
    "barcode": {},
    "datamatrix": {},
    "qrcode": {},
    "pivotview": {
      "grandTotal": "累計",
      "total": "總",
      "value": "值",
      "noValue": "沒有價值",
      "row": "行",
      "column": "柱",
      "collapse": "坍方",
      "expand": "擴大",
      "rowAxisPrompt": "在這裡放行",
      "columnAxisPrompt": "將列放在此處",
      "valueAxisPrompt": "在這裡放值",
      "filterAxisPrompt": "在此放置過濾器",
      "filter": "過濾",
      "filtered": "已過濾",
      "sort": "分類",
      "filters": "篩選器",
      "rows": "行數",
      "columns": "列",
      "values": "價值觀",
      "close": "關",
      "cancel": "取消",
      "delete": "刪除",
      "CalculatedField": "計算場",
      "createCalculatedField": "創建計算欄位",
      "fieldName": "輸入欄位名稱",
      "error": "錯誤",
      "invalidFormula": "無效的公式。",
      "dropText": "例如：（'Sum（Order_Count）'+'Sum（In_Stock）'）* 250",
      "dropTextMobile": "在此處新增欄位並編輯公式。",
      "dropAction": "計算欄位不能放置在值軸以外的任何其他區域中。",
      "alert": "警報",
      "warning": "警告",
      "ok": "好",
      "search": "搜尋",
      "drag": "拖動",
      "remove": "去掉",
      "allFields": "所有領域",
      "formula": "式",
      "addToRow": "新增到行",
      "addToColumn": "新增到列",
      "addToValue": "增值",
      "addToFilter": "新增到過濾器",
      "emptyData": "無記錄可顯示",
      "fieldExist": "此名稱中已經存在一個欄位。請輸入其他名稱。",
      "confirmText": "此名稱中已經存在一個計算欄位。您要更換嗎？",
      "noMatches": "無匹配",
      "format": "匯總值",
      "edit": "編輯",
      "clear": "明確",
      "formulaField": "拖放欄位到公式",
      "dragField": "將欄位拖到公式",
      "clearFilter": "明確",
      "by": "通過",
      "all": "所有",
      "multipleItems": "多個項目",
      "member": "會員",
      "label": "標籤",
      "date": "日期",
      "enterValue": "輸入值",
      "chooseDate": "輸入日期",
      "Before": "之前",
      "BeforeOrEqualTo": "之前或等於",
      "After": "後",
      "AfterOrEqualTo": "之後或等於",
      "labelTextContent": "顯示帶有標籤的項目",
      "dateTextContent": "顯示日期的項目",
      "valueTextContent": "顯示哪些項目",
      "Equals": "等於",
      "DoesNotEquals": "不等於",
      "BeginWith": "開始於",
      "DoesNotBeginWith": "並非始於",
      "EndsWith": "以。。結束",
      "DoesNotEndsWith": "不以",
      "Contains": "包含",
      "DoesNotContains": "不含",
      "GreaterThan": "比...更棒",
      "GreaterThanOrEqualTo": "大於或等於",
      "LessThan": "少於",
      "LessThanOrEqualTo": "小於或等於",
      "Between": "之間",
      "NotBetween": "不介於",
      "And": "和",
      "Sum": "和",
      "Count": "計數",
      "DistinctCount": "不重複計數",
      "Product": "產品",
      "Avg": "平均",
      "Min": "敏",
      "SampleVar": "樣本變量",
      "PopulationVar": "人口變量",
      "RunningTotals": "運行總計",
      "Max": "最高",
      "Index": "指數",
      "SampleStDev": "樣本標準差",
      "PopulationStDev": "人口標準",
      "PercentageOfRowTotal": "行總數的百分比",
      "PercentageOfParentTotal": "佔家長總數的百分比",
      "PercentageOfParentColumnTotal": "父欄總計的百分比",
      "PercentageOfParentRowTotal": "父行總數的百分比",
      "DifferenceFrom": "區別於",
      "PercentageOfDifferenceFrom": "相差百分比",
      "PercentageOfGrandTotal": "佔總數的百分比",
      "PercentageOfColumnTotal": "佔列總數的百分比",
      "NotEquals": "不等於",
      "AllValues": "所有值",
      "conditionalFormatting": "條件格式",
      "apply": "應用",
      "condition": "新增條件",
      "formatLabel": "格式",
      "valueFieldSettings": "值欄位設置",
      "baseField": "基本欄位：",
      "baseItem": "基本項目：",
      "summarizeValuesBy": "通過以下方式匯總值：",
      "sourceName": "欄位名稱：",
      "sourceCaption": "現場字幕：",
      "example": "例如：",
      "editorDataLimitMsg": " 更多項目。搜尋以進一步完善。",
      "details": "細節",
      "manageRecords": "管理記錄",
      "Years": "年份",
      "Quarters": "宿舍",
      "Months": "月數",
      "Days": "天",
      "Hours": "小時",
      "Minutes": "分鐘",
      "Seconds": "秒",
      "save": "保存報告",
      "new": "創建一個新報告",
      "load": "加載",
      "saveAs": "另存為當前報告",
      "rename": "重新命名當前報告",
      "deleteReport": "刪除當前報告",
      "export": "出口",
      "subTotals": "小計",
      "grandTotals": "總計",
      "reportName": "報告名稱：",
      "pdf": "PDF格式",
      "excel": "電子表格",
      "csv": "CSV檔案",
      "png": "PNG",
      "jpeg": "JPEG格式",
      "svg": "SVG",
      "mdxQuery": "MDX查詢",
      "showSubTotals": "顯示小計",
      "doNotShowSubTotals": "不顯示總計",
      "showSubTotalsRowsOnly": "僅顯示小計行",
      "showSubTotalsColumnsOnly": "僅顯示小計列",
      "showGrandTotals": "顯示總計",
      "doNotShowGrandTotals": "不顯示總計",
      "showGrandTotalsRowsOnly": "僅顯示總計行",
      "showGrandTotalsColumnsOnly": "僅顯示總計列",
      "fieldList": "顯示欄位清單",
      "grid": "顯示表",
      "toolbarFormatting": "條件格式",
      "chart": "圖表",
      "reportMsg": "請輸入有效的報告名稱！！！",
      "reportList": "報告清單",
      "removeConfirm": "您確定要刪除此報告嗎？",
      "emptyReport": "找不到報告！",
      "bar": "酒吧",
      "line": "線",
      "area": "區域",
      "scatter": "分散",
      "polar": "極性",
      "of": "的",
      "emptyFormat": "找不到格式！！！",
      "emptyInput": "輸入一個值",
      "newReportConfirm": "是否要保存更改以報告？",
      "emptyReportName": "輸入報告名稱",
      "qtr": "季度",
      "null": "空值",
      "undefined": "未定義",
      "groupOutOfRange": "超出範圍",
      "fieldDropErrorAction": "您要移動的欄位不能放置在報告的該區域中",
      "MoreOption": "更多...",
      "aggregate": "骨料",
      "drillThrough": "鑽透",
      "ascending": "上升",
      "descending": "降序",
      "number": "數",
      "currency": "貨幣",
      "percentage": "百分比",
      "formatType": "格式類型",
      "customText": "貨幣符號",
      "symbolPosition": "符號位置",
      "left": "剩下",
      "right": "對",
      "grouping": "分組",
      "true": "真正",
      "false": "假",
      "decimalPlaces": "小數位數",
      "numberFormat": "數字格式",
      "memberType": "欄位類型",
      "formatString": "格式字串",
      "expressionField": "表達",
      "customFormat": "輸入自定義格式字串",
      "selectedHierarchy": "家長階層",
      "olapDropText": "例如：[度量]。[訂單數量] +（[度量]。[訂單數量] * 0.10）",
      "Percent": "百分",
      "Custom": "自訂",
      "Measure": "測量",
      "Dimension": "尺寸",
      "Standard": "標準",
      "blank": "（空白）",
      "fieldTooltip": "拖放欄位以創建表達式。並且，如果要編輯現有的計算欄位！然後，您只需選擇“計算所得成員”下的欄位即可實現。",
      "QuarterYear": "季度年",
      "fieldTitle": "欄位名稱",
      "drillError": "無法顯示計算欄位的原始項目。",
      "caption": "現場字幕",
      "copy": "複製",
      "defaultReport": "默認報告",
      "customFormatString": "自訂格式",
      "invalidFormat": "無效的格式。",
      "group": "組",
      "unGroup": "取消分組",
      "invalidSelection": "無法將該選擇分組。",
      "groupName": "輸入標題以顯示在標題中",
      "captionName": "輸入組標題",
      "selectedItems": "選定項目",
      "groupFieldCaption": "現場字幕",
      "groupTitle": "組的名字",
      "startAt": "開始於",
      "endAt": "結束於",
      "groupBy": "間隔時間",
      "selectGroup": "選擇組",
      "numberFormatString": "示例：C，P，0000％，### 0。## 0＃等",
      "stackingcolumn": "堆積柱",
      "stackingbar": "疊桿",
      "stackingarea": "堆積面積",
      "stepline": "階梯線",
      "steparea": "台階面積",
      "splinearea": "花鍵面積",
      "spline": "花鍵",
      "stackingcolumn100": "100％堆積柱",
      "stackingbar100": "100％堆積酒吧",
      "stackingarea100": "100％堆積面積",
      "bubble": "氣泡",
      "pareto": "帕累托",
      "radar": "雷達",
      "chartTypeSettings": "圖表類型設置",
      "multipleAxes": "多軸",
      "sortAscending": "升序排序",
      "sortDescending": "降序排列",
      "sortNone": "排序數據順序",
      "clearCalculatedField": "清除編輯的欄位資訊",
      "editCalculatedField": "編輯計算欄位",
      "ChartType": "圖表類型",
      "yes": "是",
      "no": "沒有",
      "numberFormatMenu": "數字格式...",
      "conditionalFormattingMenu": "條件格式...",
      "removeCalculatedField": "您確定要刪除此計算欄位嗎？",
      "replaceConfirmBefore": "一份名為",
      "replaceConfirmAfter": " 已經存在。您要更換嗎？",
      "pie": "餡餅",
      "funnel": "漏斗",
      "doughnut": "甜甜圈",
      "pyramid": "金字塔",
      "showLegend": "顯示圖例",
      "exit": "出口",
      "invalidJSON": "無效的JSON資料",
      "invalidCSV": "無效的CSV資料",
      "stacked": "堆疊式",
      "single": "單身的",
      "multipleAxisMode": "多軸模式",
      "grandTotalPosition": "總計位置",
      "top": "最佳",
      "bottom": "底部",
      "None": "沒有任何",
      "stackingline": "疊線",
      "stackingline100": "100% 疊線",
      "rowPage": "行尋呼機",
      "rowPerPage": "每頁行數",
      "columnPage": "列尋呼機",
      "columnPerPage": "每頁列數",
      "goToFirstPage": "轉到第一頁",
      "goToPreviousPage": "轉到上一頁",
      "goToNextPage": "轉到下一頁",
      "goToLastPage": "轉到最後一頁",
      "combined": "聯合",
      "subTotalPosition": "小計位置",
      "auto": "汽車",
      "loading": "載入中..."
    },
    "pivotfieldlist": {
      "staticFieldList": "樞軸欄位列表",
      "fieldList": "欄位清單",
      "dropFilterPrompt": "在此放置過濾器",
      "dropColPrompt": "將列放在此處",
      "dropRowPrompt": "在這裡放行",
      "dropValPrompt": "在這裡放值",
      "addPrompt": "在此處新增欄位",
      "adaptiveFieldHeader": "選擇欄位",
      "centerHeader": "在以下軸之間拖動欄位：",
      "add": "加",
      "drag": "拖動",
      "filter": "過濾",
      "filtered": "已過濾",
      "sort": "分類",
      "remove": "去掉",
      "filters": "篩選器",
      "rows": "行數",
      "columns": "列",
      "values": "價值觀",
      "CalculatedField": "計算場",
      "createCalculatedField": "創建計算欄位",
      "fieldName": "輸入欄位名稱",
      "error": "錯誤",
      "invalidFormula": "無效的公式。",
      "dropText": "例如：（'Sum（Order_Count）'+'Sum（In_Stock）''）* 250",
      "dropTextMobile": "在此處新增欄位並編輯公式。",
      "dropAction": "計算欄位不能放置在值軸以外的任何其他區域中。",
      "search": "搜尋",
      "close": "關",
      "cancel": "取消",
      "delete": "刪除",
      "alert": "警報",
      "warning": "警告",
      "ok": "好",
      "allFields": "所有領域",
      "formula": "式",
      "fieldExist": "此名稱中已經存在一個欄位。請輸入其他名稱。",
      "confirmText": "此名稱中已經存在一個計算欄位。您要更換嗎？",
      "noMatches": "無匹配",
      "format": "匯總值",
      "edit": "編輯",
      "clear": "明確",
      "formulaField": "拖放欄位到公式",
      "dragField": "將欄位拖到公式",
      "clearFilter": "明確",
      "by": "通過",
      "enterValue": "輸入值",
      "chooseDate": "輸入日期",
      "all": "所有",
      "multipleItems": "多個項目",
      "Equals": "等於",
      "DoesNotEquals": "不等於",
      "BeginWith": "開始於",
      "DoesNotBeginWith": "並非始於",
      "EndsWith": "以。。結束",
      "DoesNotEndsWith": "不以",
      "Contains": "包含",
      "DoesNotContains": "不含",
      "GreaterThan": "比...更棒",
      "GreaterThanOrEqualTo": "大於或等於",
      "LessThan": "少於",
      "LessThanOrEqualTo": "小於或等於",
      "Between": "之間",
      "NotBetween": "不介於",
      "Before": "之前",
      "BeforeOrEqualTo": "之前或等於",
      "After": "後",
      "AfterOrEqualTo": "之後或等於",
      "member": "會員",
      "label": "標籤",
      "date": "日期",
      "value": "值",
      "labelTextContent": "顯示帶有標籤的項目",
      "dateTextContent": "顯示日期的項目",
      "valueTextContent": "顯示哪些項目",
      "And": "和",
      "Sum": "和",
      "Count": "計數",
      "DistinctCount": "不重複計數",
      "Product": "產品",
      "Avg": "平均",
      "Min": "敏",
      "Max": "最高",
      "Index": "指數",
      "SampleStDev": "樣本標準差",
      "PopulationStDev": "人口標準",
      "SampleVar": "樣本變量",
      "PopulationVar": "人口變量",
      "RunningTotals": "運行總計",
      "DifferenceFrom": "區別於",
      "PercentageOfDifferenceFrom": "相差百分比",
      "PercentageOfGrandTotal": "佔總數的百分比",
      "PercentageOfColumnTotal": "佔列總數的百分比",
      "PercentageOfRowTotal": "行總數的百分比",
      "PercentageOfParentTotal": "佔家長總數的百分比",
      "PercentageOfParentColumnTotal": "父欄總計的百分比",
      "PercentageOfParentRowTotal": "父行總數的百分比",
      "Years": "年份",
      "Quarters": "宿舍",
      "Months": "月數",
      "Days": "天",
      "Hours": "小時",
      "Minutes": "分鐘",
      "Seconds": "秒",
      "apply": "應用",
      "valueFieldSettings": "值欄位設置",
      "sourceName": "欄位名稱：",
      "sourceCaption": "現場字幕：",
      "summarizeValuesBy": "通過以下方式匯總值：",
      "baseField": "基本欄位：",
      "baseItem": "基本項目：",
      "example": "例如：",
      "editorDataLimitMsg": " 更多項目。搜尋以進一步完善。",
      "deferLayoutUpdate": "延遲佈局更新",
      "null": "空值",
      "undefined": "未定義",
      "groupOutOfRange": "超出範圍",
      "fieldDropErrorAction": "您要移動的欄位不能放置在報告的該區域中",
      "MoreOption": "更多...",
      "memberType": "欄位類型",
      "selectedHierarchy": "家長階層",
      "formatString": "格式字串",
      "expressionField": "表達",
      "olapDropText": "例如：[度量]。[訂單數量] +（[度量]。[訂單數量] * 0.10）",
      "customFormat": "輸入自定義格式字串",
      "Measure": "測量",
      "Dimension": "尺寸",
      "Standard": "標準",
      "Currency": "貨幣",
      "Percent": "百分",
      "Custom": "自訂",
      "blank": "（空白）",
      "fieldTooltip": "拖放欄位以創建表達式。並且，如果要編輯現有的計算欄位！您只需選擇“計算所得成員”下的欄位即可實現。",
      "fieldTitle": "欄位名稱",
      "QuarterYear": "季度年",
      "caption": "現場字幕",
      "copy": "複製",
      "group": "組",
      "numberFormatString": "示例：C，P，0000％，### 0。## 0＃等",
      "sortAscending": "升序排序",
      "sortDescending": "降序排列",
      "sortNone": "排序數據順序",
      "clearCalculatedField": "清除編輯的欄位資訊",
      "editCalculatedField": "編輯計算欄位",
      "selectGroup": "選擇組",
      "of": "的",
      "removeCalculatedField": "您確定要刪除此計算欄位嗎？",
      "yes": "是",
      "no": "沒有",
      "None": "沒有任何"
    },
    "pdfviewer": {
      "PdfViewer": "PDF查看器",
      "Cancel": "取消",
      "Download file": "下載檔案",
      "Download": "下載",
      "Enter Password": "本文檔受密碼保護。請輸入密碼。",
      "File Corrupted": "檔案損壞",
      "File Corrupted Content": "該檔案已損壞，無法打開。",
      "Fit Page": "適合頁面",
      "Fit Width": "適合寬度",
      "Automatic": "自動",
      "Go To First Page": "顯示第一頁",
      "Invalid Password": "密碼錯誤。請再試一遍。",
      "Next Page": "顯示下一頁",
      "OK": "好的",
      "Open": "打開檔案",
      "Page Number": "當前頁碼",
      "Previous Page": "顯示上一頁",
      "Go To Last Page": "顯示最後一頁",
      "Zoom": "放大",
      "Zoom In": "放大",
      "Zoom Out": "縮小",
      "Page Thumbnails": "頁面縮略圖",
      "Bookmarks": "書籤",
      "Print": "列印檔案",
      "Password Protected": "需要密碼",
      "Copy": "複製",
      "Text Selection": "文字選擇工具",
      "Panning": "平移模式",
      "Text Search": "尋找文字",
      "Find in document": "在檔案中尋找",
      "Match case": "相符",
      "Apply": "應用",
      "GoToPage": "轉到頁面",
      "No matches": "查看器已完成文檔搜尋。找不到更多匹配項",
      "No Text Found": "找不到文字",
      "Undo": "撤消",
      "Redo": "重做",
      "Annotation": "新增或編輯註釋",
      "Highlight": "突出顯示文字",
      "Underline": "下劃線文字",
      "Strikethrough": "刪除線文字",
      "Delete": "刪除註釋",
      "Opacity": "不透明度",
      "Color edit": "換顏色",
      "Opacity edit": "更改不透明度",
      "Highlight context": "突出",
      "Underline context": "底線",
      "Strikethrough context": "罷工",
      "Server error": "Web服務未監聽。 PDF Viewer的所有功能都依賴於Web服務。請啟動網絡服務以繼續。",
      "Open text": "打開",
      "First text": "第一頁",
      "Previous text": "上一頁",
      "Next text": "下一頁",
      "Last text": "最後一頁",
      "Zoom in text": "放大",
      "Zoom out text": "縮小",
      "Selection text": "選拔",
      "Pan text": "泛",
      "Print text": "列印",
      "Search text": "搜尋",
      "Annotation Edit text": "編輯註釋",
      "Line Thickness": "線的粗細",
      "Line Properties": "線屬性",
      "Start Arrow": "開始箭頭",
      "End Arrow": "結束箭頭",
      "Line Style": "線型",
      "Fill Color": "填色",
      "Line Color": "線色",
      "None": "沒有",
      "Open Arrow": "打開",
      "Closed Arrow": "關閉",
      "Round Arrow": "回合",
      "Square Arrow": "廣場",
      "Diamond Arrow": "鑽石",
      "Butt": "屁股",
      "Cut": "切",
      "Paste": "糊",
      "Delete Context": "刪除",
      "Properties": "物產",
      "Add Stamp": "加蓋郵票",
      "Add Shapes": "新增形狀",
      "Stroke edit": "更改筆觸顏色",
      "Change thickness": "更改邊框厚度",
      "Add line": "加線",
      "Add arrow": "新增箭頭",
      "Add rectangle": "新增矩形",
      "Add circle": "新增圈子",
      "Add polygon": "新增多邊形",
      "Add Comments": "新增評論",
      "Comments": "註釋",
      "No Comments Yet": "暫時沒有評論",
      "Accepted": "公認",
      "Completed": "已完成",
      "Cancelled": "取消",
      "Rejected": "拒絕",
      "Leader Length": "引線長度",
      "Scale Ratio": "比例比",
      "Calibrate": "校準",
      "Calibrate Distance": "校準距離",
      "Calibrate Perimeter": "校準周長",
      "Calibrate Area": "校準區域",
      "Calibrate Radius": "校準半徑",
      "Calibrate Volume": "校準體積",
      "highlight": "強調",
      "underline": "底線",
      "strikethrough": "擊穿",
      "Seach text": "搜尋",
      "FormDesigner": "新增和編輯表單域",
      "Client error": "發現客戶端錯誤。請檢查 AjaxRequestSettings 屬性中提供的自訂標頭和 ServerActionSettings 屬性中的 Web 操作方法。",
      "FormDesigner Edit text": "新增和編輯表單域",
      "SubmitForm": "提交表格",
      "Depth": "深度",
      "Closed": "關閉",
      "Round": "圓形的",
      "Square": "正方形",
      "Diamond": "鑽石",
      "Edit": "編輯",
      "Comment": "評論",
      "Comment Panel": "評論面板",
      "Set Status": "設定狀態",
      "Post": "郵政",
      "Page": "頁",
      "Add a comment": "新增評論",
      "Add a reply": "新增回覆",
      "Import Annotations": "從JSON檔案匯入註釋",
      "Export Annotations": "將註釋匯出到JSON檔案",
      "Export XFDF": "將註釋匯出到XFDF檔案",
      "Import XFDF": "從XFDF檔案匯入註釋",
      "Add": "新增",
      "Clear": "清除",
      "Bold": "大膽的",
      "Italic": "斜體",
      "Strikethroughs": "刪除線",
      "Underlines": "底線",
      "Superscript": "上標",
      "Subscript": "下標",
      "Align left": "左對齊",
      "Align right": "左對齊",
      "Center": "中心",
      "Justify": "證明合法",
      "Font color": "字型顏色",
      "Text Align": "文字對齊",
      "Text Properties": "字型樣式",
      "SignatureFieldDialogHeaderText": "新增簽名",
      "HandwrittenSignatureDialogHeaderText": "新增簽名",
      "InitialFieldDialogHeaderText": "新增首字母",
      "HandwrittenInitialDialogHeaderText": "新增首字母",
      "Draw Ink": "墨水",
      "Create": "建立",
      "Font family": "字型系列",
      "Font size": "字型大小",
      "Free Text": "自由文字",
      "Import Failed": "無效的JSON檔案類型或檔案名稱；請選擇一個有效的JSON檔案",
      "File not found": "在所需位置找不到匯入的JSON檔案",
      "Export Failed": "匯出註釋操作失敗；請確保正確新增註釋",
      "of": "的 ",
      "Dynamic": "動態的",
      "Standard Business": "標準業務",
      "Sign Here": "在這裡簽名",
      "Custom Stamp": "定製郵票",
      "Enter Signature as Name": "輸入你的名字",
      "Draw-hand Signature": "畫",
      "Type Signature": "類型",
      "Upload Signature": "上傳",
      "Browse Signature Image": "瀏覽",
      "Save Signature": "保存簽名",
      "Save Initial": "保存初始",
      "Textbox": "文字框",
      "Password": "密碼",
      "Check Box": "複選框",
      "Radio Button": "單選按鈕",
      "Dropdown": "落下",
      "List Box": "列表框",
      "Signature": "簽名",
      "Delete FormField": "刪除表單域",
      "Textbox Properties": "文字框屬性",
      "Name": "姓名",
      "Tooltip": "工具提示",
      "Value": "價值",
      "Form Field Visibility": "表單欄位可見性",
      "Read Only": "唯讀",
      "Required": "必需的",
      "Checked": "已檢查",
      "Show Printing": "展示印刷",
      "Formatting": "格式",
      "Fill": "充滿",
      "Border": "邊界",
      "Border Color": "邊框顏色",
      "Thickness": "厚度",
      "Max Length": "最長長度",
      "List Item": "項目名",
      "Export Value": "項目價值",
      "Dropdown Item List": "下拉項目列表",
      "List Box Item List": "列表框項目列表",
      "General": "一般的",
      "Appearance": "外貌",
      "Options": "選項",
      "Delete Item": "刪除",
      "Up": "向上",
      "Down": "下",
      "Multiline": "多行",
      "Revised": "修改",
      "Reviewed": "已稽核",
      "Received": "已收到",
      "Confidential": "機密的",
      "Approved": "得到正式認可的",
      "Not Approved": "不批准",
      "Witness": "見證",
      "Initial Here": "初始在這裡",
      "Draft": "草稿",
      "Final": "最後",
      "For Public Release": "公開發佈",
      "Not For Public Release": "不公開發佈",
      "For Comment": "徵求意見",
      "Void": "空白",
      "Preliminary Results": "初步結果",
      "Information Only": "僅供參考",
      "in": "在",
      "m": "米",
      "ft_in": "ft_in",
      "ft": "英呎",
      "p": "p",
      "cm": "釐米",
      "mm": "毫米",
      "pt": "點",
      "cu": "銅",
      "sq": "平方米",
      "Initial": "最初的"
    },
    "diagram": {
      "Copy": "複製",
      "Cut": "切",
      "Paste": "糊",
      "Undo": "撤消",
      "Redo": "重做",
      "SelectAll": "全選",
      "Grouping": "分組",
      "Group": "組",
      "UnGroup": "取消分組",
      "Order": "訂購",
      "BringToFront": "向前走",
      "MoveForward": "前進",
      "SendToBack": "發送回",
      "SendBackward": "向後發送"
    },
    "inplace-editor": {
      "save": "保存",
      "cancel": "取消",
      "loadingText": "載入中...",
      "editIcon": "點擊編輯",
      "editAreaClick": "點選編輯",
      "editAreaDoubleClick": "連按兩下即可編輯"
    },
    "drawing": {},
    "drop-down-base": {
      "noRecordsTemplate": "沒有找到記錄",
      "actionFailureTemplate": "請求失敗"
    },
    "chart": {
      "Zoom": "放大",
      "ZoomIn": "放大",
      "ZoomOut": "縮小",
      "Reset": "重啟",
      "Pan": "泛",
      "ResetZoom": "重設縮放"
    },
    "maps": {
      "Zoom": "放大",
      "ZoomIn": "放大",
      "ZoomOut": "縮小",
      "Reset": "重啟",
      "Pan": "泛",
      "ResetZoom": "重設縮放",
      "ImageNotFound": "圖片未找到"
    },
    "drop-down-tree": {
      "noRecordsTemplate": "沒有找到記錄",
      "actionFailureTemplate": "請求失敗",
      "overflowCountTemplate": "+ ${count}更多..",
      "totalCountTemplate": "已選擇${count}個"
    },
    "documenteditor": {
      "Table": "表",
      "Row": "行",
      "Cell": "細胞",
      "Ok": "好",
      "Apply": "緩慢而輕鬆",
      "Cancel": "取消",
      "Size": "尺寸",
      "Preferred Width": "首選寬度",
      "Points": "積分",
      "Percent": "百分",
      "Measure in": "量度",
      "Alignment": "結盟",
      "Left": "左邊",
      "Center": "中心",
      "Right": "正確的",
      "Decimal": "第一個小數位",
      "Bar": "和一半",
      "Leader": "操作",
      "Tab": "逾期帳戶",
      "BookMarkList": "顯示文件列表",
      "TabMarkList": "顯示段落頁",
      "Tab stop position": "磁碟空間",
      "Default tab stops": "卡丁車比賽太棒了。",
      "Tab stops to be cleared": "補卡",
      "Tabs": "選項卡窗口",
      "Set": "使用",
      "Clear": "電話",
      "Clear All": "刪除所有內容",
      "Justify": "證明合法",
      "Indent from left": "從左縮排",
      "Borders and Shading": "邊框和陰影",
      "Options": "選項",
      "Specify height": "指定高度",
      "At least": "至少",
      "Exactly": "確切地",
      "Row height is": "行高為",
      "Allow row to break across pages": "允許行跨頁",
      "Repeat as header row at the top of each page": "在每頁頂部重複作為標題行",
      "Vertical alignment": "垂直對齊",
      "Top": "頂部",
      "Bottom": "底部",
      "Default cell margins": "默認儲存格邊距",
      "Default cell spacing": "默認儲存格間距",
      "Allow spacing between cells": "允許儲存格之間的間距",
      "Cell margins": "儲存格邊距",
      "Same as the whole table": "和整張桌子一樣",
      "Borders": "邊框",
      "None": "沒有任何",
      "Style": "風格",
      "Width": "寬度",
      "Height": "高度",
      "Letter": "信",
      "Tabloid": "小報",
      "Legal": "合法的",
      "Statement": "聲明",
      "Executive": "管理人員",
      "A3": "A3",
      "A4": "A4",
      "A5": "A5",
      "B4": "B4",
      "B5": "B5",
      "Custom Size": "自訂大小",
      "Different odd and even": "奇偶不同",
      "Different first page": "第一頁不同",
      "From edge": "從邊緣",
      "Header": "標頭",
      "Footer": "頁尾",
      "First Page Header": "第一頁標題",
      "First Page Footer": "首頁頁尾",
      "Even Page Header": "偶數頁首",
      "Even Page Footer": "偶數頁尾",
      "Odd Page Header": "奇數頁首",
      "Odd Page Footer": "奇數頁尾",
      "Same as Previous": "與上一個相同",
      "Section": "部分",
      "Margin": "邊距",
      "Paper": "紙",
      "Layout": "佈局",
      "Orientation": "方向",
      "Landscape": "景觀",
      "Portrait": "肖像",
      "Show page numbers": "顯示頁碼",
      "Right align page numbers": "右對齊頁碼",
      "Nothing": "沒有什麼",
      "Tab leader": "製表頭",
      "Show levels": "顯示等級",
      "Use hyperlinks instead of page numbers": "使用超連結而不是頁碼",
      "Build table of contents from": "從中建構目錄",
      "Styles": "樣式",
      "Available styles": "可用款式",
      "TOC level": "TOC等級",
      "Heading": "標題",
      "Heading 1": "標題 1",
      "Heading 2": "標題 2",
      "Heading 3": "標題 3",
      "Heading 4": "標題 4",
      "Heading 5": "標題 5",
      "Heading 6": "標題 6",
      "List Paragraph": "列出段落",
      "Normal": "普通的",
      "Outline levels": "大綱級別",
      "Table entry fields": "表格條目欄位",
      "Modify": "調整",
      "Color": "顏色",
      "Setting": "環境",
      "Box": "盒子",
      "All": "全部",
      "Custom": "風俗",
      "Preview": "預覽",
      "Shading": "底紋",
      "Fill": "充滿",
      "Apply To": "適用於",
      "Table Properties": "表格屬性",
      "Cell Options": "單元選項",
      "Table Options": "表格選項",
      "Insert Table": "插入表格",
      "Number of columns": "列數",
      "Number of rows": "行數",
      "Text to display": "要顯示的文字",
      "ScreenTip text": "螢幕提示文字",
      "Address": "地址",
      "Insert Hyperlink": "插入超連結",
      "Edit Hyperlink": "編輯超連結",
      "Insert": "插入",
      "General": "一般的",
      "Indentation": "縮排",
      "Before text": "正文前",
      "Special": "特別的",
      "First line": "第一行",
      "Hanging": "絞刑",
      "After text": "文字後",
      "By": "經過",
      "Before": "前",
      "Line Spacing": "行間距",
      "After": "後",
      "At": "在",
      "Multiple": "多種的",
      "Spacing": "間距",
      "Define new Multilevel list": "定義新的多級列表",
      "List level": "列表等級",
      "Choose level to modify": "選擇要修改的等級",
      "Level": "等級",
      "Number format": "數字格式",
      "Number style for this level": "此等級的數字樣式",
      "Enter formatting for number": "輸入數字格式",
      "Start at": "開始於",
      "Restart list after": "之後重新啟動列表",
      "Position": "位置",
      "Text indent at": "文字縮排於",
      "Aligned at": "對齊於",
      "Follow number with": "關注號碼",
      "Tab character": "製表符",
      "Space": "空間",
      "Arabic": "阿拉伯",
      "UpRoman": "UpRoman",
      "LowRoman": "低羅馬音",
      "UpLetter": "上信",
      "LowLetter": "低信",
      "Number": "數",
      "Leading zero": "前導零",
      "Bullet": "子彈",
      "Ordinal": "序數",
      "Ordinal Text": "序數文字",
      "For East": "對於東",
      "No Restart": "不重啟",
      "Font": "字型",
      "Font style": "字型樣式",
      "Underline style": "下劃線樣式",
      "Font color": "字型顏色",
      "Effects": "效果",
      "Strikethrough": "刪除線",
      "Superscript": "上標",
      "Subscript": "下標",
      "Double strikethrough": "雙刪除線",
      "Regular": "常規的",
      "Bold": "大膽的",
      "Italic": "斜體",
      "Cut": "切",
      "Copy": "複製",
      "Paste": "貼上",
      "Hyperlink": "超級連結",
      "Open Hyperlink": "打開超連結",
      "Copy Hyperlink": "複製超連結",
      "Remove Hyperlink": "刪除超連結",
      "Paragraph": "段落",
      "Linked Style": "連結（段落和字元）",
      "Character": "特點",
      "Merge Cells": "合併儲存格",
      "Insert Above": "在上方插入",
      "Insert Below": "在下方插入",
      "Insert Left": "向左插入",
      "Insert Right": "右插入",
      "Delete": "刪除",
      "Delete Table": "刪除表",
      "Delete Row": "刪除行",
      "Delete Column": "刪除列",
      "File Name": "檔案名稱",
      "Format Type": "格式類型",
      "Save": "節省",
      "Navigation": "導航",
      "Results": "結果",
      "Replace": "代替",
      "Replace All": "全部替換",
      "We replaced all": "我們全部換掉",
      "Find": "尋找",
      "No matches": "無匹配",
      "All Done": "全做完了",
      "Result": "結果",
      "of": "的",
      "instances": "實例",
      "with": "和",
      "Click to follow link": "點選跟隨連結",
      "Continue Numbering": "繼續編號",
      "Bookmark name": "書籤名稱",
      "Close": "關閉",
      "Restart At": "重啟時間",
      "Properties": "特性",
      "Name": "姓名",
      "Style type": "款式類型",
      "Style based on": "風格基於",
      "Style for following paragraph": "以下段落的樣式",
      "Formatting": "格式化",
      "Numbering and Bullets": "編號和項目符號",
      "Numbering": "編號",
      "Update Field": "更新欄位",
      "Edit Field": "編輯欄位",
      "Bookmark": "書籤",
      "Page Setup": "頁面設定",
      "No bookmarks found": "找不到書籤",
      "Number format tooltip information": "單級數字格式：</br>[PREFIX]%[LEVELNUMBER][SUFFIX]</br>例如，“第 %1 章”。 將顯示編號如</br>第1章項目</br>第2章項目</br>... </br>第N章項目</br> </br>多級編號格式：</br>[ PREFIX]%[LEVELNUMBER][SUFFIX]+[PREFIX]%[LEVELNUMBER][SUFFIX]</br>例如，'%1.%2.' 將顯示編號，如</br>1.1。 項目</br>1.2。 項目</br>…</br>1.N. 物品",
      "Format": "格式",
      "Create New Style": "創造新風格",
      "Modify Style": "修改樣式",
      "New": "新的",
      "Bullets": "子彈",
      "Use bookmarks": "使用書籤",
      "Table of Contents": "目錄",
      "AutoFit": "自動調整",
      "AutoFit to Contents": "自動適應內容",
      "AutoFit to Window": "自動適應窗口",
      "Fixed Column Width": "固定列寬",
      "Reset": "重設",
      "Match case": "相符",
      "Whole words": "整個單詞",
      "Add": "新增",
      "Go To": "去",
      "Search for": "搜尋",
      "Replace with": "用。。。來代替",
      "TOC 1": "目錄1",
      "TOC 2": "目錄2",
      "TOC 3": "目錄3",
      "TOC 4": "目錄4",
      "TOC 5": "目錄5",
      "TOC 6": "目錄6",
      "TOC 7": "目錄7",
      "TOC 8": "目錄8",
      "TOC 9": "目錄9",
      "Right-to-left": "右到左",
      "Left-to-right": "左到右",
      "Direction": "方向",
      "Table direction": "工作台方向",
      "Indent from right": "從右縮排",
      "Contextual Spacing": "不要在相同樣式的段落之間新增空格",
      "Password Mismatch": "密碼不匹配",
      "Restrict Editing": "限制編輯",
      "Formatting restrictions": "格式限制",
      "Allow formatting": "允許格式化",
      "Editing restrictions": "編輯限制",
      "Read only": "唯讀",
      "Exceptions Optional": "例外情況（可選）",
      "Select Part Of Document And User": "選擇文件的某些部分並選擇允許自由編輯它們的使用者。",
      "Everyone": "每個人",
      "More users": "更多使用者",
      "Add Users": "新增使用者",
      "Enforcing Protection": "是，開始強制保護",
      "Start Enforcing Protection": "開始加強保護",
      "Enter User": "輸入使用者",
      "Users": "使用者",
      "Enter new password": "輸入新密碼",
      "Reenter new password to confirm": "重新輸入新密碼以確認",
      "Your permissions": "您的權限",
      "Protected Document": "本文件受到保護，不會被無意編輯。",
      "FormFieldsOnly": "您只能在該地區填寫表格。",
      "CommentsOnly": "您只能將評論插入該區域。",
      "ReadOnlyProtection": "您可以在此區域進行編輯。",
      "Stop Protection": "停止保護",
      "Password": "密碼",
      "Spelling Editor": "拼寫編輯器",
      "Spelling": "拼寫",
      "Spell Check": "拼寫檢查",
      "Underline errors": "下劃線錯誤",
      "Ignore": "忽略",
      "Ignore All": "忽略所有",
      "Add to Dictionary": "新增到詞典",
      "Change": "改變",
      "Change All": "全部更改",
      "Suggestions": "建議",
      "The password is incorrect": "密碼不正確",
      "Error in establishing connection with web server": "與網路伺服器建立連接時出錯",
      "Highlight the regions I can edit": "突出顯示我可以編輯的區域",
      "Show All Regions I Can Edit": "顯示我可以編輯的所有區域",
      "Find Next Region I Can Edit": "尋找我可以編輯的下一個區域",
      "Keep source formatting": "保留源格式",
      "Match destination formatting": "匹配目標格式",
      "Text only": "純文字",
      "Comments": "評論",
      "Type your comment": "輸入您的評論",
      "Post": "郵政",
      "Reply": "回覆",
      "New Comment": "新評論",
      "Edit": "編輯",
      "Resolve": "解決",
      "Reopen": "重開",
      "No comments in this document": "本文檔中無評論",
      "more": "更多",
      "Type your comment here": "在此輸入您的評論",
      "Next Comment": "下一條評論",
      "Previous Comment": "上一條評論",
      "Un-posted comments": "未發表的評論",
      "Discard Comment": "新增的評論未發佈。 如果您繼續，該評論將被丟棄。",
      "Discard": "丟棄",
      "No Headings": "找不到標題！",
      "Add Headings": "本文件沒有標題。 請新增標題並重試。",
      "More Options": "更多的選擇",
      "Click to see this comment": "點選查看此評論",
      "Drop Down Form Field": "下拉表單域",
      "Drop-down items": "下拉項",
      "Items in drop-down list": "下拉式清單中的項目",
      "ADD": "新增",
      "REMOVE": "消除",
      "Field settings": "欄位設定",
      "Tooltip": "工具提示",
      "Drop-down enabled": "下拉已啟用",
      "Check Box Form Field": "複選框表單域",
      "Check box size": "複選框大小",
      "Auto": "汽車",
      "Default value": "預設值",
      "Not checked": "未勾選",
      "Checked": "勾選",
      "Check box enabled": "啟用複選框",
      "Text Form Field": "文字表單域",
      "Type": "類型",
      "Default text": "默認文字",
      "Maximum length": "最大長度",
      "Text format": "文字格式",
      "Fill-in enabled": "啟用填充",
      "Default number": "默認號碼",
      "Default date": "默認日期",
      "Date format": "日期格式",
      "Merge Track": "此操作不會標記為更改。 你想繼續嗎？",
      "UnTrack": "無法追蹤",
      "Accept": "接受",
      "Reject": "拒絕",
      "Previous Changes": "以前的變化",
      "Next Changes": "接下來的變化",
      "Inserted": "已插入",
      "Deleted": "已刪除",
      "Move From": "S",
      "Move To": "因為",
      "Changes": "變化",
      "Accept all": "全都接受",
      "Reject all": "全部拒絕",
      "No changes": "沒有變化",
      "Accept Changes": "接受更改",
      "Reject Changes": "拒絕更改",
      "User": "使用者",
      "View": "看法",
      "Insertion": "插入",
      "Deletion": "刪除",
      "All caps": "全部大寫",
      "This region is locked by": "該區域被鎖定",
      "Lock": "鎖",
      "Unlock": "開鎖",
      "Already locked": "所選區域或部分區域已被其他使用者鎖定",
      "1, 2, 3, ...": "1、2、3，...",
      "a, b, c, ...": "a，b，c，...",
      "A, B, C, ...": "A，B，C，...",
      "I, II, III, ...": "I，II，III，...",
      "i, ii, iii, ...": "i，ii，iii，...",
      "Text Form": "文字形式",
      "Check Box": "複選框",
      "DropDown": "落下",
      "Form Fields": "表單欄位",
      "Fillin enabled": "啟用填充",
      "Multiple Comment": "請發表你的評論",
      "InsertFootnote": "插入腳註",
      "InsertEndnote": "插入尾注",
      "Footnote": "腳註",
      "Endnote": "尾注",
      "Notes Options": "查看選項",
      "Click to View/Edit Footnote": "點選以查看/編輯腳註",
      "Click to View/Edit Endnote": "點選以查看/編輯尾注",
      "InsertAsRows": "作為新行插入",
      "InsertAsColumns": "作為新列插入",
      "OverwriteCells": "覆蓋儲存格",
      "NestTable": "巢狀表",
      "No suggestions": "沒有建議",
      "More Suggestion": "更多建議",
      "Ignore Once": "忽略一次",
      "Keep With Next": "繼續下一步",
      "Keep Lines Together": "保持線條在一起",
      "WidowControl": "寡婦/孤兒控制",
      "Indents and Spacing": "縮排和間距",
      "Line and Page Breaks": "分行符號和分頁符",
      "Pagination": "分頁",
      "Single": "單身的",
      "DashSmallGap": "破折號小缺口",
      "DashDot": "點劃線",
      "Double": "雙倍的",
      "ThinThickSmallGap": "薄厚小間隙",
      "ThickThinSmallGap": "厚薄小間隙",
      "ThickThinMediumGap": "厚薄中等間隙",
      "ThickThinLargeGap": "厚薄大間隙",
      "SingleWavy": "單波浪",
      "DoubleWavy": "雙波浪",
      "Inset": "插圖",
      "DashLargeGap": "破折號大缺口",
      "Dot": "點",
      "DashDotDot": "點點滴滴",
      "Triple": "三倍",
      "ThinThickThinSmallGap": "薄厚薄小間隙",
      "ThinThickThinMediumGap": "薄厚薄中等間隙",
      "ThinThickThinLargeGap": "薄厚薄大間隙",
      "DashDotStroked": "DashDotStroked",
      "Engrave3D": "雕刻3D",
      "Thick": "厚的",
      "Outset": "一開始",
      "Emboss3D": "立體浮雕",
      "ThinThickLargeGap": "薄厚大間隙",
      "ThinThickMediumGap": "薄厚中等間隙",
      "Number of rows must be between": "行數必須介於",
      "Number of columns must be between": "列數必須介於",
      "and": "和",
      "Unlimited": "無限",
      "Regular text": "常規文字",
      "Date": "日期",
      "Uppercase": "大寫",
      "Lowercase": "小寫",
      "FirstCapital": "首控",
      "TitleCase": "書架",
      "Filling in forms": "填寫表格",
      "px": "像素",
      "Tracked changes": "跟蹤更改",
      "TrackChangesOnly": "您可以在此區域中進行編輯，但所有更改都將被跟蹤。",
      "RemovedIgnore": "你想刪除忽略的異常嗎？",
      "RemovedIgnoreExceptions": "如果您在文件保護中進行此更改，Word 將忽略此文件中的所有異常。",
      "Information": "資訊",
      "Yes": "是的",
      "No": "不",
      "Page Break": "分頁符",
      "Column Break": "分欄符",
      "Section Break Next Page": "分節符（下一頁）",
      "Section Break Continuous": "分節符（連續）",
      "Unsupported format": "不支援您選擇的檔案格式。 請選擇有效格式。",
      "One": "一",
      "Two": "二",
      "Three": "三",
      "Presets": "預設",
      "Columns": "列",
      "Split your text into two or more columns": "將您的文字分成兩列或更多列",
      "Line between column": "列之間的線",
      "Width and Spacing": "寬度和間距",
      "Equal column width": "列寬相等",
      "Column": "柱子",
      "Paste Content Dialog": "由於瀏覽器的安全策略，從系統剪貼簿貼上是受限制的。",
      "Paste Content CheckBox": "他沒有再出現。",
      "The top/bottom margins are too large for the page height in some sections.": "某些部分的頂部/底部邊距對於頁面高度來說太大。",
      "Column width cannot be less than 36 pt.": "列寬不能小於 36 磅。",
      "Left and right margins.": "您為左右邊距、列間距或段落縮排選擇的設定對於同一部分中的頁面寬度來說太大。",
      "The address of this site is 'not valid. Check the address and try again.": "該網站的地址無效。檢查地址並重試。",
      "Alt Text": "替代文字",
      "Title": "標題",
      "Description": "描述",
      "Left Indent": "通訊行業",
      "Right Indent": "正確路線",
      "Hanging Indent": "每個人都有自己的角色",
      "First Line Indent": "首先是彎曲線條。",
      "Left Margin": "玉塔",
      "Right Margin": "傾倒",
      "Top Margin": "感測器",
      "Bottom Margin": "演講",
      "Left Tab": "我在你耳邊",
      "Right Tab": "右耳",
      "Center Tab": "中央螢幕",
      "Decimal Tab": "死去的動物",
      "Bar Tab": "卡米爾·B.",
      "Move Table Column": "移動表中的列",
      "Paste CheckBox Content Dialog": "不再顯示",
      "The address of this site is not valid. Check the address and try again.": "該網站的地址無效。",
      "Left and right margins": "您為左右邊距、列間距或段落縮排選擇的設定對於同一部分中的頁面寬度來說太大。",
      "Number of rows must be between 1 and 32767.": "行數必須介於 1 到 32767 之間。",
      "Number of columns must be between 1 and 63.": "列數必須介於 1 到 63 之間。",
      "": "The top/bottom margins are too large for the page height in some sections.",
      "You may format text only with certain styles": "您只能使用某些樣式來設定文字格式。",
      "Ignore all": "忽略所有"
    },
    "documenteditorcontainer": {
      "New": "新的",
      "Open": "打開",
      "Undo": "撤消",
      "Redo": "重做",
      "Image": "圖像",
      "Table": "桌子",
      "Link": "關聯",
      "Bookmark": "書籤",
      "Table of Contents": "目錄",
      "HEADING - - - - 1": "標題----1",
      "HEADING - - - - 2": "標題----2",
      "HEADING - - - - 3": "標題----3",
      "Header": "標頭",
      "Footer": "頁尾",
      "Page Setup": "頁面設定",
      "Page Number": "頁碼",
      "Break": "休息",
      "Find": "尋找",
      "Local Clipboard": "本地剪貼簿",
      "Restrict Editing": "限制編輯",
      "Upload from computer": "從電腦上傳",
      "By URL": "通過網址",
      "Page Break": "分頁符",
      "Section Break": "分節符",
      "Header And Footer": "頁首頁尾",
      "Options": "選項",
      "Levels": "關卡",
      "Different First Page": "第一頁不同",
      "Different header and footer for odd and even pages": "奇數頁和偶數頁的不同頁首和頁尾。",
      "Different Odd And Even Pages": "不同的奇偶頁",
      "Different header and footer for first page": "第一頁的不同頁首和頁尾。",
      "Position": "位置",
      "Header from Top": "頂部標題",
      "Footer from Bottom": "從底部開始的頁尾",
      "Distance from top of the page to top of the header": "從頁面頂部到頁首頂部的距離。",
      "Distance from bottom of the page to bottom of the footer": "從頁面底部到頁尾底部的距離。",
      "Aspect ratio": "縱橫比",
      "W": "W",
      "H": "H",
      "Width": "寬度",
      "Height": "高度",
      "Text": "文字",
      "Paragraph": "段落",
      "Fill": "充滿",
      "Fill color": "填色",
      "Border Style": "邊框樣式",
      "Outside borders": "境外",
      "All borders": "所有邊框",
      "Inside borders": "內部邊界",
      "Left border": "左邊框",
      "Inside vertical border": "垂直邊框內",
      "Right border": "右邊框",
      "Top border": "上邊框",
      "Inside horizontal border": "水平邊框內",
      "Bottom border": "底邊框",
      "Border color": "邊框顏色",
      "Border width": "邊框寬度",
      "Cell": "細胞",
      "Merge cells": "合併儲存格",
      "Insert Or Delete": "插入/刪除",
      "Insert columns to the left": "在左側插入列",
      "Insert columns to the right": "在右側插入列",
      "Insert rows above": "在上面插入行",
      "Insert rows below": "在下方插入行",
      "Delete rows": "刪除行",
      "Delete columns": "刪除列",
      "Cell Margin": "單元邊距",
      "Top": "頂部",
      "Bottom": "底部",
      "Left": "左邊",
      "Right": "正確的",
      "Align Text": "對齊文字",
      "Align top": "對齊頂部",
      "Align bottom": "對齊底部",
      "Align center": "居中對齊",
      "Number of heading or outline levels to be shown in table of contents": "要在目錄中顯示的標題或大綱等級數。",
      "Show page numbers": "顯示頁碼",
      "Show page numbers in table of contents": "在目錄中顯示頁碼。",
      "Right align page numbers": "右對齊頁碼",
      "Right align page numbers in table of contents": "右對齊目錄中的頁碼。",
      "Use hyperlinks": "使用超連結",
      "Use hyperlinks instead of page numbers": "使用超連結而不是頁碼。",
      "Font": "字型",
      "Font Size": "字型大小",
      "Font color": "字型顏色",
      "Text highlight color": "文字高亮顏色",
      "Clear all formatting": "清除所有格式",
      "Bold Tooltip": "粗體 (Ctrl+B)",
      "Italic Tooltip": "斜體 (Ctrl+I)",
      "Underline Tooltip": "下劃線 (Ctrl+U)",
      "Strikethrough": "刪除線",
      "Superscript Tooltip": "上標 (Ctrl+Shift++)",
      "Subscript Tooltip": "下標 (Ctrl+=)",
      "Align left Tooltip": "左對齊 (Ctrl+L)",
      "Center Tooltip": "居中 (Ctrl+E)",
      "Align right Tooltip": "右對齊 (Ctrl+R)",
      "Justify Tooltip": "對齊 (Ctrl+J)",
      "Decrease indent": "減少縮進",
      "Increase indent": "增加縮排",
      "Line spacing": "行間距",
      "Bullets": "子彈",
      "Numbering": "編號",
      "Styles": "樣式",
      "Manage Styles": "管理樣式",
      "Page": "頁",
      "of": "的",
      "Fit one page": "適合一頁",
      "Spell Check": "拼寫檢查",
      "Spelling": "使用者",
      "Underline errors": "下劃線錯誤",
      "Fit page width": "適合頁面寬度",
      "Update": "更新",
      "Cancel": "取消",
      "Insert": "插入",
      "No Border": "無邊界",
      "Create a new document": "建立一個新文件。",
      "Open a document": "打開文件。",
      "Undo Tooltip": "撤消上一個操作 (Ctrl+Z)。",
      "Redo Tooltip": "重做上次操作 (Ctrl+Y)。",
      "Insert inline picture from a file": "從檔案中插入內聯圖片。",
      "Insert a table into the document": "在文件中插入表格",
      "Create Hyperlink": "在文件中建立連結以快速訪問網頁和檔案 (Ctrl+K)。",
      "Insert a bookmark in a specific place in this document": "在此文件的特定位置插入書籤。",
      "Provide an overview of your document by adding a table of contents": "通過新增目錄提供文件的概述。",
      "Add or edit the header": "新增或編輯標題。",
      "Add or edit the footer": "新增或編輯頁尾。",
      "Open the page setup dialog": "打開頁面設定對話方塊。",
      "Add page numbers": "新增頁碼。",
      "Find Text": "在文件中尋找文字 (Ctrl+F)。",
      "Toggle between the internal clipboard and system clipboard": "在內部剪貼簿和系統剪貼簿之間切換。</br>由於瀏覽器安全策略，拒絕通過指令碼訪問系統剪貼簿。 相反，</br> 1. 您可以啟用內部剪貼簿以在元件內剪下、複製和貼上。</br> 2. 您可以使用鍵盤快速鍵（Ctrl+X、Ctrl+C 和 Ctrl+V）來剪下 , 使用系統剪貼簿複製和貼上。",
      "Current Page Number": "文件中的當前頁碼。 點選或點選以導航特定頁面。",
      "Read only": "唯讀",
      "Protections": "保護措施",
      "Error in establishing connection with web server": "與網路伺服器建立連接時出錯",
      "Single": "單身的",
      "Double": "雙倍的",
      "New comment": "新評論",
      "Comments": "評論",
      "Print layout": "列印佈局",
      "Web layout": "網頁佈局",
      "Text Form": "文字形式",
      "Check Box": "複選框",
      "DropDown": "落下",
      "Update Fields": "更新欄位",
      "Update cross reference fields": "更新交叉引用欄位",
      "Hide properties pane": "隱藏屬性面板",
      "Show properties pane": "顯示屬性面板",
      "Form Fields": "表單欄位",
      "Track Changes": "跟蹤文件中所做的更改",
      "TrackChanges": "跟蹤變化",
      "AllCaps": "全大寫",
      "Change case Tooltip": "改變大小寫",
      "Insert Footnote": "插入腳註",
      "Insert Endnote": "插入尾注",
      "Footnote Tooltip": "插入腳註 (Alt+Ctrl+F)。",
      "Endnote Tooltip": "插入尾注 (Alt+Ctrl+D)。",
      "UPPERCASE": "大寫字母",
      "No color": "沒有顏色",
      "Top margin": "上邊距",
      "Bottom margin": "下邊距",
      "Left margin": "左邊距",
      "Right margin": "右邊距",
      "Normal": "普通的",
      "Heading": "標題",
      "Heading 1": "標題 1",
      "Heading 2": "標題 2",
      "Heading 3": "標題 3",
      "Heading 4": "標題 4",
      "Heading 5": "標題 5",
      "Heading 6": "標題 6",
      "ZoomLevelTooltip": "縮放等級。 點選或點選以打開“縮放”選項。",
      "None": "沒有任何",
      "Borders": "邊框",
      "ShowHiddenMarks Tooltip": "顯示隱藏的字元，如空格、製表符、段落標記和分行符號。(Ctrl + *)",
      "Columns": "列",
      "Column Break": "分欄符",
      "Link to Previous": "上一個連結",
      "Link to PreviousTooltip": "將此部分連結到上一部分的頁首或頁尾。",
      "Alternate Text": "替代文字",
      "The address of this site is not valid. Check the address and try again.": "該網站的地址無效。",
      "OK": "好的",
      "Information": "資訊",
      "Page Breaks": "在頁面底部",
      "Section Breaks": "這片區域被佔領了。",
      "Column": "分享",
      "Next Page": "另一張臉",
      "Continuous": "工作"
    },
    "carousel": {
      "nextSlide": "下一張幻燈片",
      "of": "的",
      "pauseSlideTransition": "暫停幻燈片過渡",
      "playSlideTransition": "播放幻燈片過渡",
      "previousSlide": "上一張幻燈片",
      "slide": "滑動",
      "slideShow": "幻燈片放映"
    },
    "message": {
      "close": "關"
    },
    "image-editor": {
      "0": "0%",
      "1": "1",
      "2": "2",
      "3": "3",
      "4": "4",
      "5": "5",
      "20": "20%",
      "40": "40%",
      "60": "60%",
      "80": "80%",
      "100": "100%",
      "Crop": "莊稼",
      "CropAndTransform": "裁剪和變換",
      "ZoomIn": "放大",
      "ZoomOut": "縮小",
      "Undo": "撤消",
      "Redo": "重做",
      "Transform": "轉換",
      "Annotation": "註解",
      "Finetune": "微調",
      "Brightness": "亮度",
      "Contrast": "對比",
      "Hue": "色調",
      "Saturation": "飽和",
      "Opacity": "不透明度",
      "Blur": "模糊",
      "Sharpen": "銳化",
      "Exposure": "接觸",
      "Filter": "篩選",
      "Default": "默認",
      "Chrome": "鉻合金",
      "Cold": "寒冷的",
      "Warm": "溫暖的",
      "Grayscale": "灰度",
      "BlackAndWhite": "黑與白",
      "Sepia": "棕褐色",
      "Invert": "倒置",
      "Text": "新增文字",
      "Pen": "筆",
      "Reset": "重設",
      "Save": "節省",
      "Select": "選擇",
      "RotateLeft": "向左旋轉",
      "RotateRight": "右旋",
      "HorizontalFlip": "水平翻轉",
      "VerticalFlip": "垂直翻轉",
      "OK": "好的",
      "Cancel": "取消",
      "FillColor": "填色",
      "StrokeColor": "描邊顏色",
      "StrokeWidth": "筆劃寬度",
      "FontFamily": "字型系列",
      "FontStyle": "字型樣式",
      "FontSize": "字型大小",
      "FontColor": "字型顏色",
      "Pan": "平底鍋",
      "Move": "移動",
      "Load": "載入",
      "Custom": "風俗",
      "Square": "正方形",
      "Circle": "圓圈",
      "Ellipse": "橢圓",
      "Rectangle": "長方形",
      "Line": "線",
      "Arrow": "箭",
      "Path": "小路",
      "Bold": "大膽的",
      "Italic": "斜體",
      "BoldItalic": "加粗斜體",
      "XSmall": "X 小號",
      "Small": "小的",
      "Medium": "中等的",
      "Large": "大的",
      "XLarge": "XL 號",
      "ABC": "ABC",
      "Browse": "瀏覽",
      "Duplicate": "複製",
      "Remove": "消除",
      "EditText": "編輯文字",
      "Start": "開始",
      "End": "結尾",
      "Bar": "酒吧",
      "ArrowSolid": "實心箭頭",
      "CircleSolid": "實心圓",
      "SquareSolid": "方實心",
      "None": "沒有任何",
      "CropSelection": "作物選擇",
      "Image": "新增圖片",
      "Transparency": "透明度",
      "Height": "高度",
      "Width": "寬度",
      "AspectRatio": "保持縱橫比",
      "W": "瓦",
      "H": "H",
      "DragText": "將您的圖像拖放到此處或",
      "DropText": "將您的圖片拖放到此處或",
      "BrowseText": "瀏覽此處...",
      "SupportText": "支援：",
      "Frame": "框架",
      "Mat": "墊",
      "Bevel": "斜角",
      "Inset": "插圖",
      "Hook": "鉤",
      "Color": "顏色",
      "Size": "尺寸",
      "Offset": "抵消",
      "Radius": "半徑",
      "Amount": "數量",
      "Resize": "調整大小",
      "Border": "邊界",
      "Solid": "堅硬的",
      "Dashed": "虛線",
      "Dotted": "點狀",
      "GradientColor": "漸變色",
      "ConfirmDialogHeader": "確認保存更改",
      "ConfirmDialogContent": "您想保存對圖像所做的更改嗎？",
      "AlertDialogHeader": "不支援的檔案",
      "AlertDialogContent": "不支援刪除的檔案。",
      "Yes": "是的",
      "No": "不",
      "ImageErrorDialogHeader": "選擇圖像時出錯。",
      "ImageErrorDialogContent": "只需選擇您要打開的圖像即可。",
      "Straighten": "難的",
      "NoOutline": "不需要",
      "Clone": "隱喻",
      "Delete": "破壞"
    },
    "mention": {
      "noRecordsTemplate": "沒有找到記錄"
    },
    "inplaceeditor": {
      "save": "節省",
      "cancel": "取消",
      "loadingText": "載入中...",
      "editIcon": "點選編輯",
      "editAreaClick": "點選編輯",
      "editAreaDoubleClick": "連按兩下即可編輯"
    },
    "PdfViewer": {
      "PdfViewer": "PDF閱讀器",
      "Cancel": "取消",
      "Download file": "下載檔案",
      "Download": "下載",
      "Enter Password": "本文件受密碼保護。",
      "File Corrupted": "檔案損壞",
      "File Corrupted Content": "檔案已損壞且無法打開。",
      "Fit Page": "適合頁面",
      "Fit Width": "適合寬度",
      "Automatic": "自動的",
      "Go To First Page": "顯示第一頁",
      "Invalid Password": "密碼錯誤。",
      "Next Page": "顯示下一頁",
      "OK": "好的",
      "Open": "打開檔案",
      "Page Number": "當前頁碼",
      "Previous Page": "顯示上一頁",
      "Go To Last Page": "顯示最後一頁",
      "Zoom": "飛漲",
      "Zoom In": "放大",
      "Zoom Out": "縮小",
      "Page Thumbnails": "頁面縮圖",
      "Bookmarks": "書籤",
      "Print": "列印檔案",
      "Password Protected": "要求輸入密碼",
      "Copy": "複製",
      "Text Selection": "文字選擇工具",
      "Panning": "平移模式",
      "Text Search": "尋找文字",
      "Find in document": "在文件中尋找",
      "Match case": "相符",
      "Apply": "申請",
      "GoToPage": "轉到頁面",
      "No matches": "查看者已完成文件搜尋。",
      "No Text Found": "未找到文字",
      "Undo": "撤消",
      "Redo": "重做",
      "Annotation": "新增或編輯註釋",
      "Highlight": "突出顯示文字",
      "Underline": "下劃線文字",
      "Strikethrough": "刪除線文字",
      "Delete": "刪除註釋",
      "Opacity": "不透明度",
      "Color edit": "換顏色",
      "Opacity edit": "更改不透明度",
      "Highlight context": "強調",
      "Underline context": "底線",
      "Strikethrough context": "刪除線",
      "Server error": "Web 服務未監聽。 ",
      "Open text": "打開",
      "First text": "第一頁",
      "Previous text": "上一頁",
      "Next text": "下一頁",
      "Last text": "最後一頁",
      "Zoom in text": "放大",
      "Zoom out text": "縮小",
      "Selection text": "選擇",
      "Pan text": "平底鍋",
      "Print text": "列印",
      "Search text": "搜尋",
      "Annotation Edit text": "編輯註釋",
      "Line Thickness": "線的粗細",
      "Line Properties": "線屬性",
      "Start Arrow": "開始箭頭",
      "End Arrow": "結束箭頭",
      "Line Style": "線條樣式",
      "Fill Color": "填色",
      "Line Color": "線條顏色",
      "None": "沒有任何",
      "Open Arrow": "打開",
      "Closed Arrow": "關閉",
      "Round Arrow": "圓形的",
      "Square Arrow": "正方形",
      "Diamond Arrow": "鑽石",
      "Butt": "屁股",
      "Cut": "切",
      "Paste": "貼上",
      "Delete Context": "刪除",
      "Properties": "特性",
      "Add Stamp": "新增圖章",
      "Add Shapes": "新增形狀",
      "Stroke edit": "更改描邊顏色",
      "Change thickness": "更改邊框粗細",
      "Add line": "新增線路",
      "Add arrow": "新增箭頭",
      "Add rectangle": "新增矩形",
      "Add circle": "新增圈子",
      "Add polygon": "新增多邊形",
      "Add Comments": "新增評論",
      "Comments": "評論",
      "No Comments Yet": "暫時沒有評論",
      "Accepted": "公認",
      "Completed": "完全的",
      "Cancelled": "取消",
      "Rejected": "拒絕",
      "Leader Length": "引線長度",
      "Scale Ratio": "比例",
      "Calibrate": "校準",
      "Calibrate Distance": "校準距離",
      "Calibrate Perimeter": "校準周長",
      "Calibrate Area": "校準區域",
      "Calibrate Radius": "校準半徑",
      "Calibrate Volume": "校準音量"
    },
    "stepper": {
      "optional": "選修的"
    }
  }
}
