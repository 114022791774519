import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { Directive, ContentChild, ContentChildren, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { ComplexBase, setValue, Template, ArrayBase, ComponentBase, ComponentMixins } from '@syncfusion/ej2-angular-base';
import { TreeGrid, Filter, Page, Sort, Reorder, Toolbar, Aggregate, Resize, ColumnMenu, ExcelExport, PdfExport, CommandColumn, ContextMenu, Edit, Selection, VirtualScroll, DetailRow, RowDD, Freeze, ColumnChooser, Logger, InfiniteScroll } from '@syncfusion/ej2-treegrid';
const _c0 = ["template"];
const _c1 = ["headerTemplate"];
const _c2 = ["filterItemTemplate"];
const _c3 = ["filterTemplate"];
const _c4 = ["commandsTemplate"];
const _c5 = ["editTemplate"];
const _c6 = ["footerTemplate"];
const _c7 = ["toolbarTemplate"];
const _c8 = ["pagerTemplate"];
const _c9 = ["rowTemplate"];
const _c10 = ["detailTemplate"];
const _c11 = ["editSettingsTemplate"];
export * from '@syncfusion/ej2-treegrid';
import { CommonModule } from '@angular/common';
let input$3 = ['allowEditing', 'allowFiltering', 'allowReordering', 'allowResizing', 'allowSorting', 'clipMode', 'columns', 'commands', 'customAttributes', 'defaultValue', 'disableHtmlEncode', 'displayAsCheckBox', 'edit', 'editTemplate', 'editType', 'field', 'filter', 'filterBarTemplate', 'filterTemplate', 'format', 'formatter', 'freeze', 'headerTemplate', 'headerText', 'headerTextAlign', 'hideAtMedia', 'isFrozen', 'isIdentity', 'isPrimaryKey', 'lockColumn', 'maxWidth', 'minWidth', 'showCheckbox', 'showColumnMenu', 'showInColumnChooser', 'sortComparer', 'template', 'textAlign', 'type', 'uid', 'validationRules', 'valueAccessor', 'visible', 'width'];
let outputs$4 = [];
/**
 * `e-stacked-column` directive represent the stacked column of the Angular TreeGrid.
 * It must be contained in a StackedColumns component(`e-stacked-columns`).
 * ```html
 * <ejs-treegrid [dataSource]='data' allowPaging='true' allowSorting='true'>
 *   <e-columns>
 *     <e-column field='ID' width='100'></e-column>
 *     <e-column headerText='Details' width='100'>
 *       <e-stacked-columns>
 *         <e-stacked-column field='Name' width='140'></e-stacked-column>
 *       </e-stacked-columns>
 *     </e-column>
 *   </e-columns>
 * </ejs-treegrid>
 * ```
 */
class StackedColumnDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$4);
    this.directivePropList = input$3;
  }
}
StackedColumnDirective.ɵfac = function StackedColumnDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || StackedColumnDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
StackedColumnDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: StackedColumnDirective,
  selectors: [["e-stacked-column"]],
  contentQueries: function StackedColumnDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
      i0.ɵɵcontentQuery(dirIndex, _c2, 5);
      i0.ɵɵcontentQuery(dirIndex, _c3, 5);
      i0.ɵɵcontentQuery(dirIndex, _c4, 5);
      i0.ɵɵcontentQuery(dirIndex, _c5, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.filter_itemTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.filterTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.commandsTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.editTemplate = _t.first);
    }
  },
  inputs: {
    allowEditing: "allowEditing",
    allowFiltering: "allowFiltering",
    allowReordering: "allowReordering",
    allowResizing: "allowResizing",
    allowSorting: "allowSorting",
    clipMode: "clipMode",
    columns: "columns",
    commands: "commands",
    customAttributes: "customAttributes",
    defaultValue: "defaultValue",
    disableHtmlEncode: "disableHtmlEncode",
    displayAsCheckBox: "displayAsCheckBox",
    edit: "edit",
    editTemplate: "editTemplate",
    editType: "editType",
    field: "field",
    filter: "filter",
    filterBarTemplate: "filterBarTemplate",
    filterTemplate: "filterTemplate",
    format: "format",
    formatter: "formatter",
    freeze: "freeze",
    headerTemplate: "headerTemplate",
    headerText: "headerText",
    headerTextAlign: "headerTextAlign",
    hideAtMedia: "hideAtMedia",
    isFrozen: "isFrozen",
    isIdentity: "isIdentity",
    isPrimaryKey: "isPrimaryKey",
    lockColumn: "lockColumn",
    maxWidth: "maxWidth",
    minWidth: "minWidth",
    showCheckbox: "showCheckbox",
    showColumnMenu: "showColumnMenu",
    showInColumnChooser: "showInColumnChooser",
    sortComparer: "sortComparer",
    template: "template",
    textAlign: "textAlign",
    type: "type",
    uid: "uid",
    validationRules: "validationRules",
    valueAccessor: "valueAccessor",
    visible: "visible",
    width: "width"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
__decorate([Template()], StackedColumnDirective.prototype, "template", void 0);
__decorate([Template()], StackedColumnDirective.prototype, "headerTemplate", void 0);
__decorate([Template()], StackedColumnDirective.prototype, "filter_itemTemplate", void 0);
__decorate([Template()], StackedColumnDirective.prototype, "filterTemplate", void 0);
__decorate([Template()], StackedColumnDirective.prototype, "commandsTemplate", void 0);
__decorate([Template()], StackedColumnDirective.prototype, "editTemplate", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StackedColumnDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-treegrid>e-columns>e-column>e-stacked-columns>e-stacked-column',
      inputs: input$3,
      outputs: outputs$4,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    template: [{
      type: ContentChild,
      args: ['template']
    }],
    headerTemplate: [{
      type: ContentChild,
      args: ['headerTemplate']
    }],
    filter_itemTemplate: [{
      type: ContentChild,
      args: ['filterItemTemplate']
    }],
    filterTemplate: [{
      type: ContentChild,
      args: ['filterTemplate']
    }],
    commandsTemplate: [{
      type: ContentChild,
      args: ['commandsTemplate']
    }],
    editTemplate: [{
      type: ContentChild,
      args: ['editTemplate']
    }]
  });
})();
/**
 * StackedColumn Array Directive
 * @private
 */
class StackedColumnsDirective extends ArrayBase {
  constructor() {
    super('columns');
  }
}
StackedColumnsDirective.ɵfac = function StackedColumnsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || StackedColumnsDirective)();
};
StackedColumnsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: StackedColumnsDirective,
  selectors: [["e-stacked-columns"]],
  contentQueries: function StackedColumnsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, StackedColumnDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StackedColumnsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-treegrid>e-columns>e-column>e-stacked-columns',
      queries: {
        children: new ContentChildren(StackedColumnDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input$2 = ['allowEditing', 'allowFiltering', 'allowReordering', 'allowResizing', 'allowSorting', 'clipMode', 'columns', 'commands', 'customAttributes', 'defaultValue', 'disableHtmlEncode', 'displayAsCheckBox', 'edit', 'editTemplate', 'editType', 'field', 'filter', 'filterBarTemplate', 'filterTemplate', 'format', 'formatter', 'freeze', 'headerTemplate', 'headerText', 'headerTextAlign', 'hideAtMedia', 'isFrozen', 'isIdentity', 'isPrimaryKey', 'lockColumn', 'maxWidth', 'minWidth', 'showCheckbox', 'showColumnMenu', 'showInColumnChooser', 'sortComparer', 'template', 'textAlign', 'type', 'uid', 'validationRules', 'valueAccessor', 'visible', 'width'];
let outputs$3 = [];
/**
 * `e-column` directive represent a column of the Angular TreeGrid.
 * It must be contained in a TreeGrid component(`ejs-treegrid`).
 * ```html
 * <ejs-treegrid [dataSource]='data' allowPaging='true' allowSorting='true'>
 *   <e-columns>
 *    <e-column field='ID' width='100'></e-column>
 *    <e-column field='name' headerText='Name' width='100'></e-column>
 *   </e-columns>
 * </ejs-treegrid>
 * ```
 */
class ColumnDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    this.tags = ['columns'];
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$3);
    this.directivePropList = input$2;
  }
}
ColumnDirective.ɵfac = function ColumnDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ColumnDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
ColumnDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ColumnDirective,
  selectors: [["e-column"]],
  contentQueries: function ColumnDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
      i0.ɵɵcontentQuery(dirIndex, _c2, 5);
      i0.ɵɵcontentQuery(dirIndex, _c3, 5);
      i0.ɵɵcontentQuery(dirIndex, _c4, 5);
      i0.ɵɵcontentQuery(dirIndex, _c5, 5);
      i0.ɵɵcontentQuery(dirIndex, StackedColumnsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.filter_itemTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.filterTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.commandsTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.editTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childColumns = _t.first);
    }
  },
  inputs: {
    allowEditing: "allowEditing",
    allowFiltering: "allowFiltering",
    allowReordering: "allowReordering",
    allowResizing: "allowResizing",
    allowSorting: "allowSorting",
    clipMode: "clipMode",
    columns: "columns",
    commands: "commands",
    customAttributes: "customAttributes",
    defaultValue: "defaultValue",
    disableHtmlEncode: "disableHtmlEncode",
    displayAsCheckBox: "displayAsCheckBox",
    edit: "edit",
    editTemplate: "editTemplate",
    editType: "editType",
    field: "field",
    filter: "filter",
    filterBarTemplate: "filterBarTemplate",
    filterTemplate: "filterTemplate",
    format: "format",
    formatter: "formatter",
    freeze: "freeze",
    headerTemplate: "headerTemplate",
    headerText: "headerText",
    headerTextAlign: "headerTextAlign",
    hideAtMedia: "hideAtMedia",
    isFrozen: "isFrozen",
    isIdentity: "isIdentity",
    isPrimaryKey: "isPrimaryKey",
    lockColumn: "lockColumn",
    maxWidth: "maxWidth",
    minWidth: "minWidth",
    showCheckbox: "showCheckbox",
    showColumnMenu: "showColumnMenu",
    showInColumnChooser: "showInColumnChooser",
    sortComparer: "sortComparer",
    template: "template",
    textAlign: "textAlign",
    type: "type",
    uid: "uid",
    validationRules: "validationRules",
    valueAccessor: "valueAccessor",
    visible: "visible",
    width: "width"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
__decorate([Template()], ColumnDirective.prototype, "template", void 0);
__decorate([Template()], ColumnDirective.prototype, "headerTemplate", void 0);
__decorate([Template()], ColumnDirective.prototype, "filter_itemTemplate", void 0);
__decorate([Template()], ColumnDirective.prototype, "filterTemplate", void 0);
__decorate([Template()], ColumnDirective.prototype, "commandsTemplate", void 0);
__decorate([Template()], ColumnDirective.prototype, "editTemplate", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColumnDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-treegrid>e-columns>e-column',
      inputs: input$2,
      outputs: outputs$3,
      queries: {
        childColumns: new ContentChild(StackedColumnsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    template: [{
      type: ContentChild,
      args: ['template']
    }],
    headerTemplate: [{
      type: ContentChild,
      args: ['headerTemplate']
    }],
    filter_itemTemplate: [{
      type: ContentChild,
      args: ['filterItemTemplate']
    }],
    filterTemplate: [{
      type: ContentChild,
      args: ['filterTemplate']
    }],
    commandsTemplate: [{
      type: ContentChild,
      args: ['commandsTemplate']
    }],
    editTemplate: [{
      type: ContentChild,
      args: ['editTemplate']
    }]
  });
})();
/**
 * Column Array Directive
 * @private
 */
class ColumnsDirective extends ArrayBase {
  constructor() {
    super('columns');
  }
}
ColumnsDirective.ɵfac = function ColumnsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ColumnsDirective)();
};
ColumnsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ColumnsDirective,
  selectors: [["e-columns"]],
  contentQueries: function ColumnsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ColumnDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColumnsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-treegrid>e-columns',
      queries: {
        children: new ContentChildren(ColumnDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input$1 = ['columnName', 'customAggregate', 'field', 'footerTemplate', 'format', 'type'];
let outputs$2 = [];
/**
 * `e-aggregate->e-column` directive represent a aggregate column of the Angular TreeGrid.
 * ```html
 * <ejs-treegrid [dataSource]='data' allowPaging='true' allowSorting='true'>
 *   <e-columns>
 *     <e-column field='ID' width='100'></e-column>
 *     <e-column field='name' headerText='Name' width='100'></e-column>
 *   </e-columns>
 *   <e-aggregates>
 *     <e-aggregate>
 *       <e-columns>
 *         <e-column field='ID' type='Min'></e-column>
 *       </e-columns>
 *      </e-aggregate>
 *    </e-aggregates>
 * </ejs-treegrid>
 * ```
 */
class AggregateColumnDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$2);
    this.directivePropList = input$1;
  }
}
AggregateColumnDirective.ɵfac = function AggregateColumnDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AggregateColumnDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
AggregateColumnDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AggregateColumnDirective,
  selectors: [["e-column"]],
  contentQueries: function AggregateColumnDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c6, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.footerTemplate = _t.first);
    }
  },
  inputs: {
    columnName: "columnName",
    customAggregate: "customAggregate",
    field: "field",
    footerTemplate: "footerTemplate",
    format: "format",
    type: "type"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
__decorate([Template()], AggregateColumnDirective.prototype, "footerTemplate", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AggregateColumnDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-treegrid>e-aggregates>e-aggregate>e-columns>e-column',
      inputs: input$1,
      outputs: outputs$2,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    footerTemplate: [{
      type: ContentChild,
      args: ['footerTemplate']
    }]
  });
})();
/**
 * AggregateColumn Array Directive
 * @private
 */
class AggregateColumnsDirective extends ArrayBase {
  constructor() {
    super('columns');
  }
}
AggregateColumnsDirective.ɵfac = function AggregateColumnsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AggregateColumnsDirective)();
};
AggregateColumnsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AggregateColumnsDirective,
  selectors: [["e-columns"]],
  contentQueries: function AggregateColumnsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, AggregateColumnDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AggregateColumnsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-treegrid>e-aggregates>e-aggregate>e-columns',
      queries: {
        children: new ContentChildren(AggregateColumnDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input = ['columns', 'showChildSummary'];
let outputs$1 = [];
/**
 * `e-aggregate` directive represent a aggregate row of the Angular TreeGrid.
 * It must be contained in a TreeGrid component(`ejs-treegrid`).
 * ```html
 * <ejs-treegrid [dataSource]='data' allowPaging='true' allowSorting='true'>
 *   <e-columns>
 *     <e-column field='ID' width='100'></e-column>
 *     <e-column field='name' headerText='Name' width='100'></e-column>
 *   </e-columns>
 *   <e-aggregates>
 *     <e-aggregate>
 *       <e-columns>
 *         <e-column field='ID' type='Min'></e-column>
 *       </e-columns>
 *      </e-aggregate>
 *    </e-aggregates>
 * </ejs-treegrid>
 * ```
 */
class AggregateDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    this.tags = ['columns'];
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$1);
    this.directivePropList = input;
  }
}
AggregateDirective.ɵfac = function AggregateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AggregateDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
AggregateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AggregateDirective,
  selectors: [["e-aggregate"]],
  contentQueries: function AggregateDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, AggregateColumnsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childColumns = _t.first);
    }
  },
  inputs: {
    columns: "columns",
    showChildSummary: "showChildSummary"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AggregateDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-treegrid>e-aggregates>e-aggregate',
      inputs: input,
      outputs: outputs$1,
      queries: {
        childColumns: new ContentChild(AggregateColumnsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * Aggregate Array Directive
 * @private
 */
class AggregatesDirective extends ArrayBase {
  constructor() {
    super('aggregates');
  }
}
AggregatesDirective.ɵfac = function AggregatesDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AggregatesDirective)();
};
AggregatesDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AggregatesDirective,
  selectors: [["e-aggregates"]],
  contentQueries: function AggregatesDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, AggregateDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AggregatesDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-treegrid>e-aggregates',
      queries: {
        children: new ContentChildren(AggregateDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs = ['aggregates', 'allowExcelExport', 'allowFiltering', 'allowMultiSorting', 'allowPaging', 'allowPdfExport', 'allowReordering', 'allowResizing', 'allowRowDragAndDrop', 'allowSelection', 'allowSorting', 'allowTextWrap', 'autoCheckHierarchy', 'childMapping', 'clipMode', 'columnMenuItems', 'columnQueryMode', 'columns', 'contextMenuItems', 'copyHierarchyMode', 'currencyCode', 'dataSource', 'detailTemplate', 'editSettings', 'enableAdaptiveUI', 'enableAltRow', 'enableAutoFill', 'enableCollapseAll', 'enableColumnVirtualization', 'enableHover', 'enableHtmlSanitizer', 'enableImmutableMode', 'enableInfiniteScrolling', 'enablePersistence', 'enableRtl', 'enableVirtualMaskRow', 'enableVirtualization', 'expandStateMapping', 'filterSettings', 'frozenColumns', 'frozenRows', 'gridLines', 'hasChildMapping', 'height', 'idMapping', 'infiniteScrollSettings', 'loadChildOnDemand', 'loadingIndicator', 'locale', 'pageSettings', 'parentIdMapping', 'printMode', 'query', 'rowDropSettings', 'rowHeight', 'rowTemplate', 'searchSettings', 'selectedRowIndex', 'selectionSettings', 'showColumnChooser', 'showColumnMenu', 'sortSettings', 'textWrapSettings', 'toolbar', 'treeColumnIndex', 'width'];
const outputs = ['actionBegin', 'actionComplete', 'actionFailure', 'batchAdd', 'batchCancel', 'batchDelete', 'beforeBatchAdd', 'beforeBatchDelete', 'beforeBatchSave', 'beforeCopy', 'beforeDataBound', 'beforeExcelExport', 'beforePaste', 'beforePdfExport', 'beforePrint', 'beginEdit', 'cellDeselected', 'cellDeselecting', 'cellEdit', 'cellSave', 'cellSaved', 'cellSelected', 'cellSelecting', 'checkboxChange', 'collapsed', 'collapsing', 'columnDrag', 'columnDragStart', 'columnDrop', 'columnMenuClick', 'columnMenuOpen', 'contextMenuClick', 'contextMenuOpen', 'created', 'dataBound', 'dataSourceChanged', 'dataStateChange', 'detailDataBound', 'excelExportComplete', 'excelHeaderQueryCellInfo', 'excelQueryCellInfo', 'expanded', 'expanding', 'headerCellInfo', 'load', 'pdfExportComplete', 'pdfHeaderQueryCellInfo', 'pdfQueryCellInfo', 'printComplete', 'queryCellInfo', 'recordDoubleClick', 'resizeStart', 'resizeStop', 'resizing', 'rowDataBound', 'rowDeselected', 'rowDeselecting', 'rowDrag', 'rowDragStart', 'rowDragStartHelper', 'rowDrop', 'rowSelected', 'rowSelecting', 'toolbarClick', 'dataSourceChange'];
const twoWays = ['dataSource'];
/**
 * `ejs-treegrid` represents the Angular TreeTreeGrid Component.
 * ```html
 * <ejs-treegrid [dataSource]='data' allowPaging='true' allowSorting='true'></ejs-treegrid>
 * ```
 */
let TreeGridComponent = class TreeGridComponent extends TreeGrid {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['columns', 'aggregates'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    try {
      let mod = this.injector.get('TreeGridFilter');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('TreeGridPage');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('TreeGridSort');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('TreeGridReorder');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('TreeGridToolbar');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('TreeGridAggregate');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('TreeGridResize');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('TreeGridColumnMenu');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('TreeGridExcelExport');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('TreeGridPdfExport');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('TreeGridCommandColumn');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('TreeGridContextMenu');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('TreeGridEdit');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('TreeGridSelection');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('TreeGridVirtualScroll');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('TreeGridDetailRow');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('TreeGridRowDD');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('TreeGridFreeze');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('TreeGridColumnChooser');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('TreeGridLogger');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('TreeGridInfiniteScroll');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    this.registerEvents(outputs);
    this.addTwoWay.call(this, twoWays);
    setValue('currentInstance', this, this.viewContainerRef);
    this.context = new ComponentBase();
  }
  ngOnInit() {
    this.context.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.context.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.context.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childColumns;
    if (this.childAggregates) {
      this.tagObjects[1].instance = this.childAggregates;
    }
    this.context.ngAfterContentChecked(this);
  }
};
TreeGridComponent.ɵfac = function TreeGridComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TreeGridComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
TreeGridComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TreeGridComponent,
  selectors: [["ejs-treegrid"]],
  contentQueries: function TreeGridComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c7, 5);
      i0.ɵɵcontentQuery(dirIndex, _c8, 5);
      i0.ɵɵcontentQuery(dirIndex, _c9, 5);
      i0.ɵɵcontentQuery(dirIndex, _c10, 5);
      i0.ɵɵcontentQuery(dirIndex, _c11, 5);
      i0.ɵɵcontentQuery(dirIndex, ColumnsDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, AggregatesDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.toolbarTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.pagerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.rowTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.detailTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.editSettings_template = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childColumns = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childAggregates = _t.first);
    }
  },
  inputs: {
    aggregates: "aggregates",
    allowExcelExport: "allowExcelExport",
    allowFiltering: "allowFiltering",
    allowMultiSorting: "allowMultiSorting",
    allowPaging: "allowPaging",
    allowPdfExport: "allowPdfExport",
    allowReordering: "allowReordering",
    allowResizing: "allowResizing",
    allowRowDragAndDrop: "allowRowDragAndDrop",
    allowSelection: "allowSelection",
    allowSorting: "allowSorting",
    allowTextWrap: "allowTextWrap",
    autoCheckHierarchy: "autoCheckHierarchy",
    childMapping: "childMapping",
    clipMode: "clipMode",
    columnMenuItems: "columnMenuItems",
    columnQueryMode: "columnQueryMode",
    columns: "columns",
    contextMenuItems: "contextMenuItems",
    copyHierarchyMode: "copyHierarchyMode",
    currencyCode: "currencyCode",
    dataSource: "dataSource",
    detailTemplate: "detailTemplate",
    editSettings: "editSettings",
    enableAdaptiveUI: "enableAdaptiveUI",
    enableAltRow: "enableAltRow",
    enableAutoFill: "enableAutoFill",
    enableCollapseAll: "enableCollapseAll",
    enableColumnVirtualization: "enableColumnVirtualization",
    enableHover: "enableHover",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enableImmutableMode: "enableImmutableMode",
    enableInfiniteScrolling: "enableInfiniteScrolling",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enableVirtualMaskRow: "enableVirtualMaskRow",
    enableVirtualization: "enableVirtualization",
    expandStateMapping: "expandStateMapping",
    filterSettings: "filterSettings",
    frozenColumns: "frozenColumns",
    frozenRows: "frozenRows",
    gridLines: "gridLines",
    hasChildMapping: "hasChildMapping",
    height: "height",
    idMapping: "idMapping",
    infiniteScrollSettings: "infiniteScrollSettings",
    loadChildOnDemand: "loadChildOnDemand",
    loadingIndicator: "loadingIndicator",
    locale: "locale",
    pageSettings: "pageSettings",
    parentIdMapping: "parentIdMapping",
    printMode: "printMode",
    query: "query",
    rowDropSettings: "rowDropSettings",
    rowHeight: "rowHeight",
    rowTemplate: "rowTemplate",
    searchSettings: "searchSettings",
    selectedRowIndex: "selectedRowIndex",
    selectionSettings: "selectionSettings",
    showColumnChooser: "showColumnChooser",
    showColumnMenu: "showColumnMenu",
    sortSettings: "sortSettings",
    textWrapSettings: "textWrapSettings",
    toolbar: "toolbar",
    treeColumnIndex: "treeColumnIndex",
    width: "width"
  },
  outputs: {
    actionBegin: "actionBegin",
    actionComplete: "actionComplete",
    actionFailure: "actionFailure",
    batchAdd: "batchAdd",
    batchCancel: "batchCancel",
    batchDelete: "batchDelete",
    beforeBatchAdd: "beforeBatchAdd",
    beforeBatchDelete: "beforeBatchDelete",
    beforeBatchSave: "beforeBatchSave",
    beforeCopy: "beforeCopy",
    beforeDataBound: "beforeDataBound",
    beforeExcelExport: "beforeExcelExport",
    beforePaste: "beforePaste",
    beforePdfExport: "beforePdfExport",
    beforePrint: "beforePrint",
    beginEdit: "beginEdit",
    cellDeselected: "cellDeselected",
    cellDeselecting: "cellDeselecting",
    cellEdit: "cellEdit",
    cellSave: "cellSave",
    cellSaved: "cellSaved",
    cellSelected: "cellSelected",
    cellSelecting: "cellSelecting",
    checkboxChange: "checkboxChange",
    collapsed: "collapsed",
    collapsing: "collapsing",
    columnDrag: "columnDrag",
    columnDragStart: "columnDragStart",
    columnDrop: "columnDrop",
    columnMenuClick: "columnMenuClick",
    columnMenuOpen: "columnMenuOpen",
    contextMenuClick: "contextMenuClick",
    contextMenuOpen: "contextMenuOpen",
    created: "created",
    dataBound: "dataBound",
    dataSourceChanged: "dataSourceChanged",
    dataStateChange: "dataStateChange",
    detailDataBound: "detailDataBound",
    excelExportComplete: "excelExportComplete",
    excelHeaderQueryCellInfo: "excelHeaderQueryCellInfo",
    excelQueryCellInfo: "excelQueryCellInfo",
    expanded: "expanded",
    expanding: "expanding",
    headerCellInfo: "headerCellInfo",
    load: "load",
    pdfExportComplete: "pdfExportComplete",
    pdfHeaderQueryCellInfo: "pdfHeaderQueryCellInfo",
    pdfQueryCellInfo: "pdfQueryCellInfo",
    printComplete: "printComplete",
    queryCellInfo: "queryCellInfo",
    recordDoubleClick: "recordDoubleClick",
    resizeStart: "resizeStart",
    resizeStop: "resizeStop",
    resizing: "resizing",
    rowDataBound: "rowDataBound",
    rowDeselected: "rowDeselected",
    rowDeselecting: "rowDeselecting",
    rowDrag: "rowDrag",
    rowDragStart: "rowDragStart",
    rowDragStartHelper: "rowDragStartHelper",
    rowDrop: "rowDrop",
    rowSelected: "rowSelected",
    rowSelecting: "rowSelecting",
    toolbarClick: "toolbarClick",
    dataSourceChange: "dataSourceChange"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function TreeGridComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], TreeGridComponent.prototype, "toolbarTemplate", void 0);
__decorate([Template()], TreeGridComponent.prototype, "pagerTemplate", void 0);
__decorate([Template()], TreeGridComponent.prototype, "rowTemplate", void 0);
__decorate([Template()], TreeGridComponent.prototype, "detailTemplate", void 0);
__decorate([Template()], TreeGridComponent.prototype, "editSettings_template", void 0);
TreeGridComponent = __decorate([ComponentMixins([ComponentBase])], TreeGridComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeGridComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-treegrid',
      inputs: inputs,
      outputs: outputs,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childColumns: new ContentChild(ColumnsDirective),
        childAggregates: new ContentChild(AggregatesDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, {
    toolbarTemplate: [{
      type: ContentChild,
      args: ['toolbarTemplate']
    }],
    pagerTemplate: [{
      type: ContentChild,
      args: ['pagerTemplate']
    }],
    rowTemplate: [{
      type: ContentChild,
      args: ['rowTemplate']
    }],
    detailTemplate: [{
      type: ContentChild,
      args: ['detailTemplate']
    }],
    editSettings_template: [{
      type: ContentChild,
      args: ['editSettingsTemplate']
    }]
  });
})();

/**
 * NgModule definition for the TreeGrid component.
 */
class TreeGridModule {}
TreeGridModule.ɵfac = function TreeGridModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TreeGridModule)();
};
TreeGridModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TreeGridModule
});
TreeGridModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeGridModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [TreeGridComponent, StackedColumnDirective, StackedColumnsDirective, ColumnDirective, ColumnsDirective, AggregateColumnDirective, AggregateColumnsDirective, AggregateDirective, AggregatesDirective],
      exports: [TreeGridComponent, StackedColumnDirective, StackedColumnsDirective, ColumnDirective, ColumnsDirective, AggregateColumnDirective, AggregateColumnsDirective, AggregateDirective, AggregatesDirective]
    }]
  }], null, null);
})();
const FilterService = {
  provide: 'TreeGridFilter',
  useValue: Filter
};
const PageService = {
  provide: 'TreeGridPage',
  useValue: Page
};
const SortService = {
  provide: 'TreeGridSort',
  useValue: Sort
};
const ReorderService = {
  provide: 'TreeGridReorder',
  useValue: Reorder
};
const ToolbarService = {
  provide: 'TreeGridToolbar',
  useValue: Toolbar
};
const AggregateService = {
  provide: 'TreeGridAggregate',
  useValue: Aggregate
};
const ResizeService = {
  provide: 'TreeGridResize',
  useValue: Resize
};
const ColumnMenuService = {
  provide: 'TreeGridColumnMenu',
  useValue: ColumnMenu
};
const ExcelExportService = {
  provide: 'TreeGridExcelExport',
  useValue: ExcelExport
};
const PdfExportService = {
  provide: 'TreeGridPdfExport',
  useValue: PdfExport
};
const CommandColumnService = {
  provide: 'TreeGridCommandColumn',
  useValue: CommandColumn
};
const ContextMenuService = {
  provide: 'TreeGridContextMenu',
  useValue: ContextMenu
};
const EditService = {
  provide: 'TreeGridEdit',
  useValue: Edit
};
const SelectionService = {
  provide: 'TreeGridSelection',
  useValue: Selection
};
const VirtualScrollService = {
  provide: 'TreeGridVirtualScroll',
  useValue: VirtualScroll
};
const DetailRowService = {
  provide: 'TreeGridDetailRow',
  useValue: DetailRow
};
const RowDDService = {
  provide: 'TreeGridRowDD',
  useValue: RowDD
};
const FreezeService = {
  provide: 'TreeGridFreeze',
  useValue: Freeze
};
const ColumnChooserService = {
  provide: 'TreeGridColumnChooser',
  useValue: ColumnChooser
};
const LoggerService = {
  provide: 'TreeGridLogger',
  useValue: Logger
};
const InfiniteScrollService = {
  provide: 'TreeGridInfiniteScroll',
  useValue: InfiniteScroll
};
/**
 * NgModule definition for the TreeGrid component with providers.
 */
class TreeGridAllModule {}
TreeGridAllModule.ɵfac = function TreeGridAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TreeGridAllModule)();
};
TreeGridAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TreeGridAllModule
});
TreeGridAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [FilterService, PageService, SortService, ReorderService, ToolbarService, AggregateService, ResizeService, ColumnMenuService, ExcelExportService, PdfExportService, CommandColumnService, ContextMenuService, EditService, SelectionService, VirtualScrollService, DetailRowService, RowDDService, FreezeService, ColumnChooserService, LoggerService, InfiniteScrollService],
  imports: [[CommonModule, TreeGridModule], TreeGridModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TreeGridAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TreeGridModule],
      exports: [TreeGridModule],
      providers: [FilterService, PageService, SortService, ReorderService, ToolbarService, AggregateService, ResizeService, ColumnMenuService, ExcelExportService, PdfExportService, CommandColumnService, ContextMenuService, EditService, SelectionService, VirtualScrollService, DetailRowService, RowDDService, FreezeService, ColumnChooserService, LoggerService, InfiniteScrollService]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AggregateColumnDirective, AggregateColumnsDirective, AggregateDirective, AggregateService, AggregatesDirective, ColumnChooserService, ColumnDirective, ColumnMenuService, ColumnsDirective, CommandColumnService, ContextMenuService, DetailRowService, EditService, ExcelExportService, FilterService, FreezeService, InfiniteScrollService, LoggerService, PageService, PdfExportService, ReorderService, ResizeService, RowDDService, SelectionService, SortService, StackedColumnDirective, StackedColumnsDirective, ToolbarService, TreeGridAllModule, TreeGridComponent, TreeGridModule, VirtualScrollService };
