import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";
import {Router} from "@angular/router";

const AUTH_API = `${environment.apiUrl}/${environment.projectCode}/${environment.apiVersion}`;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,
              private router: Router) {
  }

  // 登入
  login(body: any): Observable<any> {
    const url = `${AUTH_API}/login`;
    return this.http.post(url, body);
  }

  // 登出
  logout(): void {
    window.sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  register(body: any): Observable<any> {
    const url = `${AUTH_API}/register`;
    return this.http.post(url, body);
  }

  refreshToken(token: string) {
    const url = `${AUTH_API}/refresh`;
    return this.http.post(url, {refresh_token: token});
  }

}
